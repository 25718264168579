.spark-homework-upload {
  &__wrapper {
    padding-top: 7rem;
  }
  margin-bottom: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__header {
    margin: 4rem 0;
    font-size: 5rem;
    text-align: center;
    font-weight: 800;
    padding: 0 2rem;
    span {
      font-size: 3rem;
      font-weight: 500;
    }
    @include bpm-sm {
      font-size: 3.5rem;

      span {
        font-size: 2.2rem;
      }
    }
  }
  &__profile {
    width: 100%;
    padding: 0 7rem;
    margin-bottom: 2rem;
    &--name {
      font-size: 2rem;
      font-weight: 900;
      text-align: left;
      margin-bottom: 1rem;
    }
    @include bpm-sm {
      padding: 0 2rem;
    }
  }
  &__homeworks {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 1rem 0;
    border-bottom: 1px solid #aaa;
    * {
      font-size: 1.6rem;
      @include bpm-sm {
        font-size: 1.4rem;
      }
    }
    .homework-details-link {
      text-decoration: underline;
      color: #f3705b;
      font-weight: 700;
    }
    .upload {
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
      font-size: 1.2rem;
      padding: 0.3rem 0.7rem;
      border-radius: 5rem;
      background-color: #fcb444;
      text-align: center;
      width: 10rem;
      font-size: 1.4rem;
      cursor: pointer;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      @include bpm-sm {
        font-size: 1.2rem;
      }
      &.view {
        background-color: #90d4a3;
      }
    }
  }
  &__table-header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    padding: 1rem 0;
    border-bottom: 2px solid #aaa;
    span {
      font-size: 1.6rem;
      font-weight: 700;
      @include bpm-sm {
        font-size: 1.4rem;
      }
    }
  }
}
