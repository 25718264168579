.spark-ted-page {
  &__wrapper {
    padding-bottom: 7rem;
    .homepage-callback__wrapper {
      margin-top: -30rem;
      @include bpm-md {
        margin-top: -10rem;
      }
    }
  }
}
