.spelling-bee-certificate-download {
  &_wrapper {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    max-width: 992px;
    padding: 2rem 4rem;
    margin: 4rem auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    @include bpm-md {
      margin: 2rem 2rem;
    }
    @include bpm-sm {
      flex-direction: column;
      padding: 2rem 1rem;
      align-items: center;
    }
  }
  &-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 0rem;
    align-items: center;
    @include bpm-xs {
      padding: 0rem;
    }
    &-text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 60px;
      color: #333333;
      @include bpm-xs {
        font-size: 24px;
        line-height: 36px;
        padding: 0rem 1rem;
      }
    }
    &-subtext {
      color: #666;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @include bpm-xs {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
    &--ptext {
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      background: linear-gradient(142deg, #ec7f57 0%, #f2b65a 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &-right {
    &-certificate-preview {
      &_wrapper {
        width: 340px;
        height: 240px;
        border-radius: 16px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        position: relative;
        @include bpm-xs {
          width: 100%;
          height: 222px;
        }
      }
      &-image {
        width: 100%;
        height: 100%;
        border-radius: 16px;
      }
      &-overlay {
        position: absolute;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          #333333 100%
        );
        width: 340px;
        height: 240px;
        z-index: 100;
        top: 0px;
        border-radius: 16px;
        @include bpm-xs {
          width: 318px;
          height: 222px;
        }
      }
      &-childName {
        font-family: Lato;
        font-size: 14px;
        font-weight: 900;
        line-height: 39px;
        letter-spacing: 0em;
        position: absolute;
        top: 95px;
        left: 15px;
        @include bpm-xs {
          top: 85px;
          left: 15px;
        }
        color: #333333;
        z-index: 40;
      }
      &-childGrade {
        font-family: Lato;
        font-size: 11px;
        font-weight: 900;
        line-height: 39px;
        letter-spacing: 0em;
        position: absolute;
        top: 115px;
        left: 55px;
        color: #333333;
        z-index: 40;
        @include bpm-xs {
          top: 105px;
          left: 50px;
        }
      }
      &-download {
        color: #fff;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        position: absolute;
        bottom: 0;
        width: 100%;
        background: #333;
        height: 47px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        cursor: pointer;
      }
    }
    @include bpm-xs {
      margin: 2rem auto;
    }
  }
  &-buttonWrapper {
    width: 324px;
    @include bpm-xs {
      display: none;
    }
    &-mobile {
      width: "100%";
      display: none;
      @include bpm-xs {
        display: block;
      }
    }
  }
}
