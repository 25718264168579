.single-course-details {
  &__wrapper {
    // height: 60vh;
    padding: 2rem 0 5rem 0;
  }
  @include bpm-xs {
    display: flex;
    justify-content: center;
  }
  .course-details-slide {
    // height: 41rem;
    margin: 0 7rem 0 7rem;
    display: grid;
    grid-template-columns: 4fr 6fr;
    align-items: center;
    border-radius: 3rem;
    border: 5px solid #ffffff;
    overflow: hidden;
    padding: 2rem;
    max-width: 100%;
    @include bpm-md {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @include bpm-xs {
      max-width: 360px;
      // max-height: 60rem !important;
      margin: 0 1rem;
    }
    &__left {
      img {
        width: 100%;
        border-radius: 2rem;
        // height: 80%;
      }
    }
    &__right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 5rem;
      // padding-top: 3rem;
      @include bpm-custom(1300px) {
        padding: 2rem 4rem;
      }
      @include bpm-sm {
        padding: 2rem 0 0 0;
      }
      .name {
        font-size: 3rem;
        margin-bottom: 3rem;
        @include bpm-custom(1300px) {
          font-size: 2.5rem;
          margin-bottom: 2rem;
        }
        @include bpm-xs {
          font-size: 2rem;
          font-weight: 900;
        }
      }
      h2 {
        font-size: 2rem;
        font-weight: 300;
        margin-bottom: 1rem;
        span {
          font-weight: 500;
          .price {
            font-weight: 900;
          }
        }
        @include bpm-custom(1300px) {
          font-size: 1.8rem;
          margin-bottom: 0.8rem;
          text-align: left;
        }
        @include bpm-xs {
          font-size: 1.4rem;
          width: max-content;
        }
      }
      .batch-details {
        display: flex;
        align-items: flex-start;
        margin-top: 2rem;
        img {
          width: 2rem;
          margin-right: 0.5rem;
        }
        .batches {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .single-batch {
            font-size: 1.6rem;
            .date {
              color: #f3705b;
              font-weight: 700;
              display: flex;
              align-items: center;
              p {
                display: flex;
                align-items: center;
              }
              @include bpm-sm {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              }
            }
            .time {
              font-weight: 700;
              color: #333;
              padding-left: 0.5rem;
              @include bpm-sm {
                padding: 0.5rem 0 0 0;
              }
            }

            @include bpm-xs {
              font-size: 1.4rem;
              margin-left: 1rem;
              display: flex;
              flex-direction: column;
              .date {
                margin: 0;
              }
            }
          }
        }
      }
      .primary-button {
        margin-top: 3rem;
        @include bpm-custom(1300px) {
          margin-top: 2rem;
        }
        @include bpm-xs {
          align-self: center;
        }
      }
    }
  }
  .course-details-filter-swiper {
    position: relative;
    padding-top: 12rem;
    .swiper-wrapper {
      @include bpm-sm {
        margin-top: 4rem;
      }
      @include bpm-xs {
        margin-top: 6rem;
      }
    }
    .swiper-pagination {
      position: absolute;
      top: 3rem;
      margin-bottom: 18rem !important;
      &-bullet {
        width: 25rem !important;
        height: 5rem !important;
        background-color: #ffffff77 !important;
        font-size: 1.4rem !important;
        font-weight: 800 !important;
        padding-top: 1.4rem;
        border-radius: 0.8rem !important;
        border: 3px solid #fff !important;
        @include bpm-xs {
          border-radius: 10rem !important;
          width: 20rem !important;
        }
      }
      &-bullet-active {
        border: 3px solid #fcb444 !important;
      }
    }
  }
}
