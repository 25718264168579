.gsvStrip {
  &__wrapper {
    width: 100%;
    position: fixed;
    z-index: 10000;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 25px;
    margin: -1px;
    @include bpm-sm {
      padding: 0px 10px;
      height: 54px;
    }
    .gsv-close-icon {
      width: 14px;
      height: 16px;
      cursor: pointer;
    }
  }

  &--left {
    display: flex;
    align-items: center;

    .gsvStrip-cup-icon {
      width: 40px;
    }
    h2 {
      // margin-left: 15px;
      margin-left: 10px;
      margin-right: 10px;
      @include bpm-sm {
        margin-left: 10px;
      }
      @include bpm-xs {
        margin-left: 5px;
      }
      color: white;
      font-family: Lato;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.05em;
      @include bpm-sm {
        font-size: 13px;
      }
      @include bpm-xs {
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.05em;
      }
    }
    .gsvStrip-sparkstudio-span {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 110%;
        width: 100%;
        left: 0;
        height: 1px;
        border-radius: 2px;
        background: linear-gradient(
          -45deg,
          #f3705b 0%,
          #fcb444 48%,
          #fcb444 52%,
          #f3705b 100%
        );
      }
      background-image: linear-gradient(
        -45deg,
        #f3705b 0%,
        #fcb444 48%,
        #fcb444 52%,
        #f3705b 100%
      );
      background-size: 100%;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;

      // text-decoration-line: underline;
      // text-underline-position: under;
      // text-decoration-color: red;
    }
  }
  &--right {
    margin-left: 10px;
    display: flex;
    align-items: center;

    &-text {
      color: white;
      font-family: Lato;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.05em;
      margin-right: 28px;

      cursor: pointer;
      @include bpm-sm {
        font-size: 13px;
        margin-right: 8px;
      }
      @include bpm-xs {
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.05em;
      }
    }
  }
}
