.homepage-experts-section {
  @include bp-md {
    &__wrapper {
      width: 100%;
      padding: 0rem 0 3rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
    .swiper-container {
      width: 100vw !important;
      padding-bottom: 5rem !important;
    }
    .swiper-slide {
      width: 25rem !important;
    }

    .swiper-pagination {
      margin-bottom: 0 !important;
    }
  }
  &__responsive {
    .swiper-slide {
      margin-bottom: 1rem;
      transition: all 0.2s linear;
      width: 25rem !important;
    }
    .swiper-container {
      width: 100vw !important;
      padding-bottom: 5rem !important;
    }

    .swiper-button-next {
      transform: translate(7rem, -5rem);
      outline: none;
      border: none;
      background-color: rgba(255, 255, 255, 0.9);
      height: 10rem;
      width: 10rem;
      border-radius: 10rem;
      left: auto;
      right: 10px;
      position: absolute;
      &::after {
        content: "";
        background: url("../../../public/assets/nextArrow.svg") center
          center/contain no-repeat;
        background-size: 2rem;
        transform: translateX(-2.3rem);
      }
    }
    .swiper-button-prev {
      transform: translate(-7rem, -5rem);
      outline: none;
      border: none;
      background-color: rgba(255, 255, 255, 0.9);
      height: 10rem;
      width: 10rem;
      left: 10px;
      right: auto;
      border-radius: 10rem;
      position: absolute;
      &::after {
        content: "";
        background: url("../../../public/assets/nextArrow.svg") center
          center/contain no-repeat;
        background-size: 2rem;
        transform: rotate(180deg) translateX(-2.3rem);
      }
    }
    .swiper-button-disabled {
      opacity: 0 !important;
    }

    .swiper-pagination {
      margin-bottom: 0 !important;
    }
    @include bpm-md {
      .swiper-slide-active {
        transition: all 0.2s linear;
        transform: scale(1.1);
      }
    }
  }

  .swiper-button-next {
    transform: translate(7rem, -5rem);
    outline: none;
    border: none;
    background-color: rgba(255, 255, 255, 0.9);
    height: 10rem;
    width: 10rem;
    border-radius: 10rem;
    left: auto;
    right: 24px;
    position: absolute;
    &::after {
      content: "";
      background: url("../../../public/assets/nextArrow.svg") center
        center/contain no-repeat;
      background-size: 2rem;
      transform: translateX(-2.3rem);
    }
  }
  .swiper-button-prev {
    transform: translate(-7rem, -5rem);
    outline: none;
    border: none;
    background-color: rgba(255, 255, 255, 0.9);
    height: 10rem;
    width: 10rem;
    left: 24px;
    right: auto;
    border-radius: 10rem;
    position: absolute;
    &::after {
      content: "";
      background: url("../../../public/assets/nextArrow.svg") center
        center/contain no-repeat;
      background-size: 2rem;
      transform: rotate(180deg) translateX(-2.3rem);
    }
  }
  .swiper-button-disabled {
    opacity: 0 !important;
  }
}
