.completedAssignmentCard {
  &_wrapper {
  }

  border-radius: 16px;
  box-shadow: 50px 60px 100px rgba(0, 0, 0, 0.05);

  &-top {
    display: flex;
    &--image-container {
      flex: 0.5;
      height: 14rem;
      position: relative;
      @include bpm-sm {
        height: 7rem;
      }
      &_overlay {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(51, 51, 51, 0.5);
      }
      &_view-upload-btn,
      .view-assignment-btn {
        position: absolute;
        bottom: 16px;
        right: 24px;
        @include bpm-xs {
          right: 5px;
          bottom: 8px;
        }
      }
      img {
        height: 100%;
        width: 100%;
        position: relative;
        border-radius: 16px;
      }
      .primary-button {
        padding: 12px 24px;
        font-size: 18px;
        font-weight: 400;
        background: linear-gradient(90deg, #e47762 0%, #f2b65a 98.07%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }
  &-bottom {
    padding: 16px 24px 24px 40px;
    display: flex;
    justify-content: space-between;
    border-radius: 0 0 0 16px;
    gap: 25px;
    @include bpm-sm {
      padding: 16px;
    }
    &--left {
      &--courseName {
        color: #333333;
        font-weight: 900;
        font-size: 32px;
        @include bpm-sm {
          font-size: 14px;
        }
      }
      &--session {
        margin-top: 8px;
        color: #666666;
        font-weight: 400;
        font-size: 24px;
        @include bpm-sm {
          font-size: 11px;
        }
      }
      &--dueText {
        margin-top: 16px;
        color: #e47762;
        font-size: 20px;
        font-weight: 500;
        font-style: italic;
        @include bpm-sm {
          font-size: 11px;
          margin-top: 0;
          text-align: right;
        }
      }
    }
    &--right {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      @include bpm-sm {
        row-gap: 8px;
      }
      &_ratings {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        padding: 12px 12px 12px 16px;
        border-radius: 8px;
        column-gap: 16px;
        p {
          font-weight: 400;
          font-size: 17px;
          color: #333333;
          @include bpm-sm {
            font-size: 12px;
            white-space: nowrap;
          }
        }
        @include bpm-sm {
          padding: 4px 8px;
        }
      }
      .primary-button {
        padding: 12px 24px;
        font-size: 18px;
        font-weight: 400;
        background: linear-gradient(90deg, #e47762 0%, #f2b65a 98.07%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        white-space: nowrap;
        @include bpm-sm {
          font-size: 14px;
          padding: 4px 16px;
        }
      }
    }
  }
}

.view-assignment-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.75rem 2rem;
  column-gap: 1rem;
  color: #333333;
  font-weight: 300;
  font-size: 18px;
  border: 2px soild rgba(153, 153, 153, 0.6);
  border-radius: 2.5rem;
  background-color: rgba(255, 255, 255, 0.8);
  @include bpm-sm {
    font-size: 14px;
    padding: 4px 1rem;
    column-gap: 8px;
  }
  img {
    height: 22px;
    width: 22px;
    @include bpm-sm {
      height: 14px;
      width: 14px;
    }
  }
}
