.summer-camp-referal-card {
  &__wrapper {
    background: #ffffff;
    box-shadow: 50px 60px 100px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.5rem 1.5rem;
    margin: 1rem 3rem 3rem 3rem;
    border-radius: 24px;
    @include bpm-md {
      flex-direction: column;
    }
  }
  &-image {
    margin-right: 2rem;
    max-width: 355px;
    @include bpm-md {
      max-width: fit-content;
      margin-right: 0rem;
    }
  }
  &_text {
    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &-main {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      color: #333333;
      margin-bottom: 2rem;
      @include bpm-md {
        font-size: 24px;
        line-height: 29px;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
    &-sub {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #666666;
      @include bpm-md {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 2rem;
      }
    }
  }
  &_share {
    &_wrapper {
      margin-left: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include bpm-md {
        margin-left: 0rem;
      }
    }
    &_whatsapp {
      background: #4fcc5d;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 1rem 3rem;
      cursor: pointer;
      &-icon {
        margin-right: 0.8rem;
      }
      &-text {
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        white-space: nowrap;
      }
    }
  }
}
