.large-trial-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 4rem 0;
  margin: 7rem 7rem 0 7rem;
  border: 5px solid #fcb444;
  border-radius: 3rem;
  min-height: 37rem;

  &__header {
    font-size: 5rem;
    font-weight: 800;
  }
  &__content {
    font-size: 1.8rem;
    text-align: center;
    max-width: 75rem;
    margin-bottom: 2rem;
  }
  &__liner {
    color: #e47964;
    font-size: 2rem;
    font-weight: 700;
    margin: 1.5rem 0;
    text-align: center;
  }

  @include bpm-sm {
    margin: 7rem 0 0 0;
    border-radius: 5px;
    &__header {
      font-size: 3.6rem;
      text-align: center;
      padding: 0 2rem;
    }
    &__content {
      font-size: 1.5rem;
      padding: 0 2rem;
    }
  }
  &.version-2 {
    background: linear-gradient(
      111.29deg,
      rgba(255, 255, 255, 0.53) -1.83%,
      rgba(255, 255, 255, 0) 109.95%
    );
    border: 5px solid #ffffff;
    .large-trial-card__header {
      font-size: 4rem;
    }
    @include bpm-sm {
      margin: 0;
    }
  }
}
.large-trial-card1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;

  border: none;

  &__header {
    font-size: 5rem;
    font-weight: 800;
  }
  &__content {
    font-size: 1.8rem;
    text-align: center;
    max-width: 75rem;
  }
  @include bpm-sm {
    margin: 7rem 0 0 0;
    border-radius: 5px;
    &__header {
      font-size: 3.6rem;
      text-align: center;
      padding: 0 2rem;
    }
    &__content {
      font-size: 1.5rem;
      padding: 0 2rem;
    }
  }
}
