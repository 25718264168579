.new-showcase {
  &__wrapper {
    border: 5px solid white;
    border-radius: 8px;
    @include bp-sm {
      margin: 0 5rem;
      padding: 2rem 3rem;
    }
  }
  display: flex;
  align-items: flex-start;
  &.home {
    align-items: center;
    justify-content: space-around;
  }
  @include bpm-xl {
    flex-direction: column;
    align-items: center;
  }

  .swiper-container {
    padding-bottom: 4rem;
  }
  &__left {
    // min-width: 250px;
    &--header {
      font-size: 4rem;
      font-weight: 900;
      color: #333;
    }
    &--sub-header {
      margin-top: 2rem;
      font-size: 2.2rem;
      font-weight: 500;
      color: #666;
    }
    @include bpm-sm {
      padding: 2rem 3rem;
      &--header {
        font-size: 2.6rem;
      }
      &--sub-header {
        font-size: 1.6rem;
      }
    }
  }
  &__right {
    @include bpm-xl {
      .single-course-banner-card {
        width: 40rem;
        height: 30rem;
      }
      .swiper-slide {
        width: 42rem !important;
      }
    }
  }
}
