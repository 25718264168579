.on-boarding-progress-bar {
  &__wrapper {
    margin: 0;
    width: 100%;
  }
  &--conformation-msg {
    font-family: Lato;
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: -0.14941634237766266px;
    text-align: center;
    color: rgba(153, 153, 153, 1);
  }
  &__progress-container {
    width: 100%;
    height: 1.25rem;
    margin-top: 1rem;
    background-color: rgba(218, 218, 218, 1);
    border-radius: 1rem;
    &--percentage-progress {
      height: 100%;
      width: 0%;
      transition: 12s ease;
      transition-delay: 1s;
      border-radius: 1rem 1.5rem 1.5rem 1rem;
      background-color: rgba(255, 199, 0, 1);
    }
  }
}
