.video-carousel {
  height: 100%;
  width: 100%;
  &__swiper {
    .swiper-pagination {
      margin: 0;
    }
    .swiper-slide {
    }
    @include bpm-md {
      padding-bottom: 6rem !important;
    }
  }
  &__single-video {
    position: relative;

    &--details {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: linear-gradient(
        360deg,
        #292929 0%,
        rgba(250, 250, 250, 0) 168.75%
      );
      border-radius: 0px 0px 15px 15px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem 2rem;
      span {
        color: #fff;
        margin: 0.3rem;
        font-size: 1.5rem;
        &:first-child {
          font-weight: 700;
        }
      }
    }
    img,
    svg {
      width: 5rem;
    }
    &--pause {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;
      cursor: pointer;
    }
    &:hover {
      .video-carousel__single-video--pause {
        display: block;
      }
    }
    .spark-showcase-page-video {
      height: 300px !important;
      width: 400px !important;
      @include bpm-custom(420px) {
        height: 225px !important;
        width: 300px !important;
      }
    }
  }
}
