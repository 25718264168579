.result-banner {
  &__wrapper {
  }
  display: grid;
  grid-template-columns: 1fr 400px;
  grid-gap: 4rem;
  justify-content: space-between;
  @include bpm-md {
    grid-template-columns: 1fr 300px;
  }
  @include bpm-sm {
    grid-gap: 0;
    display: flex;
    flex-direction: column;
  }
  &__left {
    @include bpm-sm {
      order: 2;
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &--replay {
      font-size: 1.6rem;
      font-weight: 500;
      color: #f2b65a;
      text-decoration: underline;
    }
    &--header {
      font-size: 4.8rem;
      font-weight: 900;
      color: #333;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      text-align: left;
      span {
        font-size: 1.6rem;
        font-weight: 500;
        color: #e47762;
      }
      @include bpm-md {
        font-size: 3.6rem;
      }
    }
    &--content {
      font-size: 2rem;
      font-weight: 500;
      color: #666;
      margin: 1.5rem 0 3rem 0;
      text-align: left;
      @include bpm-md {
        font-size: 1.6rem;
      }
    }
    &--best-words {
      background: #fbf8f9;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.5rem 2rem;
      margin-bottom: 3rem;
      width: 100%;
      &--left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      &--right {
        display: flex;
        flex-direction: column;
        align-items: center;
        .coins-count {
          font-size: 3rem;
          color: #f2b65a;
          font-weight: 900;
          margin-top: 1rem;
          text-align: center;
          transform: translateX(-5px);
          @include bpm-md {
            font-size: 2rem;
          }
        }
        img {
          width: 10rem;
        }
      }
      &--title {
        font-size: 2rem;
        font-weight: 700;
        color: #333;
        margin-bottom: 2rem;
        @include bpm-md {
          margin-bottom: 1rem;
        }
      }
      &--list {
        padding-inline-start: 15px;
        .single-word {
          font-size: 1.8rem;
          font-weight: 500;
          color: #666;
          position: relative;
          margin-bottom: 5px;
          @include bpm-md {
            font-size: 1.6rem;
          }
          span {
            position: absolute;
            font-size: 1.6rem;
            font-weight: 500;
            color: #e47762;
            top: 0;
            right: -2rem;
          }
        }
      }
    }
    &--action {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      .onboarding-secondary-link {
        text-align: left;
      }
      .secondary-button {
        margin: 0;
      }
      .primary-button {
        margin-left: 2rem;
        white-space: nowrap;
        @include bpm-custom(390px) {
          margin-left: 1rem;
        }
      }
    }
  }
  &__right {
    img {
      margin: auto;
      width: 100%;
    }
    @include bpm-sm {
      img {
        width: 10rem;
      }
    }
  }
}
