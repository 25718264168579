.workshop-course-details-card {
  &__wrapper {
    border-radius: 2rem;
    border: 5px solid #fff;
    position: relative;
    overflow-x: hidden;
    max-width: 1300px;
    margin: 3rem auto;
    overflow-y: visible;
    @include bpm-xl {
      margin: 3rem 7rem;
    }
    @include bpm-lg {
      margin: 3rem;
      
    }
    @include bpm-md {
      max-width: 62rem;
      margin: 3rem auto;
      padding-top: 1rem;
    }
    @include bpm-custom(630px) {
      max-width: 100vw;
      border: none;
      border-radius: 0 0 2rem 2rem;
    }
    .free-tag {
      position: absolute;
      width: 12rem;
      top: 0;
      left: 0;
      @include bpm-md {
        width: 8rem;
      }
    }
  }
  display: grid;
  grid-template-columns: 45fr 55fr;
  @include bp-md {
    min-height: 40rem;
  }
  // @include bp-md {
  //   min-height: 35rem;
  // }
  @include bpm-custom(1340px) {
    grid-template-columns: 40fr 60fr;
  }
  @include bpm-lg {
    grid-template-columns: 50fr 50fr;
  }
  @include bpm-md {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  margin: 0 auto;
  border-radius: 2rem;

  &__left {
    > img {
      object-fit: cover;
      height: 450px;
      width: 600px;
    }
    border-radius: 1.5rem;
    overflow: hidden;
    @include bpm-md {
      margin: 0 0;
    }
    
    @include bpm-custom(630px) {
      margin: 0;
      border-radius: 0;
      width: 100%;
      img {
        width: 100%;
        height: auto;
      }
    }
    @include bpm-xs{
      padding: 0 1rem;
    }
  }
  &__right {
    padding: 4rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    * {
      color: #000632;
    }
    @include bpm-custom(1340px) {
      padding: 2rem;
    }
    @include bpm-md {
      padding: 2rem 4rem;
    }
    @include bpm-custom(630px) {
      border-radius: 0 0 2rem 2rem;
      border-left: 5px solid #fff;
      border-right: 5px solid #fff;
      border-bottom: 5px solid #fff;
      padding: 2rem;
    }
    &--details {
      max-width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      height: 100%;
      @include bpm-custom(630px) {
        align-items: center;
      }
      .title {
        span {
          font-size: 4rem;
        }
        font-weight: 900;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @include bpm-lg {
          font-size: 3rem;
        }
        @include bpm-custom(630px) {
          text-align: center;
          span {
            font-size: 3.5rem;
          }
          justify-content: center;
        }
        .age-group {
          display: flex;
          align-items: center;
          font-size: 1.6rem;
          font-weight: 700;
          // width: 15rem;
          img {
            width: 1.4rem;
            margin-right: 0.5rem;
          }
          @include bpm-custom(630px) {
            display: none;
          }
        }
      }
      .session-duration {
        display: flex;
        align-items: center;
        img {
          margin-right: 0.5rem;
        }
        font-size: 1.4rem;
        margin: 2rem 0;
        @include bpm-lg {
          margin: 1rem 0;
        }
        @include bpm-custom(630px) {
          margin: 2rem 0;
        }
      }
      .workshop-description {
        font-size: 1.4rem;
        font-weight: 400;
        @include bpm-custom(630px) {
          // text-align: center;
          padding: 0 2rem 0 0;
        }
      }
    }
    &--slots {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      margin-top: 2rem;
      @include bpm-custom(630px) {
        // align-items: center;
      }
      .slots {
        .single-slot {
          font-size: 1.5rem;
          font-weight: 500;
          margin: 0.5rem 0;
          &:first-child {
          }
          &__title {
            margin-bottom: 1rem;
            font-weight: 500;
            font-weight: 900;
          }
          @include bp-md {
            max-width: 30rem;
          }
        }
      }
      .batches {
        display: flex;
        align-items: center;
        @include bp-xs {
          span + span {
            border-left: 2px solid #333;
          }
        }
        @include bpm-custom(615px) {
          width: 100%;
          justify-content: center;
        }
        @include bpm-xs {
          flex-direction: column;
          align-items: center;
        }
        .single-batch {
          padding: 0 0.5rem;
          font-size: 1.5rem;
          font-weight: 700;
          display: inline-block;
          color: #333;
          @include bpm-md {
            text-align: center;
          }
          @include bpm-xs {
            margin: 0.4rem 0;
          }
          &__header {
            font-size: 1.5rem;
            font-weight: 700;
            // margin: 1rem 0;
            display: inline-block;
            width: max-content;
            color: #666;
            @include bpm-md {
              text-align: center;
            }
          }
        }

        border-bottom: 1px solid #aaa;

        &.bundle {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .single-bundle {
            margin: 0.5rem 0;
          }
        }
      }

      .action {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
        @include bpm-custom(630px) {
          flex-direction: column;
          align-items: center;
        }
        .age-group {
          display: flex;
          align-items: center;
          font-size: 1.6rem;
          font-weight: 700;
          img {
            width: 1.4rem;
            margin-right: 0.5rem;
          }
          margin-top: 2rem;
          @include bp-xs {
            display: none;
          }
        }
        .tags {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          width: 100%;
          list-style: none;
          padding-inline-start: 0;
          margin: 0;
          @include bpm-custom(630px) {
            justify-content: center;
            margin-top: 2rem;
            order: 2;
          }
          li {
            font-size: 1rem;
            padding: 0.3rem 0.8rem 0.3rem 2rem;
            border: 2px solid rgba(255, 255, 255, 1);
            position: relative;
            border-radius: 3rem;
            background-color: rgba(255, 255, 255, 0.5);
            margin-right: 1rem;
            &::before {
              content: "";
              position: absolute;
              width: 0.5rem;
              height: 0.5rem;
              background-color: black;
              border-radius: 2rem;
              left: 0.7rem;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
        .cta-price {
          display: flex;
          flex-direction: column;
          align-items: center;
          .price-container {
            display: flex;
            align-items: center;
            strike {
              margin-left: 0.5rem;
              display: inline-block;
              font-size: 1.5rem;
              @include bpm-custom(615px) {
                margin: 1.5rem 0 0 0.5rem;
              }
            }
          }
          .price {
            display: inline-block;
            font-size: 2rem;
            font-weight: 900;
            color: #333;
            min-width: 9rem;
            align-self: center;
            margin: 1.5rem 0;
            @include bpm-custom(615px) {
              margin: 1.5rem 0 0 0;
            }
          }
          .primary-button {
            color: #fff;
            width: 20rem;
            margin-bottom: 1.5rem;
            @include bpm-custom(630px) {
              margin-top: 2rem;
            }
          }
          .discount-text {
            font-size: 1.5rem;
            font-weight: 700;
            color: #f3705b;
            display: inline-block;
            margin-bottom: 1rem;
            @include bpm-custom(630px) {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
  &__separator {
    transform: translateX(5%);
    height: 2px;
    width: 90%;
    margin: 1rem 0;
    background-color: #ccc;
    display: inline-block;
  }
}
