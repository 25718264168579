.courses-curriculum-section {
  &__wrapper {
    padding: 2rem;
    max-width: 450px;
  }
  max-height: 400px;
  overflow-y: scroll;
  &__title {
    font-size: 2.8rem;
    font-weight: 900;
    color: #333;
    margin-bottom: 2rem;
  }
  &__main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .single-curriculum-card {
    width: 100%;
    border-bottom: 1px solid #ccc;
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 1rem 0;
    &__left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-color: #fff7e7;
      border-radius: 1rem;
      padding: 1rem;
      margin-right: 2rem;
      .session-number {
        font-size: 1.6rem;
        margin-bottom: 1rem;
        background-color: #f3705b;
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 5rem;
        color: #fff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .session-word {
        font-size: 2rem;
        text-align: left;
        display: inline-block;
      }
    }
    &__right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &--title {
        font-size: 1.8rem;
        font-weight: 700;
        color: #333;
        margin-bottom: 1.5rem;
      }
      &--content {
        font-size: 1.4rem;
        font-weight: 500;
        color: #666;
      }
    }
  }
}
