.refer-page_container {
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: rgba(242, 182, 90, 0.4);
  }
  &__wrapper {
    padding: 0;
    @include bpm-md {
      padding: 0 3.6rem;
    }
    @include bpm-xxs {
      padding: 0 1.6rem;
    }
  }
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  @include bpm-md {
    flex-direction: column;
    row-gap: 32px;
  }
  &__descriptions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0rem 3% 1.6rem 12%;
    // margin-left: 10%;
    @include bpm-md {
      margin-left: 0;
      width: 100%;
    }
    @include bpm-lg {
      margin-left: 6%;
    }
    &--p-text {
      font-family: Lato;
      font-size: 28px;
      font-weight: 800;
      line-height: 34px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(51, 51, 51, 1);
      @include bpm-md {
        font-family: Lato;
        font-size: 24px;
        font-weight: 800;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    &--button {
      @include bpm-md {
        display: none;
      }
    }
  }
  &__left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 25%;
    margin-left: 10%;
    @include bpm-md {
      margin-left: 0;
      width: 100%;
    }
    @include bpm-lg {
      margin-left: 2%;
    }
  }
  &__right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60%;
    margin-bottom: auto;
    @include bpm-md {
      width: 100%;
    }
  }
}
