.spark-dashboard-assignments {
  &__wrapper {
    // width: max-content;
    // margin: 0 auto;
    margin: 0 96px;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    text-align: left;
    padding-bottom: 50px;
    @include bpm-sm {
      margin: 0 16px;
      min-height: 80vh;
    }
  }

  &_category-tabs {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 16px;

    &_single-tab {
      position: relative;
      display: flex;
      justify-content: center;
      padding: 16px 0;
      cursor: pointer;
      flex: 1;
      color: #333333;
      font-size: 20px;

      &.active {
        background-color: #333333;
        color: #fff;
        font-weight: 900;
        position: relative;
        overflow: hidden;
        transition: background-color 1s;
      }
      @include bpm-sm {
        font-size: 16px;
        padding: 7px;
      }
      &_active-border {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: linear-gradient(90deg, #f3705b, #fcb444);
        position: absolute;
        bottom: 0;
        @include bpm-sm {
          height: 1.5px;
        }
      }
    }
  }

  &-head {
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    /* identical to box height */
    margin-left: 20px;
    margin: 30px;
    text-align: center;
    color: #333333;
    @include bpm-xs {
      max-width: 160px;
      font-size: 28px;
      margin: 10px;
      margin-left: 20px;
      line-height: 36px;
    }
  }
}
