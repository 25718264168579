.spark-game-new-results {
  &__wrapper {
    // background: url("../../../public/assets/results_bg.png") center
    //   center/contain no-repeat;
    padding: 0 2.4rem 3.2rem;
    background-color: rgba(247, 238, 231, 1);
    min-height: 100vh;
    @include bpm-md {
      padding: 0.2rem 1.4rem;
    }
  }
  &__grammarCard {
    display: flex;
    width: 75%;
    margin: 0rem auto 4.8rem;
    justify-content: space-between;
  }
  &__swiper {
    margin: 4rem 0;
    // @include bpm-md {
    //   display: none;
    // }
    padding-bottom: 6rem !important;
    @include bpm-md {
      padding: 2rem !important;
    }
    .swiper-slide {
      width: 42rem;
    }
    .swiper-pagination {
      margin-bottom: 0;
    }
    @include bpm-custom(850px) {
      .swiper-slide {
        width: 35rem;
      }
    }
    @include bpm-xs {
      .swiper-slide {
        width: 32rem;
      }
    }
  }
}
