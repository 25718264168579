.gameIndexEligiblecourses {
  &--header {
    padding: 4rem 7rem;
    @include bpm-xs{
        padding: 4rem 2rem;
    }
    h2 {
      font-family: Lato;
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      line-height: 38px;

      color: #333333;
      margin-bottom: 12px;
    }
    p {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      /* or 150% */
      color: #333333;
      max-width: 557px;
    }
  }
}
