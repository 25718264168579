// ! Game Start modal
.game-start-modal {
  &__wrapper {
    width: 450px;
    padding: 2rem;
    @include bpm-sm {
      padding: 1.5rem;
      width: 100%;
    }
  }
  &__layout {
    border-radius: 2rem;
    .MuiPaper-rounded {
      border-radius: 1.5rem !important;
      @include bpm-sm {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
      }
    }
  }
  display: flex;
  flex-direction: column;
  align-items: center;

  &__top {
    &--image {
      border-radius: 1rem;
      width: 100%;
      object-fit: cover;
    }
  }
  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 1rem 0;
    &--details {
      .title {
        font-size: 1.8rem;
        color: #333;
        font-weight: 700;
        margin-bottom: 1rem;
      }
      .prompt {
        font-size: 1.6rem;
        color: #666;
        font-weight: 500;
      }
    }
    &--action {
    }
  }
}
// ! Pause state modal
.pause-state-modal {
  &__wrapper {
    padding: 1.5rem;
    border-radius: 1.5rem;
  }
  display: flex;
  flex-direction: column;
  //   align-items: center;
  &__header {
    font-size: 4.8rem;
    color: #333;
    font-weight: 900;
    text-align: left;
    margin-bottom: 1rem;
    @include bpm-sm {
      font-size: 2.2rem;
    }
  }
  &__sub-header {
    font-size: 2rem;
    color: #666;
    font-weight: 500;
    text-align: left;
    @include bpm-sm {
      font-size: 1.6rem;
    }
  }
  &__textarea {
    width: 100%;
    height: 30rem;
    overflow-y: scroll;
    border-radius: 1.5rem;
    margin: 2rem 0;
    background-color: #f5f5f5;
    border: 2px solid #ccc;
    outline: none;
    padding: 1.5rem;
    font-size: 1.8rem;
    line-height: 2.5rem;
    color: #333;
    font-weight: 500;
    text-align: left;
    &:focus,
    &:active {
      border: 2px solid #e47762;
    }
  }
  .primary-button {
    align-self: center;
  }
}
// ! PStage submit modal
.stage-submit-modal {
  &__wrapper {
    padding: 2.4rem 2.4rem 1.5rem 2.4rem;
    border-radius: 1.5rem;
    &.game {
      padding: 2.4rem 12.4rem 1.5rem 12.4rem;
    }
  }
  display: flex;
  position: relative;
  flex-direction: column;
  &__cross-icon {
    position: absolute;
    font-size: 1.5rem;
    margin-left: 5rem;
    right: 0rem;
    top: 0.75rem;
  }
  &__header {
    font-size: 2.4rem;
    color: rgba(51, 51, 51, 1);
    font-weight: 700;
    font-family: Lato;
    text-align: left;
    margin-bottom: 1.5rem;
    @include bpm-sm {
      font-size: 2rem;
    }
  }
  &__sub-header {
    font-size: 1.8rem;
    color: #666;
    font-weight: 500;
    text-align: left;
    @include bpm-sm {
      font-size: 1.6rem;
    }
    span {
      color: #e47762;
      display: inline-block;
      margin-top: 0.5rem;
    }
  }
  &__action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 3rem;
    &--secondaryButton {
      font-size: 16px;
      cursor: pointer;
      font-weight: 400;
      line-height: 19px;
      text-align: left;
    }
    &__button {
      margin-left: 0;
    }
    .primary-button {
      // margin-left: 2.5rem;
      width: 9rem;
      height: 3.25rem;
    }
    .MuiCircularProgress-svg {
      color: #e47762 !important;
    }
    &.game {
      gap: 10px;
      flex-direction: column-reverse;
    }
  }
}

.stage-submit-modal-v2 {
  &__wrapper {
    padding: 2.4rem 2.4rem 1.5rem 2.4rem;

    border-radius: 1.5rem;
    &.game {
      padding: 2.4rem 12.4rem 1.5rem 12.4rem;
      @include bpm-xxs {
        padding: 2.4rem 6.4rem 1.5rem 6.4rem;
      }
    }
  }
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__cross-icon {
    position: absolute;
    font-size: 1.5rem;
    margin-left: 5rem;
    right: 0rem;
    top: 0.75rem;
  }
  &__header {
    font-size: 2.4rem;
    color: rgba(51, 51, 51, 1);
    font-weight: 700;
    font-family: Lato;
    text-align: left;
    margin-bottom: 1.5rem;
    text-align: center;
    @include bpm-sm {
      font-size: 2rem;
    }
  }
  &__sub-header {
    font-size: 1.8rem;
    color: #666;
    font-weight: 500;
    text-align: left;
    @include bpm-sm {
      font-size: 1.6rem;
    }
    span {
      color: #e47762;
      display: inline-block;
      margin-top: 0.5rem;
    }
  }
  &__action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // margin-top: 3rem;
    &--secondaryButton {
      font-size: 16px;
      cursor: pointer;
      font-weight: 400;
      line-height: 19px;
      text-align: left;
    }
    &__button {
      margin-left: 0;
    }
    .primary-button {
      // margin-left: 2.5rem;
      width: 19rem;
      height: 4.25rem;
    }
    .MuiCircularProgress-svg {
      color: #e47762 !important;
    }
    &.game {
      gap: 10px;
      flex-direction: column-reverse;
    }
  }
}

.internet-down-modal {
  &__wrapper {
    padding: 1.5rem;
    border-radius: 2rem;
    font-family: Lato;
  }
  .primary-button {
    width: 12rem;
    height: 4rem;
    margin: 2.5rem auto 0;
    @include bpm-xxs {
      margin-top: 2rem;
    }
  }
  display: flex;
  position: relative;
  width: 38rem;
  height: 40rem;
  border-radius: 1.6rem;
  flex-direction: column;
  @include bpm-xxs {
    width: 35rem;
    height: 37rem;
  }
  background-color: rgba(252, 252, 255, 1);
  &__cross-icon {
    position: absolute;
    font-size: 1.8rem;
    margin-left: 5rem;
    right: 0rem;
    top: -1rem;
  }

  &__icon {
    object-fit: scale-down;
  }

  &__networkdown-image {
    // width: 15rem;
    margin: 3rem auto 1.5rem;
    @include bpm-xxs {
      margin: 1.5rem auto 1.5rem;
    }
    // &::after {
    //   content: "";
    //   background: url("../../../../public/assets/noInternet.svg") center
    //     center/contain no-repeat;
    // }
  }

  &__heading {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2rem;
    margin: 0 auto;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(51, 51, 51, 1);
  }
  &__subheading {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 1rem;
    padding: 0rem 5rem;
    color: rgba(102, 102, 102, 1);
  }
}

.fteu-modal {
  &__wrapper {
    border-radius: 2rem;
    padding: 1.5rem;
    background-color: rgba(255, 255, 255, 1);
    padding: 0.5rem;
    border-radius: 1rem;
    top: 24rem;
    left: 57rem;
    height: 6rem;
  }
  display: flex;
  flex-direction: column;
  height: 24rem;
  margin-bottom: 28rem;
  border-radius: 1rem;
  width: 37rem;
  z-index: 10;
  background-color: rgba(252, 252, 255, 1);
  @include bpm-custom(425) {
    width: 33rem;
    margin-bottom: 50rem;
  }
  &__card {
    width: 100%;
    min-height: 15rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 245, 222, 1);
  }
  &__navigation-container {
    display: "flex";
    width: "100%";
    padding: 0 3rem;
    // flex-direction: column;
    justify-content: "space-between";
    &--next-button {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      cursor: pointer;
      letter-spacing: 0em;
      text-align: center;
      color: rgba(214, 146, 0, 1);
      text-decoration: underline;
    }
    &--previous-button {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      cursor: pointer;
      text-align: center;
      color: rgba(102, 102, 102, 1);
      text-decoration: underline;
    }
  }

  &__swiper {
    .swiper-slide {
      // width: 20rem !important;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @include bpm-custom(375) {
        width: 100%;
      }
      .intructions {
        font-size: 1.4rem;
        color: rgba(51, 51, 51, 1);
        font-weight: 700;
        width: 100%;
        font-family: Poppins;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        padding: 0 2.5rem;
        span {
          color: #e78261;
          font-weight: 500;
        }
      }
    }

    .swiper-pagination {
      margin-bottom: 0rem !important;
      top: 7.85rem;
      left: 0;
      width: 100%;
    }
  }
}

.no-microphonealert-modal {
  &__wrapper {
    padding: 1.5rem;
    font-family: Lato;
    display: flex;
    @include bpm-xxs {
      margin-top: 1.5rem;
    }
  }
  .primary-button {
    width: 12rem;
    height: 4rem;
    margin: 2.5rem auto 0;
    @include bpm-xxs {
      margin-top: 2rem;
    }
  }
  display: flex;
  position: relative;
  width: 43rem;
  padding-bottom: 1rem;
  // height: 42rem;
  border-radius: 1.6rem;
  flex-direction: column;
  @include bpm-xxs {
    width: 37rem;
    // height: 40rem;
  }
  background-color: rgba(252, 252, 255, 1);
  &__cross-icon {
    position: absolute;
    font-size: 1.8rem;
    margin-left: 5rem;
    right: 0rem;
    top: -1rem;
  }
  &__no-microphone-image {
    // width: 15rem;
    margin: 1.5rem auto 1.5rem;
    @include bpm-xxs {
      margin: 1.5rem auto 1.5rem;
    }
    // &::after {
    //   content: "";
    //   background: url("../../../../public/assets/noInternet.svg") center
    //     center/contain no-repeat;
    // }
  }
  &__heading {
    font-family: Lato;
    font-size: 2rem;
    font-style: normal;
    font-weight: 800;
    margin: 0 auto;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(51, 51, 51, 1);
  }
  &__points {
    margin: 1.8rem auto 0;
    padding: 0rem 0.5rem;

    li {
      font-size: 1.5rem;
      font-weight: 600;
      opacity: 0.6;
      margin-top: 1rem;
      margin: 1rem 1.75rem 1em;

      @include bpm-xxs {
        font-size: 1.35rem;
      }
    }
  }

  // ul {
  //   list-style: none;
  // }

  // ul li::before {
  //   content: "\2022";
  //   color: black;
  //   font-weight: bold;
  //   display: inline-block;
  //   width: 2rem;
  //   margin-left: -1em;
  // }
  &__subpoints {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
}
