.confirmation-ticket {
  &__wrapper {
    margin: 0;
  }
  .confirmation-info-text {
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.14941634237766266px;
    text-align: left;
    color: rgba(153, 153, 153, 1);
  }
  .confirmation-date {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    margin-left: -2rem;
    line-height: 21px;
    letter-spacing: -0.14941634237766266px;
    text-align: left;
  }
  // .primary-button.version-5 {
  //   margin-top: 3rem !important;
  // }
  &__buttonStyle {
    margin: 3.2rem auto;
    // width: 18rem;
    width: 16.5rem;
    height: 4.2rem;
    display: flex;
    font-weight: 600;
    font-size: 1.4rem;
    justify-content: center;
  }
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  &__gradient-img {
    position: relative;
    width: 32rem;
    height: 15rem;
  }
  &__sparklogo {
    position: absolute;
    top: 3rem;
    z-index: 100;
    left: 3.5rem;
  }
  &__ticket-layout-img {
    position: absolute;
    left: 1.135rem;
    bottom: 0.7rem;
  }
  &--coursename {
    position: absolute;
    top: 2.75rem;
    z-index: 100;
    left: 12rem;
    width: 17rem;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: right;
    color: #333333;
  }
  &--p-text {
    position: absolute;
    left: 3.5rem;
    bottom: 5rem;
  }
  &--s-text {
    position: absolute;
    left: 3.5rem;
    bottom: 2.5rem;
  }
  &--date-position {
    position: absolute;
    left: 23rem;
    bottom: 4.7rem;
    &.custom-slot {
      left: 21.5rem;
    }
  }
  &--time-position {
    position: absolute;
    left: 22.5rem;
    bottom: 2.2rem;
  }
}
