.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-pagination {
  margin-bottom: 4rem;
}

.swiper-pagination-bullet {
  // height: 1.25rem !important;
  // width: 1.25rem !important;
  // background-color: #fff !important;
  // border: 2px solid #fcb444;
  // border-radius: 2rem !important;
  // cursor: pointer !important;
  opacity: 1 !important;
  // margin-bottom: 0.85rem !important;
  height: 6px !important;
  width: 20px !important;
  border-radius: 3rem !important;
  background-color: #ccc !important;
  cursor: pointer !important;
}

.swiper-pagination-bullet-active {
  // background-color: #fcb444 !important;
  // height: 2rem !important;
  // width: 2rem !important;
  // border: none;
  // border-radius: 2rem !important;
  opacity: 1 !important;
  // margin-bottom: 1px !important;
  height: 6px !important;
  width: 45px !important;
  border-radius: 3rem !important;
  background-color: #fcb444 !important;
  cursor: pointer !important;
}

.swiper-button-next {
  transform: translateY(-5rem);
  &::after {
    content: "" !important;
    background: url("../../public/assets/rightArrow.svg") center center/contain
      no-repeat;
    height: 3rem;
    width: 3rem;
  }
}
.swiper-button-prev {
  transform: translateY(-5rem);
  &::after {
    content: "" !important;
    background: url("../../public/assets/leftArrow.svg") center center/contain
      no-repeat;
    height: 3rem;
    width: 3rem;
  }
}
