.unsubscribe_page {
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7eee7;
    //padding-top: 10rem;
    min-height: 100vh;
    background: url("../../public/assets/login_bg.webp") center center/contain
      no-repeat;
    @include bpm-custom(480px) {
      // padding-top: 3rem;
    }
  }
  &__cta-container {
    margin-top: 6rem;
    display: flex;
    gap: 4rem;
    &--unsubscribe-btn {
      padding: 1.5rem 4rem;
      justify-content: center;
      font-weight: 600;
      color: black;
      background-color: lightgray;

      //   background: linear-gradient(90deg, #f3705b 0%, #fcb444 100%);
      //   box-shadow: 0px 2px 6px rgba(91, 22, 170, 0.12), 0px 4px 14px #eeeeee,
      //     inset 1px 0px 3px rgba(255, 255, 255, 0.5);

      border-radius: 5rem;

      display: flex;
      -webkit-align-items: center;
      align-items: center;
      font-size: 1.6rem;
      cursor: pointer;
      border: none;
    }
    @include bpm-custom(480px) {
      flex-direction: column;
    }
  }
  &__image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 30rem;
      width: 20rem;
      @include bpm-custom(480px) {
        height: 25rem;
        width: 15rem;
      }
    }
  }
}
