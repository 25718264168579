.alert-container {
  > div > div {
    width: 350px !important;
    padding: 15px !important;
    background-color: #333 !important;
    border-radius: 5px !important;
    > span {
      font-size: 1.2rem !important;
    }
  }
}
