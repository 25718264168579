.spark-booking-course-card {
  &__wrapper {
    * {
      box-sizing: border-box !important;
      font-family: "Lato" !important;
    }
    margin: 1.3rem 0.8rem;
    width: 100%;
    > div {
      border-radius: 8px !important;
      margin-right: 1rem !important;
      border: none !important;
      box-shadow: 0px 0px 8px rgba(75, 0, 188, 0.08) !important;
      -webkit-box-shadow: 0px 0px 8px rgba(75, 0, 188, 0.08) !important;
      -webkit-appearance: none !important;
    }
    &.checked {
      > div {
        border: 2px solid #f2b65a !important;
      }
      .spark-booking-course-card__label {
        span {
          color: #f2b65a;
          font-weight: 700;
        }
      }
    }
    &.disabled {
      > div {
        background-color: #eee !important;
      }
    }
  }
  height: 5.5rem;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  padding: 0 1.5rem 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 8px !important;

  .course-title {
    font-size: 1.6rem;
    font-weight: 500;
  }
  > div {
    margin: 0;
  }
  &__label {
    justify-content: space-between;
    width: 100%;
    margin-right: 0 !important;
    padding: 17px 0 17px 10px;
    span {
      font-size: 1.6rem !important;
      color: #666;
      font-weight: 700;
      .single-course-title {
        // color: red;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
      }
      .co-booked-title {
        font-size: 1.2rem !important;
        font-weight: 500 !important;
        padding-left: 1.2rem;
        position: relative;
        color: #666 !important;
        &::before {
          content: "";
          position: absolute;
          z-index: 10;
          height: 0.8rem;
          width: 0.8rem;
          left: 0px;
          top: 50%;
          transform: translateY(-50%);
          background: url("../../../../public/assets/star.svg") center
            center/cover no-repeat;
        }
      }
    }
    .course-checkbox {
      order: 2;
      height: 1.5rem;
      width: 1.5rem;
      position: relative;
      z-index: 5;
      visibility: hidden;
      &::after {
        visibility: visible;
        content: "";
        position: absolute;
        top: -0.4rem;
        left: 0;
        height: 2rem;
        width: 2rem;
        cursor: pointer;
        z-index: 10;
      }
      &.checked {
        &::after {
          background: url("../../../../public/assets/checked.svg") center
            center/contain no-repeat;
        }
      }
      &.unchecked {
        &::after {
          background: url("../../../../public/assets/unchecked.svg") center
            center/cover no-repeat;
        }
      }
    }
  }
  &.disabled {
    .spark-booking-course-card__label {
      cursor: not-allowed !important;
      span {
        color: #999;
      }
    }
  }
  &__details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &--first-line {
      line-height: 1.8rem !important;
      padding: 2rem 0;
      border-top: 1px solid #eee;
      font-size: 1.4rem !important;
    }
    &--second-line {
      line-height: 1.8rem !important;
      font-size: 1.4rem !important;
      font-weight: 700 !important;
      padding-left: 3.5rem !important;
      position: relative;
      z-index: 5;
      &::before {
        content: "";
        position: absolute;
        z-index: 10;
        height: 2rem;
        width: 2rem;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);

        background: url("../../../../public/assets/star.svg") center
          center/cover no-repeat;
      }
    }
  }
  .MuiAccordionSummary-expandIcon {
    padding: 0.5rem;
  }
}
