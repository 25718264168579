.courseCardNew {
  &__wrapper {
    width: 328px;
    height: 100%;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    transition: 0.5s;
    margin-top: 10px;
    @include bpm-xs {
      margin-bottom: 30px;
    }

    //! Css changes when mouse hovers on the card
    &:hover {
      margin-top: -50px;
      transition: 0.5s;
    }
    &.active {
      .courseCardNew {
        &--top {
          h1,
          span {
            color: #ffffff;
          }
          &-flags-sessionFlag {
            p {
              color: #333333;
            }
          }
        }
      }
    }
  }
  &--top {
    background: #d5f1fe;

    &-flags {
      display: flex;
      // align-items: flex-start;
      justify-content: space-between;
      //! The session flag on the left of the flags div
      &-sessionFlag {
        margin-top: 15px;
        position: relative;
        p {
          position: absolute;
          top: 7px;
          left: 15px;
          font-family: Lato;
          font-style: normal;
          font-weight: 800;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */
          color: #ffffff;
        }
      }
      //! The discount flag on the right of the flags div
      &-discountFlag {
        position: relative;
        p {
          position: absolute;
          top: 7px;
          left: 25px;
          font-family: Lato;
          font-style: normal;
          font-weight: 900;
          font-size: 16px;
          line-height: 19px;
          text-align: center;

          color: #333333;
        }
      }
    }
    //! h1 class to remove negative margin when no discount
    .no-margin {
      margin-top: 12px;
    }

    h1 {
      font-family: Lato;
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 30px;
      /* identical to box height */

      margin-left: 15px;
      margin-top: -10px;
      max-width: 245px;
    }
    //! This is the ratings div
    .single-element {
      margin-top: 10px;
      margin-left: 15px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      span {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */
        color: #333333;
      }
    }
  }
  //! The css for the middle section of the card - middle div
  &--middle {
    padding-left: 15px;
    padding-right: 10px;
    padding-top: 15px;
    background: #f8f8f8;

    p {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #333333;
    }
    h2 {
      margin-top: 15px;
    }
    &-lessonPlan {
      margin: 15px 0px;
      margin-left: 1rem;
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-decoration-line: underline;
      color: #333333;
      cursor: pointer;
    }
    &-underline {
      border-bottom: 1px solid #e0e0e0;
      margin: 0px 5px;
    }
    //! Css for the ul list
    &-list {
      height: 205px;
      overflow: hidden;
      &.active {
        overflow: visible;
        height: auto;
      }
    }
    ul {
      margin-left: -20px;
      li {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #666666;
        margin-bottom: 10px;
        margin-right: 22px;
      }
    }
  }
  &__container {
    display: flex;
    flex-direction: row;
    margin-top: 2.4rem;
    border-radius: 0.8rem;
    &_tag {
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 30px;
      margin-right: 15px;
      font-weight: 400;
      // margin-top: -10px;
      text-align: center;
      padding: 0.2rem 0.8rem;
      max-width: max-content;
      color: #333333;
      border-radius: 0.8rem;
    }
  }
  &--bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 15px;
    background: #f8f8f8;
    &-underline {
      border-bottom: 1px solid #e0e0e0;
      margin: 0px 15px;
      margin-top: 15px;
    }
    &-price {
      padding: 13px 0px;
      padding-left: 30px;
      padding-right: 15px;

      &-normalPrice {
        display: flex;
        justify-content: space-between;
        h1 {
          font-family: Lato;
          font-style: normal;
          font-weight: 800;
          font-size: 24px;
          line-height: 29px;
          color: #333333;
        }
        h2 {
          font-family: Lato;
          font-size: 18px;
          font-style: normal;
          font-weight: 800;
          line-height: 22px;
          letter-spacing: 0px;
          text-align: left;
          color: #333333;
        }
        span {
          font-family: Lato;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0px;
          text-align: left;
          color: #666666;
        }
        &-perClass {
          display: flex;
          justify-content: space-between;
          width: 100%;
          span {
            margin-bottom: 10px;
          }
          h2 {
            margin-top: 2px;
          }
        }
      }
      &-slashedPrices {
        // font-style: italic;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 19px;
        color: #f47759;
        font-style: italic;
      }
      &-slashed {
        padding-top: 5px;
        .slashed-price {
          font-family: Lato;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: #999999;
        }
      }
    }
    &-activities {
      padding: 5px 12px;
      margin: 5px 0px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .single-element {
        display: flex;
        align-items: center;
        span {
          font-family: Lato;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */
          color: #333333;
        }
      }
    }
    //! Css for the batch selection div (slots)
    &-slots {
      padding: 0px 15px;
      text-align: center;
      &-selection-label {
      }
      .no-batch {
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #f47759;
      }
      .MuiRadio-colorSecondary.Mui-checked {
        color: #333333;
      }
      .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.2px;
        color: #666666;
        text-align: left;
        @include bpm-custom(630) {
          font-size: 1.4rem;
        }
      }
      .new-course-card__top__right--batch-selection-single-batch {
        margin: 2px 0px;
      }
    }
  }
  &--buyNow {
    background: #f8f8f8;
    padding: 22px 0px;
    .primary-button {
      width: 160px;
      padding: 15px 10px;
      margin: 0 auto;
    }
  }
}
