.spark-dashboard-all-classes {
  //! Wrapper Styling ->
  &__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    // height: 100vh;
    padding: 0rem 2rem;
  }

  //! Styling for the head section - > includes upcoming class card ->
  &_head {
    &__wrapper {
      display: flex;
      justify-content: center;
      width: 100%;
      padding-left: 80px;
      @include bpm-lg {
        padding-left: 0px;
      }
    }
    &--lottie {
      width: 100%;
      // height: 200px;
      background-color: #ffffff;
      border-radius: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &---text {
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #333333;
        padding: 1rem 0rem;
      }
    }
  }
  &_upcoming-classes-table-wrapper {
    padding-left: 80px;
    @include bpm-lg {
      padding-left: 0px;
    }
  }
  &_storyteller-card-wrapper {
    padding-left: 80px;
    @include bpm-lg {
      padding-left: 0px;
    }
  }
  &_invite-friend-card-wrapper {
    padding-left: 80px;
    @include bpm-lg {
      padding-left: 0px;
    }
  }
  &_app-download-card-wrapper {
    padding-left: 80px;
    @include bpm-lg {
      padding-left: 0px;
    }
  }
  &_certificates-wrapper {
    padding-left: 80px;
    @include bpm-lg {
      padding-left: 0px;
    }
  }
}
