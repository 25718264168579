.storytelling-save {
  &__wrapper {
    padding: 0;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &--p-heading {
    font-family: Lato;
    font-size: 32px;
    font-weight: 800;
    line-height: 38px;
    letter-spacing: 0px;
    text-align: left;
    color: #333333;
    margin-bottom: 1.6rem;
    @include bpm-xxs {
      font-size: 20px;
    }
  }

  &__image-container {
    position: relative;
    @include bpm-xxs {
      display: flex;
      justify-content: center;
    }
    &--inputfield {
      position: absolute;
      outline: 0;
      right: 10rem;
      top: 3rem;
      width: 28.2rem;
      height: 4rem;
      border-radius: 0.8rem;
      border: none;
      outline: none;
      background: #ffffff;
      padding: 0 0.8rem;
      font-family: Lato;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;

      @include bpm-xxs {
        right: 3.5rem;
        top: 2rem;
        width: 25.2rem;
        height: 3.6rem;
      }
      @include bpm-custom(360) {
        right: 4rem;
        top: 2rem;
        width: 20.2rem;
        height: 3rem;
      }
    }
    &--img {
      border-radius: 0.8rem;
      width: 49.8rem;
      @include bpm-xxs {
        width: 33rem;
      }
      @include bpm-custom(360) {
        width: 28rem;
      }
    }
  }

  &--inputfield {
  }
  //   .primary-button{

  //   }
}
