.course-banner {
  &__wrapper {
    padding: 4rem 7rem;
    background: linear-gradient(
      174.62deg,
      #ffffff 4.31%,
      rgba(255, 255, 255, 0) 134.84%
    );
    @include bpm-md {
      padding: 4rem;
    }
    @include bpm-xs {
      padding: 4rem 1rem;
    }
  }
  display: grid;
  grid-template-columns: 500px 400px;
  justify-content: space-between;
  @include bp-xl {
    grid-template-columns: 600px 400px;
  }
  @include bpm-md {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include bpm-md {
      width: 100%;
      align-items: center;
    }
    @include bpm-xs {
      padding: 0 1rem;
    }
    &--title {
      font-size: 4.8rem;
      color: #333;
      font-weight: 900;
      @include bpm-md {
        font-size: 2.8rem;
        text-align: center;
      }
    }
    &--description {
      font-size: 1.8rem;
      color: #666;
      font-weight: 500;
      margin: 2rem 0;
      line-height: 2.4rem;
      @include bpm-md {
        font-size: 1.6rem;
        max-width: 600px;
        text-align: center;
      }
    }
    &--elements {
      background-color: #fff7e7;
      border-radius: 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 330px;
      padding: 1.5rem;
      @include bp-xl {
        width: 400px;
      }
      @include bpm-md {
        margin: 2rem auto 0 auto;
      }
      &-row {
        width: 100%;
        display: grid;
        grid-template-columns: 2fr 1.2fr;
        margin-bottom: 1rem;
        @include bp-xl {
          margin-bottom: 1.5rem;
        }
        .single-element {
          display: flex;
          align-items: center;
          svg {
            width: 2rem;
            margin-right: 1rem;
          }
          .rating-icons {
            width: 8rem;
          }
          span {
            font-size: 1.3rem;
            color: #333;
            @include bp-xl {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
    &--separator {
      display: block;
      height: 2px;
      width: 80%;
      margin: 3rem 0 1rem 0;
      opacity: 0.6;
      background-color: #ffa724;
      @include bpm-md {
        width: 100%;
      }
    }
    &--buy {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 80%;
      margin-top: 2rem;
      @include bpm-md {
        width: 100%;
      }
      @include bpm-xs {
        align-items: center;
      }
      &-price {
        display: flex;
        align-items: center;
        span {
          font-size: 3.2rem;
          color: #333;
          font-weight: 900;
        }
        .slashed-price {
          margin-left: 2rem;
          font-size: 2rem;
          font-weight: 500;
          color: #999;
        }
        &--margin-text {
          margin-left: 0.5rem;
        }
        @include bpm-xs {
          span {
            font-size: 2.4rem;
          }
          .slashed-price {
            font-size: 1.8rem;
          }
        }
        @include bpm-custom(440px) {
          flex-direction: column;
          align-items: flex-start;
          .slashed-price {
            margin: 1rem 0;
          }
        }
      }
      &-cta {
      }
      &-sessionPrice {
        width: 100%;
        span {
          font-size: 1.5rem;
          color: #999;
        }
      }
    }
    &--urgency {
      display: flex;
      align-items: center;
      svg {
        margin-right: 1rem;
      }
      color: #e47762;
      font-size: 1.8rem;
      margin: 1rem 0;
      align-self: flex-start;
      span {
        margin-right: 0.8rem;
        display: inline-block;
      }
      .countdown_text {
        display: flex;
        align-items: center;
      }
      @include bpm-custom(450px) {
        .countdown_text {
          flex-direction: column;
        }
      }
    }
    &--policy {
      font-size: 1.6rem;
      color: #333;
      margin: 1rem 0 2rem 0;
      align-self: flex-start;
      display: flex;
      align-items: center;
      img {
        width: 10rem;
      }
    }
    &--promo {
      display: flex;
      width: 80%;
      align-items: center;
      justify-content: space-between;
      align-self: flex-start;
      &-label {
        margin: 1rem 0;
        position: relative;
        span {
          position: absolute;
          right: 2rem;
          top: 50%;
          transform: translateY(-50%);
          font-size: 1.6rem;

          font-weight: 700;
        }
      }
      &-apply {
        cursor: pointer;
        color: #e47762;
      }
      &-applied {
        color: #6ca52c;
      }
      &-input {
        border: none;
        outline: none;
        background: #eeeeee;
        border-radius: 8px;
        width: 30rem;
        font-weight: 500;
        font-size: 1.8rem;
        color: #333;
        height: 4rem;
        padding: 0 1rem;
        box-shadow: 0px 0px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0px 0px rgba(0, 0, 0, 0.3);
      }
      &-response {
        font-size: 1.3rem;
        &.success {
          color: limegreen;
        }
        &.failure {
          color: #ed465a;
        }
      }
      &-coupon {
        color: #e47762;
        font-size: 1.4rem;
        text-decoration: underline;
        cursor: pointer;
      }
      &-gift {
        color: #e47762;
        font-size: 1.4rem;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  &__right {
    &--show-case-img {
      margin-top: 4rem;
      width: 400px !important;
      height: 300px !important;
      border-radius: 2rem !important;
      @include bpm-custom(420px) {
        height: 225px !important;
        margin-top: 0;
        width: 300px !important;
        border-radius: 2rem !important;
      }
    }
  }
}
