.homework-uploaded-modal {
  &__wrapper {
  }
  margin: 0 auto;

  .primary-button {
    margin: 0 auto;
    margin-top: 20px;
  }
  //! Max width is 80% for the whole container
  max-width: 80%;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 0px 8px rgba(75, 0, 188, 0.08);
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 0px 8px rgba(75, 0, 188, 0.08);
  -webkit-appearance: none;
  border-radius: 16px;
  padding: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // position: relative;
  // overflow: hidden;
  position: relative;
  overflow: visible;

  @include bpm-custom(850px) {
    padding: 2rem 1.5rem;
    margin: 0 1.5rem;
    min-height: auto;
  }
  @include bpm-md {
    max-width: 85%;
  }
  // video {
  //   width: 100%;
  // }

  img {
    width: 100%;
  }

  //! css for the close button
  &__close {
    position: absolute;
    background-color: #fff;
    height: 4rem;
    width: 4rem;
    border-radius: 10rem;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
    -webkit-appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -2rem;
    right: -1.5rem;
    cursor: pointer;
    img {
      width: 3rem;
    }
  }
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    //! Static width + height set for the container
    // width: 500px;
    // max-height: 700px;

    &-inputs {
      display: flex;
      margin-top: 10px;
      @include bpm-xs {
        flex-direction: column;
      }
      align-items: center;
      margin-bottom: 10px;
      width: 100%;

      &-preview {
        position: relative;
        margin-right: 10px;
        z-index: 0;
        .file-preview {
          width: 240px;
          border: 0.5px dashed orange;
          border-radius: 6.6px;
          padding-right: 5px;
        }
        .file-remove {
          width: 40px;
          position: absolute;
          right: -7%;
          top: -7%;
          z-index: 1000;
          cursor: pointer;
        }
      }
      //! The div that holds the input boxes
      &-field {
        display: flex;
        flex-direction: column;
        margin-left: 5px;
        @include bpm-xs {
          margin-left: 0px;
        }
        input[type="text"] {
          width: 100%;
          padding: 12px 20px;
          margin: 8px 0;
          border: none;
          box-shadow: 0px 0px 8px rgba(75, 0, 188, 0.08);
          border-radius: 8px;

          font-family: Lato;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: #333333;
          &:focus {
            outline: none;
          }
        }

        textarea {
          width: 100%;
          padding: 12px 20px;
          border: none;
          box-shadow: 0px 0px 8px rgba(75, 0, 188, 0.08);
          border-radius: 8px;

          font-family: Lato;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: #333333;
          height: 130px;
          &:focus {
            outline: none;
          }
        }
      }
      //! css for the input container/wrapper div
      &__wrapper {
        max-height: 400px;
        @include bpm-xs {
          max-height: 200px;
        }
        // width: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        // &::-webkit-scrollbar {
        //   display: none;
        // }

        // /* Hide scrollbar for IE, Edge and Firefox */
        // & {
        //   -ms-overflow-style: none; /* IE and Edge */
        //   scrollbar-width: none; /* Firefox */
        // }
        &::-webkit-scrollbar {
          width: 4px;               /* width of the entire scrollbar */
        }
        &::-webkit-scrollbar-track {
                 /* color of the tracking area */
                 margin: 20px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #fcb444;    /* color of the scroll thumb */
          border-radius: 20px;       /* roundness of the scroll thumb */
           /* creates padding around scroll thumb */
        }
      }
    }
  }
  &-multipleUpload {
    margin: 0 auto;
    margin-top: 20px;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;
    @include bpm-xs {
      font-size: 14px;
    }
    line-height: 24px;
    /* identical to box height */
    border-bottom: 1px solid #333333;
    color: #333333;
  }
}
//! CSS for the dropbox container
.dragDrop--container {
  width: max-content;
  margin: 0 auto;
  margin-top: 15px;
  .dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 250px;
    @include bpm-xs {
      height: 220px;
      width: 270px;
    }
    border: 1px dashed;
    border-radius: 12px;
    &.active{
      border: 3px dashed;
      border-color: #fcb444;
    }
    img {
      width: 54px;
      height: 54px;
      margin: 0px auto;
      margin-bottom: 30px;
      @include bpm-xs {
        width: 30px;
        height: 30px;
        margin-bottom: 5px;
      }
    }
    h2 {
      margin: 5px 0px;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0px;
      @include bpm-xs {
        font-size: 15px;
        line-height: 20px;
      }
    }
    //! The css for the files uploaded being listed
    &--files {
      max-height: 80px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      & {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
      li {
        font-family: Lato;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.20000000298023224px;
        margin-bottom: 3px;
        text-align: center;
      }
    }
  }
}
