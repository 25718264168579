.timeslot-change-strip {
  &_wrapper {
    width: 100vw;
    margin: 1rem -4rem;
    @include bpm-md {
      margin: 1rem -2.4rem;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 1rem;
    background: #0e1232;
  }

  &-text {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #ffffff;
    margin-right: 2rem;
    @include bpm-xs {
      margin-right: 1rem;
      max-width: 50%;
    }
  }
  &_buttonwrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    position: relative;
    &-icon {
      width: 15px;
      height: 15px;
      margin-left: 1rem;
      margin-top: 2px;
      transition: 0.25s ease-in;
      &.active {
        transform: rotate(180deg);
        transition: 0.25s ease-in;
      }
    }
    &-text {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 17px;
      line-height: 20px;
    }
    &-gradient {
      &.text {
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 17px;
        line-height: 20px;
      }
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 110%;
        width: 100%;
        left: 0;
        height: 1px;
        border-radius: 2px;
        background: linear-gradient(
          -45deg,
          #f3705b 0%,
          #fcb444 48%,
          #fcb444 52%,
          #f3705b 100%
        );
      }
      background-image: linear-gradient(
        -45deg,
        #f3705b 0%,
        #fcb444 48%,
        #fcb444 52%,
        #f3705b 100%
      );
      background-size: 100%;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
    }
  }
  &_dropdown {
    position: absolute;
    top: 105%;
    width: 100%;
    background-color: #ffffff;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    padding: 1rem 1rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    opacity: 1;
    z-index: 1000;
    max-height: 400px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 2px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      margin: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #fcb444;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: orange;
    }
    &.inactive {
      display: none;
    }

    &-innerWrapper {
      border-bottom: 1px solid #000000;
    }
    &-country {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      color: #000000;
      margin: 6px 0px;
    }
    &-timezone {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: #555555;
      padding-bottom: 3px;
      margin: 1px 0px;
      &.active {
        background-color: #fcb444;
        border-radius: 4px;
        padding-left: 5px;
      }
    }
  }
}
