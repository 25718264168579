.class_grading_modal {
  background-color: #ffffff;
  border-radius: 16px;
  opacity: 1;
  width: 95%;
  position: relative;
  @include bpm-lg {
    width: 100%;
  }

  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
    display: none;
  }

  &-close {
    width: 42px;
    height: 42px;
    position: absolute;
    right: 10px;
    top: -15px;
    z-index: 1000;
    cursor: pointer;
  }

  &-body {
    margin: 2.5rem 2rem;
    @include bpm-lg {
      height: auto;
      overflow-x: scroll;
      display: grid;

      margin: 2.5rem 1rem;
    }
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &--content {
      &_wrapper {
        @media only screen and (min-width: 414px),
          screen and (min-height: 896px) {
          max-height: 300px !important;
        }
        @media only screen and (min-width: 1024px),
          screen and (min-height: 600px) {
          max-height: 250px !important;
        }
        @media only screen and (min-width: 1366px),
          screen and (min-height: 768px) {
          max-height: 330px !important;
        }
        @media only screen and (min-width: 1400px),
          screen and (min-height: 900px) {
          max-height: 400px !important;
        }
        @media only screen and (min-width: 1680px),
          screen and (min-height: 1050px) {
          max-height: 500px !important;
        }
        @media only screen and (min-width: 1920px),
          screen and (min-height: 1080px) {
          max-height: 600px !important;
        }

        overflow-y: scroll;
      }
    }
    &--head {
      &_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 2rem;
      }
      &_left {
        display: flex;
        flex-direction: column;

        @include bpm-lg {
          flex-shrink: 0;
        }
        &-heading {
          font-family: "Lato";
          font-style: normal;
          font-weight: 800;
          font-size: 32px;
          line-height: 38px;
          color: #333333;
          margin-bottom: 1rem;
        }
        &-subHeading {
          font-family: "Lato";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 29px;
          color: #666666;
        }
        &-markedText {
          color: #333333;
          font-weight: 700;
          background-color: #f2b65a;
          border-radius: 4px;
          padding: 0.5rem 0.5rem;
          text-transform: capitalize;
        }
      }
      &_right {
        &-buttonWrapper {
          padding-top: 0.5rem;
          height: 51px;
          @include bpm-lg {
            margin-left: 1rem;
          }
        }
      }
    }
    &--footer {
      &_wrapper {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 16px;
        overflow: hidden;
        margin-top: 1rem;
        display: flex;
        background: #f2b65a;
      }
      &---left {
        background: #e47762;
        padding: 2rem 2rem;
        width: max-content;
        &----header {
          font-family: "Lato";
          font-style: normal;
          font-weight: 900;
          font-size: 17px;
          line-height: 20px;
          color: #ffffff;
        }
        &----sub_header {
          font-family: "Lato";
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          line-height: 20px;
          color: #ffffff;
          width: 170px;
          margin-top: 1rem;
        }
      }
      &---right {
        width: 100%;
        textarea {
          border: none;
          width: 100%;
          height: 100%;
          background: #f2b65a;
          padding: 1rem 1rem;
          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  display: flex;
  flex-direction: column;

  &-table {
    &_wrapper {
      padding: 10px 0px;
      width: 100%;
      // white-space: nowrap;
      // margin-bottom: 20px;
      td,
      th {
        padding: 20px 0px;
        @include bpm-lg {
          padding-right: 20px;
        }
        @include bpm-xs {
          padding: 15px 10px;
        }
      }
    }
    &-head_row {
      padding: 10px 0px;
      &-heading {
        font-family: "Lato";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #333333;
        padding: 0rem 1rem !important;
        position: relative;
        @include bpm-xs {
        }
        &--title {
          text-align: center;
        }
      }
    }
    &-content {
      &:last-child {
        td {
          border-bottom: none;
        }
      }
      &--element {
        text-align: center;
        padding: 2rem 1rem !important;
        &.bottomBorder {
          border-bottom: 2px solid rgba(242, 182, 90, 0.48);
        }
        &---student_info {
          display: flex;
          flex-direction: column;
          background: linear-gradient(270deg, #fee1e8 0%, #fffdc9 126.94%);
          box-shadow: 0px 0.64px 0.64px rgba(221, 94, 10, 0.1);
          border-radius: 16px;
          padding: 2rem 1rem;
          &-name {
            font-family: "Lato";
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            line-height: 22px;
            color: #333333;
          }
          &-zoom_id {
            font-family: "Lato";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #666666;
            margin: 1rem 0rem;
          }
          &-age {
            font-family: "Lato";
            font-style: normal;
            font-weight: 800;
            font-size: 16px;
            line-height: 19px;
            color: #333333;
          }
        }
        &---checkbox {
          width: 32px;
          height: 32px;
          border-radius: 8px;
          // background: #d3efc4;
          cursor: pointer;
          &_wrapper {
            display: flex;
            justify-content: center;
          }
        }
        &---rating {
          cursor: pointer;
          width: 55px;
          height: 83px;
          &_nil {
            &_wrapper {
              display: flex;
              width: 100%;
              height: 100%;
              justify-content: center;
              align-items: center;
            }
            width: 15px;
            height: 5px;
            background-color: #666666;
          }
        }
        &---faculty_notes {
          input[type="text"] {
            border-radius: 8px;
            border: 1px solid #f9dcb0;
            width: 100%;
            height: 110px;
            padding-bottom: 60px;
            padding-left: 5px;
            &:focus {
              outline: none;
            }
          }
        }
        &---dropdown {
          &_wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
