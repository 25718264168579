.pause-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 450px;
  min-width: 360px;
  //   margin: 0 2rem;
  max-height: 550px;
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 1rem;
  &__header {
    font-weight: 700;
    align-self: flex-start;
    line-height: 2rem;
    margin-bottom: 1rem;
    font-size: 2.6rem;
  }
  &__subheading {
    opacity: 0.7;
    align-self: flex-start;
    line-height: 2rem;
    margin-bottom: 2rem;
    font-size: 1.5rem;
  }
  &__textArea {
    overflow-y: scroll;
    padding: 2.5rem 1.5rem 1.5rem 1.5rem;
    resize: none;
    background-color: #f5f5f5;
    height: 19rem;
    font-size: 1.75rem;
    font-family: Lato;
    // border: 2px solid #e78261;
    outline: none;
    border: none;
    width: 100%;
    border-radius: 1rem;
    &:focus {
      border: none;
      outline: none;
    }
    &::-webkit-scrollbar {
      width: 0.5rem;
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      background-color: rgba(231, 130, 97, 0.5);
    }
    &:active {
      outline: none;
      border: none;
    }
  }
  &__icon {
    @include bpm-md {
      display: flex;
      margin-left: 0;
    }
    // margin-left: 13rem;
  }
  &__emptyIcon {
    display: flex;
    width: 6rem;
    @include bpm-md {
      display: none;
    }
  }
  &__hidden {
    @include bpm-md {
      display: none;
    }
  }

  &__pauseButton {
    width: 10rem;
    height: 3rem;
    font-size: 1.4rem;
    justify-content: center;
  }

  &__logo {
    width: 8rem;
  }
  .primary-button {
    width: 13rem;
    height: 3.5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  &__separator {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 3rem;
    span {
      height: 1px;
      width: 4.5rem;
      background-color: #949494;
    }
    p {
      color: #949494;
      font-size: 1.1rem;
    }
  }
  &__alternate-button {
    border: none;
    outline: none;
    background-color: transparent;
    color: #ff8671;
    cursor: pointer;
    width: max-content;
    &.inactive {
      cursor: not-allowed;
      color: #888;
    }
    span {
      color: #fff;
      background-color: #ff8671;
      height: 2rem;
      width: 2rem;
      border-radius: 5rem;
      display: inline-block;
      text-align: center;
      padding-top: 3px;
      padding-right: 1px;
    }
  }
  position: relative;

  &__close {
    position: absolute;
    width: 2.3rem;
    top: 2rem;
    right: 1.5rem;
    cursor: pointer;
  }
}
