.championship-rules {
  &_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 130vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1100;
    display: none;
  }
  &-close {
    width: 42px;
    height: 42px;
    position: absolute;
    right: 10px;
    top: -15px;
    z-index: 1000;
    // cursor: pointer;
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // gap: 50px;
    margin-bottom: 1rem;
    // justify-content: center;
    &-mainText {
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 32px;
      line-height: 34px;
      text-align: initial;
      color: #333333;
      margin: 2rem 0rem;
      @include bpm-xs {
        font-size: 22px;
        margin: 1rem 0rem;
      }
    }
  }
  &-subtext {
    color: #333;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 20px;
    @include bpm-xs {
      font-size: 17px;
      margin: 1rem 0rem;
    }
  }

  background-color: #ffffff;
  border-radius: 16px;
  opacity: 1;
  width: max-content;
  position: relative;
  padding: 2.5rem;
  @include bpm-lg {
    max-width: 85%;
  }
  @include bpm-xs {
    width: max-content;
    padding: 1rem 2rem 2rem 2rem;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  &-topImage {
    width: 305.48px;
    height: 314.08px;
    @include bpm-xs {
      width: 202px;
      height: 207px;
    }
  }

  &-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    color: #333333;
  }

  &--p-text {
    font-family: Lato;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
    color: #666666;
    margin: 2rem 0;
  }
  .primary-button {
    margin: 1.6rem 0 0 0;
  }
}

.start-game-modal {
  &--p-text {
    font-family: Lato;
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0px;
    text-align: center;
    color: #e50300;
    margin: 2.4rem 0;
    @include bpm-sm {
      font-size: 20px;
    }
  }
}
.support_modal-close {
  width: 42px;
  height: 42px;
  position: absolute;
  right: 10px;
  top: -15px;
  z-index: 1000;
  cursor: pointer;
}
