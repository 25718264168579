.homeGameCard {
  &__wrapper {
    background: #e6f7ff;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    width: 95%;
    margin: 20px auto;
    padding: 30px;
    display: flex;
    @include bpm-xs{
      flex-direction: column;
    }
    justify-content: space-between;
  }
  &--left {
    text-align: left;
    h1 {
      font-family: Lato;
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 18px;
      color: #002e42;
      margin-bottom: 15px;
    }
    p {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #999999;
      max-width: 410px;
      margin-bottom: 25px;
    }
    .primary-button {
      width: 137px;
      height: 42px;
      padding: 0px;
    }
  }
  &--right {
   
    img {
      width: 200px;
    }
    @include bpm-xs{
      text-align: center;
      margin-top: 30px;
    }
  }
}
