.spelling-bee-score-bar {
  width: 326px;
  height: 74px;
  background: #e4fcff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  left: 160px;
  @include bpm-xs {
    width: 296px;
    height: 74px;
    left: 10px;
    bottom: 0px;
  }
  &-boldText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    text-transform: lowercase;
    color: #333333;
    margin: 0rem 1rem;
  }
  &-regText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    text-transform: lowercase;
    color: #999999;
  }
  &-coinImage {
    width: 40px;
    height: 40px;
  }
  &-beeImage {
    width: 96px;
    height: 94px;
    position: absolute;
    right: -50px;
    bottom: 30px;
    @include bpm-xs {
      width: 55.15px;
      height: 54px;
      bottom: 40px;
      right: -25px;
    }
  }
}
