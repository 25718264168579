.spark-game-image-reveal {
  &__wrapper {
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  &__header {
    font-size: 4.8rem;
    margin-bottom: 2rem;
    color: #333;
    font-weight: 900;
    text-align: center;
    @include bpm-sm {
      margin-bottom: 1rem;
      font-size: 3.6rem;
    }
  }
  &__sub-header {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: #666;
    font-weight: 500;
    text-align: center;
    @include bpm-sm {
      margin-bottom: 1rem;
      font-size: 1.8rem;
    }
  }
  &__images {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1320px;
    margin: 2rem 0 4rem 0;
    .game-image__wrapper {
      margin: 0 1rem;
    }
  }
  &__swiper {
    margin: 4rem 0;
    padding-bottom: 6rem !important;
    .swiper-slide {
      width: 42rem;
    }
    .swiper-pagination {
      margin-bottom: 0;
    }
    @include bpm-custom(850px) {
      .swiper-slide {
        width: 35rem;
      }
    }
    @include bpm-xs {
      .swiper-slide {
        width: 32rem;
      }
    }
  }
  .game-image {
    width: 40rem;
    border-radius: 2rem;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    @include bpm-custom(850px) {
      width: 33rem;
    }
    @include bpm-xs {
      width: 31rem;
    }
    &__wrapper {
    }
    &__title {
      font-size: 1.8rem;
      font-weight: 700;
      color: #666;
      text-align: center;
      margin-top: 2rem;
    }
  }
}
