.myStudio {
  //! wrapper class wraps both mystudio and footer
  &__wrapper {
    display: flex;
    flex-direction: column;
    background: linear-gradient(
      180deg,
      rgba(254, 251, 183, 0.4) 3.13%,
      #fee1e8 100%
    );
    margin-top: -23px;
  }
  // object-fit: cover;
  // background-repeat: no-repeat;

  // background: #f7eee7;
  //! max width set to 1700px on desktop
  //! and float is set to right for the whole wrapper

  //! width now changed to 100% and padding left set to 300px instead
  //! Reason- the bg color doesnt fill out the left of the page
  //!because of flat right and setting a specific width
  // width: 1600px;
  width: 100%;
  // padding-left: 300px;
  float: right;
  &.padding {
    padding-left: 100px;
    @include bpm-xl {
      padding-left: 0px;
    }
  }
  display: flex;
  flex-direction: column;
  //! max width of page wrapper set to 100% on ipad pros and lower
  @include bpm-xl {
    //   width: 100%;
    padding-left: 0px;
  }
  margin: 0 auto;
  //! Wrapper for the swiper

  //! CSS for the swiper
  .studioSwiper {
    .swiper-wrapper {
      margin-left: 16%;
      @include bpm-lg {
        margin-left: auto;
        margin-right: auto;
      }
    }
    width: 100%;

    //! width set to 100 % on ipad pros and lower res devs
    @include bpm-xl {
      width: 100%;
    }
    .swiper-slide {
      width: 35rem;
    }
  }
  //! swiper button changed
  .swiper-button-next {
    transform: translate(7rem, -5rem);
    outline: none;
    border: none;
    background-color: rgba(255, 255, 255, 0.9);
    height: 10rem;
    width: 10rem;
    border-radius: 10rem;
    position: absolute;
    &::after {
      content: "";
      background: url("../../../public/assets/nextArrow.svg") center
        center/contain no-repeat;
      background-size: 2rem;
      transform: translateX(-2.3rem);
    }
  }
  .swiper-button-prev {
    transform: translate(-7rem, -5rem);
    outline: none;
    border: none;
    background-color: rgba(255, 255, 255, 0.9);
    height: 10rem;
    width: 10rem;
    border-radius: 10rem;
    position: absolute;
    &::after {
      content: "";
      background: url("../../../public/assets/nextArrow.svg") center
        center/contain no-repeat;
      background-size: 2rem;
      transform: rotate(180deg) translateX(-2.3rem);
    }
  }
  .swiper-button-disabled {
    opacity: 0 !important;
  }
  .swiper-pagination {
    // bottom: -3rem !important;
    margin-bottom: 1px;
  }

  .primary-button {
    margin: 50px auto;
  }

  //! Needed Class ?
  //! Wrapper div for the swiper for cards
  &--swiper {
    width: 100%;
  }
}
