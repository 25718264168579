.dashboardHead {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  //  @include bpm-lg{
     margin-left:20px;
  //  }
  }

  &--left {
    text-align: left;
    h1 {
      font-family: Lato;
      font-size: 45px;
      font-style: normal;
      font-weight: 900;
      line-height: 54px;
      letter-spacing: 0em;
      text-align: left;
      color: #333333;
    }
    h2 {
      font-family: Lato;
      font-size: 45px;
      font-style: normal;
      font-weight: 300;
      line-height: 54px;
      letter-spacing: 0em;
      text-align: left;
      color: #666666;
    }
   
  }
  &--right{
    @include bpm-lg{
      display: none;
    }
  
  }
}
