.spelling-bee-share-strip {
  &_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgba(255, 221, 81, 0.64);
    box-shadow: 50px 60px 100px rgba(0, 0, 0, 0.05);
    margin: 4rem 0rem 2rem 0rem;
    @include bpm-md {
      flex-direction: column;
      box-shadow: 50px 60px 100px rgba(0, 0, 0, 0.05);
      border-radius: 16px;
      width: 95%;
      margin: 4rem auto 2rem auto;
    }
  }
  &_left {
    &-image {
      max-width: 377px;
      max-height: 182px;
    }
  }
  &_middle {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 1rem;
    &-headText {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      color: #333333;
      text-align: left;
      @include bpm-md {
        font-size: 24px;
        line-height: 29px;
        padding: 0rem 1rem;
        margin-top: 1rem;
      }
    }
    &-subText {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      text-align: left;
      color: #666666;
      @include bpm-md {
        font-size: 20px;
        line-height: 24px;
        padding: 0rem 2rem;
      }
    }
  }
  &_right {
    display: flex;
    align-items: center;
    margin: 0rem 2rem;
    @include bpm-md {
      justify-content: center;
      margin: 2rem 0rem;
    }
  }
}
