.tooltip-text-container {
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  text-align: left;
  width: 234px;
  min-height: 139px;
  padding: 12px 24px 16px 16px;
  background: #e47762;
  border-radius: 8px;
  color: #fff;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -40px;
  margin-top: 8px;
  //   @include bpm-md {
  //     left: 0;
  //     top: -5px;
  //     right: 110%;
  //   }
  &::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 15%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #e47762 transparent;
    // @include bpm-md {
    //   bottom: 0;
    //   top: 15%;
    //   left: 100%;
    // }
  }
  li {
    margin-top: 4px;
  }
  &_terms-text {
    font-weight: 900;
    font-size: 16px;
    line-height: 120%;

    text-decoration-line: underline;

    color: #ffffff;
  }
}
