.eligibleCoursesCard {
  //! The entire wrapper for the card is based on flex and direction of flex is row

  &__wrapper {
    display: flex;
    flex-direction: row;

    // background: linear-gradient(
    //   114.28deg,
    //   #fdcd4c -12.61%,
    //   rgba(255, 255, 255, 0) 94.49%
    // );

    //! padding added individually to evade excessive padding from swiper bullets
    //! padding added in one go since bullets are being hidden until tested
    padding: 20px 25px;
    // padding-top: 20px;
    border-radius: 18px;
    margin-bottom: 50px;
    //! On ipads and lower the flex direction is reversed to get the teacher card uptop

    @include bpm-sm {
      flex-direction: column-reverse;
      align-items: center;
      padding: 15px;
    }
    //! CSS for the swiper
    .swiper-slide {
      width: max-content;
      padding: 0px;
      margin: 0px;
    }
    //! Padding set for the swiper bullets alongside margin
    .eligibleCourses-slider {
      // padding-bottom: 50px;
      .swiper-pagination {
        margin-bottom: 0rem !important;
        @include bpm-md {
          margin-bottom: 1rem !important;
        }
        &-bullet {
          background-color: #fcb444 !important;
        }
        &-bullet-active {
          background-color: #000 !important;
        }
      }
    }
  }
  //! This section is the css for the subtext in the card
  &--left {
    color: #252525;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    //! alignment for mobiles is centered
    @include bpm-sm {
      align-items: center;
    }
    h1 {
      font-family: Lato;
      font-size: 36px;
      font-style: normal;
      font-weight: 900;
      line-height: 43px;
      letter-spacing: 0.1527777761220932px;
      // padding-right: 20px;
      max-width: 550px;
      text-align: left;
      @include bpm-xs {
        font-size: 22px;
        max-width: 300px;
      }
    }
    p {
      font-family: Lato;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: 0px;
      color: #666666;
      //! Width of p tag set to a static size plus a margin is added
      width: 510px;
      margin-right: 100px;
      margin-top: 10px;
      text-align: left;
      //! text centered for ipad
      //! Margin and max width of the p tag is changed to accomodate ipads and

      @include bpm-sm {
        text-align: center;
        max-width: 450px;
        margin-right: 0px;
      }
      @include bpm-lg {
        max-width: 450px;
      }

      //! Max width set for the p tags to prevent overflow - font reduced too
      @include bpm-xs {
        max-width: 310px;
        font-size: 14px;
      }
    }

    //! This is the css for the tags in the course card
    &--tags {
      display: flex;
      align-items: flex-start;
      @include bpm-sm {
        justify-content: center;
      }
      flex-wrap: wrap;
      max-width: 320px;
      width: 100%;
      margin: 45px 0px;

      .course-tag {
        font-size: 1rem;
        margin-right: 10px;
        margin-top: 5px;
        padding: 0.3rem 0.8rem 0.3rem 2rem;
        border: 2px solid rgba(255, 255, 255, 1);
        position: relative;
        border-radius: 3rem;
        background-color: rgba(255, 255, 255, 0.5);
        &::before {
          content: "";
          position: absolute;
          width: 0.5rem;
          height: 0.5rem;
          background-color: black;
          border-radius: 2rem;
          left: 0.7rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    //! css for the primary button
    .primary-button {
      box-shadow: 0px 3.055555582046509px 10.69444465637207px 0px #eeeeee;
      box-shadow: 0px 1.5277777910232544px 4.583333492279053px 0px #5b16aa1f;
      box-shadow: 0.7638888955116272px 0px 2.2916667461395264px 0px #ffffff80
        inset;
      width: max-content;
    }
  }
  //! CSS for right half - i.e, the card- width is set to 500px for large devices,400px for ipad and 300px for small

  &--right {
    // border-radius: 24px;
    // overflow:hidden;
    width: 500px;
    @include bpm-xs {
      max-width: 300px;
    }
    @include bp-md {
      max-width: 400px;
      //! max-width:300px;
    }
    //! CSS for the fallback image
    //! padding bottom set along with max width on small
    .fallback-image {
      // padding-bottom: 25px;
      border-radius: 24px;
      border: 2px solid white;
      @include bpm-lg {
        max-width: 300px;
      }
      // @include bpm-lg{
      //   max-width: 300px;
      // }
    }
  }
}
