.choose-game {
  &__wrapper {
    padding: 0 3.6rem;
    @include bpm-xxl {
      padding: 3.2rem 1.2rem;
    }
  }
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  &__card {
    margin: 0 0 3.6rem;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: auto;
    // width: 100%;
    @include bpm-xxs {
      width: 50%;
      align-items: center;
    }
    &--img {
      height: 174px;
      border-radius: 1.6rem;
      @include bpm-xxs {
        width: 90%;
        height: auto;
        border-radius: 1rem;
      }
    }

    &-p-text {
      font-family: Poppins;
      font-size: 20px;
      font-weight: 800;
      line-height: 36px;
      letter-spacing: 0px;
      text-align: left;
      color: #333333;
      margin-top: 0.4rem;

      @include bpm-xxl {
        font-size: 16px;
        margin-top: 0.1rem;
        padding: 0 0 0 1rem;
      }
    }
    &-s-text {
      font-family: Poppins;
      font-size: 18px;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: 0px;
      text-align: left;
      color: #666666;
      @include bpm-xxl {
        font-size: 14px;
        line-height: 14px;
        padding: 0 0 0 1rem;
      }
      //   margin-top: 0.8rem;
    }
  }
}
