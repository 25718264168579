.card-wrapper {
  display: flex;
  align-items: stretch;
  &-text {
    text-align: center;
    padding-top: 10rem;
    h1 {
      @include h1-styling;
      font-size: 70px;
    }
    p {
      @include p-styling;
      text-align: center;
    }
  }

  .card-container {
    width: 363px;
    margin: 5% auto;

    text-align: center;

    border-radius: 45px;
    background: linear-gradient(
      90deg,
      rgba(255, 194, 0, 0.7) 0%,
      rgba(255, 174, 0, 0.7) 27.77%,
      rgba(255, 152, 0, 0.7) 53.45%,
      rgba(255, 126, 8, 0.7) 68.21%,
      rgba(255, 96, 17, 0.7) 88.51%,
      rgba(255, 85, 21, 0.7) 100%
    );
    box-shadow: 0px 8.58673px 11.449px rgba(255, 184, 1, 0.2);

    &-card-top {
      height: 130px;
      padding-left: 6rem;
      padding-right: 6rem;
      padding-top: 15px;
      img{
        // width: 225px;
        // height: 225px;
         border-radius: 50%;
         object-fit: cover;
         width: 100%;
         height: 250px;
      }
    }
    &-card-white {
      overflow: hidden;
      height: 450px;
      font-family: Lato;
      margin-top:30px;
      &-name {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        color: rgba(253, 179, 64, 1);
      }
      &-title {
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0em;
      }
      &-school {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
      }
      &-country {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
      }
      &-description {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
      }
      &-fact {
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: 16px;
        letter-spacing: 0em;
        padding-top: 12px;
      }
      .single-fact {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0em;
        padding-top: 5px;
      }

      > * {
        padding-bottom: 8px;
        word-wrap: break-word;
        width: 85%;
        margin: 0 auto;
      }
      padding-top: 30%;
      padding-bottom: 5%;
      text-align: center;
      border-bottom-left-radius: 45px;
      border-bottom-right-radius: 45px;
      background-color: #fffefe;
    }
    &-card-grey {
      padding-top: 1rem;
    }
    &-card-bottom {
      background-color: #fefbf9;
      border-bottom-right-radius: 45px;
      border-bottom-left-radius: 45px;
    }
  }
}
