.section-header {
  position: relative;
  margin-top: 4rem;
  margin-bottom: 1rem;
  @include bpm-xs {
    margin: 2rem 0;
  }
  &::before {
    content: "";
    position: absolute;
    background: rgba(248, 255, 230, 0.8);
    filter: blur(50px);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 15rem;
  @include bpm-lg {
    height: 10rem;
  }
  &__top {
    z-index: 2;
    font-size: 5rem;
    color: #333;
    text-align: center;
    font-weight: 800;
    @include bpm-lg {
      font-size: 4rem;
    }
    @include bpm-xs {
      font-size: 3rem;
    }
    .ted {
      color: #da3a34;
      font-weight: 900;
    }
    .ed {
      color: #000;
      font-weight: 900;
    }
    .club {
      color: #000;
      font-weight: 300;
    }
  }
  &__bottom {
    z-index: 2;
    font-size: 2rem;
    color: #666;
    text-align: center;
    @include bpm-lg {
      font-size: 1.8rem;
    }
    @include bpm-xs {
      font-size: 1.6rem;
    }
  }
}
