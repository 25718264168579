.spellingBee {
  &_wrapper {
    // width: max-content;
    // margin: 0 auto;
    // padding-bottom: 20px;
    // margin: 64px 14%;
    padding-bottom: 20px;
  }
  &_primary-button-container {
    .primary-button {
      background: linear-gradient(94.85deg, #ec7f57 -7.32%, #f2b65a 122.67%);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
      border: none;
      margin: 5px auto;
      // width: 40%;
      width: 457px;
      @include bpm-sm {
        width: 90%;
        margin: 24px auto;
      }
    }
    &__p-text {
      text-align: center;
      font-family: Lato;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      background: linear-gradient(94.85deg, #ec7f57 -7.32%, #f2b65a 122.67%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: 1.6rem 0;
      cursor: pointer;
      // padding-left: ;
      @include bpm-md {
        margin: 1.6rem 0;
        padding: 0;
      }
      &.padding {
        padding-left: 24%;
      }
    }
  }
  &_trophy-container {
    img {
      margin: 40px auto;
      @include bpm-sm {
        margin: 24px auto;
      }
      width: 60px;
      height: 72px;
    }
  }
  &_sparkEdapp-banner {
    position: relative;
    &-container {
    }
    .banner-image {
      border-radius: 16px;
      @include bpm-sm {
        border-radius: 8px;
      }
    }
    .playstore-image {
      position: absolute;
      width: 214px;
      height: 64px;
      left: 25px;
      top: 175px;
      @include bpm-sm {
        width: 90px;
        height: 30px;
        // top: 20px;
        top: 92%;
      }
    }
  }
  &_loader {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    margin-top: -100px;
    z-index: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &-lottie {
      width: 600px;
      height: 600px;
      @include bpm-xs {
        width: 300px;
        height: 300px;
      }
    }
    &-mainText {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      text-align: center;
      color: #333333;
      margin-bottom: 4rem;
    }
    &-subText {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      text-align: center;
      color: #f2b65a;
    }
  }
  &_complete {
    &-headText {
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 40px;
      line-height: 48px;
      text-align: center;
      text-transform: capitalize;
      color: #333333;
      margin-top: 3rem;
    }
    &-topStats {
      &_wrapper {
        display: flex;
        justify-content: center;
        @include bpm-md {
          align-items: center;
          flex-direction: column;
        }
      }
      &-left {
        &_wrapper {
          width: 595.25px;
          height: 612.81px;
          position: relative;
          @include bpm-xs {
            width: 310px;
            height: 344px;
            object-fit: contain;
          }
        }
        &-image {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      &-right {
        &_wrapper {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 20px;
          margin-left: 5rem;
          margin-top: 5rem;
          @include bpm-md {
            margin-left: 0rem;

            justify-content: center;
          }
        }
      }
    }
    &-cefrText {
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 40px;
      line-height: 48px;
      text-align: center;
      color: #333333;
      margin: 6rem 0rem 3rem 0rem;
      @include bpm-xs {
        display: none;
      }
    }
    &-answersBreakDown {
      &_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 2.5rem;
        gap: 20px;
        @include bpm-md {
          flex-direction: column;
          gap: 0px;
        }
      }
    }
    &-appCardText {
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      text-transform: capitalize;
      color: #333333;
      display: none;
      @include bpm-xs {
        display: block;
      }
    }
    &-buttonWrapper {
      margin-bottom: 4rem;
      width: 474px;
      margin: 0 auto;
      margin-bottom: 4rem;
      @include bpm-xs {
        width: 312px;
      }
    }
    &-faqWrapper {
      margin-left: 20px;
      @include bpm-xl {
        margin-left: 0px;
      }
    }
  }
}
