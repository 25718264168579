.studioHeadImage {
  //! The wrapper class encloses the head image div
  //! Display is set to flex so as to align the item to the left on small devices
  &__wrapper {
    .myStudio-head-image {
      width: 602px;
      @include bpm-md {
        width: 400px;
      }
      @include bpm-xs {
        width: 212px;
      }
    }
  }
}
