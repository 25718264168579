.user-details {
  &-container {
    border-radius: 16px 16px 0px 0px;
    background: #fff;
    box-shadow: 65.3166px 78.38px 130.633px rgba(0, 0, 0, 0.05);
    width: 100%;
  }
  &-header {
    display: flex;
    align-items: center;
    height: 77px;
    background: #ffd77c;
    border-radius: 16px 16px 0px 0px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    color: #333333;
    padding: 24px;
  }
  &-body {
    padding: 0 40px 13px 40px;
    @include bpm-xs {
      padding: 0;
    }
  }
}
