.schoolspage-app-introcard {
  &__wrapper {
    padding: 0;
    margin-top: 8rem;
    @include bpm-xs {
      padding: 0 2.8rem;
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include bpm-xs {
    align-items: flex-start;
  }
  &__primary-header {
    font-family: Lato;
    font-size: 64px;
    font-weight: 800;
    line-height: 85px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(51, 51, 51, 1);
    @include bpm-xs {
      font-size: 42px;
      font-weight: 800;
      line-height: 48px;
      letter-spacing: 0px;
      text-align: left;
    }
  }
  &__secondary-header {
    font-family: Lato;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(51, 51, 51, 1);
    margin-top: 2.4rem;
    @include bpm-xs {
      font-size: 16px;
      line-height: 19px;
      text-align: left;
    }
  }
  &__t-header {
    font-family: Lato;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: center;
    width: 70%;
    color: rgba(51, 51, 51, 1);
    margin: 2.4rem 0 4rem;
    @include bpm-xs {
      font-family: Lato;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0px;
      text-align: left;
      width: 100%;
    }
  }
  &__appViewContainer {
    &__wrapper {
      padding: 0 16rem;
      margin-top: 6.4rem;
      @include bpm-lg {
        padding: 0 8rem;
      }
      @include bpm-xs {
        padding: 0 4rem;
      }
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include bpm-sm {
      flex-direction: column;
    }

    &__card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      &--img {
        // height: 95%;
        // width: 95%;
        // object-fit: contain;
        margin-bottom: 4rem;
        @include bpm-xs {
          margin-bottom: 2.4rem;
        }
      }
      &--p-text {
        font-family: Lato;
        font-size: 24px;
        font-weight: 900;
        line-height: 28px;
        letter-spacing: 0px;
        text-align: center;
        color: rgba(0, 6, 50, 1);
        @include bpm-xs {
          margin-bottom: 2.4rem;
        }
      }
    }
  }
  &__buttonWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    justify-content: space-evenly;
    // margin-left: 130px;
    @include bpm-xs {
      margin-left: 0;
      width: 100%;
    }
  }
  &--img {
    width: 170px;
    height: 50px;
    object-fit: cover;
    margin-left: 30px;
    cursor: pointer;
    @include bpm-xs {
      width: 130px;
    }
  }
  .primary-button {
    padding: 1.5rem 4rem;
    @include bpm-xs {
      padding: 1.5rem 3rem;
    }
  }
}
