.courses-table {
  &__wrapper {
    width: 100%;
    .timezone {
      margin: auto;
      text-align: center;
      width: 100%;
      display: inline-block;
      margin: 1rem 0;
      font-size: 1.2rem;
    }
  }
  display: flex;
  flex-direction: column;
  //   border: 0.5px solid #eee;
  background: #fffcfa;
  box-shadow: 0px 2.98833px 2.98833px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  &__single-row {
    display: grid;
    grid-template-columns: 1.7fr 1.1fr 1fr 1.2fr;
    span {
      border: 0.5px solid #eee;
      font-size: 1.3rem;
      padding: 1rem 0;
      &.course {
        font-weight: 700;
        padding: 1rem;
      }
      &.date {
        text-align: center;
      }
      &.day,
      &.time {
        font-weight: 900;
        text-align: center;
      }
    }
    &:first-child {
      .course {
        border-top-left-radius: 8px;
      }
      .time {
        border-top-right-radius: 8px;
      }
    }
    &:last-child {
      .course {
        border-bottom-left-radius: 8px;
      }
      .time {
        border-bottom-right-radius: 8px;
      }
    }
    &--header {
      span {
        font-weight: 900 !important;
      }
    }
    position: relative;
    .add-to-calendar-link {
      z-index: 100;
      cursor: pointer;
      position: absolute;
      right: -1.3rem;
      top: 50%;
      transform: translateY(-50%);
      height: 2.6rem;
      width: 2.6rem;
      border-radius: 5rem;
      background: linear-gradient(90deg, #f3705b 0%, #fcb444 100%);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      &::after {
        z-index: 99;
        content: "";
        position: absolute;
        height: 1.6rem;
        width: 1.6rem;
        left: 50%;
        top: 50%;
        background: url("../../../../public/assets/calendarIconWhite.svg")
          center center/contain no-repeat;
        transform: translate(-50%, -50%);
      }
      &__dropdown-open {
        &::after {
          height: 1.3rem;
          width: 1.3rem;
          background: url("../../../../public/assets/whiteCross.svg") center
            center/contain no-repeat;
        }
      }
      &__dropdown {
        z-index: 100;
        position: absolute;
        top: 100%;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 15rem;
        background: #fffcfa;
        span {
          width: 100%;
          padding: 1rem 1.5rem;
          cursor: pointer;
        }
      }
      &__tooltip {
        height: 2.5rem;
        width: 18rem;
        background-color: #eee;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
        -webkit-appearance: none;
        border-radius: 8px;
        padding: 0.5rem;
        text-align: center;
        position: absolute;
        right: -2rem;
        top: 110%;
        z-index: 102;
        &::after {
          content: "";
          position: absolute;
          top: -1rem;
          right: 1rem;
          height: 1rem;
          width: 2rem;
          background-color: #ddd;
          -webkit-clip-path: polygon(50% 50%, 0% 100%, 100% 100%);
          clip-path: polygon(50% 20%, 0% 100%, 100% 100%);
        }
        span {
          display: inline-block;
          text-align: center;
          width: 100%;
          font-size: 1.2rem;
          border: none;
          padding: 0;
        }
      }
    }
  }
}
