.course-category-slider {
  &__wrapper {
    padding: 4rem 7rem;
    height: 610px;
  }
  display: grid;
  grid-template-columns: 400px 1fr;
  height: 100%;
  @include bpm-sm {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__wrapper {
      height: auto;
      padding: 4rem 0;
    }
  }
  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 5rem;
    @include bpm-sm {
      align-items: center;
      padding-bottom: 0;
    }
    &--header {
      font-size: 5rem;
      color: #333;
      font-weight: 800;
      @include bpm-sm {
        font-size: 3.6rem;
      }
    }
    &--duration {
      display: flex;
      align-items: center;
      img {
        width: 1.3rem;
        margin-right: 1rem;
        transform: translateY(0.1rem);
      }
      font-size: 1.4rem;
      color: #333;
      @include bpm-sm {
        margin-top: 3rem;
      }
    }
    &--content {
      font-size: 1.4rem;
      color: #333;
      max-width: 280px;
      font-weight: 600;
      line-height: 2.4rem;
      @include bpm-sm {
        margin: 3rem 0;
        text-align: center;
      }
    }
    &--keywords {
      display: flex;
      flex-wrap: wrap;
      max-width: 20rem;
      align-items: center;
      @include bpm-sm {
        justify-content: center;
        max-width: 100vw;
      }
      > div {
        display: block;
        width: max-content;
        height: 1.7rem;
        margin-bottom: 1rem;
        &:first-child {
          border-right: 2px solid black;
          padding-right: 1.7rem;
          @include bpm-sm {
            padding-right: 1.5rem;
          }
        }
        &:nth-child(2) {
          padding-left: 1.7rem;
          @include bpm-sm {
            padding: 0 1.5rem;
            border-right: 2px solid black;
          }
        }
        &:nth-child(3) {
          @include bpm-sm {
            padding-left: 1.5rem;
          }
        }
      }
      .keyword {
        font-size: 1.2rem;
        color: #333;
        // margin-bottom: 1.5rem;
        // &:nth-child(1) {
        //   border-right: 2px solid black;
        //   padding-right: 1.7rem;
        // }
      }
    }
    .secondary-button {
      margin-top: 5rem;
    }
  }
  .course-category-swiper {
    width: calc(100vw - 485px) !important;
    @include bpm-sm {
      width: 100vw !important;
      padding-bottom: 5rem;
      margin-top: 3rem;
      .swiper-pagination {
        margin-bottom: -1.5rem !important;
      }
    }
    margin-left: 0;

    &--communication {
      .swiper-pagination-bullet {
        background-color: #fff !important;
        border: 2px solid #83def0;
      }
      .swiper-pagination-bullet-active {
        background-color: #83def0 !important;
      }
    }
    &--music {
      .swiper-pagination-bullet {
        background-color: #fff !important;
        border: 2px solid #ffc142;
      }
      .swiper-pagination-bullet-active {
        background-color: #ffc142 !important;
      }
    }
    &--art {
      .swiper-pagination-bullet {
        background-color: #fff !important;
        border: 2px solid #8469c0;
      }
      .swiper-pagination-bullet-active {
        background-color: #8469c0 !important;
      }
    }
    @include bpm-sm {
      .swiper-slide {
        transition: all 0.15s linear !important;
        width: 28rem !important;
      }
      .swiper-slide-active {
        transition: all 0.15s linear !important;
        transform: scale(1.05) !important;
      }
    }
  }
  .swiper-slide {
    width: 32rem !important;
  }
  .swiper-pagination {
    // bottom: -3rem !important;
    margin-bottom: 1rem !important;
    @include bpm-sm {
      margin-bottom: -1.5rem !important;
    }
  }
  .swiper-button-next {
    transform: translate(7rem, -5rem);
    outline: none;
    border: none;
    background-color: rgba(255, 255, 255, 0.9);
    height: 10rem;
    width: 10rem;
    border-radius: 10rem;
    position: absolute;
    &::after {
      content: "";
      background: url("../../../public/assets/nextArrow.svg") center center/contain
        no-repeat;
      background-size: 2rem;
      transform: translateX(-2.3rem);
    }
  }
  .swiper-button-prev {
    transform: translate(-7rem, -5rem);
    outline: none;
    border: none;
    background-color: rgba(255, 255, 255, 0.9);
    height: 10rem;
    width: 10rem;
    border-radius: 10rem;
    position: absolute;
    &::after {
      content: "";
      background: url("../../../public/assets/nextArrow.svg") center center/contain
        no-repeat;
      background-size: 2rem;
      transform: rotate(180deg) translateX(-2.3rem);
    }
  }
  .swiper-button-disabled {
    opacity: 0 !important;
  }
}
