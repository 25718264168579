.summercamp-headercomponent {
  &__wrapper {
    padding: 7rem 7rem 0 7rem;
    margin-bottom: 4.8rem;

    @include bpm-md {
      padding: 7rem 3rem 0 3rem;
    }
    @include bpm-sm {
      padding: 7rem 1rem 0 1rem;
    }
    .homepage-callback__wrapper {
      margin-top: -20rem;
      @include bpm-md {
        margin-top: -10rem;
      }
    }
  }
  display: flex;
  flex-direction: column;
  // margin-top: 3rem;
  justify-content: center;
  align-items: center;
  // summer-camp-bg

  &-img {
    position: absolute;
    top: 0rem;
    width: 100%;
  }
  &--heading {
    font-family: Lato;
    font-size: 60px;
    font-style: normal;
    font-weight: 800;
    line-height: 77px;
    letter-spacing: 0px;
    text-align: center;
    color: rgba(0, 0, 0, 1);
    // z-index: 100;
    @include bpm-md {
      font-size: 28px;
      font-style: normal;
      font-weight: 800;
      letter-spacing: 0px;
      line-height: 35px;
      margin-top: 2.8rem;
      text-align: center;
    }
    // background-color: white;
  }
  &--sub-heading {
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 0.8rem;
    color: rgba(156, 95, 0, 1);
    // z-index: 100;
    @include bpm-md {
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
    }
    // background-color: white;
  }
  .primary-button {
    width: 20rem;
    margin: 3.2rem 0;
    z-index: 10;
    @include bpm-md {
      margin: 2.4rem 0 4.2rem;
    }
  }
  &__hamper-container {
    &__wrapper {
      padding: 0;
      width: 100%;
    }
    display: flex;
    // justify-content: center;
    justify-content: space-around;
    @include bpm-md {
      flex-direction: column;
      justify-content: center;
    }
  }
  &__termsAndConditions {
    font-family: Lato;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    // line-height: 32px;
    margin-top: 2.4rem;
    letter-spacing: 0px;
    // margin-top: 0.8rem;
    text-align: center;
    z-index: 5;
    cursor: pointer;
    color: rgba(51, 51, 51, 1);
    text-decoration: underline;
    @include bpm-md {
      margin-top: 0;
    }
  }
  &__s-button {
    width: 20rem;
    z-index: 100;
    margin: 2.4rem auto 0;
  }
}
