.spark-game {
  &__wrapper {
  }
  &__header {
    font-size: 4.8rem;
    margin-bottom: 2rem;
    color: #333;
    font-weight: 900;
    text-align: center;
    @include bpm-md {
      margin-bottom: 0rem;
      font-size: 2rem;
      display: none;
    }
  }
  &__sub-header {
    font-size: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    color: #666;
    font-weight: 500;
    @include bpm-md {
      margin-bottom: 1rem;
      font-size: 1.8rem;
    }
    span {
      color: #e47762;
    }
  }
}
