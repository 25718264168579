.copyReferral-button {
  color: #fff;
  background: linear-gradient(90deg, #f3705b 0%, #fcb444 100%);
  box-shadow: 0px 4px 14px #eeeeee;
  border-radius: 48px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  margin-top: 16px;
  cursor: pointer;
  img {
    margin-right: 16px;
    height: 24px;
    width: 24px;
  }
}
