.spark-book-a-trial {
  &__wrapper {
    padding: 10rem 0 5rem 4rem;
    max-width: 1200px;
    margin: auto;
    min-height: 640px;
    @include bpm-md {
      // max-width: 370px;
      // padding: 10rem 0 5rem 0;
    }
    @include bpm-custom(450px) {
      padding: 10rem 2rem 5rem 2rem;
    }
    &.low-padding {
      @include bpm-custom(450px) {
        padding: 8rem 0.5rem 5rem 0rem;
      }
    }
    &.low-padding-varient {
      @include bpm-md {
        padding: 10rem 2rem 5rem 2rem;
      }
    }
    &.app-booking-variant {
      padding: 0rem 0rem 5rem 1rem;
      height: 100vh;
    }
    &.app-booking-variant-1 {
      padding: 0rem 0rem 5rem 0rem;
      height: 100vh;
    }
    &.app-booking-confirmed {
      background: linear-gradient(180deg, #22b8fc -6.98%, #bffcea 68.64%);
      padding: 0rem 0rem 0rem 0rem;
      height: 100%;
    }
  }
  display: grid;
  grid-template-columns: 370px 1fr;
  &.confirming-page {
    grid-template-columns: 1fr;
  }

  @include bpm-md {
    display: block;
  }
  &__left {
    // background-color: red;
    // background: linear-gradient(135deg, #FFF4F8 2.88%, #F2FBFF 100%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include bpm-xxs {
      align-items: center;
    }
    &--stage {
      display: flex;
      align-items: center;
      .single-stage {
        font-size: 1.5rem;
        font-weight: 700;
        margin-right: 1rem;
        cursor: pointer;
        color: #333;
        @include bpm-md {
          font-size: 1.4rem;
        }
        &:hover {
          color: #333;
        }
        span {
          display: inline-block;
          text-align: center;
          font-size: 1.6rem;
          font-weight: 700;
          background-color: #ddd;
          height: 2.6rem;
          width: 2.6rem;
          border-radius: 5rem;
          padding: 0.3rem 0 0 0;
          margin: 0 0.5rem;
          color: #666;
          @include bpm-md {
            height: 2.2rem;
            width: 2.2rem;
            font-size: 1.4rem;
            padding: 0.2rem 0 0 0;
          }
        }
        &.active {
          span {
            background-color: #f2b65a;
            color: #fff;
          }
        }
        &.done {
          span {
            background-color: #90d4a3;
            color: #fff;
          }
        }
      }
    }
    &--app-booking-header {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      margin-top: 2rem;
      &---headText {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        color: #333333;
        margin-left: 2rem;
      }
      &---img {
        width: 160px;
        height: 120px;
      }
    }
    &--liner {
      font-size: 1.6rem;
      color: #666;
      font-weight: 500;
      margin: 1rem 0;
    }
    &--progress-bar-container {
      margin-top: 1rem;
      height: 3px;
      width: 100%;
      background-color: #ddd;
      span {
        height: 3px;
        background-color: #90d4a3;
        display: block;
      }
    }
  }
  &__right {
    &.onboarding-page {
      display: flex;
      justify-content: center;
      @include bpm-md {
        display: none;
      }
    }

    &--confirming-loader {
      width: 65%;
      display: flex;
      margin-left: 24rem;
    }
    &--confirmation-img {
      // margin-top: 0rem;
      // margin-left: 25%;
      // margin-right: 5%;
      margin: 10rem 5% 0 25%;
      width: 75%;
    }
    &--calender-img {
      width: 30%;
      margin-left: 35%;
    }
    &--joining-img {
      width: 60%;
      margin-left: 30%;
    }
    &--course-outline-img {
      width: 65%;
      object-fit: contain;
      margin-left: auto;
      margin-right: 5%;
    }
    &--personalise-img {
      width: 80%;
      margin-left: 15%;
      margin-top: 3rem;
    }
    &--bookingConfirmation-img {
      width: 80%;
      margin: 9% 3% 0 auto;
    }
    &--invitefriend-img {
      width: 67%;
      display: flex;
      margin-left: 25rem;
    }
    &--all-set-img {
      width: 80%;
      margin-left: 15%;
    }
    &__welcome_bg {
      width: 50%;
    }

    &--right__bg {
      width: 80%;
    }

    @include bpm-md {
      display: none;
    }
  }
}
