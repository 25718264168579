.profileCompletion {
  &__wrapper {
    display: flex;
    border-radius: 21px;
    background-color: white;
    box-shadow: 0px 0px 21.938579559326172px 0px #00000033;
    width: max-content;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 7px 12px;

    p {
      font-family: Lato;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0em;
    }
    img {
      margin-left: 5px;
      cursor: pointer;
    }
  }
  &--progressBar {
    width: 94px;
    background-color: #dcdcdc;
    border-radius: 10px;
    margin: 0px 10px;

    &-progress {
      background: linear-gradient(90.01deg, #f3705b -8.62%, #fcb444 60.83%);
      height: 4px;

      border-radius: 10px;
    }
  }
}
