.courseAccordion {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }
  &--header {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    border-bottom: 1px solid #eaeaea;
    &.active {
      border: none;
    }
    &-wrapper {
      display: flex;
    }
    h1 {
      font-family: Lato;
      font-style: normal;
      font-weight: 800;
      font-size: 14px;
      line-height: 16px;
      color: #333333;
    }
    &-trigger {
      cursor: pointer;
      transition: 0.5s;
      &.active {
        transform: rotate(180deg);
        transition: 0.5s;
      }
    }
  }
  &--details {
    display: none;
    &.active {
      display: block;
      padding-bottom: 5px;
      border-bottom: 1px solid #eaeaea;
      p {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.2px;
        color: #999999;
      }
    }
  }
}
