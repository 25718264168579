.hamper-card {
  &__wrapper {
    padding: 0;
    box-shadow: 0px 0px 8px rgba(75, 0, 188, 0.08);
    border-radius: 24px;
  }
  display: flex;
  flex-direction: column;
  position: relative;

  //   background-color: rgba(243, 112, 91, 0.9);
  // width: 100%;
  width: 50rem;
  // min-height: 44rem;
  border-radius: 1.6rem;
  margin: 0 0 0rem;
  @include bpm-lg {
    width: 43rem;
  }
  @include bpm-custom(1024) {
    width: 40rem;
  }
  @include bpm-xs {
    width: 35rem;
  }
  @include bpm-md {
    margin: 0 auto 3.2rem;
  }

  &__topBanner {
    width: 100%;
    background-color: white;
    height: 5.5rem;
    border-radius: 1.6rem 1.6rem 0 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 2rem;

    h1 {
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 900;
      letter-spacing: 0px;
      text-align: center;
      color: rgba(51, 51, 51, 1);
      width: 100%;
    }
  }
  &--img {
    width: 100%;
  }

  &__bottomBanner {
    width: 100%;
    background-color: white;
    min-height: 4rem;
    // position: absolute;
    border-radius: 1.6rem 1.6rem 0 0;
    border-radius: 0 0 1.6rem 1.6rem;
    padding: 0.5rem 0 0 2rem;

    bottom: -1px;

    h1 {
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0px;
      text-align: center;
    }
  }

  //   background-color: rgba(255, 255, 255, 1);
}
