.recommended-class {
  &__wrapper {
    padding: 0;
  }
  display: flex;
  justify-content: center;
  margin: 4rem 0;
  @include bpm-xs {
    margin: 0;
  }
  &__card {
    &__wrapper {
      width: 100%;
      padding: 2.4rem 4rem 1.6rem;
      background-color: white;
      min-height: 20rem;
      border-radius: 1.6rem;
      @include bpm-md {
        padding: 2.4rem 3rem;
      }
      @include bpm-xs {
        padding: 2.4rem 2.25rem;
      }
    }
    display: flex;
    flex-direction: row;
    width: 100%;
    @include bpm-md {
      flex-direction: column-reverse;
      width: 100%;
    }
    &__left {
      display: flex;
      flex-direction: column;
      width: 50%;
      @include bpm-md {
        width: 100%;
        // flex-direction: row;
      }
      // @include bpm-lg {
      //   width: 60%;
      // }
      &__row {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 1.5rem;
        // justify-content: space-around;
        @include bpm-xs {
          width: 100%;
          flex-direction: column;
        }

        &.buttonContainer {
          margin-bottom: 0;
          justify-content: right;
          @include bpm-xs {
            align-items: center;
          }
        }
        // flex-direction: row;
        h3 {
          font-family: Lato;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
        }
        &--s-text {
          font-family: Lato;
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
        }
        &--t-text {
          font-family: Lato;
          font-size: 16px;
          font-weight: 500;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: left;
        }
        .primary-button {
          width: 12rem;
          height: 4rem;
        }
      }
    }
    &__right {
      display: flex;
      width: 55%;
      &--img {
        width: 46%;
        object-fit: contain;
        @include bpm-md {
          width: 100%;
        }
      }
    }
  }
}
