.course-info-card {
  &__wrapper {
    font-family: Lato;
    margin: 1.6rem 0 1rem 0rem;
    h1 {
      color: rgba(51, 51, 51, 1);
      font-size: 18px;
      font-style: normal;
      font-weight: 900;
      line-height: 22px;
      letter-spacing: -0.14941634237766266px;
      text-align: left;
      span {
        color: rgba(51, 51, 51, 1);
        font-size: 18px;
        margin-left: 0.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.14941634237766266px;
        text-align: left;
      }
    }
  }

  max-width: 95%;
  height: 8.4rem;
  padding: 2rem 1.5rem;
  margin: 1rem 0 2rem 0.8rem;
  border-radius: 0.5rem;
  background-color: #e7f2ff;
  &.bg-yellow {
    background-color: rgba(255, 250, 233, 1);
  }
  &.bg-purple {
    background-color: rgba(241, 233, 255, 1);
  }
  &--course-name {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 700;
    color: rgba(102, 102, 102, 1);
    line-height: 19px;
    letter-spacing: -0.14941634237766266px;
    text-align: left;
    &.app_booking {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.149416px;
      color: #333333;
      margin-top: -5px;
    }
  }
  &--timimgs {
    font-size: 1.4rem;
    font-style: normal;
    margin-top: 1rem;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: -0.14941634237766266px;
    text-align: left;
    color: rgba(102, 102, 102, 1);
    &.app_booking {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height */
      letter-spacing: -0.149416px;
      color: #666666;
    }
  }
  &--edit {
    font-size: 1.6rem;
    margin: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: center;
    margin-right: 3rem;
    margin-top: 1.1rem;
    color: #e47762;
    text-decoration: underline;
  }
  &.app_booking {
    box-shadow: 0px 4px 4px 1px rgba(51, 51, 51, 0.25);
    border-radius: 8px;
  }
}
