.spark-game-choose-difficulty {
  &__wrapper {
    padding-top: 5rem;
  }
  &__header {
    font-size: 4.8rem;
    margin-bottom: 2rem;
    color: #333;
    font-weight: 900;
    text-align: center;
    @include bpm-sm {
      margin-bottom: 1rem;
      font-size: 3.6rem;
    }
  }
  &__sub-header {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: #666;
    font-weight: 500;
    text-align: center;
    @include bpm-sm {
      margin-bottom: 1rem;
      font-size: 1.8rem;
    }
  }
  &__levels {
    max-width: 76rem;
    margin: 4rem auto 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    justify-items: center;
    overflow-x: hidden !important;
    @include bpm-custom(850px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
