.change-age-alert {
  position: fixed;
  width: 100%;
  top: 7rem;
  z-index: 1000;
  background-color: #e47762;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 7rem;
  font-size: 1.6rem;
  color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &.disable-z-index {
    z-index: 0;
    top: 65px;
  }
  @include bpm-custom(630) {
    padding: 0 2rem;
  }
  &__btn {
    font-weight: 700;
    cursor: pointer;
  }
}
