.edit-details-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc6c6;
  padding: 0 16px;
  @include bpm-xs {
    padding: 0;
  }

  &_wrapper {
    height: 72px;
    @include bpm-xs {
      padding: 0 16px;
    }
  }
  .primary-button {
    height: 20px;
    width: 60px;
    padding: 15px;
    border: none;
  }
  &_input {
    outline: none;
    background: #fff;
    padding: 5px;
    margin: 15px;
    border: none;
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    color: #333333;
    width: 400px;
    @include bpm-lg {
      width: 300px;
    }
    @include bpm-md {
      width: 200px;
    }
  }
  &_edit-text {
    width: 29px;
    height: 19px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-decoration: underline;
    background: linear-gradient(90deg, #e47762 0%, #f2b65a 98.07%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    border-bottom: 2px solid #f2b65a;
    margin-left: 8px;
  }
  &_edit-icon_wrapper {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
