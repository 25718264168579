.course-card-slider {
  &__wrapper {
    width: 100%;
    &.bg-color {
      background-color: rgba(255, 248, 238, 1);
    }
  }
  &__swiper {
    width: 100%;
    padding-bottom: 6rem !important;
    .swiper-slide {
      width: 34rem;
    }
    .swiper-pagination {
      margin-bottom: 0;
    }

    .swiper-button-prev.swiper-button-disabled {
      opacity: 0 !important;
    }
  }
}
