.spark-welcome-screen {
  &__wrapper {
    min-height: 40rem;
  }
  &__header {
    font-size: 2rem;
    color: #333;
    font-weight: 700;
    margin-bottom: 2rem;
    &.v1 {
      font-size: 42px;
      font-weight: 900;
      line-height: 58px;
      letter-spacing: -0.20000000298023224px;
      text-align: left;
    }
    &__span {
      font-size: 28px;
      font-weight: 900;
      line-height: 38px;
      letter-spacing: -0.20000000298023224px;
      text-align: left;
    }
  }
  &__content {
    font-size: 1.6rem;
    color: #666;
    margin-bottom: 1rem;
    &.v1 {
      font-size: 22px;
      line-height: 32px;
      font-weight: 400;
      letter-spacing: -0.20000000298023224px;
      text-align: left;
      margin-bottom: 8rem;
    }
  }
  &__list {
    margin-bottom: 10rem;
    li {
      font-size: 1.6rem;
      font-weight: 700;
    }
  }
  .urgency {
    display: inline-block;
    width: 100%;
    margin: auto;
    font-size: 2rem;
    font-weight: 900;
    text-align: center;
    margin: 1rem 0;
  }
  .primary-button {
    width: 25rem;
    margin: auto;
  }
}
