.upcomingClassCard {
  display: inline-grid;
  grid-template-columns: auto;
  width: 95%;
  @include bp-sm {
    grid-template-columns: 350px auto;
  }

  padding: 25px;
  border-radius: 8px;
  // background: linear-gradient(
  //   113.87deg,
  //   #a0dce7 -9.5%,
  //   rgba(255, 255, 255, 0.5) 97.11%
  // );
  box-shadow: 50px 60px 100px rgba(0, 0, 0, 0.05);

  &__wrapper {
    margin-top: 50px;
  }
  &--footer {
    display: flex;
    p {
      color: #999999;
      font-family: Lato;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px;
      letter-spacing: 0px;
      text-align: left;
      max-width: max-content;
      @include bpm-sm {
        font-size: 10px;
      }
    }
  }
  &--left {
    margin-right: 50px;
    @include bpm-sm {
      margin-right: 0px;
    }
    display: grid;
    text-align: left;
    &-top {
      h1 {
        font-family: Lato;
        font-size: 26px;
        font-style: normal;
        font-weight: 900;
        line-height: 25px;
        letter-spacing: 0em;
        max-width: 350px;
        color: #333333;
        @include bpm-sm {
          margin-bottom: 15px;
          color: #000632;
          font-size: 24px;
        }
      }
      p {
        display: none;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 21px;
        letter-spacing: 0em;

        span {
          font-family: Lato;
          font-size: 14px;
          font-style: normal;
          font-weight: 900;
          line-height: 21px;
          letter-spacing: 0em;
          margin-left: 2px;
        }
        @include bpm-sm {
          display: block;
        }
        margin-bottom: 10px;
      }
    }
    &-bottom {
      p {
        font-family: Lato;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #666666;
        @include bpm-sm {
          font-size: 14px;
        }
      }
      span {
        font-family: Lato;
        font-size: 17px;
        font-style: normal;
        font-weight: 900;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #333333;
        @include bpm-sm {
          font-size: 14px;
        }
      }
    }
  }
  //!This section holds the right half of the card
  //! the top upcoming class text is hidden on smaller devices and the one the left section is
  //! displayed instead
  &--right {
    margin-right: 20px;
    &-top {
      @include bpm-sm {
        display: none;
      }
      p {
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 21px;
        letter-spacing: 0em;
      }
      span {
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 900;
        line-height: 21px;
        letter-spacing: 0em;
        margin-left: 2px;
      }
    }
    //! This section holds the css for the timer / countdown part
    &-bottom {
      background-color: white;
      margin: 20px;
      box-shadow: 0px 2px 6px rgba(91, 22, 170, 0.12),
        inset 1px 0px 3px rgba(255, 255, 255, 0.5);
      border-radius: 12px;

      padding: 0px 50px;
      padding-top: 10px;
      @include bpm-sm {
        padding: 0px;
      }
      p {
        font-family: Lato;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0px;
        text-align: center;
        color: #333333;
      }
      //! Timer encapsulates the timer element
      &-timer {
        display: flex;
        justify-content: center;
        //! element is individual sections in the timer component
        &-element {
          margin: 15px;
          &:nth-child(2) {
            border-left: 1px solid #c7bdbd;
            border-right: 1px solid #c7bdbd;
            padding: 0px 15px;
          }
          //! This class is added to the timer when the days element is present
          //! This class is needed to change borders and margins for this scenario
          &.withDays {
            margin: 15px 5px;
            &:nth-child(1) {
              margin: 15px;
            }
            &:nth-child(2) {
              margin: 15px;
            }
            &:nth-child(3) {
              margin: 15px;
            }
            &:nth-child(4) {
              display: none;
            }
          }
          h2 {
            color: #e47762;
            font-family: Lato;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0px;
          }
          p {
            font-family: Lato;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
            letter-spacing: 0px;
            text-align: center;
            color: #666666;
          }
        }
      }
    }
    &-reschedule {
      display: flex;
      justify-content: flex-end;
      p {
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */
        text-decoration-line: underline;
        color: #666666;
        padding-right: 2rem;
        cursor: pointer;
        @include bpm-xs {
          margin-top: -1rem;
          margin-bottom: 1rem;
        }
      }
    }
  }
}
