.certificate_download_modal {
  &_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 130vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1100;
    display: none;
  }

  &-close {
    width: 42px;
    height: 42px;
    position: absolute;
    right: 10px;
    top: -15px;
    z-index: 1000;
    cursor: pointer;
  }
  background-color: #ffffff;
  border-radius: 16px;
  opacity: 1;
  width: max-content;
  position: relative;
  padding: 1rem 7rem 3rem 7rem;
  @include bpm-lg {
    // width: 85%;
  }
  @include bpm-xs {
    width: max-content;
    padding: 1rem 2rem 2rem 2rem;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  &-topImage {
    width: 305.48px;
    height: 314.08px;
    @include bpm-xs {
      width: 202px;
      height: 207px;
    }
  }
  &-imageWrapper {
  }
  &-heading {
    h1 {
      color: #333;
      font-size: 30px;
      font-family: Lato;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      letter-spacing: 0.2px;
    }

    p {
      color: #666;
      text-align: center;
      font-size: 18px;
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.2px;
      margin: 11px 0;
    }
  }
}

.courses_modal {
  &_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 130vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1100;
    display: none;
  }
  &_container {
    span {
      color: #333;
      font-size: 28px;
      font-family: Lato;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
    }
    &__courseContainer {
      margin-top: 18px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 10px;
      &-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        img {
          width: 160px;
          height: 120px;
          flex-shrink: 0;
          border-radius: 8px;
          box-shadow: 0px 4px 4px 0px rgba(51, 51, 51, 0.4);
        }
        div {
          color: #333;
          font-size: 18px;
          font-family: Lato;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }

  &-close {
    width: 42px;
    height: 42px;
    position: absolute;
    right: 10px;
    top: -15px;
    z-index: 1000;
    cursor: pointer;
  }
  background-color: #ffffff;
  border-radius: 16px;
  opacity: 1;
  max-width: 992px;
  position: relative;
  padding: 1rem 7rem 3rem 7rem;
  @include bpm-lg {
    width: 85%;
  }
  @include bpm-xs {
    width: max-content;
    padding: 1rem 2rem 2rem 2rem;
  }
  @include bpm-custom(500) {
    max-width: 391px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  &__peep {
    width: 155px;
    height: 244px;
    position: absolute;
    top: -23.2rem;
    left: 2rem;
  }
}
