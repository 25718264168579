.ted-ed-combo {
  &__wrapper {
    padding: 5rem 7rem;
    @include bpm-custom(600px) {
      padding: 5rem 0;
    }
    .ted {
      color: #da3a34;
      font-weight: 900;
    }
    .ed {
      color: #000;
      font-weight: 900;
    }
    .club {
      color: #000;
      font-weight: 300;
    }
  }
  display: flex;
  align-items: flex-start;
  @include bpm-custom(1300px) {
    flex-direction: column;
    align-items: center;
  }
  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 50rem;
    @include bpm-custom(1300px) {
      align-items: center;
    }
    @include bpm-custom(600px) {
      padding: 3rem;
    }
    &--header {
      font-size: 3.2rem;
      font-weight: 900;
      color: #333;
      text-align: center;
    }
    &--content {
      font-size: 2rem;
      font-weight: 500;
      color: #666;
      margin: 2rem 0 5rem 0;
    }
    &--purchase {
      display: flex;
      align-items: center;
      .price {
        margin-left: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        .slashed-price {
          font-size: 1.8rem;
          font-weight: 700;
          position: relative;
          margin-bottom: 0.5rem;
          &::after {
            content: "";
            position: absolute;
            bottom: 45%;
            left: 0;
            height: 1px;
            width: 130%;
            transform: translateX(-12%);
            background-color: #333;
          }
        }
        .final-price {
          font-size: 2.4rem;
          font-weight: 900;
        }
      }
      @include bpm-custom(1300px) {
        margin-bottom: 5rem;
      }
    }
  }
  &__right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @include bpm-custom(1300px) {
      justify-content: center;
      > div + div {
        margin-left: 3rem;
      }
    }
    .swiper-slide {
      width: 28rem;
    }
    .swiper-pagination {
      margin-bottom: 0 !important;
    }
    .swiper-container {
      padding-bottom: 5rem !important;
    }
  }
}
