.results-vocabulary-card {
  &__wrapper {
    background-color: rgba(44, 159, 146, 1);
    min-height: 43.7rem;
    margin: 4.8rem auto;
    width: 75%;

    border-radius: 2.4rem;
    @include bpm-md {
      width: 100%;
      height: 55.2rem;
      margin: 2rem auto;
      overflow-y: scroll;
    }
  }
  display: flex;
  justify-content: space-between;
  padding: 3.2rem 2.4rem;
  @include bpm-md {
    flex-direction: column;
    padding: 3.2rem 2.4rem 12rem;
  }
  @include bpm-custom(375) {
    padding: 3.2rem 1rem 12rem;
  }
  &__left {
    display: flex;
    flex-direction: column;
    width: 50%;
    @include bpm-md {
      width: 100%;
    }
    &__primaryContainer {
      display: flex;
      flex-direction: column;
      @include bpm-md {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
      }
      &__left {
        display: flex;
        flex-direction: column;
      }
      &__right {
        @include bp-md {
          display: none;
        }
        &--img {
          object-fit: contain;
        }
      }
    }
    &--p-heading {
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 29px;
      letter-spacing: 0px;
      text-align: left;
      color: rgba(255, 255, 255, 1);
    }
    &--s-heading {
      margin-top: 0.8rem;
      font-family: Lato;
      margin-bottom: 1.2rem;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      color: rgba(255, 255, 255, 1);
    }
    &__label-container {
      display: flex;
      padding-left: 2rem;
      margin-top: 2.2rem;
      @include bpm-md {
        display: none;
      }
      &--square-box {
        width: 4.3rem;
        height: 2rem;
        border-radius: 0.4rem;
        margin: 0 1rem 0 0rem;
        &.orange {
          background-color: rgba(254, 162, 65, 1);
        }
        &.yellow {
          margin: 0 1rem 0 1.5rem;
          background-color: rgba(255, 208, 29, 1);
        }
        @include bpm-xs {
          width: 2.5rem;
          height: 2rem;
        }
      }
      &--p-text {
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        color: rgba(255, 255, 255, 1);
        text-align: left;
      }
    }
    &__label-mobile-container {
      display: flex;
      // padding-left: 2rem;
      margin-top: 2.2rem;
      margin-bottom: 2rem;
      @include bp-md {
        display: none;
      }
      &--square-box {
        width: 4.3rem;
        height: 2rem;
        border-radius: 0.4rem;
        margin: 0 0.5rem;
        &.orange {
          background-color: rgba(254, 162, 65, 1);
        }
        &.yellow {
          background-color: rgba(255, 208, 29, 1);
        }
      }
      &--p-text {
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        color: rgba(255, 255, 255, 1);
        text-align: left;
      }
    }
  }
  &__right {
    display: flex;
    width: 50%;
    @include bpm-md {
      width: 100%;
    }
    flex-direction: column;
    &--p-text {
      font-family: Lato;
      font-size: 18px;
      position: relative;
      padding: 0 8% 0 0;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      @include bpm-md {
        display: none;
      }
      @include bpm-custom(1230) {
        font-size: 16px;
        // padding: 0 11rem 0 0;
      }
      @include bpm-custom(1024) {
        font-size: 14px;
        // padding: 0 8rem 0 0;B
      }
      color: rgba(255, 255, 255, 1);
      &--thinking-logo {
        width: 9rem;
        height: 9rem;
        right: 2rem;
        top: -8rem;
        @include bpm-md {
          display: none;
        }
        // position: absolute;
      }
    }
    &--mobile-p-text {
      font-family: Lato;
      font-size: 17px;
      position: relative;
      padding: 0 8% 0 0;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0px;
      margin-top: 1.6rem;
      text-align: left;
      @include bp-md {
        display: none;
      }

      color: rgba(255, 255, 255, 1);
    }
    &__container {
      display: flex;
      flex-wrap: wrap;
      margin-top: 3rem;
      @include bpm-md {
        justify-content: space-between;
      }
      padding: 0;
      &--card {
        width: 18.55rem;
        min-height: 8rem;
        display: flex;
        padding: 1rem 1rem 1rem;
        flex-direction: column;
        margin: 0.5rem 1rem;
        justify-content: space-around;
        border-radius: 1.6rem;
        background-color: rgba(251, 248, 249, 0.8);
        @include bpm-custom(1230) {
          padding: 1rem 1rem 1rem;
          width: 14.55rem;
        }
        @include bpm-custom(1024) {
          width: 13rem;
        }
        @include bpm-md {
          width: 43%;
          min-height: 7rem;
          border-radius: 0.8rem;
        }
        @include bpm-custom(400) {
          margin: 0.5rem;
        }

        &--p-text {
          font-family: Lato;
          font-size: 16px;
          font-style: normal;
          font-weight: 900;
          letter-spacing: 0px;
          text-align: center;
          color: rgba(51, 51, 51, 1);
          @include bpm-custom(1230) {
            font-size: 14px;
          }
          @include bpm-custom(1024) {
            font-size: 12px;
          }
        }
        &--s-text {
          font-family: Lato;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 25px;
          letter-spacing: 0px;
          text-align: center;
          color: rgba(51, 51, 51, 1);
        }
      }
    }
    &--s-text {
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      width: 100%;
      margin: 4rem auto 1rem;
      padding-left: 32%;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      color: rgba(255, 255, 255, 1);
      text-decoration: underline;
    }
  }
}
