.assignment-upload-modal-new {
  padding: 20px;
  &_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 120vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
    display: none;
  }
  &-close {
    width: 42px;
    height: 42px;
    position: absolute;
    right: 10px;
    top: -15px;
    z-index: 1000;
    cursor: pointer;
  }
  &-header {
    font-weight: 700;
    font-size: 22px;
  }
  background-color: #ffffff;
  border-radius: 16px;
  opacity: 1;
  width: 40%;
  position: relative;
  @include bpm-lg {
    width: 40%;
  }
  @include bpm-sm {
    width: 65%;
  }
  @include bpm-xs {
    width: 94%;
  }
  display: flex;
  flex-direction: column;
  //   align-items: center;
  flex-direction: column;
  &_dragDrop--container {
    margin-top: 2rem;
    background: rgba(242, 182, 90, 0.2);
    border: 1px dashed rgba(242, 182, 90, 0.48);
    border-radius: 16px;
    display: flex;
    justify-content: center;

    & > div {
      padding: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    &_drag-text {
      margin-top: 2rem;
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
    }
    &_supported-format-text {
      margin-top: 1rem;
      color: #676767;
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
    }
  }
  &_file-uploading-section {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    &--heading-text {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #676767;
    }
    &_files {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 16px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #0f0f0f;
      border: 1px solid #11af22;
      border-radius: 4px;
    }
  }

  &_uploaded-file-view {
    display: flex;
    gap: 2.5rem;
    max-height: 180px;
    &_left {
      position: relative;
      flex: 0.4;
      .file-preview {
        width: 100%;
        height: 100%;
      }
      &--bottom-overlay-div {
        position: absolute;
        bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
      }
    }
    &_right {
      flex: 0.6;
      display: flex;
      flex-direction: column;
      gap: 16px;
      input[type="text"] {
        width: 100%;
        padding: 12px 20px;
        margin: 8px 0;
        border: none;
        box-shadow: 0px 0px 8px rgba(75, 0, 188, 0.08);
        border-radius: 8px;

        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #333333;
        &:focus {
          outline: none;
        }
      }

      textarea {
        width: 100%;
        padding: 12px 20px;
        border: none;
        box-shadow: 0px 0px 8px rgba(75, 0, 188, 0.08);
        border-radius: 8px;

        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #333333;
        height: 130px;
        resize: none;
        &:focus {
          outline: none;
        }
      }
    }
  }
}
