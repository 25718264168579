.onboarding-stage {
  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .single-stage {
    height: 5px;
    width: calc(25% - 0.5rem);
    border-radius: 5rem;
    &.empty {
      background-color: rgba(236, 127, 87, 0.2);
    }
    &.half {
      background-color: rgba(236, 127, 87, 0.2);
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 5px;
        width: 50%;
        border-radius: 5rem;
        background-color: rgb(236, 127, 87);
      }
    }
    &.filled {
      background-color: rgb(236, 127, 87);
    }
  }
  &__stage-title {
    display: flex;
    align-items: center;
    margin: 1.5rem 0 2.5rem;
    width: 100%;
    span {
      min-width: 5.5rem;
      font-size: 1.5rem;
      font-weight: 500;
      display: inline-block;
      margin-right: 1rem;
      color: #666;
    }
    h2 {
      font-size: 2.4rem;
      font-weight: 900;
      color: #333;
      min-width: 215px;
    }
  }
}
