.cart-drawer {
  // position: fixed;
  &__wrapper {
    -webkit-overflow-scrolling: touch;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100vh - 6.9rem);
    overflow-y: scroll;
  }
  position: relative;
  &__close-button {
    cursor: pointer;
    position: absolute;
    width: 3rem;
    top: 1.6rem;
    left: 0.5rem;
    background: #fff;
    border-radius: 5rem;
  }
  &__header {
    font-size: 1.8rem;
    text-align: center;
    margin: 2rem 0;
  }
  &__bottom {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 2rem;
    .primary-button {
      width: 100%;
    }
  }
  &__discount-text {
    text-align: center;
    color: #f3705b;
    font-size: 1.6rem;
    margin: 1rem 0;
    font-weight: 700;
  }
  &__promo {
    height: 5rem;
    padding: 0 2rem;
    font-size: 1.6rem;
    width: 100%;
    border: none;
    outline: none;
    border-radius: 8px;
    margin-top: 2rem;
    &:focus,
    &:active {
      border: none;
      outline: none;
    }
    background-color: #fff;
    justify-self: flex-start;
  }
  &__terms {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    align-self: center;
    color: #333;
    margin: 2rem 0 !important;
    input {
      cursor: pointer;
      margin-bottom: -0.1rem;
    }
    a {
      color: #f2715a;
      font-weight: 500;
      margin-left: 0.4rem;
      text-decoration: underline;
    }
  }
}
