.gsv-welcome-page {
  &__wrapper {
    padding: 0;
  }
  display: flex;
  width: 100%;
  margin-top: 2rem;
  position: absolute;
  justify-content: space-between;
  @include bpm-md {
    flex-direction: column;
  }
  &__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 14rem 0px 7rem;
    width: 50%;
    @include bpm-md {
      width: 100%;
      margin-bottom: 2.4rem;
    }
    @include bpm-xs {
      padding: 0 3rem 0px 3rem;
    }

    &--heading {
      font-family: Poppins;
      font-size: 40px;
      font-weight: 900;
      line-height: 50px;
      letter-spacing: 0px;
      text-align: left;
      color: rgba(51, 51, 51, 1);
      @include bpm-md {
        font-size: 25px;
        font-weight: 900;
        line-height: 30px;
        margin-bottom: 2.4rem;
      }
    }

    &__container {
      width: 26rem;
      height: 26rem;
      @include bp-md {
        display: none;
      }
      &-img {
        position: absolute;
        width: 20rem;
        right: 3rem;
        top: 3rem;
      }
    }
    &--s-heading {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      margin-top: 4rem;
      line-height: 30px;
      letter-spacing: 0px;
      text-align: left;
      .span {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0px;
        text-align: left;
      }
      @include bpm-xs {
        margin-top: 3.2rem;
        line-height: 21px;
      }
    }
  }
  &__right {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 50%;
    @include bpm-md {
      width: 100%;
    }
    &__container {
      width: 32rem;
      height: 32rem;
      @include bpm-md {
        display: none;
      }
      &-img {
        position: absolute;
        width: 24rem;
        right: 3.9rem;
        top: 4rem;
      }
    }
  }
}

.gsv-footer {
  display: flex;
  width: 100%;
  margin-top: 2rem;
  position: absolute;
  background: transparent;
  box-shadow: none;
  justify-content: flex-start;
}

.gsv-button-mobile {
  margin-top: 1.6rem;
  @include bpm-md {
    display: none;
  }
}

.gsv-button-desktop {
  margin-top: 1.6rem;
  @include bp-md {
    display: none;
  }
}
