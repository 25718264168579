.spelling-bee-answers-breakdown {
  &_wrapper {
    box-shadow: 0px 6.50084px 6.50084px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    width: max-content;
    padding: 2rem 2rem 0rem 2rem;
    @include bpm-md {
      width: 100%;
      border-radius: 0px;
      margin-bottom: 2rem;
      padding: 2rem 3rem 0rem 3rem;
      box-shadow: none;
    }
  }
  &-top {
    &_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 2rem;
    }
    &-text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      letter-spacing: 0.03em;
      color: #333333;
      text-align: left;
      @include bpm-xs {
        font-size: 20px;
        line-height: 30px;
      }
    }
    &-bulb {
      width: 84px;
      height: 100px;
      @include bpm-xs {
        width: 52px;
        height: 62px;
      }
    }
  }
  &-word {
    &_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      width: 436px;
      height: 68px;
      margin-bottom: 1.5rem;
      padding: 0rem 2rem;
      @include bpm-md {
        width: 100%;
      }
      @include bpm-xs {
        height: 40px;
      }
    }
    &-text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #333333;
      @include bpm-md {
        word-wrap: none;
        line-break: none;
      }
      @include bpm-xs {
        font-size: 16px;
        line-height: 24px;
      }
    }
    &-image {
      width: 25px;
      height: 24px;
      @include bpm-xs {
        display: none;
      }
    }
  }
}
