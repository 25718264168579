.studioFooter {
  &__wrapper {
    position: relative;
    @include bpm-xs{
        margin-top: 100px;
    }
  }

  &-image {
    width: 100%;
  }
  &--top {
  }
  &--bottom {
    position: absolute;
    top: 10%;
    left: 35%;
    @include bpm-lg {
      top: 15%;
      left: 25%;
    }
    @include bpm-sm{
      top: 6%;
      left: 30%;
    }
    @include bpm-custom(420px){
    left: 55px;
    top: -120px;
    }
    @include bpm-custom(400px){
      
        left: 40px;
      
    }
   
  }
}
