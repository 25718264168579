.studio-container {
  &_wrapper {
    padding: 0;
    margin: 0;
    z-index: 1;
  }
  display: flex;
  flex-direction: column;
  width: 77%;
  min-height: 15rem;
  margin: 2.4rem 0 2.4rem 16%;
  border-radius: 2.4rem;
  background-color: #ffffff;
  padding: 3.8rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @include bpm-md {
    padding: 1.6rem;
    margin-top: 2.4rem;
    margin-left: auto;
    margin-right: auto;
  }
  @include bpm-xs {
    margin-left: 50px;
    background-color: transparent;
  }

  &_header {
    display: flex;
    justify-content: space-between;
    padding: 0 25px;
    align-items: center;
    &__left {
      color: #333;
      font-size: 30px;
      font-family: Lato;
      font-weight: 900;
    }
  }
  &-cards {
  }
  .card-1 {
    margin: 30px 0px 0px 16px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    border-radius: 24px;
  }
  .card-2 {
    margin: 30px 0px 0px 0px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    border-radius: 24px;
    @include bpm-md {
      margin: 30px 36px 0px 105px;
    }
  }
  .card-3 {
    margin: 30px 0px 0px 0px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    border-radius: 24px;
  }
}
.share {
  font-size: 20px !important;
  font-family: Lato;
  font-weight: 700;
  background: -webkit-linear-gradient(
    rgba(243, 112, 91, 1),
    rgba(252, 180, 68, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @include bpm-xs {
    display: none;
  }
}

.studio_swiper .swiper-slide {
  // display: flex !important;
  // justify-content: space-between !important;
  // width: 100% !important;
  width: auto !important;
  gap: 8rem !important;
  @include bpm-md {
    gap: 0rem !important;
  }
}
