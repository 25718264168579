.prefered-batch-selection-modal {
  padding: 2rem 2rem;

  @include bpm-xs {
    max-height: 750px;
    padding: 2rem 2rem;
    overflow-y: scroll;
  }

  &-header {
    font-family: "Lato";
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
    /* identical to box height */

    color: #333333;
  }
  &-batches {
    &_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 2rem;
      margin-top: 2rem;
      margin-bottom: 3rem;
    }

    border: 2px solid rgba(153, 153, 153, 0.4);
    filter: drop-shadow(50px 60px 100px rgba(0, 0, 0, 0.05));
    border-radius: 8px;
    padding: 2rem 2rem;
    cursor: pointer;
    position: relative;
    &.active {
      border: 2px solid #98e35f;
    }

    @include bpm-xs {
      padding: 1rem 1rem;
    }

    &-batchNumber {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #999999;
      padding: 1rem 1rem;
      border: 1px solid rgba(153, 153, 153, 0.2);
      border-radius: 8px;
      margin-bottom: 1rem;
      width: max-content;
      &.active {
        border: 1px solid rgba(152, 227, 95, 0.8);
        color: #333333;
      }
    }
    &-batchDate {
      &-bold {
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: #333333;
      }
      &-fade {
        font-family: "Lato";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #666666;
      }
    }
    &-tickIcon {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}
