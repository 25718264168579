.live_upcoming_class {
  //! Css for upcoming class card wrapper
  &-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 20px;
    // background: linear-gradient(
    //   113.87deg,
    //   #a0dce7 -9.5%,
    //   rgba(255, 255, 255, 0.5) 97.11%
    // );
    box-shadow: 50px 60px 100px rgba(0, 0, 0, 0.05);
    // backdrop-filter: blur(70px);
    border-radius: 16px;
    @include bpm-xs {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    @include bpm-custom(375) {
      padding: 20px 4px;
    }
  }
  //! Css for left half of upcoming class card
  &-left {
    display: flex;
    flex-direction: column;
    &-mainText {
      font-family: "Lato";
      font-style: normal;
      font-weight: 800;
      font-size: 40px;
      line-height: 48px;
      color: #333333;
      white-space: nowrap;
      margin-bottom: 15px;
      @include bpm-xs {
        font-size: 24px;
        line-height: 29px;
        text-align: center;
      }
    }
    &-subText {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      color: #333333;
      text-align: left !important;
      @include bpm-xs {
        font-size: 20px;
        line-height: 28px;
        text-align: center !important;
      }
    }
    &-invite_friend_wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 15px;
      cursor: pointer;
      @include bpm-xs {
        justify-content: center;
        align-items: center;
      }
      &.mobile {
        display: none;
        @include bpm-xs {
          display: flex;
          margin-top: 0px;
          margin-bottom: 10px;
        }
      }
      &.desktop {
        display: flex;
        @include bpm-xs {
          display: none;
          margin-top: 0px;
          margin-bottom: 20px;
        }
      }
    }
    &-invite_friend_image {
      margin-right: 10px;
      @include bpm-xs {
        width: 24px;
        height: 24px;
        margin-right: 5px;
      }
    }
    &-invite_friend_text {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 25px;
      color: #333333;
      @include bpm-xs {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
  //! Css for right half of upcoming class card
  &-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include bpm-xs {
      width: 100%;
    }
    &-buttonWrapper {
      padding: 0px 15px;
      &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        &__button {
          cursor: pointer;
          width: fit-content;
          text-align: center;
          display: none;
          font-family: "Lato";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 120%;
          text-decoration-line: underline;
          color: #333333;

          @include bpm-xs {
            display: flex;
            margin: 1rem 0rem;
          }
        }
        &__p-button {
          width: 100%;

          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
          &.disabled {
            background: linear-gradient(89.83deg, #7e7e7e 0.1%, #b0b0b0 99.81%);
          }
        }
      }
      &--reminderText {
        font-family: "Lato";
        font-style: italic;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        text-transform: uppercase;
        color: #fa3e3e;
        text-align: center;
        margin-bottom: 1rem;
      }
    }
  }
}
