.spelling-bee-results-app-card {
  &_desktopWrapper {
    max-width: 1008px;
    max-height: 240px;
    border-radius: 16px;
    margin: 4rem auto;
    @include bpm-xs {
      display: none;
    }
    cursor: pointer;
  }
  &_mobileWrapper {
    width: 95vw;
    height: 435px;
    border-radius: 8px;
    margin: 4rem auto;
  }
  &-image {
    &-desktop {
      width: 100%;
      height: 100%;
      border-radius: 16px;
      object-fit: contain;
    }
  }
}
