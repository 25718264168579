.teacher-class-confirmation {
  &_wrapper {
    width: 100%;
    margin-top: 5rem;
  }
  &_headWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    @include bpm-xs {
      flex-direction: column;
    }
    &-text {
      font-family: "Lato";
      font-style: normal;
      font-weight: 800;
      font-size: 40px;
      line-height: 48px;
      color: #333333;
      @include bpm-md {
        font-size: 25px;
      }
      @include bpm-xs {
        font-size: 23px;
        margin-bottom: 1rem;
      }
    }
    &-confirmButtonWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      &--button {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        margin: 0rem 0.8rem;
        background-color: #ffffff;
        padding: 0.7rem 1.2rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 64px;
        &---icon {
          width: 24px;
          height: 24px;
        }
        &---text {
          font-family: "Lato";
          font-style: normal;
          font-weight: 700;
          font-size: 13px;
          line-height: 24px;
          color: #333333;
          margin-left: 1.3rem;
        }
      }
    }
  }
}
