.summercamp-explorecomponent {
  &__wrapper {
    padding: 0;
    margin-bottom: 4.8rem;
  }
  display: flex;
  width: 100%;
  min-height: 48rem;
  justify-content: space-between;
  padding: 4.8rem 3% 3rem 4%;
  margin-bottom: 4.8rem;
  @include bpm-md {
    flex-direction: column;
    justify-content: center;
    padding: 1.5rem 0 0 0.5rem;
    margin-bottom: 4.2rem;
  }
  @include bpm-xs {
    padding: 0.5rem 0 0 0.5rem;
    margin-bottom: 4.2rem;
  }
  &__left {
    display: flex;
    flex-direction: column;
    width: 37%;
    @include bpm-md {
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    &--title {
      font-family: Lato;
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 38px;
      letter-spacing: 0px;
      margin-top: 4rem;
      color: rgba(51, 51, 51, 1);
    }
    &--sub-title {
      font-family: Lato;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      margin-top: 1.6rem;
      line-height: 24px;
      letter-spacing: 0px;
      @include bpm-md {
        margin-top: 1.2rem;
      }
    }
    &--age {
      color: rgba(0, 0, 0, 1);
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      margin-top: 3.2rem;
      display: flex;
      font-weight: 600;
      line-height: 17px;
      letter-spacing: 0px;
      padding: 4px;
      span {
        margin-right: 5px;
      }
      @include bpm-md {
        margin-top: 1.6rem;
      }
    }
    &--description {
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      width: 65%;
      letter-spacing: 0px;
      margin-top: 1.6rem;
      @include bpm-sm {
        width: 90%;
        margin-top: 2.8rem;
      }
      @include bpm-md {
        width: 80%;
        margin-top: 2.4rem;
      }
    }
  }
  &__right {
    display: flex;
    width: 63%;
    justify-content: space-evenly;
    @include bpm-md {
      width: 100%;
      margin-top: 4.2rem;
      display: none;
    }
  }
  &__right_mobile {
    display: flex;
    justify-content: center;
    // margin-left: 3%;
    padding-left: 3%;
    width: 100%;
    margin-top: 4rem;
    // background-color: black;
    @include bp-md {
      display: none;
    }
  }
  ul {
    display: flex;
    column-gap: 25px;
    // row-gap: 5px;
    width: 40%;
    margin-top: 1.2rem;
    margin-bottom: 0;
    padding-left: 1.6rem;
    flex-wrap: wrap;
    @include bpm-md {
      justify-content: center;
      margin-top: 0;
    }
    @include bpm-sm {
      width: 100%;
      margin-top: 0;
    }
    li {
      font-family: Lato;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      margin-top: 1.2rem;
      line-height: 14px;
      letter-spacing: 0px;
      text-align: left;
      color: rgba(51, 51, 51, 1);
    }
  }
  .primary-button {
    width: 18rem;
    /* height: 5rem; */
    margin-top: 3.2rem;
    @include bpm-md {
      margin-bottom: 0;
    }
    margin-bottom: 2rem;
    padding: 1.2rem 1.8rem;
  }
}
