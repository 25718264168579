.expert-section {
  &__wrapper {
    position: relative;
    margin-bottom: 65rem;
    > img {
      position: absolute;
      width: 35%;
      opacity: 0.6;
      &:first-child {
        top: 0;
        left: -15%;
        @include bpm-sm {
          width: 50vw;
        }
      }
      &:last-child {
        top: 10rem;
        right: -15%;
        @include bpm-sm {
          width: 50vw;
        }
      }
    }
    @include bpm-sm {
      margin-bottom: 70rem !important;
    }
    @include bpm-custom(570px) {
      margin-bottom: 80rem !important;
    }
  }
  position: absolute;
  top: 5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 60vw;
  @include bpm-sm {
    top: 0;
    width: 85vw;
    z-index: 10;
  }
  &__header {
    font-size: 4rem;
    font-weight: 900;
    @include bpm-sm {
      text-align: center;
    }
  }
  &__sub-header {
    font-size: 2rem;
    font-weight: 500;
    margin-top: 1rem;
    @include bpm-sm {
      text-align: center;
      font-size: 1.6rem;
    }
  }
  &__bottom {
    display: flex;
    align-items: flex-start;
    margin-top: 3rem;
    @include bpm-sm {
      flex-direction: column;
      align-items: center;
    }
    &--left {
      img {
        width: 20rem;
      }
      h3 {
        font-size: 2.5rem;
        font-weight: 800;
        margin: 1rem 0;
      }
      p {
        font-size: 1.8rem;
        color: #666;
      }
      @include bpm-sm {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    &--right {
      padding-left: 4rem;

      p {
        font-size: 1.8rem;
        font-weight: 500;
        margin: 0 0 2rem 0;
      }
      @include bpm-sm {
        padding: 0;
        p {
          margin-top: 3rem;
        }
      }
      h2 {
        font-size: 1.8rem;
        font-weight: 700;
        display: flex;
        align-items: center;
        margin: 1rem 0;
        position: relative;
        margin-left: 2rem;
        span {
          position: absolute;
          left: -2rem;
          height: 1.2rem;
          width: 1.2rem;
          border-radius: 10rem;
          top: 0.5rem;
        }
      }
    }
  }
}
