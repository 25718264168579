.new-testimonial-slider {
  &__swiper {
    width: 100%;
    padding-bottom: 6rem !important;
    .swiper-slide {
      width: 90rem;
    }
    @include bpm-md {
      .swiper-slide {
        width: 46.5rem;
      }
    }
    @include bpm-xs {
      .swiper-slide {
        width: 32rem;
      }
    }
    .swiper-pagination {
      margin-bottom: 0;
    }
    .swiper-button-next {
      transform: translate(7rem, -5rem);
      outline: none;
      border: none;
      background-color: rgba(255, 255, 255, 0.9);
      height: 10rem;
      width: 10rem;
      border-radius: 10rem;
      position: absolute;
      &::after {
        content: "";
        background: url("../../../public/assets/nextArrow.svg") center
          center/contain no-repeat;
        background-size: 2rem;
        transform: translateX(-2.3rem);
      }
    }
    .swiper-button-prev {
      transform: translate(-7rem, -5rem);
      outline: none;
      border: none;
      background-color: rgba(255, 255, 255, 0.9);
      height: 10rem;
      width: 10rem;
      border-radius: 10rem;
      position: absolute;
      &::after {
        content: "";
        background: url("../../../public/assets/nextArrow.svg") center
          center/contain no-repeat;
        background-size: 2rem;
        transform: rotate(180deg) translateX(-2.3rem);
      }
    }
  }
  &__header {
    font-size: 4rem;
    font-weight: 900;
    color: #333;
    margin: 3rem 0 2rem 0;
    @include bpm-sm {
      font-size: 3rem;
      margin: 2rem 0 1rem 0;
    }
    @include bpm-xs {
      font-size: 2.5rem;
    }
  }
  &__sub-header {
    font-size: 2rem;
    font-weight: 500;
    color: #666;
    margin-bottom: 4rem;
    @include bpm-sm {
      font-size: 1.6rem;
      margin-bottom: 3rem;
    }
  }
  &__single-testimonial {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 2rem 4rem;
    border-radius: 2rem;
    max-width: 85rem;
    min-height: 33rem;
    &--left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 50rem;
      min-height: 29rem;
      justify-content: space-between;
    }
    &--header {
      font-size: 3.2rem;
      color: #333;
      font-weight: 900;
      text-align: left;
      margin-bottom: 3rem;
      word-wrap: break-word;
    }
    &--content {
      font-size: 1.6rem;
      color: #666;
      font-weight: 500;
      text-align: left;
      margin: 1rem 0 3rem 0;
      word-wrap: break-word;
      position: relative;
      &-quotes {
        position: absolute;
        top: -5rem;
        left: -2rem;
        width: 5rem;
      }
    }
    &--highlight {
      font-size: 2rem;
      color: #333;
      font-weight: 700;
      text-align: left;
      word-wrap: break-word;
    }
    &--right {
      img {
        width: 20rem;
        border-radius: 50rem;
      }
    }
    @include bpm-md {
      position: relative;
      min-height: 41rem;
      padding: 2rem 3rem;
      &--left {
        min-height: 37rem;
        justify-content: space-between;
      }
      &--right {
        position: absolute;
        top: 2rem;
        right: 2rem;
        img {
          width: 12rem;
        }
      }
      max-width: 450px;
      &--header {
        font-size: 2.8rem;
        font-weight: 700;
        max-width: 25rem;
        margin-bottom: 7rem;
      }
      &--content {
        &-quotes {
          top: -6rem;
          left: -2rem;
          width: 7rem;
        }
      }
    }
    @include bpm-xs {
      &--right {
        top: 1rem;
        right: 1rem;
        img {
          width: 10rem;
        }
      }
      max-width: 31rem;
      min-height: 43rem;
      &--left {
        min-height: 39rem;
        justify-content: space-between;
      }
      &--header {
        font-size: 2.2rem;
        max-width: 15rem;
        margin-bottom: 0;
      }
      &--content {
        font-size: 1.4rem;
      }
    }
  }
}
