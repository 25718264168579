.course-cta-strip {
  &__wrapper {
    position: relative;
    height: 20rem;
    margin-bottom: 5rem;
  }
  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    @include bpm-lg {
      transform: scale(1.2);
    }
    @include bpm-md {
      transform: scale(1.5);
    }
    @include bpm-sm {
      transform: scale(2);
    }
    @include bpm-custom(630px) {
      transform: scale(3);
    }
    @include bpm-custom(480px) {
      transform: scale(4);
    }
    @include bpm-custom(420px) {
      transform: scale(5);
    }
  }
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 7rem;
  @include bpm-md {
    padding: 0 4rem;
  }
  @include bpm-sm {
    padding: 0 2rem;
    align-items: flex-start;
  }
  @include bpm-xs {
    padding: 0 1rem;
  }
  &__left {
    &--title {
      font-size: 2.4rem;
      color: #333;
      font-weight: 700;
      margin-bottom: 1.5rem;
      @include bpm-md {
        font-size: 2rem;
      }
      @include bpm-custom(630px) {
        font-size: 1.8rem;
      }
    }
    &--pitch {
      font-size: 1.8rem;
      font-weight: 500;
      color: #666;
      max-width: 450px;
      @include bpm-md {
        max-width: 350px;
        font-size: 1.6rem;
      }
      @include bpm-sm {
        max-width: 250px;
      }
      @include bpm-custom(630px) {
        font-size: 1.4rem;
      }
    }
    &--faq {
      margin-top: 1rem;
      font-size: 1.8rem;
      font-weight: 500;
      color: #333;
      max-width: max-content;
      @include bpm-md {
        max-width: 350px;
        font-size: 1.6rem;
      }
      @include bpm-sm {
        max-width: 250px;
      }
      @include bpm-custom(630px) {
        font-size: 1.4rem;
      }
      span {
        cursor: pointer;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
  &__right {
    display: flex;
    align-items: flex-start;
    &.centered {
      align-items: center;
    }
    &--price {
      margin-right: 2rem;

      .sessionPrice {
        font-size: 1.5rem;
        color: #999;
        text-align: center;
        @include bpm-sm {
          margin: 6px 0px;
        }
      }
      .price {
        display: flex;
        align-items: center;
        > span {
          font-size: 2.2rem;
          font-weight: 900;
          color: #333;
        }
        .slashed-price {
          margin-left: 1rem;
          color: #666;
          font-size: 1.6rem;
          font-weight: 500;
        }
      }
      .urgency {
        display: flex;
        align-items: flex-start;
        font-size: 1.6rem;
        color: #e98d75;
        font-weight: 700;
        max-width: 200px;
        margin-top: 1.5rem;
        svg {
          width: 2.5rem;
          margin-right: 0.8rem;
        }
      }
      @include bpm-md {
        .price {
          > span {
            font-size: 1.8rem;
          }
          .slashed-price {
            font-size: 1.4rem;
          }
        }
        .urgency {
          font-size: 1.4rem;
          color: #e98d75;
          max-width: 180px;
          svg {
            width: 2.1rem;
          }
        }
      }
      @include bpm-sm {
        margin-right: 0;
        .price {
          justify-content: center;
          width: 100%;
        }
        .urgency {
          margin: 1rem 0;
        }
      }
      @include bpm-custom(630px) {
        .urgency {
          font-size: 1.2rem;
        }
      }
    }
    @include bpm-sm {
      flex-direction: column;
      align-items: center;
    }
    .primary-button {
      width: 15rem;
    }
  }
}
