.affiliate {
  &--share-icon {
    width: 2.5rem;
    object-fit: contain;
  }
  &__wrapper {
    padding: 0rem 5rem 5rem 5rem;
    @include bpm-md {
      padding: 0rem 3rem 5rem 3rem;
    }
    @include bpm-xs {
      padding: 0rem 0rem 3rem 0rem;
    }
  }
  display: flex;
  flex-direction: column;
  &--heading {
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(102, 102, 102, 1);
  }
  &--sub-heading {
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(102, 102, 102, 1);
  }
}
