@import url("https://fonts.googleapis.com/css2?family=Grandstander:wght@700&family=Poppins:wght@400;500;700&family=Lato:wght@400;700&display=swap");
* {
  box-sizing: border-box;
}

body {
  box-sizing: border-box;
}

// body {
//   font-family: "Poppins", sans-serif;
//   font-size: 14px;
//   line-height: 18px;
//   color: #333333;
//   background: #f8de8d;
//   padding: 0;
//   min-height: 100vh;
// }

.wrap {
  padding: 16px;
}

.blocks {
  font-family: "Grandstander", cursive;
}

.locked {
  position: relative;
  background-color: rgb(231, 231, 231);
  border-radius: 5px;
  overflow: hidden;
  img.blur {
    -webkit-filter: blur(8px); /* Safari 6.0 - 9.0 */
    filter: blur(8px);
    width: 100%;
  }
  .lock {
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
  }
}

.lottie {
  &.add-coins {
    width: 500px;
    height: 500px;
    position: absolute;
    left: 50%;
    top: 255px;
    transform: translate(-50%, -50%);
  }
  &.hand-tap {
    width: 120px;
    height: 120px;
    position: absolute;
    left: 50%;
    top: 255px;
    transform: translate(-50%, -50%);
  }
}

.youtube {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  pointer-events: all;
  iframe {
    pointer-events: all;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.top-bar > div {
  min-width: 80px;
}

.top-bar.center-align {
  justify-content: center;
}

.type-box-wrap {
  margin: 35px 0;
}

.row-top {
  display: flex;
  align-items: center;
  justify-content: center;
}

.type-box {
  width: 42px;
  height: 42px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: #edd2a8;
  color: #000;
  font-family: "Grandstander", cursive;
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 7.5px 15px;
  box-shadow: -4px 4px 0 0 #139487;
}

.row-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link-btn {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #333333;
  display: block;
  text-align: center;
  text-decoration: none;
  padding: 10px 0;
  margin: 10px 0 0;
}

.img-wrap {
  margin: 0 0 15px;
}

.img-wrap img {
  max-width: 100%;
  width: 100%;
}

.justify-wrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 100px);
  align-items: flex-start;
  padding-bottom: 50px;
  position: relative;
}

.justify-wrap.with-idea {
  height: calc(100vh - 90px);
}

.grammar-content h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  color: #139487;
}

.grammar-content h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 40px;
  color: #333333;
}

.tap-play {
  position: absolute;
  bottom: 24px;
  left: 0;
  width: 100%;
  text-align: center;
}

.tap-play-resume {
  position: absolute;
  bottom: 24px;
  left: 0;
  width: 100%;
  text-align: center;
  p {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #999999;
  }
}

.tap-play a {
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #999999;
}

.timer-wrap {
  font-family: Lato;
  font-size: 36px;
  font-weight: 900;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: left;

  // font-style: normal;
  // font-weight: bold;
  // font-size: 16px;
  // line-height: 24px;
  // text-align: center;
  // color: #333333;
}

.game-box-wrap {
  background: #ffffff;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  margin: 30px 0;
  /* padding: 18px 0; */
  overflow: hidden;
}

.row-one {
  position: relative;
  display: flex;
}

.row-one img {
  width: 100%;
}

.game-box {
  height: 50%;
  background: #149487;
  position: absolute;
  cursor: pointer;
  border: 1px solid #fff1ce;
}

.game-box.removed {
  background-color: rgba(0, 0, 0, 0);
  border: 0 none;
}

/* SIX BOX */

.six-box .game-box:nth-of-type(1) {
  width: 33.33%;
  left: 0;
  top: 0;
}

.six-box .game-box:nth-of-type(2) {
  width: 33.33%;
  left: 33.33%;
  top: 0;
}

.six-box .game-box:nth-of-type(3) {
  width: 33.33%;
  left: 66.66%;
  top: 0;
}

.six-box .game-box:nth-of-type(4) {
  width: 33.33%;
  left: 0;
  top: 50%;
}

.six-box .game-box:nth-of-type(5) {
  width: 33.33%;
  left: 33.33%;
  top: 50%;
}

.six-box .game-box:nth-of-type(6) {
  width: 33.33%;
  left: 66.66%;
  top: 50%;
}

/* TWO BOX */

.two-box .game-box {
  height: 100%;
}

.two-box .game-box:nth-of-type(1) {
  width: 50%;
  left: 0;
  top: 0;
}

.two-box .game-box:nth-of-type(2) {
  width: 50%;
  left: 50%;
  top: 0;
}

/* FOUR BOX */

.four-box .game-box:nth-of-type(1) {
  width: 50%;
  left: 0;
  top: 0;
}

.four-box .game-box:nth-of-type(2) {
  width: 50%;
  left: 50%;
  top: 0;
}

.four-box .game-box:nth-of-type(3) {
  width: 50%;
  left: 0;
  top: 50%;
}

.four-box .game-box:nth-of-type(4) {
  width: 50%;
  left: 50%;
  top: 50%;
}

.correction-text-wrap {
  margin: 0 0 25px;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 40px;
  color: #000000;
}

.correction-text-wrap a {
  display: inline-block;
  color: #000000;
  text-decoration: none;
}

.correction-text-wrap .tapped {
  background: rgba(140, 219, 171, 0.7);
  border-radius: 16px;
  padding: 0 5px;
}

.correction-text-wrap .tapped-wrong {
  background: rgba(228, 119, 98, 0.72);
  border-radius: 16px;
  padding: 0 5px;
}

.message-wrap {
  display: flex;
  z-index: 10;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  // width: 40rem;
  // height: 65rem;
  border-radius: 1.6rem;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(
    180deg,
    rgba(19, 148, 135, 0.16) 0%,
    #59b260 100%
  );
  // position: fixed;
  // left: 0;
  // bottom: 0;
  // width: 100vw;
  // height: 100vh;
  // display: flex;
  // z-index: 10;

  // flex-direction: column;
  // align-items: center;
  // justify-content: flex-end;
  // background: linear-gradient(
  //   180deg,
  //   rgba(19, 148, 135, 0.16) 0%,
  //   #59b260 100%
  // );
}

.wrong-message {
  background: linear-gradient(
    180deg,
    rgba(247, 167, 112, 0.16) 0%,
    #e47762 100%
  ) !important;
}

.message-wrap .icon {
  margin: 0 0 5px;
}

.message-wrap h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  margin: 0;
}

.message-wrap p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
}

.overlay-box {
  background: rgba(51, 51, 51, 0.24);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.bottom-drawer-wrap {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  background: #faf9f9;
  border-radius: 16px 16px 0px 0px;
  z-index: 10;
  padding: 45px 25px 25px;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
}

.bottom-drawer {
  position: relative;
}

.handle {
  background: #666666;
  border-radius: 8px;
  width: 32px;
  height: 4px;
  display: inline-block;
  position: absolute;
  left: calc(50% - 16px);
  top: -32px;
  z-index: 10;
}

.bottom-drawer-wrap p {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  color: #333333;
  margin-bottom: 10px;
}

.bottom-drawer-wrap p.text-center {
  justify-content: center;
  color: #666666;
}

.bottom-drawer-wrap p span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  font-weight: 700;
  font-size: 16px;
}

.bottom-drawer-wrap p span img {
  margin: 0 0 0 2px;
}

.bottom-drawer-wrap h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  color: #333333;
  justify-content: center;
}

.unlock-btn {
  background: linear-gradient(90deg, #f3705b 0%, #f2b65a 100%);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 48px;
  border: 0 none;
  justify-content: center;
  padding: 14px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #ffffff !important;
  text-decoration: none;
}

.btn-wrap-two .cancel-btn {
  margin: 15px 0 0;
}

.cancel-btn {
  display: block;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  /* identical to box height, or 17px */
  display: flex;
  align-items: center;
  color: #666666;
  justify-content: center;
  text-decoration: none;
}

span.coin {
  display: inline-flex;
  align-items: center;
  padding: 0 2px;
  line-height: 24px;
  font-weight: 600;

  img {
    margin-left: 2px;
  }
}

.btn-wrap-two {
  margin: 25px 0 0;
}

.green-box {
  background: #139487;
  border-radius: 16px;
  padding: 15px;
  /* min-height: 480px; */
}

.flip-card {
  background-color: transparent;

  border: 1px solid #f1f1f1;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  min-height: 400px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card.flip .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
}

/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
}

.green-box .game-box-wrap {
  margin: 0 0 15px;
}

.gift-wrap {
  margin: 0;
}

.gift-wrap p.added-coin {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gift-wrap .normal-text-gift {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  padding: 0 15px;
}

.flip-wrap {
  text-align: center;
  margin: 35px 0 0;
}

.flip-wrap img {
  margin: 0 5px 0 0;
}

.flip-wrap a {
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  display: inline-flex;
  align-items: center;
  color: #ffffff;
}

.share-btn {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  color: #333333;
  display: block;
  text-align: center;
  margin: 15px 0 0;
  text-decoration: none;
}

.share-btn img {
  margin: 0 0 0 5px;
}

.video-wrap {
  margin: 25px 10px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  overflow: hidden;
}

.video-wrap img {
  width: 100%;
  max-width: 100%;
}

.content-wrap {
  margin: 0 0 30px;
}

.content-wrap p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
}

.content-wrap p a {
  font-weight: 600;
  text-decoration: none;
  color: #fff !important;
}

.two-image {
  display: flex;
  align-items: center;
  margin: 0 0 25px;
  justify-content: center;
}

.left-image {
  position: relative;
}

.right-image {
  position: relative;
}

.right-image span {
  display: inline-flex;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  position: absolute;
  left: 0;
  top: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.right-image span img {
  margin: 0 0 0 5px;
}

.one-image {
  display: flex;
  align-items: center;
  margin: 0 0 25px;
  justify-content: center;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  position: relative;
}

.one-image span {
  position: absolute;
  width: 30px;
  left: calc(50% - 15px);
  top: calc(50% - 16px);
  z-index: 1;
}

.one-image img {
  max-width: 100%;
  width: 100%;
}

.unlocked-wrap h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 52px;
  color: #ffffff;
  text-align: center;
  margin: 15vh 0 5vh;
}

.unlocked-wrap h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
  margin: 5vh 5vh 2vh;
}

.unlocked-wrap h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  font-weight: 400;
  margin: 0 3vh;
}

.unloced-image {
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  border: 2px solid #fff;
  overflow: hidden;
  border-radius: 8px;
  display: flex;
}

.unloced-image img {
  width: 100%;
  max-width: 100%;
}

.progress-bar-wrap {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 8px;
}

.progress-bar-box {
  position: relative;
  height: 8px;
  &.gift {
    span {
      background-color: #fff;
      opacity: 0.4;
    }
  }
}

.progress-bar-box span {
  background: #007aff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: inline-block;
  height: 8px;
  position: absolute;
  left: 0;
  bottom: 0;
}

/* GAME OMIT  START*/

.bg-game {
  background: #f8de8d;
}

.bg-completed {
  /* background: #f8de8d url("images/graphics/bg-completed.svg") 50% 0 no-repeat; */
  background: 50% 50% no-repeat;
  background-size: 100%;
  min-height: 100vh;
}

.bg-unlocked {
  background: linear-gradient(135.07deg, #a1ff8b -47%, #3f93ff 100%) no-repeat;
  min-height: 100vh;
  &.gift {
    background: linear-gradient(45deg, #151c40 -47%, #45114d 100%) no-repeat;
  }
}

/* GAME OMIT END*/

/* GAME BUBBLE BLUST START*/

.bg-bubble {
  background: #adc3ec;
}

.bg-bubble .type-box {
  box-shadow: -4px 4px 0 0 #139487;
}

.bg-bubble .right-wallet {
  background: #f5a604;
  box-shadow: 0px 2px 6px #dadada;
}

.bg-bubble .coin-box {
  box-shadow: 0px 2px 0 0 #91a6ff;
  background: #adc3ec;
}

.bg-bubble .why-play-text ul li::before {
  /* background: url(images/icons/list-bullet-icon-bubble.svg) 50% 50% no-repeat; */
  background: 50% 50% no-repeat;
}

.game-learn-wrap {
  margin: 2vh -18px;
  padding: 18px;
  border-bottom: 1px solid #83aeff;
}

.game-learn-wrap h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #91a6ff;
}

.game-learn-wrap h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #333333;
  padding: 0 35px 0 0;
}

.bubble-blust-game-wrap {
  min-height: calc(100vh - 270px);
  position: relative;
  overflow: hidden;
  width: 100%;
}

.bubble-1 {
  left: 8%;
  animation-delay: 1s;
}

.bubble-1 .bubble-animation-y {
  animation-duration: 7s;
  animation-delay: 0s;
}

.bubble-2 {
  left: 40%;
  animation-delay: 2s;
}

.bubble-2 .bubble-animation-y {
  animation-duration: 6s;
  animation-delay: 2s;
}

.bubble-3 {
  right: 15%;
  animation-delay: 1s;
}

.bubble-3 .bubble-animation-y {
  animation-duration: 5s;
  animation-delay: 1.5s;
}

.bubble-4 {
  left: 8%;
  animation-delay: 2s;
}

.bubble-4 .bubble-animation-y {
  animation-duration: 6s;
  animation-delay: 3s;
}

.bubble-5 {
  left: 40%;
  animation-delay: 1s;
}

.bubble-5 .bubble-animation-y {
  animation-duration: 5s;
  animation-delay: 0;
}

.bubble-6 {
  left: 45%;
  animation-delay: 2s;
}

.bubble-6 .bubble-animation-y {
  animation-duration: 7s;
  animation-delay: 3.5s;
}

.bubble-7 {
  right: 15%;
  animation-delay: 1s;
}

.bubble-7 .bubble-animation-y {
  animation-duration: 5.5s;
  animation-delay: 3s;
}

.bubble-8 {
  right: 5%;
  animation-delay: 2s;
}

.bubble-8 .bubble-animation-y {
  animation-duration: 7s;
  animation-delay: 3s;
}

/* Static Circles */

.bubble-static {
  width: 500px;
  height: 500px;
  border-radius: 100%;
  background-color: #055e8f;
  position: absolute;
}

.bubble-9 {
  top: -375px;
  left: 14%;
}

.bubble-10 {
  bottom: -350px;
  right: -50px;
}

.bubble-container {
  position: absolute;
  bottom: -250px;
}

.bubble-large,
.bubble-small {
  border-radius: 100%;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1),
    inset 0px 10px 30px 5px rgba(96, 147, 195, 1);
}

.bubble-large {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 27px;
  color: #333333;
  position: relative;
  background: radial-gradient(
    circle,
    rgba(187, 222, 222, 1) 0%,
    rgba(219, 238, 238, 0) 53%,
    rgb(255 255 255) 73%,
    rgba(187, 222, 222, 1) 100%
  );
  border-radius: 50%;
  border-left: 1px solid white;
  border-right: 1px solid #7b027747;
  box-shadow: -1px -1px 5px rgb(41 169 139 / 28%);
  flex-direction: column;
}

.bubble-large::after {
  content: "";
  width: 2rem;
  height: 2rem;
  position: absolute;
  left: 66%;
  top: 20%;
  transform: skew(-0deg, 40deg) rotate(125deg);
  border-top-right-radius: 50%;
  background: linear-gradient(
    90deg,
    rgb(255 255 255) 0%,
    rgb(255 255 255 / 0%) 50%
  );
  border-radius: 50%;
}

.bubble-large::before {
  content: "";
  width: 2rem;
  height: 3rem;
  position: absolute;
  left: 20%;
  top: 50%;
  transform: rotate(296deg) skew(-22deg, 34deg);
  border-top-right-radius: 50%;
  background: linear-gradient(
    89deg,
    rgb(255 255 255) 0%,
    rgb(255 255 255 / 0%) 26%
  );
  border-radius: 50%;
}

.bubble-small {
  width: 82px;
  height: 82px;
}

.bubble-animation-x {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bubbleXAnimFrames;
  animation-timing-function: ease;
  animation-direction: alternate;
}

.bubble-animation-y {
  animation-iteration-count: infinite;
  animation-name: bubbleYAnimFrames;
  animation-timing-function: linear;
}

@keyframes bubbleXAnimFrames {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(50px);
  }
}

@keyframes bubbleYAnimFrames {
  0% {
    transform: translateY(0);
  }
  50% {
    opacity: 85%;
  }
  100% {
    transform: translateY(-1250px);
    opacity: 0;
  }
}

.correct-bubble {
  background: #b1edc2 !important;
}

.correct-bubble .game-learn-wrap {
  border-bottom: 1px solid #bcffcf;
}

.bubble-correct {
  border: 1px dashed #42ec72;
  color: #005806;
}

.bubble-correct span {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #005806;
}

.incorrect-bubble {
  background: #eb7e83 !important;
}

.incorrect-bubble .game-learn-wrap {
  border-bottom: 1px solid #fea6aa;
}

.bubble-incorrect {
  border: 1px dashed #c50017;
  color: #c50017;
}

.bubble-incorrect span {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #c50017;
}

.bg-completed-bubble {
  /* background: #59B260 url(images/graphics/bg-completed.svg) 50% 0 no-repeat !important; */
  background: 50% 50% no-repeat;
}

.bg-completed-bubble .share-btn {
  color: #fff;
}

.next-step-text-wrap {
  margin: 13vh 0 5vh;
}

.next-step-text-wrap h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 1.6rem;
}

.next-step-text-wrap h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}

.bg-times-up {
  background: #f7a770 !important;
}

.bg-times-up .progress-bar-box span {
  border-radius: 0;
  background: #009c2c;
}

.times-up-text-wrap {
  margin: 13vh 0 5vh;
}

.times-up-text-wrap h2 {
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  color: #333333;
  margin: 0 0 5px;
}

.times-up-text-wrap h3 {
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #666666;
}

.times-icon {
  text-align: center;
  margin: 10vh 0;
}

.times-icon img {
  max-width: 100%;
}

.add-coin-wrap {
  display: flex;
  margin: 15vh 0 28vh;
  align-items: center;
  justify-content: center;
}

.add-coin-wrap span {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 72px;
  color: #333333;
  display: flex;
  align-items: center;
}

.add-coin-wrap span img {
  margin: 0 0 0 12px;
}
.bg-times-up .add-coin-wrap {
  margin: 10vh 0 0;
}

/* GAME BUBBLE BLUST END*/

/*-------------Card Swipe-----------------*/

.bg-card-swipe {
  background: #e9dfea;
}

.bg-card-swipe .type-box {
  box-shadow: -4px 4px 0 0 #320e3b;
}

.bg-card-swipe .why-play-text ul li::before {
  /* background: url("images/icons/list-bullet-icon-card-swipe.svg") 50% 50% no-repeat; */
  background: 50% 50% no-repeat;
}

.game-swipe-panel {
  padding: 28px 0 33px;
  min-height: 520px;
}

.game-swipe-panel-inner {
  position: relative;
}

.game-swipe-card {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  min-height: 467px;
  padding: 30px 20px;
}

.game-swipe-card.card-dark {
  background: #363636;
  z-index: 2;
  transform: matrix(1, 0.04, -0.03, 1, 0, 0);
  min-height: 450px;
  top: 10px;
}

.game-swipe-card.card-pink {
  background: #ffb0b0;
  z-index: 4;
  transform: matrix(1, -0.03, 0.02, 1, 0, 0);
  min-height: 450px;
  top: 10px;
}

.game-swipe-card.card-dark-shadow {
  background: #8b8484;
  z-index: 1;
  transform: matrix(1, 0.09, -0.06, 1, 0, 0);
  min-height: 450px;
  top: 15px;
}

.game-swipe-card.card-yellow {
  background: #ffc66f;
  z-index: 5;
}

.game-swipe-sound-icon {
  text-align: center;
  margin-bottom: 80px;
}

.game-swipe-card h2 {
  font-size: 24px;
  line-height: 36px;
  color: #333;
  font-weight: 600;
}

.game-swipe-card h3 {
  font-size: 44px;
  line-height: 66px;
  color: #000;
  font-weight: 600;
  text-align: center;
  margin-top: 60px;
}

.game-swipe-card-hint {
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
}

.game-swipe-card-hint p {
  font-size: 12px;
  line-height: 20px;
  color: #666;
  text-align: center;
}

.bg-card-swipe .right-wallet {
  background: #fff;
  color: #f4a500;
}

.swipe-control-section {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 12px;
}

.swipe-control-section a {
  font-size: 12px;
  color: #000;
  text-decoration: none;
}

.swipe-control-false {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.swipe-control-false-arrow {
  /* background: url("images/icons/swipe-left-arrow-game-swipe.svg") no-repeat; */
  background: 50% 50% no-repeat;
  width: 72px;
  height: 20px;
  display: block;
}

.swipe-control-true-arrow {
  /* background: url("images/icons/swipe-right-arrow-game-swipe.svg") no-repeat; */
  background: 50% 50% no-repeat;
  width: 72px;
  height: 20px;
  display: block;
}

.message-wrap.game-swipe-wrong-messag,
.message-wrap.game-swipe-correct {
  height: calc(100vh - 80px);
  z-index: 99;
}

.bg-card-swipe-green {
  background: #59b260;
  /* background: url("images/graphics/card-swipe-bg-graphics.svg") no-repeat 0 100% #59B260; */
  background: 0% 100% no-repeat #59b260;
}

.bg-card-swipe-green .top-bar {
  display: flex;
  justify-content: center;
}

.bg-card-swipe-green .right-wallet {
  background: #fff;
  color: #f4a500;
}

.card-swipe-correct-aswer {
  text-align: center;
  padding-top: 60px;
}

.card-swipe-correct-aswer h2 {
  color: #fff;
  font-size: 30px;
  line-height: 45px;
  color: #fff;
  font-weight: 700;
}

.card-swipe-correct-aswer p {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  font-weight: 400;
}

.card-swipe-correct-coin {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 104px;
  margin-bottom: 190px;
  align-items: center;
}

.card-swipe-correct-coin p {
  font-size: 48px;
  line-height: 72px;
  color: #333;
  font-weight: 700;
  margin-bottom: 0;
  margin-right: 10px;
}

.game-swipe-share-panel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.game-swipe-share-panel a {
  font-size: 17px;
  line-height: 25px;
  color: #fff;
  text-decoration: none;
}

.game-swipe-share-panel a i {
  margin-left: 8px;
}

.game-swipe-top-heading {
  padding-top: 45px;
}

.game-swipe-panel-set-2 h3 {
  margin-bottom: 84px;
}

.game-swipe-panel-set-2 .card-yellow {
  min-height: 340px;
}

.game-swipe-panel-set-2 .card-pink {
  min-height: 330px;
}

.game-swipe-panel-set-2 .card-dark {
  min-height: 330px;
}

.game-swipe-panel-set-2 .card-dark-shadow {
  min-height: 330px;
}

.game-swipe-bottom-btns {
  position: fixed;
  bottom: 0;
  display: flex;
  left: 0;
  right: 0;
  justify-content: space-between;
}

.game-swipe-bottom-left {
  /* background: url("images/icons/a.svg") no-repeat; */
  background: no-repeat;
  width: 130px;
  height: 130px;
  display: flex;
  font-size: 20px;
  line-height: 24px;
  color: #000;
  font-weight: 600;
  text-decoration: none;
  align-items: center;
  justify-content: center;
}

.game-swipe-bottom-left span {
  padding-right: 20px;
  padding-top: 20px;
}

.game-swipe-bottom-right {
  /* background: url("images/icons/b.svg") no-repeat; */
  background: no-repeat;
  width: 130px;
  height: 130px;
  display: flex;
  font-size: 20px;
  line-height: 24px;
  color: #000;
  font-weight: 600;
  text-decoration: none;
  align-items: center;
  justify-content: center;
}

.game-swipe-bottom-right span {
  padding-left: 20px;
  padding-top: 20px;
}

.bg-card-swipe-green .progress-bar-box span {
  background: rgba(51, 51, 51, 0.4);
}

/*-------------Card Swipe-----------------*/

.alert-block {
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;
  margin-bottom: 0;
  &.success {
    background: $success;
    border-color: $success;
  }

  &.wrong {
    background: $error;
    border-color: $error;
  }

  &.skip {
    background: #c8d6d3;
    border-color: #c8d6d3;
    color: #333;

    h2,
    h3 {
      color: inherit;
    }

    h3 {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: center;
    }

    .page-icon {
      width: 100px;
      height: 100px;
      margin: 0 auto;
      display: block;
    }
  }

  .progress-bar-box span {
    background: rgba(51, 51, 51, 0.4);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0 8px 8px 0;
  }

  .ward {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    .box {
      height: 50px;
      min-width: 256px;
      left: 51px;
      top: 341px;
      border-radius: 8px;
      background: #fff;
      font-family: Poppins;
      font-size: 28px;
      font-weight: 700;
      line-height: 42px;
      letter-spacing: 10px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #91a6ff;

      .right {
        color: #59b260;
      }

      .wrong {
        color: #e47762;
      }
    }
  }
  .add-coin-wrap {
    margin: 0;
  }
}

.alert-block .alert-success {
  background: $success;
  border-color: $success;
  font-size: 10px;
  line-height: 15px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 60px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 0;
  padding-left: 8px;
}

.alert-block .alert-warning {
  background: $error;
  border-color: $error;
  font-size: 10px;
  line-height: 15px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 60px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 0;
  padding-left: 8px;
}

.alert-block .alert-dismissible .btn-close {
  /* background: url("../images/icons/alert-close-btn.svg") no-repeat !important; */
  background: no-repeat !important;
  padding: 0;
  width: 1.2em;
  height: 1.2em;
  background-size: cover;
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
}

.alert-message {
  display: flex;
  align-items: center;
}

.alert-message span {
  width: 24px;
  height: 24px;
  background: #fff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px 0 0;
}
.overlay {
  // position: absolute;
  // width: 40rem;
  // height: 65rem;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  top: 0;
  left: 0;
  // border-radius: 1.6rem;
  // &.times-up {
  //   bottom: -278px;
  //   top: auto;
  // }
  // z-index: 99;
  // width: 40rem;
  // height: 65rem;
  // border-radius: 1.6rem;
  // position: absolute;
  // top: 0px;
  // left: 0px;

  .anim {
    // width: 100vw;
    // height: 100vh;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
}

.no-internet {
  h2 {
    font-family: "Lato", sans-serif;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
  }

  p {
    font-family: "Lato", sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    max-width: 220px;
    margin: 0 auto;
  }
  a {
    font-family: "Lato", sans-serif !important;
    font-size: 16px;
  }

  .image {
    display: flex;
    justify-content: center;
    lottie-player {
      width: 180px;
      height: 180px;
    }
  }
}

.new-score-bg {
  background: #fff
    linear-gradient(
      90deg,
      rgba(0, 78, 255, 0.3) 13.16%,
      rgba(0, 255, 209, 0.3) 49.7%,
      rgba(179, 71, 234, 0.3) 82.79%
    );
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: 100vh;
  width: 100vw;
  color: #222;

  &.global {
    background: #fff
      linear-gradient(
        162.43deg,
        rgba(255, 0, 229, 0.3) -10.14%,
        rgba(249, 68, 9, 0.3) 44.54%,
        rgba(0, 255, 209, 0.3) 95.9%
      );
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  .cross-btn {
    position: absolute;
    z-index: 99;
    right: 16px;
    top: 16px;
    width: 24px;
    height: 24px;
  }

  .score {
    padding: 40px 30px 0 30px;

    h1 {
      font-size: 48px;
      text-align: center;
      margin: 4.8rem 0 0;
    }

    h2 {
      font-size: 28px;
      text-align: center;
      margin: 2.4rem 0;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      margin: 2.4rem 0;
    }

    img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      margin: 0 auto;
    }

    lottie-player {
      height: 200px;
    }

    .share-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      color: #4880ff;

      svg {
        margin: 0;
        margin-left: 6px;
        width: 15px;
        color: #4880ff;
      }
    }
  }
}

.common-word-image-box {
  background-color: rgba(255, 255, 255, 0.24);
  border-radius: 16px;
  width: 100%;
  padding: 12px;
  display: flex;
  flex-wrap: wrap;

  .image {
    background-color: #d4d4d4;
    border: 1px solid #fff;
    margin: 4px;
    border-radius: 8px;
    flex-grow: 1;
    min-width: 40%;
    padding-top: 30%;
  }
}
.skip-question {
  font-style: normal;
  text-decoration-line: underline;
  color: rgba(153, 153, 153, 1);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 56px 0 16px;
  cursor: pointer;
  font-family: Lato;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
}

.ans-box {
  // background-color: #fff;
  height: 48px;
  position: relative;
  margin: 0 auto;
  border-radius: 8px;
  display: inline-flex;
  padding: 0px 8px;
  margin-top: 8rem;
  .latter {
    width: 48px;
    max-width: 48px;
    height: 48px;
    // border-bottom: 2px solid #000;
    margin: 6px 4px 4px 4px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    color: #000;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-transform: uppercase;
    z-index: 9999;
    opacity: 0;
  }
}

.options {
  margin-top: 24px;
  display: flex;
  align-items: center;
  .latters {
    width: 270px;
    margin-right: 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    .latter {
      width: 40px;
      height: 40px;
      background: #fff;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 3px 0;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      color: #000;
      position: relative;
    }
  }
  .use-hint {
    width: 50px;
    background: linear-gradient(180deg, #25486f -24.43%, #091420 70.45%);
    border-radius: 4px;
    height: 86px;
    p {
      color: #fff;
      text-align: center;
      font-size: 26px;
      font-weight: 600;
    }

    .coins {
      background: #f5a604;
      box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.25),
        inset 0px 2px 1px rgba(0, 0, 0, 0.25);
      border-radius: 18px;
    }
  }
}

// spelling - bee
.question-area {
  width: 100vw;
  height: calc(100vh - 58px);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .bee-container {
    position: absolute;
    transform: translate(-50%, 0%);
    left: 50%;
    top: 0%;
    width: 100%;
    transition: all 0.3s cubic-bezier(0.09, 0.51, 0.29, 0.95);
    &.center {
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
    }
    .bee {
      // width: 100vw;
      // height: 120px;
      // margin: 0 auto;
      display: flex;
      position: relative;
      align-items: center;
      flex-direction: column;
      overflow: hidden;
      margin-top: 1.6rem;
      margin-top #bee {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        height: 640px;
        margin-top: 25px;
      }
    }

    .sub-text {
      text-align: center;
      color: $sub-text-color;
      position: absolute;
      bottom: 0;
      width: 100%;
      margin: 0;
    }
  }

  .q-obj {
    opacity: 0;
    transition: all 0.24s ease-out;
  }

  &.active {
    cursor: pointer;
    .q-obj {
      opacity: 1;
    }
  }
}

.ans-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 136px;
  padding-bottom: 16px;
}

.hint {
  margin: 8px 16px;
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 32px;
  &.open {
    justify-content: left;
  }
  span {
    display: inline-flex;
    background: #ededed;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    margin-left: -16px;
    z-index: 1;
    transition: all 0.24s ease-out;
    img {
      mix-blend-mode: luminosity;
    }
    &.active {
      left: 16px;
      img {
        mix-blend-mode: normal;
      }
    }
  }

  div.idea-text {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 16px;
    left: 0;
    top: 0;
    z-index: 0;
    padding: 5px 15px;
    width: 100%;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    color: $text-color;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    flex-wrap: wrap;

    p {
      width: 100%;
      flex-shrink: 0;
      margin: 0;
      margin-left: 50px;
      display: flex;
      align-items: center;
      &.strong {
        font-weight: 600;
      }

      img {
        margin-left: 12px;
      }
    }
  }
}

.secondary-btn {
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 14px;
  align-items: center;
  width: 50%;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
  border: 0 none;
  background: #91a6ff;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  height: 40px;
  margin-top: 12rem;
}

.right-wallet-white {
  background: #fff;
  box-shadow: 0px 2px 6px rgb(218 218 218 / 40%);
  border-radius: 25px;
  color: #f5a604;
  padding: 5px 15px 5px 5px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 2px 6px #dadada;
  border-radius: 18px;
}

.right-wallet-white span {
  width: 24px;
  height: 24px;
  background: #fff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px 0 0;
}
