.games-awesome-page {
  &_wrapper {
    padding: 0;
  }
  display: flex;
  justify-content: center;
  padding: 12rem 0px 0px;

  &__container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 12rem 0px 0px;
    font-family: Poppins;
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.204px;
    text-align: left;
    color: rgb(255, 255, 255);
    &--img {
      width: 60px;
      margin: 6.4rem 0 0.8rem;
    }
    &--p-text {
      font-family: Poppins;
      font-size: 40px;
      font-weight: 600;
      line-height: 60px;
      letter-spacing: 0px;
      text-align: center;
      color: #ffffff;
    }
    &--s-text {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: 0px;
      text-align: center;
      color: #ffffff;
    }
    .primary-button {
      padding: 0rem 4rem;
      box-shadow: none;
      margin-top: 4.8rem;
    }
  }
}
