.spark-booking {
  &__wrapper {
    width: 100%;
    // margin-top: 2rem;
    // change according responsiveness
    padding: 1rem 1.5rem 1rem;
    @include bpm-sm {
      margin-bottom: 5rem;
    }
  }
  @include bpm-sm {
    // position: relative;
    margin-bottom: 5rem;
  }
  &__contact {
    display: flex;
    width: 100%;
    .spark-booking-input-box__wrapper {
      &:first-child {
        width: 20%;
      }
      &:last-child {
        width: calc(75% - 1.5rem);
        margin-left: 1.5rem;
      }
    }
  }
  &__details-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .PhoneInput {
      margin-bottom: 1.5rem;
      &Country {
        justify-content: space-evenly;
        width: 7rem;
        max-height: 5.5rem;
        &Icon {
          width: 3.8rem;
          height: 2.5rem;
          border: none;
          outline: none;
          background-color: transparent;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
          -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
          -webkit-appearance: none;
        }
        .PhoneInputCountrySelectArrow {
          width: 0.8rem;
          height: 0.8rem;
        }
      }
      width: 95%;
      .spark-booking-input-box__wrapper {
        margin: 0;
      }
    }
    .primary-button {
      transform: translateX(-5%);
      margin: auto;
      // @include bpm-sm {
      //   position: fixed;
      //   bottom: 2.5rem;
      //   left: 50%;
      //   transform: translateX(-50%);
      //   z-index: 99;
      // }
    }
    .timezone {
      margin: -0.5rem 0 1.5rem 0;
    }
  }
}
