.studio-batch-modal {
  &_wrapper {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 120vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
  }
  padding: 2rem;
  border-radius: 16px;
  background: #C2FFD9;
  opacity: 1;
  width: 400px;
//   height: 517px;
  position: relative;
  @include bpm-lg {
    width: 40%;
  }
  @include bpm-sm {
    width: 65%;
  }
  @include bpm-xs {
    width: 94%;
  }
  display: flex;
  flex-direction: column;
  &__batch{
    width: 305px;
    height: 305px;
    position: absolute;
    top: -16rem;
    left: 5rem;
  }
  &__bottom{
    margin-top: 112px;
    display: flex;
    flex-direction: column;
    gap: 25px;
  &--title {
    color: #333;
    text-align: center;
    font-size: 40px;
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
  &-bar {
    width: 350px;
    height: 40px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 25px;
    // position: relative;
    @include bpm-xs{
      width: 300px;
    }
    &_status {
        margin: 20px 0;
      font-size: 16px;
      color: rgba(153, 153, 153, 1);
      font-family: Lato;
      font-weight: 500;
    }
}
    &_progress {
      width: 30%;
      height: 100%;
      background-image: linear-gradient(
        rgba(190, 23, 164, 1),
        rgba(51, 42, 254, 1)
      );
      border-radius: 25px;
      position: relative;

      img {
        position: absolute;
        right: -1.2rem;
        top: -0.4rem;
      }
    }
}
}
