.profile-selection {
  &__wrapper {
    padding: 0;
  }
  position: fixed;
  z-index: 110;
  cursor: pointer;
  display: flex;
  padding: 10rem 16rem 15rem;
  flex-direction: column;
  opacity: 1;
  //   align-items: center;
  background-color: rgba(51, 51, 51, 0.95);
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 1.2rem;
    height: 8px;
    margin-right: 5rem;
  }
  &::-webkit-scrollbar-track {
    // background-color: #fff;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background-color: rgba(256, 256, 256, 1);
  }
  @include bpm-sm {
    padding: 10rem 8rem 15rem;
  }
  &__heading {
    font-family: Lato;
    font-size: 45px;
    font-style: normal;
    font-weight: 300;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    @include bpm-sm {
      font-size: 30px;
    }
  }
  &__sub-heading {
    font-family: Lato;
    font-size: 45px;
    font-style: normal;
    font-weight: 900;
    line-height: 54px;
    margin-top: 0.8rem;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    @include bpm-sm {
      font-size: 37px;
      line-height: 40px;
    }
  }
  &__select-container {
    display: flex;
    flex-direction: row;
    padding-bottom: 4rem;
    margin-top: 6rem;
    flex-wrap: wrap;
    row-gap: 3rem;
    column-gap: 4%;
  }
}
