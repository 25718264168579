.batch-allotment_modal {
  padding: 20px 15px 0px 30px;
  &_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 120vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
    display: none;
  }
  &-close {
    width: 42px;
    height: 42px;
    position: absolute;
    right: 10px;
    top: -15px;
    z-index: 1000;
    cursor: pointer;
  }
  background-color: #ffffff;
  border-radius: 16px;
  opacity: 1;
  width: 30%;
  position: relative;
  @include bpm-lg {
    width: 40%;
  }
  @include bpm-sm {
    width: 65%;
  }
  @include bpm-xs {
    width: 94%;
  }
  display: flex;
  flex-direction: column;
  &-top_wrapper {
    width: 100%;
    // padding: 20px 15px 0px 30px;
    display: flex;
    flex-direction: column;
    &_image-section {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: auto;
      }
      @include bpm-xs {
        width: 60%;
      }
    }
    &_text-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: 0.2px;
      color: #333333;
      .helper-text {
        display: block;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #666666;
        margin: 16px 0 8px 0;
      }
      @include bpm-xs {
        width: 40%;
        margin-top: 0px;
        .helper-text {
          display: none;
        }
        .secondary-text {
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
        }
      }
    }

    @include bpm-xs {
      flex-direction: row-reverse;
    }
  }
  &-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  }
}
