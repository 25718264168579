.studioShareButton {
  //! The wrapper class encloses the whole button div
  //! set to flex and width is max content
  &__wrapper {
    display: flex;
    width: max-content;
    padding: 7px 10px;
    border-radius: 30px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    p {
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      color: #333333;
      margin-left: 10px;
      margin-bottom: 2px;
    }
  }
}
