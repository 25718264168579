.studioShowcaseCard {
  //! The wrapper of the card
  border-radius: 24px;
  &__wrapper {
    //   display:grid;
    border-radius: 24px;
    overflow: hidden;
    width: 335px;
    height: 360px;
    //! for audio top
    #audio {
      border: 2px solid white;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      overflow: hidden;
    }
  }

  &--topVideo {
    //! flex is set to make play button appear at center
    //! position is set to relative to enable overlaying the p tag over the image
    //! The image on the card is set through inline css ( background - image property)
    display: flex;
    align-items: center;
    justify-content: center;
    height: 235px;
    width: 100%;
    //! set to cover since height and width are higher
    background-size: cover;
    position: relative;
    p {
      position: absolute;
      width: 100%;
      height: 20px;
      bottom: 0px;
      text-align: left;
      background: linear-gradient(
        111.29deg,
        rgba(245, 245, 245, 0.53) -1.83%,
        rgba(243, 243, 243, 0) 109.95%
      );
      filter: drop-shadow(50px 60px 100px rgba(0, 0, 0, 0.05));
      backdrop-filter: blur(70px);
      color: rgba(51, 51, 51, 1);
      font-family: Lato;
      font-size: 10px;
      font-weight: 700;
      line-height: 12px;
      letter-spacing: 0em;
      text-align: left;
      padding: 0.4rem 0.4rem 0.4rem 1.2rem;
    }

    //! width set to 4-px for the play button
    &-playButton {
      // position: absolute;
      width: 40px;
      cursor: pointer;
    }
    //! For image type content to fit in card
    .content-type-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  //! the bottom half of the card - contrains text that is left aligned
  &--bottomText {
    padding: 2rem 0rem 2rem 1.5rem;
    text-align: left;
    background: linear-gradient(
      113.87deg,
      #a0dce7 -9.5%,
      rgba(255, 255, 255, 0.5) 97.11%
    );
    border: 0px solid white;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    h2 {
      font-family: Lato;
      font-size: 22px;
      font-style: normal;
      font-weight: 800;
      line-height: 26px;
      letter-spacing: 0px;
      color: #333333;
      margin-bottom: 15px;
    }
    p {
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0px;
      color: #666666;
      height: 25px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.studio_swiper .swiper-pagination-bullets {
  bottom: -42px !important;
}
.studio_swiper .swiper-container {
  padding-bottom: 25px !important;
}

.studio_swiper .swiper-button-prev {
  transform: translate(-7rem, -5rem);
  outline: none;
  border: none;
  // background-color: rgba(255, 255, 255, 0.9);
  height: 10rem;
  width: 10rem;
  border-radius: 10rem;
  position: absolute;
}

.studio_swiper .swiper-button-next {
  transform: translate(7rem, -5rem);
  background: linear-gradient(90deg, #f3705b 0%, #fcb444 100%);
  opacity: 1;
  outline: none;
  border: none;
  background-color: rgba(255, 255, 255, 0.9);
  height: 10rem;
  width: 10rem;
  border-radius: 10rem;
  position: absolute;
  &::after {
    content: "";
    background: url("../../../../public/assets/nextArrow.svg") center
      center/contain no-repeat;
    background-size: 2rem;
    transform: translateX(-2.3rem);
  }
}
.studio_swiper .swiper-button-prev {
  transform: translate(-7rem, -5rem);
  background: linear-gradient(90deg, #f3705b 0%, #fcb444 100%);
  opacity: 0.7;
  outline: none;
  border: none;
  background-color: rgba(255, 255, 255, 0.9);
  height: 10rem;
  width: 10rem;
  border-radius: 10rem;
  position: absolute;
  &::after {
    content: "";
    background: url("../../../../public/assets/nextArrow.svg") center
      center/contain no-repeat;
    background-size: 2rem;
    transform: rotate(180deg) translateX(-2.3rem);
  }
}
.studio_swiper .swiper-button-disabled {
  opacity: 0 !important;
}

.studio_swiper .swiper-wrapper {
  @include bpm-xs {
    display: contents;
  }
}

.react-player__shadow {
  border-radius: 50px !important;
  background: white !important;
  margin-right: auto !important;
  margin-left: auto !important;
}
.react-player__play-icon {
  border-color: transparent transparent transparent black !important;
  border-radius: 5px !important;
}
