.start-page-icon {
  display: flex;
  justify-content: center;
  margin: 35px 0;
}

.game-title {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: inherit;
}

.story-telling-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px 15px;

  .label {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: flex-end;
    color: #333333;
  }

  .member-count {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      font-variant: small-caps;
      color: #333333;
      display: inline-flex;
      margin: 0 0 0 5px;
    }
  }
}

.score-box-wrap {
  background: $body-secondary;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 20px;
  position: relative;
  margin: 0px;
  &:before {
    content: "";
    width: 1px;
    height: 100%;
    background: #fff;
    position: absolute;
    left: calc(50% - 0.5px);
    top: 0;
  }

  .coin-box {
    position: absolute;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $body;
    border-radius: 50%;
    top: -22px;
    left: calc(50% - 22px);
  }

  .score-box {
    display: inline-block;
    color: #fff;
    text-align: center;
    width: 50%;

    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 18px;
      color: #ffffff;
    }
  }
}

.why-play-text {
  margin: 0 0 30px;
}

.why-play-text h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  color: #333333;
  margin: 15px 25px;
}

.why-play-text ul {
  list-style: none;
  padding: 0 25px 0 25px;
  margin: 0 0 25px;
}

.why-play-text ul li {
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #666666;
  padding: 0 0 0 15px;
  margin: 0 0 5px;
}

.why-play-text ul li::before {
  content: "";
  /*  background: url("images/icons/list-bullet-icon.svg") 50% 50% no-repeat; */
  background: 50% 50% no-repeat;
  width: 8px;
  height: 8px;
  position: absolute;
  left: 0;
  top: 6px;
}

.btn-wrap {
  margin: 0 0 15px;
}

.primary-btn {
  background: #202c39;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 48px;
  border: 0 none;
  width: 100%;
  justify-content: center;
  padding: 14px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.challange-friend-wrap {
  background: #fdefd8;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 16px;
  padding: 15px;
}
.challange-friend-wrap h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin: 0 0 15px;
}
