.spark-creativity-challenge-page {
  &__wrapper {
    padding-top: 7rem;
  }
  &__banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -10rem;
    @include bpm-sm {
      margin-top: -5rem;
    }
    &--image {
      width: 60%;
      @include bpm-sm {
        width: 85%;
      }
    }
    overflow-x: hidden;
    &--details {
      display: flex;
      align-items: flex-start;
      justify-content: center;

      position: relative;
      .confetti {
        position: absolute;
        width: 40rem;
        z-index: 2;
        &-1 {
          left: -20rem;
          bottom: 10rem;
        }
        &-2 {
          right: -20rem;
          bottom: 0rem;
        }
      }
      @include bpm-md {
        flex-direction: column;
      }
      .challenge-step-card {
        &__wrapper {
          z-index: 5;
          max-width: 55rem;
          &:first-child {
            margin-right: 1.5rem;
          }
          &:last-child {
            margin-left: 1.5rem;
          }

          @include bpm-lg {
            max-width: 45rem;
          }
          @include bpm-md {
            margin: 0 2rem 2rem 2rem !important;
          }
        }
        &__top {
          background-color: #fff;
          padding: 3rem 2rem;
          border-radius: 2rem;
          box-shadow: 0px 0px 8px rgba(75, 0, 188, 0.08);

          &--header {
            font-size: 2.4rem;
            font-weight: 900;
            margin-bottom: 1rem;
            color: #333;
          }
          &--content {
            font-size: 1.6rem;
            margin-bottom: 1.5rem;
            font-weight: 300;
            color: #333;
          }
        }
        &__bottom {
          margin-top: 1.5rem;
          padding: 0 2rem;
          &--content {
            font-size: 1.6rem;
            font-weight: 500;
            line-height: 2.3rem;
            span {
              font-weight: 900;
              display: inline-block;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  &__header {
    margin: 7rem 0;
    h1 {
      text-align: center;
      font-size: 5rem;
      font-weight: 900;
    }
    @include bpm-sm {
      margin: 5rem 0;
      h1 {
        font-size: 4rem;
      }
    }
  }
  &__rules {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 7rem;
    &--header {
      font-size: 4rem;
      font-weight: 700;
      margin-bottom: 4rem;
    }
    &--content {
      font-size: 2.4rem;
      font-weight: 500;
      margin: 1rem 0;
    }
    @include bpm-sm {
      margin: 7rem 2rem;
      &--header {
        font-size: 2.4rem;
        margin-bottom: 2rem;
      }
      &--content {
        font-size: 1.8rem;
      }
    }
  }
  &__grid {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .single-course-banner-card {
      margin: 2rem 3rem;
    }
  }
}
