.schools-landingpage {
  &__wrapper {
    padding: 0;
    @include bpm-xs {
    }
  }
  &__mobile {
    padding: 10rem 1.6rem 5rem;
    font-family: Lato;
    font-size: 25px;
    font-weight: 800;
    /* line-height: 85px; */
    /* letter-spacing: 0px; */
    text-align: center;
    color: #333333;
  }
  display: flex;
  flex-direction: column;
  &__description {
    &__wrapper {
      padding: 0;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include bpm-xs {
      padding: 0 3rem;
    }
    &__primary-header {
      font-family: Lato;
      font-size: 64px;
      font-weight: 800;
      line-height: 85px;
      letter-spacing: 0px;
      text-align: center;
      color: rgba(51, 51, 51, 1);
      @include bpm-md {
        font-size: 52px;
      }
      @include bpm-xs {
        font-size: 42px;
        margin-top: 4.8rem;
        text-align: left;
        line-height: 4.8rem;
      }
    }
    &__secondary-header {
      margin: 2.4rem 0;
      font-family: Lato;
      width: 80%;
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0px;
      text-align: center;
      color: rgba(0, 6, 50, 1);
      @include bpm-md {
        font-size: 16px;
        margin: 2rem 0;
      }
      @include bpm-xs {
        font-family: Lato;
        line-height: 19px;
        letter-spacing: 0px;
        text-align: left;
        width: 100%;
        font-weight: 500;
        margin: 2rem 0 0;
      }
    }
  }
  &__blueBanner {
    &__wrapper {
      padding: 0 6.4rem 0;
      background-color: #2267e1;
      @include bpm-xs {
        padding: 0 3rem 0;
        margin-top: 2.8rem;
      }
    }
    display: flex;
    height: 15rem;
    justify-content: space-between;
    align-items: center;
    @include bpm-xs {
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-evenly;
    }

    &__primary-header {
      font-family: Lato;
      font-size: 28px;
      font-weight: 800;
      line-height: 43px;
      letter-spacing: 0px;
      text-align: left;
      color: #ffffff;
      box-shadow: none;
      @include bpm-xs {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
  .primary-button {
    padding: 1.5rem 2rem;
    width: 20rem;
    height: 5rem;
    box-shadow: none;
    @include bpm-xs {
      width: 16rem;
    }
  }
}
