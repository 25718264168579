@import "colors.scss";

.top-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;

  .back-btn {
    margin: 0;
    span {
      width: 40px;
      height: 40px;
      background: #ffffff;
      box-shadow: 0px 4px 4px 1px rgba(155, 155, 155, 0.2);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }

  .wallet {
    background: #fff;
    color: #f5a604;
    padding: 5px 15px 5px 5px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 6px rgb(218 218 218 / 40%);
    border-radius: 18px;

    span {
      width: 24px;
      height: 24px;
      background: #fff;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin: 0 10px 0 0;
    }
  }
}

.coins-pop,
.share-pop {
  position: absolute;
  width: 158px;
  padding: 12px;
  right: 16px;
  top: 73px;
  background: #fff2ee;
  border-radius: 4px;
  z-index: 999;

  .anchor {
    content: "";
    position: absolute;
    top: -8px;
    right: 16px;
    background: inherit;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    transform: rotate(45deg);
  }

  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #333333;
    margin: 0 !important;
    margin-bottom: 6px !important;
  }

  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #333333;
    margin: 0;

    span {
      text-decoration: underline;
    }
  }
}

.share-pop {
  right: auto;
  left: 16px;
  .anchor {
    left: 16px;
    right: auto;
  }
}
