.spelling-bee-homepage-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-header {
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    text-transform: capitalize;
    color: #333333;
    margin-top: 4rem;
    @include bpm-xs {
      margin-top: 2rem;
      font-size: 3rem;
      padding: 0rem 1rem;
    }
  }
  &-subHeader {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: #666666;
    margin-top: 2.5rem;
    @include bpm-xs {
      margin-top: 1rem;
      font-size: 20px;
    }
  }
  &-card {
    display: flex;
    flex-direction: column;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 24px;
    overflow: hidden;
    margin-top: 2rem;
    &-imgDesktop {
      max-width: 1210px;
      max-height: 288px;
      border-radius: 24px 24px 0px 0px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
    &-imgMobile {
      border-radius: 16px;
      width: 100%;
      height: 100%;
    }
    &-bottom {
      display: flex;
      justify-content: center;
      padding: 2rem 2rem;
      background-color: #f9f7e2;
    }
  }
  &-cardMobile {
    border-radius: 16px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin-top: 2rem;
    max-width: 328px;
    max-height: 278px;
    cursor: pointer;
  }
}
