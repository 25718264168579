.audio-player {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  background-color: white;

  .song {
    user-select: none;
    margin: 0 20px;
    .song__title {
      text-align: left;
      margin: 0;
      color: black;
      font-family: "Permanent Marker", cursive;
      font-weight: normal;
      font-size: 3.5em;

      &:hover {
        color: greenyellow;
      }
    }

    .song__artist {
      margin: 0;
      color: black;
      font-family: "Rock Salt", cursive;
      font-weight: normal;
      font-size: 1em;

      &:hover {
        color: greenyellow;
        cursor: pointer;
      }
    }
  }

  .controls {
    flex-grow: 1;
    margin: 0 20px;
    display: flex;
    // flex-direction: ;
    justify-content: center;
    align-items: center;
  }

  .player__button {
    width: fit-content;
    margin-bottom: 15px;
    background-color: transparent;
    border: none;

    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
      svg {
        color: greenyellow;
      }
    }

    svg {
      font-size: 4em;
      color: white;
    }
  }

  .bar {
    user-select: none;
    width: 100%;
    display: flex;
    margin-left: 15px;
    color: black;
    // background-color: black;
    align-items: center;

    .bar__time {
      color: black;
      font-size: 14.4px;
    }

    .bar__progress {
      flex: 1;
      border-radius: 5px;
      border: black;
      width: 14px;
      margin: 0 20px;
      height: 4px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .bar__progress__knob {
        position: relative;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background: linear-gradient(180deg, #eaac59 0%, #dd775f 100%);
      }
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;

    .controls {
      width: 100%;
      margin-top: 1rem;
    }

    .bar {
      width: 90%;
    }
  }
}
