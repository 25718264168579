.spark-showcase-page {
  //! ***************************** Wrapper covers all three carousels ************
  &__wrapper {
    .primary-button.version-1 {
      width: 260px;
      height: 50px;
      margin: 0 auto;
      text-align: center;
      margin-top: 30px;
    }
    h1 {
      padding-bottom: 30px;
      font-family: Lato;
      font-size: 2vw;
      font-style: normal;
      font-weight: 900;
      text-align: center;
      @include bpm-md {
        font-size: 25px;
        padding-bottom: 20px;
      }
    }
  }

  //! ******************************* Styling used for the header component - *****************
  //! Solution for the header image( Sort of a hack)
  //! Set image in div and set its visibility to hidden therefore enlargening the div to fit the image,
  //! Then use background-image property to to show the image, and set positioning to center with no-repeat and set size to cover.
  //! To solve the issue of the image previously being too small in responsive mode, set the size of the hidden image to 210px

  &-header {
    background-image: url("../../public/assets/studio.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 30px;
    h1 {
      margin-top: -70px;
    }
    img {
      width: 100%;
      height: 470px;
      visibility: hidden;
    }

    @include bpm-lg {
      img {
        height: 210px;
        width: 100%;
      }
      h1 {
        margin-top: 10px;
        font-size: 30px;
      }
    }
  }

  //! ******************* Styling for the video contrainer - added white curved border - overflowing content hidden
  &-video {
    &-container {
      border-radius: 20px;
      border: 2px solid white;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      overflow: hidden;
      // padding: 20px 0;
    }
  }

  //! ************************* Styling for the 3 carousels - background color and padding ************
  &-carousel {
    &-first,
    &-second,
    &-third {
      //! ************************** Styling for h1,h2,p tags used in Page - responsive size change included .

      h1 {
        padding-bottom: 30px;
        font-family: Lato;
        font-size: 2vw;
        font-style: normal;
        font-weight: 900;
        text-align: center;
        @include bpm-md {
          font-size: 25px;
          padding-bottom: 20px;
        }
      }
      h2 {
        text-align: center;
        padding-bottom: 20px;
      }
      p {
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        width: 40%;
        text-align: center;
        margin: 0 auto;
        padding-bottom: 60px;
        color: rgba(117, 117, 117, 1);
        //! Size fix for spacing in responsive mode
        @include bpm-md {
          font-size: 13px;
          width: 80%;
          padding-bottom: 10px;
        }
      }

      //! ********************* Styling for text used in the carousel - along with alignment styling for video components
      .video-text {
        h1 {
          padding-left: 8px;
          padding-top: 10px;
          font-family: Lato;
          font-size: 16px;
          font-style: normal;
          font-weight: 900;
          line-height: 28px;
          letter-spacing: 0em;
          color: rgba(0, 6, 50, 1);
          text-align: left;
          width: 100%;
          padding-bottom: 0px;
        }
        p {
          text-align: left;
          width: 100%;
          padding-left: 8px;
          color: rgba(0, 6, 50, 1);
          font-family: Lato;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: 0em;
        }

        &-icon {
          display: flex;
        }
      }
    }
    &-first {
      padding: 70px 0;
      background: rgba(236, 127, 87, 0.16);
    }
    &-second {
      padding: 70px 0;
      background: rgba(236, 252, 255, 1);
    }
    &-third {
      padding: 70px 0;
      background: rgba(255, 244, 227, 1);
    }
    //! Size change to remove excessive spacing when on mobiles *****************************************
    @include bpm-lg {
      &-first {
        padding: 20px 0;
        background: rgba(236, 127, 87, 0.16);
      }
      &-second {
        padding: 20px 0;
        background: rgba(236, 252, 255, 1);
      }
      &-third {
        padding: 20px 0;
        background: rgba(255, 244, 227, 1);
      }
    }
  }
}
