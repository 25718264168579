.ambassador-banner {
  h1 {
    @include h1-styling;
  }
  h3 {
    @include h3-styling;
    line-height: 30px;
  }
  margin: 0 3rem;
}
