.spark-booking {
  &__choose-slot {
    max-height: 34rem;
    overflow-y: scroll;
    overflow-x: hidden;
    &__locationContainer {
      display: flex;
      justify-content: space-between;
      &--p-text {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0px;
        text-align: left;
        color: #000;
      }
      &--s-text {
        color: #e47762;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0px;
        text-align: left;
      }
    }
    &::-webkit-scrollbar {
      width: 1.2rem;
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      background-color: rgba(0, 0, 0, 0.1);
    }
    position: relative;
  }
  &__wrapper {
    .primary-button {
      transform: translateX(-5%);
      margin: auto;
      width: max-content;
      margin-top: 3rem;
      // @include bpm-sm {
      //   position: fixed;
      //   bottom: 2.5rem;
      //   left: 50%;
      //   transform: translateX(-50%);
      //   z-index: 99;
      // }
    }
    &.choose-slot__wrapper {
      position: relative;
      overflow: hidden;
    }
    .shadow {
      position: absolute;
      width: 100%;
      height: 1px;
      box-shadow: 0 0 25px 2px rgb(150, 150, 150);
      transform: translateX(0.8rem) !important;
      &-1 {
        top: 0;
        clip-path: inset(0px 0px -25px 0px);
      }
      &-2 {
        clip-path: inset(-25px 0px 0px 0px);
        bottom: 7rem;
      }
    }
  }
}

.slot-selection-component {
  &__wrapper {
    padding: 0;
  }
  display: flex;
  flex-direction: column;
  &__container {
    &__wrapper {
      padding: 0;
      margin: 1.6rem 0;
    }
    &--p-text {
      color: rgba(51, 51, 51, 1);
      font-family: Lato;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
    }
  }
}
