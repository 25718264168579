.results-grammer-card {
  &__wrapper {
    padding: 3.2rem 2.4rem;
    width: 45%;
    height: 45rem;
    border-radius: 2.4rem;
    background-color: rgba(85, 38, 61, 1);
    @include bpm-custom(1230) {
      padding: 1.6rem 2.4rem;
      width: 48%;
    }
    @include bpm-custom(2560) {
      height: 66rem;
    }
    @include bpm-custom(2000) {
      height: 57rem;
    }
    @include bpm-custom(1680) {
      height: 51rem;
    }
    @include bpm-custom(1410) {
      height: 45rem;
    } 
    @include bpm-md {
      width: 100%;
      min-height: 55.2rem;
      padding: 0;
      overflow-y: scroll;
      margin: 2rem auto;
    }
  }
  &__badge-icon {
    object-fit: contain;
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  min-width: 48%;
  min-height: 48rem;
  @include bpm-md {
    padding: 3.2rem 2.4rem;
  }
  &__primary-container {
    display: flex;
    justify-content: space-between;
    &__left {
      display: flex;
      flex-direction: column;
      width: 80%;
      &--p-heading {
        font-family: Lato;
        font-size: 24px;
        position: relative;
        font-style: normal;
        text-transform: capitalize;
        font-weight: 800;
        line-height: 29px;
        letter-spacing: 0px;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        @include bpm-custom(1230) {
          font-size: 20px;
        }
        @include bpm-custom(1024) {
          font-size: 18px;
        }
        @include bpm-md {
          font-size: 22px;
          font-weight: 800;
          text-transform: capitalize;
        }
      }
      &--s-heading {
        font-family: Lato;
        font-size: 18px;
        margin-top: 0.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        @include bpm-custom(1230) {
          font-size: 14px;
          margin-top: 0.6rem;
        }
        @include bpm-custom(1024) {
          font-size: 18px;
          margin-top: 0.4rem;
        }
        @include bpm-md {
          font-size: 18px;
          font-weight: 500;
          margin-top: 0.8rem;
          text-transform: capitalize;
        }
      }
      &--p-text {
        color: rgba(255, 255, 255, 1);
        font-family: Lato;
        position: relative;
        font-size: 18px;
        margin-top: 4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        @include bpm-custom(1230) {
          margin-top: 3.8rem;
        }
        @include bpm-custom(1024) {
          margin-top: 3.4rem;
        }
        @include bpm-md {
          font-weight: 700;
          line-height: 24px;
        }
      }
    }
    &__right {
      display: flex;
      width: 20%;
      align-items: flex-start;
      &.speech-img {
        width: auto;
      }
    }
  }

  &--s-text {
    font-family: Lato;
    font-size: 20px;
    margin-top: 2.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(255, 181, 65, 1);
    @include bpm-custom(1230) {
      font-size: 1.6rem;
      margin-top: 1.6rem;
    }
    @include bpm-custom(1024) {
      font-size: 1.4rem;
      margin-top: 1.4rem;
    }
    @include bpm-md {
      font-size: 18px;
      font-weight: 700;
    }
  }
  &--broken-rules {
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;

    margin-top: 1.6rem;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    &.margin-left {
      display: flex;
      margin-left: 1.5rem;
      margin-top: 2px;
    }
    &--index {
      margin-left: 0.3rem;
    }
    &--rule {
      // text-decoration: underline;
      margin-left: 5px;
    }
  }
  &--rules {
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    margin-top: 4.8rem;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    @include bpm-custom(375) {
      margin-top: 3.6rem;
    }
    &.b-points {
      font-size: 17px;
    }
  }
  &--download {
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 2.8rem;
    text-decoration: underline;
    color: rgba(255, 255, 255, 1);
    @include bpm-custom(1230) {
      font-size: 16px;
      margin-top: 2.4rem;
    }
    @include bpm-custom(1024) {
      font-size: 14px;
      margin-top: 2.2rem;
    }
  }
}
