.spellingBee_heroImage-container {
  padding-left: 14%;
  padding-right: 14%;
  @include bpm-sm {
    padding-left: 16px;
    padding-right: 16px;
  }
  width: 99%;
  img {
    width: 100%;
    object-fit: contain;
  }
}
