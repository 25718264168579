.learn-card-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // margin-top: 60px;
  // margin-left: ;
  margin: 6rem 0rem 0rem auto;
  z-index: 1;
  // border: 1px solid #fff;
  // width: 100%;
  min-height: 313px;
  padding: 1rem 3rem;
  // border-radius: 25px;
  // box-shadow: 0 3px 7px rgb(0 0 0 / 0.1);
  &--heading {
    margin: 1rem auto 0rem;
    font-size: 2rem;
    font-weight: 800;
    color: #333;
    font-family: Lato;
    @include bp-xl {
      margin: 0rem 0rem 4rem;
      font-size: 3rem;
    }
  }
  &--subheading {
    margin: 1.5rem auto 3rem;
    font-size: 1.6rem;
    font-weight: 400;
    color: #333;
    // text-align: center;
    font-family: Lato;
    opacity: 0.7;
    @include bp-xl {
      display: none;
    }
  }

  .learn-card {
    height: 600px;
  }
  .circle-icon {
    font-size: 1.5rem;
    display: flex;
    width: 100%;
    font-weight: 700;
    // margin: 0rem 8% 0rem;
    cursor: pointer;
    color: #333;
    @include bpm-md {
      font-size: 1.4rem;
    }
    @include bpm-xl {
      margin-bottom: 1rem;
    }
    &:hover {
      color: #333;
    }
    span {
      display: inline-block;
      text-align: center;
      font-size: 1.6rem;
      font-weight: 700;
      background-color: #ddd;
      height: 2.6rem;
      width: 2.6rem;
      border-radius: 5rem;
      padding: 0.3rem 0 0 0;
      margin: 0 0.5rem;
      color: #666;
      @include bpm-md {
        height: 2.2rem;
        width: 2.2rem;
        font-size: 1.4rem;
        padding: 0.2rem 0 0 0;
      }
    }
    &.active {
      span {
        background-color: #f2b65a;
        color: #fff;
      }
    }
    &.done {
      span {
        background-color: #90d4a3;
        color: #fff;
      }
    }
  }
  .info-text {
    display: flex;
    width: 100%;
    flex-direction: column;
    font-size: 1.8rem;
    font-weight: 800;
    margin-left: 4%;
    @include bp-xl {
      margin-left: 0%;
    }
  }
  .transpert-info-text {
    font-size: 1.6rem;
    font-weight: 400;
    color: #333;
    margin-left: 4%;
    @include bp-xl {
      margin-left: 1.5%;
    }
    margin-bottom: 2rem;
    font-family: Lato;
    opacity: 0.7;
  }
}
