.gameLearningOutcomes {
  &__wrapper {
    padding: 6rem 7rem;
    @include bpm-xs {
      padding: 4rem 2rem;
      flex-direction: column;
    }
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    background-color: #fffdfa;
  }
  &--left {
    @include bpm-sm {
      display: none;
    }
  }
  &--right {
    //! Margin left to accomodate space on ipad pros
    //! removed on ipads and lower
    margin-left: 30px;
    @include bpm-sm {
      margin-left: 0px;
    }
    h2 {
      font-family: Lato;
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      line-height: 38px;
      color: #333333;
      margin-bottom: 30px;
      @include bpm-xs {
        font-size: 20px;
      }
    }
    &-content {
      display: flex;
      margin-bottom: 15px;
      @include bpm-xs{
        margin-bottom: 13px;
      }
      img {
        height: 100%;
        margin-right: 15px;
      }
      p {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        @include bpm-xs {
          font-size: 14px;
        }
        line-height: 24px;
        /* or 150% */
        color: #666666;
        max-width: 512px;
      }
    }
  }
  &--play {
    display: none;
    @include bpm-xs {
      display: block;
    }
    .primary-button {
      width: 160px;
      padding: 20px 10px;
      margin: 0 auto;
    }
  }
}
