.spark-otp-form {
  .otp-input-boxes {
    justify-content: space-evenly;
    width: 100%;
    input {
      height: 3.5rem !important;
      width: 3.5rem !important;
      border: none;
      border-radius: 0px;
      outline: none;
      border-bottom: 1px solid #999;
      font-size: 2rem;
      color: #666;
      font-weight: 700;
    }
  }
  &__otp-input-container {
    margin: 3rem 0 2rem 0;
  }
  &__resend-otp-container {
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    span {
      width: 100%;
      display: inline-block;
      text-align: center;
      cursor: pointer;
      color: #ff8671;
      font-size: 1.5rem;
    }
  }
  .MuiCircularProgress-colorPrimary {
    color: #ff8671;
  }
}
