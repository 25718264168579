.all-courses-banner-filter {
  &__wrapper {
    padding-top: 14rem;
    padding-bottom: 7rem;
    .swiper-slide {
      width: 36rem !important;
    }
    .swiper-wrapper {
      margin-bottom: 6rem;
    }
    .swiper-pagination {
      // bottom: -3rem !important;
      margin-bottom: 0 !important;
    }
    .swiper-button-next {
      transform: translate(7rem, -5rem);
      outline: none;
      border: none;
      background-color: rgba(255, 255, 255, 0.9);
      height: 10rem;
      width: 10rem;
      border-radius: 10rem;
      position: absolute;
      &::after {
        content: "";
        background: url("../../../public/assets/nextArrow.svg") center
          center/contain no-repeat;
        background-size: 2rem;
        transform: translateX(-2.3rem);
      }
    }
    .swiper-button-prev {
      transform: translate(-7rem, -5rem);
      outline: none;
      border: none;
      background-color: rgba(255, 255, 255, 0.9);
      height: 10rem;
      width: 10rem;
      border-radius: 10rem;
      position: absolute;
      &::after {
        content: "";
        background: url("../../../public/assets/nextArrow.svg") center
          center/contain no-repeat;
        background-size: 2rem;
        transform: rotate(180deg) translateX(-2.3rem);
      }
    }
    .swiper-button-disabled {
      opacity: 0 !important;
    }
    // @include bpm-md {
    //   padding: 10rem 0 1rem 0;
    // }
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  &__header {
    font-size: 5rem;
    font-weight: 800;
    text-align: center;
    @include bpm-sm {
      font-size: 3.6rem;
    }
    &__workshop {
      margin-bottom: 2rem;
    }
  }
  &__sub-header {
    font-size: 2rem;
    font-weight: 500;
    margin-top: 1rem;
    text-align: center;
    @include bpm-sm {
      font-size: 1.6rem;
      text-align: left;
      align-self: flex-start;
      padding: 0 1.5rem;
    }
  }
  &__search {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4rem 0;
    @include bpm-sm {
      padding: 0 3rem;
      flex-wrap: wrap;
    }
    label {
      input {
        height: 5rem;
        width: 44rem;
        font-size: 1.4rem;
        outline: none;
        border: none;
        padding: 0 2rem 0 5rem;
        border-radius: 8px;
        &:active,
        &:focus {
          outline: none;
          border: none;
        }
        @include bpm-sm {
          width: calc(100vw - 20rem);
        }
      }
      margin-right: 2rem;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        height: 2rem;
        width: 2rem;
        // background-color: red;
        background: url("../../../public/assets/searchIcon.svg") center
          center/contain no-repeat;
        left: 1.5rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    select {
      height: 5rem;
      width: 15rem;
      font-size: 1.4rem;
      outline: none;
      border: none;
      margin-right: 2rem;
      border-radius: 8px;
      padding-left: 1rem;
      cursor: pointer;
      option {
        outline: none;
        border: none;
        color: #333;
        font-weight: 600;
        // background-color: #fcb44433;
        &:hover {
          background-color: red !important;
        }
      }
      @include bpm-sm {
        order: 3;
        margin-right: 0;
        margin-top: 4rem;
        width: 20rem;
      }
    }
  }
  &__age-filter {
    list-style: none;
    padding-inline-start: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    margin-bottom: 6rem;
    @include bpm-md {
      margin-bottom: 2rem;
    }
    &-item {
      padding: 0.5rem 0;
      width: 10rem;
      text-align: center;
      background: linear-gradient(
        111.29deg,
        rgba(234, 234, 234, 0.53) -1.83%,
        rgba(255, 255, 255, 0) 109.95%
      );
      // filter: drop-shadow(50px 60px 100px rgba(0, 0, 0, 0.05));
      // backdrop-filter: blur(70px);
      //   filter: drop-shadow(50px 60px 100px rgba(0, 0, 0, 0.05));
      // backdrop-filter: blur(70px);
      font-size: 1.6rem;
      border-radius: 8px;
      border: 3px solid #fff;
      //   box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
      margin: 1rem 1.5rem;
      cursor: pointer;
      font-weight: 500;
      color: #666;
      @include bpm-md {
        margin: 1rem;
      }
      &.active {
        border: 3px solid #fcb444;
      }
    }
  }
  &__courses-grid {
    display: grid;
    justify-items: center;
    grid-gap: 1rem;

    @include bp-sm {
      grid-template-columns: 1fr 1fr;
    }
    @include bp-lg {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @include bp-custom(1600px) {
      max-width: 1600px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
  &__wrapper {
    @include bp-sm {
      display: flex;
      flex-direction: column;
      align-items: center;
      .secondary-button.version-4 {
        margin-top: 3rem;
        height: 5rem;
        font-size: 1.6rem;
        width: 20rem;
        img {
          display: none;
        }
      }
    }
  }
}
