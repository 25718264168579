.courseCardAgeSelector {
  &__wrapper {
    display: flex;
    justify-content: center;
    gap: 50px;
    margin: 30px 0px;
  }
  &-button {
    width: 147px;
    height: 100px;
    border-radius: 8px;
    // background-color: #d5f1fe;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    h2 {
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: 0em;

      color: #333333;
    }
    span {
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0em;
      color: #666666;
    }
    &.active {
      h2 {
        color: white;
      }
      span {
        color: white;
      }
      &:after {
        z-index: 10;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%);
        content: "";
        width: 0;
        height: 0;
        border-top: solid 12px;
        border-left: solid 8px transparent;
        border-right: solid 8px transparent;
      }
    }
  }
}
