.expert-card {
  height: 300px;
  width: 220px;
  border-radius: 3rem;
  margin: 1.5rem 0;
  transition: all 0.1s linear;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.4);
  -webkit-appearance: none;
  @include bpm-md {
    margin: 2.5rem 0;
  }
  @include bp-md {
    &:hover {
      transition: all 0.1s linear;
      transform: scale(1.02);
    }
  }
  &--blue {
    background: linear-gradient(
      113.87deg,
      #a0dce7 -9.5%,
      rgba(255, 255, 255, 0.5) 97.11%
    );
    .primary-button {
      color: #333;
      background: linear-gradient(
        113.87deg,
        rgba(255, 255, 255, 0.5) -9.5%,
        #a0dce777 62.13%
      );
    }
  }
  &--yellow {
    background: linear-gradient(
      111.29deg,
      #fdcd4c -1.83%,
      rgba(255, 255, 255, 0) 109.95%
    );
    .primary-button {
      color: #333;
      background: linear-gradient(
        111.29deg,
        rgba(255, 255, 255, 0.3) -1.83%,
        #fdcd4c77 66.87%
      );
    }
  }
  &--purple {
    background: linear-gradient(
      111.29deg,
      #8469c0 -1.83%,
      rgba(255, 255, 255, 0) 109.95%
    );
    .primary-button {
      color: #333;
      background: linear-gradient(
        111.29deg,
        rgba(255, 255, 255, 0.2) -1.83%,
        #8469c077 66.87%
      );
    }
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 1rem;
  img {
    width: 55%;
    border-radius: 0.8rem;
  }
  &__name {
    font-size: 2rem;
    font-weight: 800;
    color: #000632;
  }
  &__title {
    font-size: 1.6rem;
  }
  &__content {
    font-size: 1.2rem;
    text-align: center;
    color: #666;
    padding: 0 1rem;
  }
}
