.app-homepage {
  &_wrapper {
    height: 100vh;

    padding: 100px 0px 0px 0px;
    max-width: 750px;
    margin: 0 auto;
    @include bpm-xs {
      height: 100%;
      width: 100%;
    }
  }
  background-color: #ffff;
  padding: 2rem 2rem;
  box-shadow: 50px 60px 100px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  text-align: left;
  width: 100%;
  margin-bottom: 1rem;
  @include bpm-xs {
    width: 95%;
    margin: 0 auto;
  }

  &_header {
    font-family: Lato;
    font-size: 30px;
    font-weight: 700;

    @include bpm-xs {
      font-family: Lato;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 2rem;
    }
  }
  &_separatorLine {
    width: 100%;
    height: 1px;
    background-color: #6666;
    margin: 3rem 0rem;
  }
  &_text {
    font-family: Lato;
    font-size: 17px;
    line-height: 1.4;
    margin-bottom: 3rem;
  }
  &_underline {
    text-decoration-line: underline;
    text-underline-position: under;
  }
  &_link {
    text-decoration-line: underline;
    text-underline-position: under;
    color: #0000ff;
  }
}
