.dashboard-profile {
  &__wrapper {
  }
  &--top {
    display: flex;
    &-left {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      
      &-picWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 50%;
        width:140px;
        height: 150px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      }
      &-picture {
        // border-radius: 50%;
        width: 120px;
        height: 140px;
      }
      &-edit {
        cursor: pointer;
        position: absolute;
        right: -2px;
        bottom: 18px;
        background-color: white;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
        border-radius: 30px;
        width: 25px;
        padding: 3px 0px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        &-icon {
          width: 15px;
        }
      }
    }
    &-right {
      text-align: left;
      margin-left: 25px;

      h1 {
        font-family: Lato;
        font-size: 29px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px;
        letter-spacing: 0em;
      }
      h2 {
        font-family: Lato;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        color: #666666;
      }
      &-edit {
        display: flex;
        margin-top: 5px;
        &-icon {
          margin-left: 10px;
          cursor: pointer;
        }
        span {
          color: #666666;
          font-family: Lato;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 0em;
        }
      }
      &-studio {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin: 15px 0px;
      }
    }
  }
  &--bottom {
    display: flex;
    justify-content: flex-end;
    //! The profile completion section is shifted to center on mobile screens
    @include bpm-xs {
      justify-content: center;
    }
    margin-top: 20px;
  }
}
