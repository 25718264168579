.storybook-highlight {
  &__wrapper {
    margin: 3rem 0;
    height: 30rem;
    width: 100%;
    border-radius: 2rem;
    position: relative;
    z-index: 5;
    &::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 0.2)
      );
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 2rem;
      top: 0;
      left: 0;
      z-index: 10;
    }
  }
  z-index: 15;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 30rem;
  padding: 2rem 3rem;
  &__title {
    font-size: 2.4rem;
    color: #eee;
    font-weight: 900;
    text-align: left;
    z-index: 15;
  }
  &__transcript {
    font-size: 1.8rem;
    z-index: 15;
    color: #ccc;
    font-weight: 500;
    max-width: 400px;
    margin: 2rem 0 0 0;
    text-align: left;
    height: 15rem;
    overflow: hidden;
  }
  &__play-icon {
    width: 5rem;
  }
  &__action {
    justify-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 2rem;

    .primary-button {
      margin-left: 2rem;
      width: max-content;
    }
  }
}
