.learn-page-metronome {
  &__wrapper {
    padding: 15rem 0 10rem 0;
  }
}
.metronome {
  &__wrapper {
    margin: auto;

    height: 500px;
    width: 700px;
    background-color: rgb(245, 245, 245);
    box-shadow: 0px 1px 6px grey;
    border-radius: 10px;
    // ! flexed two sections
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    .btn-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      button {
        cursor: pointer;
        margin: 0.5rem;
      }
    }
  }
  &__header {
    font-size: 3rem;
  }
  &__octaves {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &--list {
      display: flex;
      align-items: center;
      width: 71%;
      justify-content: space-between;
      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
          margin-bottom: 0.5rem;
        }
        .shape {
          height: 30px;
          width: 30px;
          background-color: rgba(255, 221, 4, 0.805);
        }
        &:nth-child(2),
        &:nth-child(4) {
          .shape {
            border-radius: 5rem;
          }
        }
      }
    }
    &--graph {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      border-bottom: 2px solid rgb(2, 119, 214);
      width: 80%;
      margin-top: 2rem;
      .small-bar {
        width: 2.8px;
        height: 50px;
        background-color: red;
      }
      .large-bar {
        width: 1px;
        height: 80px;
        background-color: rgb(2, 119, 214);
        &:first-child {
          transform: translateX(3.5px);
        }
        &:last-child {
          transform: translateX(-3.5px);
        }
        &-red {
          background-color: slategrey;
        }
      }
      position: relative;
      .seeker-bar {
        height: 60px;
        width: 10px;
        background-color: red;
        z-index: 2;
        position: absolute;
        left: 0;
        bottom: -2px;
        clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 50% 100%, 0% 80%, 0% 0%);
      }
    }
  }
  &__control {
    display: flex;
    align-items: center;

    padding: 2rem 3rem;
    border-radius: 1.5rem;
    box-shadow: 0px 1px 4px rgb(238, 248, 251), 0px 0px 10px rgb(192, 192, 192);
    .play-btn {
      width: 6rem;
      margin-left: 2rem;
      box-shadow: 0px 1px 4px rgb(101, 100, 100);
      border-radius: 20rem;
      cursor: pointer;
    }

    // ! For GSAP slider

    .slider-container {
      width: 450px;
      height: 2px;
      background-color: rgb(2, 119, 214);
      border-radius: 5px;
    }
    .slider-target {
      width: 100px;
      height: 35px;
      background: rgb(245, 245, 245);
      border-radius: 4rem;
      border: 1px solid rgb(2, 119, 214);
      cursor: pointer;
      transform: translateY(calc(-50% + 1px));
      display: flex;
      align-items: center;
      justify-content: center;
      .bpm-level {
        font-size: 1.6rem;
        color: rgb(2, 119, 214);
      }
    }
  }
  &__button {
    outline: none;
    border: none;
    background-color: rgb(255, 77, 77);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.6);
    padding: 0.7rem 2rem;
    border-radius: 5rem;
    cursor: pointer;
    margin-left: 2rem;
    &.disabled {
      background-color: rgb(159, 159, 159);
      cursor: not-allowed;
    }
  }
}
