.teacher-class-confirmation-table {
  //! Wrapper css ---------------------->
  @include bpm-lg {
    overflow-x: scroll;
  }
  height: auto;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  //! end ------------------------------>

  //! Table css ------------------------>
  &_wrapper {
    width: 100%;
    border-spacing: 0px;
    white-space: nowrap;
    border-radius: 16px;
    overflow: hidden;
    th {
      padding: 20px 0px;
      &:nth-child(1) {
        padding-left: 2.5rem;
      }
      @include bpm-lg {
        padding-right: 20px;
      }
      @include bpm-xs {
        padding: 15px 0px;
        padding-right: 20px;
      }
    }
    td {
      &:nth-child(1) {
        padding-left: 2.5rem;
      }
      border-bottom: 2px solid rgba(242, 182, 90, 0.48);
      padding: 20px 0px;

      @include bpm-lg {
        padding-right: 20px;
      }
      @include bpm-xs {
        padding: 15px 0px;
        padding-right: 20px;
      }
    }
  }

  //! Table head-css ------------------->
  &-head_row {
    padding: 10px 0px;
    margin-left: 2rem;
    overflow: hidden;
    background: #d3efc4;
    &-heading {
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 24px;
      line-height: 29px;
      color: #333333;
      text-align: left;
      @include bpm-xs {
        font-size: 20px;
        line-height: 24px;
      }
      &.spacing {
        text-align: center;
      }
    }
  }
  //! end ------------------------------>

  //! Table content css ---------------->
  &-content {
    &--batchId {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      color: #666666;
    }
    &--course {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #333333;
    }
    &--students {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #666666;
      text-align: center;
    }
    &--dateTime {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      color: #666666;
    }
    &--cutoffTime {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      color: #e50300;
    }
    &--status {
      &---confirmed {
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #00a912;
      }
      &---rejected {
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #fa3e3e;
      }
      &---timeout {
        font-family: "Lato";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: #333333;
      }
      &---choose {
        display: flex;
        flex-direction: row;
        align-items: center;
        &_confirm {
          width: 40px;
          height: 40px;
          margin-right: 2rem;
          cursor: pointer;
        }
        &_reject {
          width: 40px;
          height: 40px;
          cursor: pointer;
        }
      }
    }
  }
  //! end ------------------------------>

  //! END ---------------------->
}
