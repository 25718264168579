.spark-booking {
  &__courses-form {
    max-height: 34rem;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 1.2rem;
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      background-color: rgba(0, 0, 0, 0.1);
    }
    position: relative;
  }
  &__wrapper {
    .primary-button {
      transform: translateX(-5%);
      margin: auto;
      width: max-content;
      margin-top: 3rem;
      // @include bpm-sm {
      //   position: fixed;
      //   bottom: 2.5rem;
      //   left: 50%;
      //   transform: translateX(-50%);
      //   z-index: 99;
      // }
    }
    &.courses__wrapper {
      position: relative;
      overflow: hidden;
    }
    .shadow {
      position: absolute;
      width: 100%;
      height: 1px;
      box-shadow: 0 0 25px 2px rgb(150, 150, 150);
      transform: translateX(0.8rem) !important;
      &-1 {
        top: 0;
        clip-path: inset(0px 0px -25px 0px);
      }
      &-2 {
        clip-path: inset(-25px 0px 0px 0px);
        bottom: 7rem;
      }
    }
  }
}
