.whatsappShare-button {
  color: #fff;
  background: #4fcc5d;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
  border-radius: 32px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  margin-top: 16px;
  padding: 1.6rem;
  cursor: pointer;
  div {
    display: flex;
    align-items: center;
    img {
      margin-right: 16px;
      height: 24px;
      width: 24px;
    }
  }
}
