.summer-camp-special-courses {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 5%;

  @include bpm-lg {
    padding: 3rem 6rem;
  }
  @include bpm-xs {
    padding: 3rem 2.5%;
  }
  &_header {
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    margin: 2rem 0rem;
    color: #333333;
  }
  &_card {
    &__wrapper {
      max-width: 1332px;
      border-radius: 24px;
      // padding: 1rem 1rem;
      display: flex;
      flex-direction: row;
      margin-bottom: 3rem;
      @include bpm-lg {
        flex-direction: column;
      }
    }
    //! Css for left section of card - >

    &-left {
      padding: 0rem 1.5rem 0rem 2rem;
      display: flex;
      align-items: center;
      @include bpm-lg {
        width: 100%;
        padding: 2rem 1.5rem;
      }

      &-image {
        border-radius: 16px;
        width: 398px;
        height: 292px;

        @include bpm-lg {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
    }

    //! Css for middle section of card - >

    &-middle {
      display: flex;
      flex-direction: column;
      padding: 2rem 1.5rem;
      width: 50%;

      @include bpm-lg {
        width: 100%;
        padding: 2.2rem 3rem;
      }

      @include bpm-xs {
        padding: 1rem 1.5rem;
      }
      &-headTextWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: max-content;
        margin-bottom: 1.5rem;
        &-innerWrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        @include bpm-lg {
          margin-bottom: 1rem;
          align-items: flex-start;
        }
        &-courseName {
          font-family: "Lato";
          font-style: normal;
          font-weight: 900;
          font-size: 24px;
          @include bpm-xl {
            font-size: 22px;
          }
          line-height: 29px;
          text-transform: capitalize;
          color: #333333;
          word-break: break-all;
          &::after {
            content: " |";
          }
          @include bpm-md {
            font-size: 20px;
          }
          @include bpm-xs {
            font-size: 24px;

            &::after {
              content: none;
            }
          }
        }
        &-ages {
          font-family: "Lato";
          font-style: normal;
          font-weight: 900;
          font-size: 16px;
          line-height: 19px;
          color: #000000;
          @include bpm-md {
            font-size: 13px;
            white-space: nowrap;
          }
          @include bpm-xs {
            display: none;
          }
        }
        &-poweredBy {
          width: 116px;
          height: 64px;
          @include bpm-xs {
            display: none;
          }
          &-mobile {
            display: none;
            @include bpm-xs {
              display: block;
              width: 88px;
              height: 44px;
              margin: 1rem 0rem;
            }
          }
        }
      }
      &-person-icon {
        width: 15px;
        height: 15px;
        margin: 0rem 0.8rem;
        @include bpm-xs {
          display: none;
        }
      }
      &-courseDescription {
        font-family: "Lato";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        overflow: hidden;
        color: #666666;
        &.expand {
          max-height: none;
        }
        &.retract {
          max-height: 40px;
        }
        @include bpm-md {
          font-size: 15px;
          max-height: none !important;
        }
        @include bpm-xs {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 1rem;
        }
      }
      &-courseDescriptionToggle {
        text-decoration: underline;
        font-size: 12px;
        cursor: pointer;
        @include bpm-lg {
          display: none;
        }
      }

      //! Css for course duration div visible on mobile only ->

      &-courseDuration-mobile {
        display: none;
        @include bpm-xs {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 1rem 0rem;
        }
        &-person-icon {
          width: 15px;
          height: 15px;
          margin: 0rem 0.8rem;
        }
        &-ages {
          font-family: "Lato";
          font-style: normal;
          font-weight: 900;
          font-size: 16px;
          line-height: 19px;
          color: #000000;
          font-size: 13px;
          white-space: nowrap;
        }
        &-topWrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: 1rem;
          &-clockIcon {
            width: 16px;
            height: 16px;
            margin-right: 0.5rem;
          }
          &-session {
            font-family: "Lato";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #333333;
          }
        }
      }
      &-batches {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
        margin: 1rem 0rem;
        @include bpm-md {
          margin: 1rem 0rem;
        }
        @include bpm-xs {
          flex-direction: column;
          flex: 1 0 auto;
        }
        &-batchWrapper {
          position: relative;
          background: linear-gradient(
            111.29deg,
            rgba(255, 255, 255, 0.53) -1.83%,
            rgba(15, 14, 14, 0) 109.95%
          );
          border-radius: 8px;
          border: 1px solid #ffffff;
          padding: 1rem 1rem;
          @include bpm-md {
            padding: 0.8rem 0.8rem;
          }
          @include bpm-xs {
            padding: 1rem 1rem;
          }
        }
        &-mainText {
          font-family: Lato;
          font-size: 15px;
          font-weight: 700;
          line-height: 18px;
          letter-spacing: 0px;
          text-align: left;
          color: #333333;
          max-width: 135px;
          margin-bottom: 1rem;
          @include bpm-md {
            font-size: 13px;
          }
        }
        &-subText {
          font-family: Lato;
          font-size: 15px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0px;
          text-align: left;
          color: #666666;
          @include bpm-md {
            font-size: 13px;
          }
        }
        &-batchNumber {
          background: #ffffff;
          border-radius: 8px;
          padding: 1rem 1rem;
          width: max-content;
          position: absolute;
          right: 10px;
          bottom: 15px;
          display: none;
          @include bpm-xs {
            display: block;
          }
          &-text {
            font-family: "Lato";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #999999;
          }
        }
      }
      &-downloadDetails {
        &_wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          @include bpm-lg {
            justify-content: center;
            margin-top: 1.5rem;
          }
        }
        &-icon {
          width: 32px;
          height: 32px;
          @include bpm-md {
            width: 22px;
            height: 22px;
          }
          margin-right: 0.5rem;
          @include bpm-xs {
            display: none;
          }
          &-mobile {
            width: 24px;
            height: 24px;
            margin-right: 0.7rem;
            display: none;
            @include bpm-xs {
              display: block;
            }
          }
        }
        &-text {
          font-family: "Lato";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          text-decoration-line: underline;
          color: #333333;
          @include bpm-md {
            font-size: 16px;
          }
        }
      }
    }
    &-right {
      width: 20%;
      box-shadow: 50px 60px 100px rgba(0, 0, 0, 0.05);
      backdrop-filter: blur(35px);
      border-radius: 0px 24px 24px 0px;
      padding: 3rem 1rem 2rem 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      @include bpm-lg {
        width: 100%;
        background: none !important;
        box-shadow: none;
        backdrop-filter: none;
        border-radius: none;
      }
      @include bpm-md {
        padding: 2rem 1rem 4rem 1rem;
      }
      @include bpm-xs {
        padding: 1rem 1rem 2rem 1rem;
      }
      &-topWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        @include bpm-xs {
          display: none;
        }
        &-clockIcon {
          width: 20px;
          height: 20px;
          margin-right: 1rem;
          @include bpm-md {
            width: 16px;
            height: 16px;
          }
        }
        &-session {
          font-family: "Lato";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #333333;
          @include bpm-lg {
            margin-bottom: 1rem;
          }
        }
      }
      &-bottomWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        &-price {
          font-family: "Lato";
          font-style: normal;
          font-weight: 900;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          text-transform: capitalize;
          color: #333333;
          margin-bottom: 2rem;
        }
        &-gradientText {
          font-family: "Lato";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          cursor: pointer;
          line-height: 24px;
          padding-top: 2rem;
          background-image: linear-gradient(
            -45deg,
            #f3705b 0%,
            #fcb444 48%,
            #fcb444 52%,
            #f3705b 100%
          );
          background-size: 100%;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-text-fill-color: transparent;
        }
      }
    }
  }
}
