.student-card {
  &__wrapper {
    position: relative;
  }
  &__bg {
    // position: absolute;
    @include bpm-sm {
      width: 95%;
    }
  }
  &__image {
    position: absolute;
    width: 12rem;
    height: 12rem;
    object-fit: cover;
    border-radius: 30rem;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    @include bpm-sm {
      width: 9rem;
      height: 9rem;
      top: 4rem;
    }
  }
  &__student-details {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 3rem;
    @include bpm-sm {
      bottom: 5rem;
    }
  }
  &__name {
    color: #fff;
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 1rem;
    @include bpm-sm {
      font-size: 1.8rem;
    }
  }
  &__school-name {
    color: #fff;
    font-weight: 500;
    font-size: 1.6rem;
    max-width: 14rem;
    @include bpm-sm {
      font-size: 1.4rem;
    }
  }
}
