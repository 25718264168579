.storybook-component {
  &__wrapper {
    height: 100%;
    width: 100%;
    background-color: #444;
  }
  position: relative;
  .fullscreen-icons {
    position: absolute;
    width: 6rem;
    height: 6rem;
    background-color: #fff;
    border-radius: 10rem;
    cursor: pointer;
    padding: 1.5rem;
    margin: 1.5rem;
    bottom: 0;
    right: 0;
    img {
      width: 3rem;
    }
    @include bpm-md {
      width: 4rem;
      height: 4rem;
      margin: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      left: auto;
      right: 0.5rem;
      img {
        width: 2rem;
      }
      transform: scale(0.8);
    }
  }
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  &__main-game-container {
    margin: 1rem auto 2rem auto;
    // @include bpm-custom(1025) {
    //   margin: 22% 0 1rem 1rem;
    // }
    // @include bpm-md {
    //   margin: 20% 0 1rem 1rem;
    // }

    @include bpm-custom(700) {
      width: 100%;
    }
  }
  &__nav {
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 1.5rem 0;
    background-color: rgba(0, 0, 0, 0.7);
    @include bpm-md {
      padding: 0.5rem;
    }
    &--title {
      background-color: rgba(255, 176, 5, 0.8);
      border-radius: 0 0.5rem 0.5rem 0;
      padding: 1rem 1.5rem 1rem 1.5rem;
      justify-self: flex-start;
      flex-direction: column;
      display: flex;
      align-items: flex-start;
      h1 {
        display: flex;
        font-size: 2.2rem;
        align-items: center;
        text-transform: capitalize;
        font-weight: 900;
        color: #333;
        @include bpm-sm {
          font-size: 1.4rem;
        }
        p {
          font-weight: 600;
          margin-left: 0.5rem;
          font-size: 1.39rem;
          @include bpm-sm {
            font-size: 1rem;
          }
          color: #333;
        }
      }
      h2 {
        font-size: 1.6rem;
        align-items: center;
        font-weight: 700;
        color: #000000;
        @include bpm-sm {
          font-size: 1.2rem;
          margin-top: 3px;
        }
      }

      // @include bpm-md {
      //   padding: 0.7rem 2rem 0.7rem 1rem;
      //   h1 {
      //     align-items: center;
      //     font-size: 1.8rem;
      //     font-weight: 900;
      //     color: #333;
      //   }
      // }
    }
    &--logo {
      padding: 1.5rem;
      display: flex;
      background-color: #eee;
      position: absolute;
      left: 47%;
      border-radius: 0.7rem;
      // width: 13rem;
      justify-self: center;
      img {
        width: 10rem;
      }
      @include bpm-md {
        padding: 1rem;
        display: flex;
        justify-self: center;
        img {
          width: 8rem;
          object-fit: contain;
        }
      }
    }
    &--action {
      display: flex;
      align-items: center;
      justify-self: flex-end;
      .primary-button {
        margin: 0 2rem;
        width: max-content;
        @include bpm-md {
          margin-right: 0;
          margin-left: 0rem;
          transform: scale(0.8);
        }
      }
      .secondary-button {
        margin-top: 0;
        @include bpm-md {
          transform: scale(0.8);
        }
      }
      &-share {
        font-size: 1.6rem;
        font-weight: 500;
        color: #f2b65a;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  &__story-container {
    // width: 35vw;
    margin: 0 0 5rem 15rem;
    background-color: rgba(255, 181, 65, 0.9);
    padding: 1rem 2rem;
    border-radius: 1.5rem;
    width: 41rem;
    height: 20rem;
    overflow-y: scroll;
    @include bpm-custom(700) {
      height: 15.4rem;
    }
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    @include bpm-custom(1024) {
      margin: 0 0 2rem 2rem;
      padding: 1rem 2rem;
      border-radius: 1rem;
    }
    &--transcripts {
      .single-transcript {
        font-size: 1.7rem;
        // display: flex;
        font-family: Lato;
        text-align: justify;
        line-height: 2rem;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 1rem;
        .tab-space {
          display: inline-block;
          width: 5rem;
        }
        @include bpm-md {
          font-size: 1.6rem;
          line-height: 2.2rem;
        }
      }
    }
  }
  &__controller {
    display: flex;
    align-items: center;
    width: 80vw;
    @include bpm-custom(700) {
      margin-left: 2rem;
    }
    > img {
      width: 4rem;
      margin-right: 1.5rem;
    }
  }
  &__seeker {
    &--container {
      width: 100%;
      // height: 5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 1rem;
      padding: 1.5rem;
      @include bpm-md {
        // width: 90vw;
      }
      position: relative;
      .stage-indicator {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 1.45rem;
        width: 1rem;
        // border-radius: 5rem;
        background: linear-gradient(180deg, #eaac59 0%, #dd775f 100%);
        @include bpm-md {
          height: 0.6rem;
          width: 0.6rem;
        }
      }
    }
    height: 0.5rem;
    width: 100%;
    border-radius: 2rem;
    background-color: #ccc;
    @include bpm-md {
      height: 0.5rem;
    }
    .completed {
      border-radius: 2rem;
      background: linear-gradient(180deg, #eaac59 0%, #dd775f 100%);
      height: 100%;
      .slider-knob {
        transform: translateY(-0.7rem);
        height: 2rem;
        width: 2rem;
        border-radius: 5rem;
        background: linear-gradient(180deg, #eaac59 0%, #dd775f 100%);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
        @include bpm-md {
          height: 1.5rem;
          width: 1.5rem;
          transform: translateY(-0.4rem);
        }
      }
    }
    // position: relative;

    // &--stage-markers {
    //   display: flex;
    //   align-items: center;
    //   width: 100%;
    //   margin-top: 5px;
    //   span {
    //     display: inline-block;
    //   }
    //   .separator {
    //     height: 3rem;
    //     width: 0.4rem;
    //     border-radius: 5rem;
    //     background-color: #f2b65a;
    //     @include bpm-md {
    //       height: 0.6rem;
    //       width: 0.6rem;
    //     }
    //   }
    //   .stage_title {
    //     font-size: 1.6rem;
    //     color: #fff;
    //     text-align: center;
    //     @include bpm-md {
    //       font-size: 1.2rem;
    //     }
    //   }
    // }
  }
  &__controls {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 1rem;
    padding: 1rem;
    position: absolute;
    right: 1.5rem;
    bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      cursor: pointer;
      margin: 0.8rem 0;
      width: 5rem;
    }
    @include bpm-md {
      right: 1rem;
      bottom: 7rem;
      img {
        width: 3rem;
      }
    }
  }
  &__portrait {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-image: linear-gradient(to bottom, #fff, #888, #666, #444);
    &--logo {
      width: 12rem;
      margin: 3rem 0;
    }
    &--title {
      font-size: 2.2rem;
      text-align: center;
      max-width: 90vw;
      margin: auto;
      color: #eee;
    }
  }
}
