.summercamp-brandnewcomponent {
  &__wrapper {
    padding: 1.2rem 4rem;
    background-color: rgba(255, 248, 238, 1);
    @include bpm-xs {
      padding: 0 3rem;
    }
    @include bpm-xxs {
      padding: 0 1rem;
    }
  }
  display: flex;
  width: 100%;
  min-height: 20rem;
  flex-direction: column;
  &__sliderContainer {
    margin-top: 3.2rem;
  }
  .primary-button {
    width: 20rem;
    margin: 4.8rem auto;
    @include bpm-md {
      margin: 1.6rem auto 4rem;
    }
  }
}
