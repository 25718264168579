.affiliate-table {
  &__wrapper {
    padding: 0;
  }
  display: flex;
  &__fullname {
    font-size: 1.5rem;
    text-align: center;
  }
  &__childname {
    &__container {
      display: flex;
      flex-direction: column;
      height: 9.6rem;
      overflow-y: scroll;
      &-p-text {
        margin-bottom: 5px;
      }
    }
  }
  &__purchaseStaus {
    font-size: 1.5rem;
    text-align: center;
  }
  &__classesRegistered {
    &__container {
      display: flex;
      flex-direction: column;
      height: 9.6rem;
      overflow-y: scroll;
      &--studentName {
        font-weight: 900;
      }
      p {
        font-size: 1.4rem;
        margin-bottom: 0.5rem;
      }
    }
  }
  &__upcomingClass {
    &__container {
      display: flex;
      flex-direction: column;
      height: 9.6rem;
      overflow-y: scroll;
      &--studentname {
        font-weight: 900;
      }
      &--time {
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
  }
}
