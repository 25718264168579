.spellingBee_complete-similar-schools-strip {
  &_wrapper {
    width: 100%;
    padding: 1rem 1rem;
    background: #333333;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 3rem 0rem;
    @include bpm-xs {
      flex-direction: column;
      margin: 2rem 0rem;
      align-items: flex-start;
      justify-content: none;
      display: none;
    }
  }
  &_leftWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &-image {
    width: 90%;
    height: 90%;
    object-fit: contain;
    &_wrapper {
      width: 50px;
      height: 50px;
      background: linear-gradient(113.87deg, #a9cbff -9.5%, #ffffff 97.11%);
      box-shadow: 36.5643px 43.8772px 73.1286px rgba(0, 0, 0, 0.05);
      box-sizing: border-box;
      border-radius: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin-right: 2rem;
      @include bpm-xs {
        margin-right: 1rem;
        width: 40px;
        height: 40px;
      }
    }
  }
  &-text {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #ffffff;
    text-align: left;
    @include bpm-xs {
      font-size: 20px;
      line-height: 24px;
    }
  }
  &-checkNow {
    position: relative;
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: left;
    &::before {
      content: "";
      position: absolute;
      top: 110%;
      width: 100%;
      left: 0;
      height: 1px;
      border-radius: 2px;
      background: linear-gradient(
        -45deg,
        #f3705b 0%,
        #fcb444 48%,
        #fcb444 52%,
        #f3705b 100%
      );
    }
    background-image: linear-gradient(
      -45deg,
      #f3705b 0%,
      #fcb444 48%,
      #fcb444 52%,
      #f3705b 100%
    );
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    text-decoration-line: underline;
    text-underline-position: under;
    text-decoration-color: #fcb444;
    cursor: pointer;
    @include bpm-xs {
      font-size: 20px;
      line-height: 24px;
      margin-left: 45px;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}
