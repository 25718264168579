.invite_friend_modal {
  &_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 130vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1100;
    display: none;
  }
  &-close {
    width: 42px;
    height: 42px;
    position: absolute;
    right: 10px;
    top: -15px;
    z-index: 1000;
    cursor: pointer;
  }
  background-color: #ffffff;
  border-radius: 16px;
  opacity: 1;
  // width: 77%;
  position: relative;
  padding: 1rem 7rem 3rem 7rem;
  @include bpm-lg {
    // width: 85%;
  }
  @include bpm-xs {
    width: max-content;
    padding: 1rem 2rem 2rem 2rem;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  &_container {
    &-img {
      margin-top: 5px;
      width: 293px;
      height: 156px;
    }
    &-text {
      padding-top: 15px;
      padding-bottom: 15px;
      color: #333;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      letter-spacing: 0.2px;
    }
    p {
      color: #666;
      text-align: center;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
  &__copyButton {
    cursor: pointer;
    margin-top: 5px;
    width: 100%;
    height: 4.4rem;
    background: linear-gradient(90deg, #f3705b 0%, #fcb444 100%);
    box-shadow: 0px 4px 14px 0px #eee;
    border-radius: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.2rem;
    @include bpm-md {
      width: 100%;
    }
    &--text {
      font-family: Lato;
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      background: white;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}

.unlocked_studio_modal {
  &_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 130vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1100;
    display: none;
  }
  &-close {
    width: 42px;
    height: 42px;
    position: absolute;
    right: 10px;
    top: -15px;
    z-index: 1000;
    cursor: pointer;
  }
  background-color: #c8e8bc;
  border-radius: 16px;
  opacity: 1;
  max-width: 624px;
  position: relative;
  padding: 1rem 7rem 3rem 7rem;
  @include bpm-lg {
    width: 85%;
  }
  @include bpm-xs {
    width: max-content;
    padding: 1rem 2rem 2rem 2rem;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
}
