.request-callback {
  &__wrapper {
    margin-left: 80px;
    padding: 0 12%;
    @include bpm-md {
      padding: 0;
      margin-left: 0;
    }
    margin-bottom: 24px;
    margin-top: 32px;
  }

  padding: 12px;
  border-radius: 16px;
  background-color: #000632;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  @include bpm-md {
    flex-direction: column;
    justify-content: center;
    row-gap: 16px;
  }
  @include bpm-xs {
    padding: 0;
  }
  //   align-items: center;
  column-gap: 16px;
  &--left {
    width: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include bpm-md {
      width: 312px;
    }
    img {
      width: 100%;
      height: 262px;
      // @include bpm-xs {
      //   width: 278px;
      // }
    }
  }
  &--right {
    @include bpm-xs {
      padding: 0 12px 12px 12px;
    }
    @include bpm-md {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    padding: 12px;
    &__heading-text {
      color: #fff;
      font-size: 32px;
      font-weight: 900;
      text-align: left;
      @include bpm-md {
        font-size: 20px;
        text-align: center;
      }
    }
    &__subheading-text {
      margin-top: 16px;
      font-style: italic;
      font-size: 20px;
      text-align: left;
      color: #fff;
      @include bpm-md {
        font-size: 16px;
        text-align: center;
      }
      @include bpm-xs {
        margin-top: 8px;
      }
    }
    &__query--input {
      &__wrapper {
        width: 100%;
        position: relative;
        cursor: pointer;
      }
      margin-top: 48px;
      @include bpm-xs {
        margin-top: 24px;
      }
      display: flex;
      align-items: center;
      border-radius: 8px;
      background-color: #fff;
      height: 42px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      padding: 0 16px;
      input {
        cursor: pointer;
        width: 95%;
        height: 100%;
        padding: 12px;
        color: #333;
        border-radius: 8px;
        line-height: 17.43px;
        outline: none;
        border: none;
        font-size: 16px;
      }
    }
    &__cta {
      &--container {
        margin-top: 16px;
      }
      cursor: pointer;
      background: linear-gradient(89deg, #f2b65a 0%, #e47762 100%);
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
      padding: 12px 24px 12px 16px;
      display: flex;
      align-items: center;
      gap: 16px;
      width: 220px;
      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
      @include bpm-md {
        width: 264px;
      }
      border-radius: 5rem;
      img {
        height: 24px;
        width: 24px;
      }
      &--text {
      }
      color: #fff;
      font-size: 18px;
      font-weight: 700;
    }
    &__query-lists {
      &--container {
        position: absolute;
        bottom: 46px;
        width: 100%;
      }
      &::-webkit-scrollbar {
        display: none;
      }
      max-height: 204px;
      width: 100%;
      overflow: scroll;
      border-radius: 8px;
      background-color: #fff;
      &--query {
        padding: 16px 24px;
        color: #333;
        text-align: left;
        font-size: 16px;
        @include bpm-md {
          font-size: 14px;
        }
        &__other-query {
          margin-top: 16px;
          width: 100%;
          border: 1px soild #ffc700;

          font-size: 16px;
          &__input {
            min-height: 80px;
            border-radius: 8px;
            padding: 16px;
            width: 100%;
            border: none;
            outline: none;
            resize: none;
          }
        }
        &__divider {
          height: 1px;
          width: 100%;
          background-color: rgba(153, 153, 152, 0.4);
        }
      }
      &--query:hover {
        font-weight: 700;
        background-color: rgba(0, 169, 18, 0.2);
      }
    }
  }
}
