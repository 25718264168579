.result-storybook-card {
  &__wrapper {
    padding: 0 2rem 2rem 0rem;
    background-color: rgba(64, 68, 168, 1);
    height: 32rem;
    @include bpm-md {
      width: 100%;
      min-height: 55.2rem;
      // overflow-y: scroll;
      padding: 0;
    }
    margin: 2rem auto;
    width: 75%;
    border-radius: 2.4rem;
  }
  display: flex;
  justify-content: space-between;
  &__left {
    display: flex;
    flex-direction: column;
    width: 50%;
    &--image {
      width: 100%;
      height: 27rem;
      object-fit: cover;
      margin-bottom: 0rem;
      border-radius: 2rem;
    }
    &--p-heading {
      font-family: Lato;
      color: rgba(255, 255, 255, 1);
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 29px;
      letter-spacing: 0px;
      text-align: left;
    }
    &--sub-heading {
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      margin-top: 0.8rem;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      color: rgba(255, 255, 255, 1);
    }
    &__reportCard {
      padding: 3.2rem 2.4rem;
      border-radius: 0.8rem;
      display: flex;
      margin-top: 3.2rem;
      background-color: rgba(251, 248, 249, 1);
      // width: 52.9rem;
      width: 100%;
      height: 20rem;
      &__left {
        width: 70%;
        &--p-heading {
          font-family: Lato;
          margin-bottom: 0.4rem;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: 0px;
          text-align: left;
          color: rgba(51, 51, 51, 1);
        }
        &__best-words-container {
          display: flex;
          // flex-direction: column;
          flex-wrap: wrap;
          margin-left: 0.8rem;

          &--p-text {
            margin-top: 5px;
            width: 155px;
            display: inline-block;
          }
          &--text {
            position: relative;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0px;
            position: relative;
            text-align: left;
            color: #333333;
          }
          &--score {
            position: absolute;
            left: 36px;
            top: -17px;
            color: rgba(229, 120, 98, 1);
            font-family: Lato;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0px;
            text-align: left;
          }
        }
      }
      &__right {
        display: flex;
        width: 30%;
        justify-content: flex-end;
      }
    }
    &--s-text {
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      margin-top: 2.4rem;
      margin-left: 1.6rem;
      color: rgba(251, 248, 249, 1);
      text-decoration: underline;
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    padding: 0rem 1rem 1rem 7rem;
    @include bpm-lg {
      padding: 0rem 1rem 1rem 3rem;
    }

    &--p-heading {
      margin-top: 3rem;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      text-transform: capitalize;
      line-height: 29px;
      letter-spacing: -0.20000000298023224px;
      text-align: left;
      color: rgba(255, 255, 255, 1);
    }
    &--transcript {
      margin-top: 0.8rem;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      min-height: 46%;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(255, 255, 255, 1);
    }
    &__container {
      display: flex;
      align-items: center;
      margin-top: 2rem;
      justify-content: space-around;
      &--buttonStyle {
        padding: 1.8rem 3rem;
        box-shadow: none;
        justify-content: center;
        font-weight: 600;
        font-size: 1.4rem;
        @include bpm-custom(1230) {
          font-size: 1.2rem;
          padding: 1rem 2rem;
        }
        @include bpm-custom(1024) {
          font-size: 1rem;
          padding: 1rem 1.5rem;
        }
      }
      .primary-button {
        @include bpm-lg {
          padding: 1.5rem 2rem;
          font-size: 1.3rem;
        }
        @include bpm-custom(1010) {
          padding: 1.25rem 1rem;
          font-size: 1.3rem;
        }
      }
      &--p-text {
        color: rgba(255, 164, 23, 1);
        font-family: Lato;
        font-size: 13px;
        cursor: pointer;
        text-decoration: underline;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: left;
      }
    }
  }
  &__swiper {
    margin: 0;
    padding-bottom: 5rem !important;
    .swiper-slide {
      width: 42rem;
    }
    .swiper-pagination {
      margin-bottom: 0;
    }
    .swiper-button-next {
      display: none;
    }
    @include bpm-custom(850px) {
      .swiper-slide {
        width: 35rem;
      }
    }
    @include bpm-xs {
      .swiper-slide {
        width: 32rem;
      }
    }
  }
}

.result-mobile-storybook-card {
  &__wrapper {
    padding: 0 2rem 2rem 0rem;
    background-color: rgba(64, 68, 168, 1);

    // height: 32rem;
    margin: 2rem auto;
    width: 75%;
    border-radius: 2.4rem;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__left {
    display: flex;
    flex-direction: column;
    // width: 50%;
    &--image {
      width: 100%;
      height: 27rem;
      object-fit: cover;
      margin-bottom: 0rem;
      border-radius: 2rem;
      @include bpm-md {
        border-radius: 2rem 2rem 0 0;
      }
    }
    &--p-heading {
      font-family: Lato;
      color: rgba(255, 255, 255, 1);
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 29px;
      letter-spacing: 0px;
      text-align: left;
    }
    &--sub-heading {
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      margin-top: 0.8rem;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      color: rgba(255, 255, 255, 1);
    }
    &__reportCard {
      padding: 3.2rem 2.4rem;
      border-radius: 0.8rem;
      display: flex;
      margin-top: 3.2rem;
      background-color: rgba(251, 248, 249, 1);
      // width: 52.9rem;
      width: 100%;
      height: 20rem;
      &__left {
        width: 70%;
        &--p-heading {
          font-family: Lato;
          margin-bottom: 0.4rem;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: 0px;
          text-align: left;
          color: rgba(51, 51, 51, 1);
        }
        &__best-words-container {
          display: flex;
          // flex-direction: column;
          flex-wrap: wrap;
          margin-left: 0.8rem;

          &--p-text {
            margin-top: 5px;
            width: 155px;
            display: inline-block;
          }
          &--text {
            position: relative;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0px;
            position: relative;
            text-align: left;
            color: #333333;
          }
          &--score {
            position: absolute;
            left: 36px;
            top: -17px;
            color: rgba(229, 120, 98, 1);
            font-family: Lato;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0px;
            text-align: left;
          }
        }
      }
      &__right {
        display: flex;
        width: 30%;
        justify-content: flex-end;
      }
    }
    &--s-text {
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      margin-top: 2.4rem;
      margin-left: 1.6rem;
      color: rgba(251, 248, 249, 1);
      text-decoration: underline;
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    padding: 0rem 2rem 1rem 2rem;
    @include bpm-md {
      padding: 0rem 1rem 1rem 2rem;
    }

    &--p-heading {
      margin-top: 1.2rem;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      text-transform: capitalize;
      line-height: 29px;
      letter-spacing: -0.20000000298023224px;
      text-align: left;
      color: rgba(255, 255, 255, 1);
    }
    &--transcript {
      margin-top: 0.8rem;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(255, 255, 255, 1);
      min-height: 46%;
      @include bpm-md {
        min-height: 10rem;
      }
    }
    &__container {
      display: flex;
      align-items: center;
      margin-top: 7rem;
      justify-content: space-around;
      &--buttonStyle {
        padding: 1.8rem 3rem;
        box-shadow: none;
        justify-content: center;
        font-weight: 600;
        font-size: 1.4rem;
        @include bpm-custom(1230) {
          font-size: 1.2rem;
          padding: 1rem 2rem;
        }
        @include bpm-custom(1024) {
          font-size: 1rem;
          padding: 1rem 1.5rem;
        }
      }
      .primary-button {
        @include bpm-lg {
          padding: 1.5rem 2rem;
          font-size: 1.3rem;
        }
        @include bpm-md {
          font-size: 13px;
          padding: 1rem 1rem;
        }
      }
      &--p-text {
        color: rgba(255, 164, 23, 1);
        font-family: Lato;
        font-size: 11px;
        text-decoration: underline;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: left;
      }
    }
  }
  &__swiper {
    margin: 0;
    padding-bottom: 5rem !important;
    @include bpm-md {
      padding-bottom: 0rem !important;
    }
    .swiper-slide {
      width: 42rem;
    }
    .swiper-pagination {
      margin-bottom: 0;
    }
    @include bpm-custom(850px) {
      .swiper-slide {
        width: 35rem;
      }
    }
    @include bpm-xs {
      .swiper-slide {
        width: 32rem;
      }
    }
  }
}
