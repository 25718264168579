.support_modal_dropdown {
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 10px;

  &-tab {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px 5px;
    cursor: pointer;
    &-topic {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #333333;
    }
    &--arrowInactive {
      transform: rotate(-90deg);
      transition: 0.5s;
      cursor: pointer;
    }
    &--arrowActive {
      transition: 0.5s;
      cursor: pointer;
    }
  }
  &-body {
    padding: 0px 0px 15px 15px;
    &-question {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #333333;
    }
    &-answer {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #999999;
      margin-top: 4px;
    }
  }
  &-callback {
    display: flex;
    flex-direction: row;
    @include bpm-xs {
      flex-direction: column;
      align-items: flex-start;
    }
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px 15px 15px;
    &-text {
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #333333;
      @include bpm-xs {
        margin-bottom: 15px;
      }
    }
  }
}
