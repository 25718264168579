.slot-selection-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 2.6rem;
  border-radius: 1rem;
  &__p-container {
    display: flex;
    width: 100%;
    &-p-text {
      font-family: Lato;
      font-size: 22px;
      font-weight: 800;
      line-height: 33px;
      letter-spacing: -0.011em;
      text-align: left;
    }
  }
  &__s-text {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: -0.011em;
    text-align: left;
  }
  &__t-text {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: -0.011em;
    text-align: center;
    text-decoration: underline;
    color: #e47762;
    margin-top: 1.6rem;
  }
  &__callback-container {
    display: "flex" !important;
    flex-direction: "column";
    justify-content: "center";
    align-content: "center";
    width: "100%";
    margin-top: "1rem";
    &__wrapper {
      text-align: center;
    }
    &--p-text {
      font-family: Lato;
      font-size: 24px;
      font-weight: 900;
      line-height: 36px;
      letter-spacing: -0.011em;
      text-align: left;
      color: #333333;
      margin-top: 1.6rem;
    }
  }
  .primary-button {
    width: 13rem;
    height: 4rem;
    // margin: 2rem 0 1rem auto;
    font-weight: 700;
    font-size: 1.45rem;
    margin: 3.6rem auto 0 !important;
    &.callback {
      margin: 2.4rem auto 0 !important;
      width: 100%;
    }
  }

  position: relative;

  &__close {
    position: absolute;
    width: 3rem;
    top: 3rem;
    right: 2rem;
    cursor: pointer;
  }
}
