.courseProgressBar {
  //! wrapper that wraps around the whole div
  &__wrapper {
    margin: 10px 0px;
    display: flex;
    align-items: center;
  }
  //! The progress bar is set to a static width+height
  //! A color is added to the container
  width: 189px;
  @include bpm-xs {
    width: 150px;
  }
  background: #f7eee7;
  border-radius: 10px;
  height: 10px;
  //! a different color for the progress child element
  &__p-text {
    font-family: Lato;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #333333;
    margin: 0 2.4rem;
  }
  &--progress {
    height: 10px;
    width: 50%;
    border-radius: 10px;
  }
}
