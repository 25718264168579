.spark-about-us {
  &__wrapper {
    padding-top: 7rem;
    .linkedin-button {
      position: relative;
      .linkedin-tooltip {
        position: absolute;
        bottom: -110%;
        left: 50%;
        transform: translateX(-50%);
        display: none;
        background-color: #fff;
        border: 2px solid #f3705b;
        border-radius: 5rem;
        span {
          font-size: 1.4rem;
          text-align: center;
          padding: 0.8rem 2rem;
          display: flex;
          align-items: center;
          flex-direction: column;
          width: max-content;
        }
      }
      &:hover {
        .linkedin-tooltip {
          display: block;
        }
      }
    }
  }
  &__strip {
    margin: 5rem 0;
    @include bpm-md {
    }
    &--header {
      font-size: 4rem;
      font-weight: 900;
      text-align: center;
      span.heart {
        display: inline-block;
        margin: 0 1rem;
        img {
          width: 5rem;
          transform: translateY(1rem);
        }
      }
      span.brackets {
        color: #f3705b;
        margin: 0 0.5rem;
      }
    }
    &--companies {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 990px;
      margin: 4rem auto 0 auto;
      img {
        width: 15rem;
        @include bpm-md {
          transform: scale(0.8);
        }
        &.company-2,
        &.company-5 {
          width: 8rem;
        }
      }
      @include bpm-md {
        justify-content: flex-start;
        overflow-x: scroll;
        padding-right: 4rem;
        margin: 2rem auto 0 auto;
      }
    }
  }
  &__career {
    margin-bottom: 7rem;
    .linkedin-input-field {
      width: 40%;
      height: 5rem;
      background: #faefec;
      border: 1px solid #b6b6b6;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 0 2rem;
      outline: none;
      @include bpm-md {
        width: 60%;
      }
      @include bpm-xs {
        width: 80%;
      }
      @include bpm-xxs {
        margin: 2rem 0;
      }
      &:focus,
      &:active {
        outline: none;
      }
    }
  }
}
