.game-requirements {
  max-width: 800px;
  padding: 0 7rem;
  padding-top: 4rem;
  padding-bottom: 10rem;
  margin: 0 auto;
 @include bpm-xs{
   display: none;
 }
  &__header {
    font-size: 2.4rem;
    color: #333;
    font-weight: 900;
    margin-bottom: 3rem;
    text-align: center;
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
