.official-certification {
  &__wrapper {
    padding: 4rem 7rem;
    @include bpm-md {
      padding: 4rem;
    }
    @include bpm-sm {
      padding: 4rem 2rem;
    }
  }
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // grid-gap: 3rem;
  width: 100%;
  @include bpm-sm {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
  }
  &__left {
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      max-width: 1200px;
      border-radius: 2rem;
    }
  }
  &__right {
    margin-top: 4rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    // max-width: 50rem;
    p {
      font-size: 1.6rem;
      display: flex;
      align-items: flex-start;
      margin: 1rem 0;

      img {
        width: 2.5rem;
        margin-right: 1.5rem;
      }
    }
  }
}
