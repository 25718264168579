.live_class_snackbar {
    &-wrapper {
        position: fixed;
        left: 0;
        bottom: 2vh;
        display: flex;
        width: 100vw;
        z-index: 100;
    }

    &-minimize {
        width: 50px;
    }
    
    height: 50px;
    width: 320px;
    margin-left: 5vw;
    border-radius: 40px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #0E1232;
    display: flex;
    flex-direction: row;
    transition: 1s;
    overflow: hidden;

    &-img {
        margin: 4px 4px 4px 4px;
    }
    
    &-pie {
        --thickness: 3px;
        --color: #F2B65A;
        --size: 40px;
    
        aspect-ratio: 1/1;
        position: relative;
        display: inline-grid;
        margin: 5px;
        place-content: center;
    }
    
    &-pie:before,
    &-pie:after {
        content: "";
        position: absolute;
        border-radius: 50%;
    }
    
    &-pie:before {
        inset: 0;
        background:
            radial-gradient(farthest-side, var(--color) 98%, #0000) top/var(--thickness) var(--thickness) no-repeat,
            conic-gradient(var(--color) calc(var(--join-class-pie-percentage)*1%), #0000 0);
        -webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--thickness)), #000 calc(100% - var(--thickness)));
        mask: radial-gradient(farthest-side, #0000 calc(99% - var(--thickness)), #000 calc(100% - var(--thickness)));
    }
    
    &-pie:after {
        inset: calc(50% - var(--thickness)/2);
        background: var(--color);
        transform: rotate(calc(var(--join-class-pie-percentage)*3.6deg - 90deg)) translate(calc(var(--size)/2 - 50%));
    }
    
    &-text {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        white-space: nowrap;

        margin: auto 0 auto 8px;

        color: #FFFFFF;
    }

    &-button {
        box-sizing: border-box;
        width: 83px;
        height: 32px;
        margin: auto 8px;
        border: 2px solid #F2B65A;
        cursor: pointer;
        display: flex;
        border-radius: 16px;
    }

    &-button>p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        margin: auto;
        text-transform: uppercase;
        color: #F2B65A;
    }
}