.sequencer-wrapper {
  margin: auto;
  background-color: #fff;
  height: 600px;
  width: 900px;
  box-shadow: 0px 1px 6px grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 10px;
  > h1 {
    font-size: 3rem;
  }
  .notes-grid-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 80%;
    height: 50%;
    position: relative;
    .sequence-slider {
      position: absolute;
      height: 80%;
      width: 4px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: red;
      z-index: 3;
    }
    .visualizer-wrapper {
      position: absolute;
      right: -1.5rem;
      top: 50%;
      transform: translateY(-50%);
      z-index: 3;
      height: 60%;
      width: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .visualizer {
        height: 3rem;
        width: 3rem;
        &-1 {
          background-color: rgb(0, 149, 255);
        }
        &-2 {
          background-color: limegreen;
          border-radius: 3rem;
        }
        &-3 {
          background-color: salmon;
          clip-path: polygon(50% 0%, 100% 100%, 0% 100%, 50% 0%);
          transform: translateY(-0.4rem);
        }
      }
    }
  }
  .notes-grid {
    width: 100%;
    margin: 0 auto;
    height: 3.3px;
    background-color: #f5d1c3;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .note-checkbox {
      visibility: hidden;
      position: relative;
      cursor: pointer;
      &::after {
        visibility: visible;
        content: "";
        height: 10px;
        width: 10px;
        border-radius: 5rem;
        background-color: #aa3925;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      &:checked {
        &::after {
          visibility: visible;
          content: "";
          height: 25px;
          width: 25px;
          background-color: #ffb542;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .control {
    display: flex;
    align-items: center;
    padding: 2rem 3rem;
    border-radius: 1.5rem;
    box-shadow: 0px 1px 4px rgb(238, 248, 251), 0px 0px 10px rgb(192, 192, 192);
    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .stop-recording {
        outline: none;
        border: none;
        background-color: rgb(255, 77, 77);
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.6);
        padding: 0.7rem 2rem;
        border-radius: 5rem;
        cursor: pointer;
        margin-left: 2rem;
        &.disabled {
          background-color: rgb(159, 159, 159);
          cursor: not-allowed;
        }
      }
    }
    .play-btn {
      width: 6rem;
      margin-left: 2rem;
      box-shadow: 0px 1px 4px rgb(101, 100, 100);
      border-radius: 20rem;
      cursor: pointer;
    }
    // ! For GSAP slider

    .slider-container {
      width: 450px;
      height: 2px;
      background-color: rgb(2, 119, 214);
      border-radius: 5px;
    }
    .slider-target {
      width: 100px;
      height: 35px;
      background: rgb(245, 245, 245);
      border-radius: 4rem;
      border: 1px solid rgb(2, 119, 214);
      cursor: pointer;
      transform: translateY(calc(-50% + 1px));
      display: flex;
      align-items: center;
      justify-content: center;
      .bpm-level {
        font-size: 1.6rem;
        color: rgb(2, 119, 214);
      }
    }
  }
}
