.studioProfile {
  //! Wrapper class wraps around the whole component

  &__wrapper {
    //! display set to flex with direction column ( one below another)
    //! items aligned to center with margin left of 15px
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15px;

    h1 {
      color: #333333;
      font-family: Lato;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px;
      letter-spacing: 0em;
      margin-bottom: 10px;
    }
  }
  //! The edit class is the combo of the p tag plus the edit icon
  //! set to display flex with align items center and a bottom margin
  &-edit {
    display: flex;
    margin-bottom: 40px;
    align-items: center;
    //! css for the edit icon (margin left of 7px)
    .studio-profile-edit-icon {
      margin-left: 7px;
      cursor: pointer;
    }
    span {
      margin-top: 10px;
      color: #666666;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: 0em;
    }
  }
  //! Profile pic in the studioprofile Component
  //! width of 180px
  &-profileImage {
    width: 92px;
    height: 92px;
    background-color: #fff;
    border-radius: 100%;
  }
  &--img {
    width: 95%;
    height: 100%;
    border-radius: 100%;
    object-fit: contain;
  }

  &-bar {
    width: 350px;
    height: 40px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 25px;
    position: relative;
    @include bpm-xs {
      width: 300px;
    }
    &_status {
      position: absolute;
      top: 28%;
      font-size: 14px;
      color: rgba(153, 153, 153, 1);
      font-family: Lato;
      font-weight: 500;
    }
    &_progress {
      height: 100%;
      background-image: linear-gradient(
        rgba(190, 23, 164, 1),
        rgba(51, 42, 254, 1)
      );
      border-radius: 25px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        position: absolute;
        right: -1.3rem;
        top: -0.4rem;
      }
      &_status {
        font-size: 14px;
        color: white;
        font-family: Lato;
        font-weight: 500;
      }
    }
  }
}
