.spellingBee_game_complete {
  &_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 130vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1100;
    display: none;
  }

  &-close {
    width: 42px;
    height: 42px;
    position: absolute;
    right: 10px;
    top: -15px;
    z-index: 1000;
    cursor: pointer;
  }
  background-color: #ffffff;
  border-radius: 16px;
  opacity: 1;
  width: max-content;
  position: relative;
  padding: 1rem 7rem 3rem 7rem;

  @include bpm-xs {
    width: max-content;
    padding: 1rem 2rem 2rem 2rem;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  &-topImage {
    width: 305.48px;
    height: 314.08px;
    @include bpm-xs {
      width: 202px;
      height: 207px;
    }
  }
  &-imageWrapper {
  }
  &-cardsWrapper {
    background: #e4fcff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    max-width: 187px;
    max-height: 74px;
    margin: -33px auto 0rem auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem 1rem;
    &-bold {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 42px;
      color: #333333;
      margin: 0rem 0.5rem 0rem 1rem;
    }
    &-normal {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #999999;
    }
  }
  &-statCards {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: max-content;
    width: 100px;
    height: 90px;
    @include bpm-xs {
      width: 90px;
      height: 80px;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 1rem;
    margin: 0rem 0.5rem;
    &-coinImage {
      width: 20px;
      height: 20px;
    }
    &-peopleImage {
      width: 25px;
      height: 20px;
    }
    &-text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      text-align: left;
      color: #333333;
    }
    &-lightText {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      color: #999999;
    }
  }
  &-mainText {
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: #333333;
    margin: 2rem 0rem;
    @include bpm-xs {
      font-size: 22px;
      margin: 1rem 0rem;
    }
  }
  &-subText {
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    font-size: 23px;
    line-height: 34px;
    text-align: center;
    color: #666;
    margin: 0rem 0rem 2rem 0rem;
    @include bpm-xs {
      font-size: 22px;
      margin: 1rem 0rem;
    }
  }
}

.spellingBee_profile_qualify {
  &_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 130vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1100;
    display: none;
  }

  &-close {
    width: 42px;
    height: 42px;
    position: absolute;
    right: 10px;
    top: -15px;
    z-index: 1000;
    cursor: pointer;
  }
  background-color: #ffffff;
  border-radius: 16px;
  opacity: 1;
  max-width: 478px;
  width: max-content;
  position: relative;
  padding: 1rem 7rem 3rem 7rem;
  @include bpm-md {
    max-width: 390px;
  }
  @include bpm-xs {
    width: max-content;
    padding: 1rem 2rem 2rem 2rem;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  &-topImage {
    width: 398px;
    height: 316px;
    margin-top: 10px;
    @include bpm-xs {
      width: 202px;
      height: 207px;
    }
  }
  &-imageWrapper {
  }

  &-mainText {
    color: #333;
    text-align: center;
    font-family: Lato;
    font-size: 28px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%; /* 39.2px */
    margin: 1rem 0rem 0rem 0rem;
    @include bpm-xs {
      font-size: 22px;
    }
  }
  &-subText {
    color: #666;
    text-align: center;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28px */
    margin: 1.2rem 0rem;
    @include bpm-xs {
      font-size: 22px;
      margin: 1rem 0rem;
    }
  }
}
