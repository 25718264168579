.live_upcoming_class_alert_bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px 15px 15px;
  background: #333333;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  position: fixed;
  // width: 95%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 70%;
  @include bpm-sm {
    width: 95%;
  }
  &.dashboard {
    top: 100px;
    z-index: 1000;
  }
  &-left {
    display: flex;
    flex-direction: row;
    &--desktop {
      display: flex;
      flex-direction: row;
      align-items: center;
      &---image_wrapper {
        background-color: #ffffff;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        margin: 0px 22px;
        padding: 2px 2px;
      }
      &---image {
        width: 100%;
        height: 100%;
      }
      &---textWrapper {
        text-align: left;
        &----mainText {
          font-family: "Lato";
          font-style: normal;
          font-weight: 800;
          font-size: 18px;
          line-height: 20px;
          color: #ffffff;
          margin-bottom: 5px;
        }
        &----subTextWrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          &-----timing {
            font-family: "Lato";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #e9ba7d;
            margin-right: 1rem;
          }
          &-----countdown {
            font-family: "Lato";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #ffffff;
          }
        }
      }
      @include bpm-xs {
        display: none;
      }
    }
    &--mobile {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: rgba(255, 255, 255, 0.87);
      margin-left: 10px;
      display: none;
      @include bpm-xs {
        display: flex;
      }
    }
  }
  &-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    &--desktop {
      display: flex;
      flex-direction: row;
      align-items: center;
      @include bpm-xs {
        display: none;
      }
      &---copyText {
        font-family: "Lato";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
      }
    }
    &--mobile {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #f2b65a;
      cursor: pointer;
      display: none;
      @include bpm-xs {
        display: flex;
      }
    }
    &--mobile_invite_wrapper {
      display: none;
      @include bpm-xs {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}
