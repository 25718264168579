.favorite-category {
  &__wrapper {
    padding: 10rem 0 5rem 4rem;
    max-width: 1200px;
    margin: auto;
    @include bpm-md {
      // max-width: 400px;
      padding: 10rem 0 5rem 2rem;
    }
    @include bpm-custom(450px) {
      padding: 10rem 0.5rem 5rem 0.5rem;
    }
  }
  display: flex;
  width: 82%;

  // display: grid;
  // grid-column: 1;
  // -ms-grid-column: 395px 1fr;
  // grid-template-columns: 395px 1fr;

  @include bpm-md {
    display: block;
  }

  &__left {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    margin-top: 3rem;
    border: 4px black;
    flex-direction: column;
    align-items: center;
    font-family: Lato;
    color: #333;
    &_container {
      width: 85%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @include bpm-custom(490) {
      }
      // @include bpm-md {
      //   flex-direction: column;
      //   display: flex;
      //   width: 85%;
      // }
    }
    &--card {
      align-items: flex-start;
      padding: 2rem;
      width: 36rem;
      cursor: pointer;
      border-radius: 1rem;
      background-color: rgba(242, 182, 90, 0.9);
      .card-text {
        text-align: left;
        font-size: 2.5rem;
        color: #fff;
        font-weight: 700;
        font-family: Lato;
        @include bpm-custom(490) {
          font-size: 2rem;
        }
      }
      @include bpm-xs {
        width: 32rem;
      }
      .card-subtext {
        text-align: left;
        font-size: 1.61rem;
        color: #333;
        margin-top: 3px;
        font-weight: 600;
        font-family: Lato;
        @include bpm-custom(490) {
          font-size: 1.41rem;
        }
      }
      .card-bottom-row {
        margin-top: 5rem;
        display: flex;
        line-height: 1.5rem;
        justify-content: flex-start;
        &--text {
          font-size: 1.3rem;
          margin-left: 1.2rem;
          color: #fff;
          @include bpm-custom(490) {
            font-size: 1.4rem;
          }
        }
        &--sidetext {
          font-size: 1.7rem;
          margin-left: auto;
          z-index: 10;
          font-weight: 600;
          color: #333;
          @include bpm-custom(490) {
            font-size: 1.4rem;
            // margin-left: 3rem;
          }
        }
      }
      &.yellow-color {
        background: linear-gradient(180deg, #f2b65a -19.03%, #ffdaa0 150%);
      }
      &.blue-color {
        background: linear-gradient(180deg, #28b7ff -19.03%, #c7ecff 150%);
      }
      &.purple-color {
        background: linear-gradient(
          180deg,
          rgba(190, 5, 255, 0.8) -19.03%,
          rgba(124, 10, 255, 0.12) 150%
        );
      }
      &.green-color {
        background: linear-gradient(180deg, #65d78b -19.03%, #d3ffe2 150%);
      }
    }
    .yellow-color {
      background: linear-gradient(180deg, #f2b65a -19.03%, #ffdaa0 150%);
    }
    .blue-color {
      background: linear-gradient(180deg, #28b7ff -19.03%, #c7ecff 150%);
    }
    .purple-color {
      background: linear-gradient(
        180deg,
        rgba(190, 5, 255, 0.8) -19.03%,
        rgba(124, 10, 255, 0.12) 150%
      );
    }
    .green-color {
      background: linear-gradient(180deg, #65d78b -19.03%, #d3ffe2 150%);
    }
    &--text {
      font-size: 1.7rem;
      font-weight: 300;
      font-family: Lato;
      margin: 2rem auto 0.4rem;
      //   line-height: 2.8rem;
    }

    &-- &--paragraph {
      margin-top: 6rem;
      font-size: 2rem;
      line-height: 3rem;
      font-weight: 400;
    }
    &--heading {
      margin: 1rem 0rem 0.5rem;
      font-size: 3.5rem;
      position: relative;
      font-weight: 800;
    }
    &--heading-2 {
      font-size: 3.5rem;
      font-weight: 800;
    }

    &--subheading {
      margin-top: 1.3rem;
      font-size: 1.8rem;
      line-height: 2rem;
      font-weight: 300;
      opacity: 0.7;
    }
    .trail-button {
      @include bpm-custom(450px) {
        margin-left: auto;
        margin-right: auto;
      }
      margin-left: 11.5rem;
      margin-top: 5rem;
    }

    &--stage {
      display: flex;
      align-items: center;
      .single-stage {
        font-size: 1.5rem;
        font-weight: 700;
        margin-right: 1rem;
        cursor: pointer;
        color: #333;
        @include bpm-md {
          font-size: 1.4rem;
        }
        &:hover {
          color: #333;
        }
        span {
          display: inline-block;
          text-align: center;
          font-size: 1.6rem;
          font-weight: 700;
          background-color: #ddd;
          height: 2.6rem;
          width: 2.6rem;
          border-radius: 5rem;
          padding: 0.3rem 0 0 0;
          margin: 0 0.5rem;
          color: #666;
          @include bpm-md {
            height: 2.2rem;
            width: 2.2rem;
            font-size: 1.4rem;
            padding: 0.2rem 0 0 0;
          }
        }
        &.active {
          span {
            background-color: #f2b65a;
            color: #fff;
          }
        }
        &.done {
          span {
            background-color: #90d4a3;
            color: #fff;
          }
        }
      }
    }
    &--liner {
      font-size: 1.6rem;
      color: #666;
      font-weight: 500;
      margin: 1rem 0;
    }
    &--progress-bar-container {
      margin-top: 1rem;
      height: 3px;
      width: 100%;
      background-color: #ddd;
      span {
        height: 3px;
        background-color: #90d4a3;
        display: block;
      }
    }
  }
}
