.feedback-review-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 450px;
  min-width: 360px;
  @include bpm-custom(375) {
    min-width: 290px;
  }
  //   margin: 0 2rem;
  max-height: 550px;
  background-color: #fff;
  padding: 2.4rem;
  border-radius: 1rem;
  @include bpm-xxs {
    padding: 2rem;
  }
  &__peep {
    width: 155px;
    height: 249px;
    position: absolute;
    top: -17rem;
    z-index: -1;
    left: 14px;
  }

  &__container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    &.column {
      flex-direction: column;
      justify-content: center;
      align-items: left;
    }
    &--img {
      height: 6rem;
    }
    &--img-v1 {
      height: 5rem;
    }
    &__p-heading {
      font-family: Lato;
      font-size: 20px;
      font-weight: 800;
      line-height: 24px;
      letter-spacing: -0.14941634237766266px;
      text-align: left;
      color: #333333;
      margin: 0rem 0 1.4rem;
    }
    &__s-heading {
      font-family: Lato;
      font-size: 15px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.14941634237766266px;
      text-align: center;
      color: #666666;
      margin-top: 0.8rem;
    }
  }

  &__header {
    font-size: 2.4rem;
    font-weight: 800;
    align-self: flex-start;
    margin-bottom: 2rem;
    &.margin {
      margin-top: 2rem;
    }
  }
  &__checkout--wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    &--p-text {
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0px;
      text-align: left;
      color: rgba(102, 102, 102, 1);
    }
    &__container {
      display: flex;
      margin-top: 1rem;
      margin-bottom: 1.6rem;

      &__left {
        width: 10rem;
        height: 7rem;
      }
      &__right {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 0 0 2.8rem;
        &--p-heading {
          font-family: Lato;
          font-size: 18px;
          font-style: normal;
          font-weight: 800;
          line-height: 22px;
          letter-spacing: 0px;
          text-align: left;
          color: rgba(51, 51, 51, 1);
        }
        &--course-title {
          font-family: Lato;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          margin-top: 0.8rem;
          line-height: 17px;
          letter-spacing: 0px;
          text-align: left;
          color: rgba(102, 102, 102, 1);
        }
        &__secondaryContainer {
          display: flex;
          justify-content: space-between;
          margin-top: 0.8rem;
          width: 100%;
          &--course-details {
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 17px;
            letter-spacing: 0px;
            text-align: left;
            color: rgba(102, 102, 102, 1);
          }
        }
        &--line {
          width: 100%;
          height: 0.5px;
          background-color: #949494;
          &.bottom {
            margin-bottom: 1.6rem;
          }
        }
        &__amount-container {
          display: flex;
          justify-content: space-between;
          margin: 1.6rem 0;
          padding: 0 0.7rem;
          &--total {
            font-family: Lato;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0px;
            text-align: left;
            color: rgba(0, 0, 0, 1);
          }
          &--amount {
            font-family: Lato;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0px;
            text-align: left;
            color: rgba(0, 0, 0, 1);
          }
        }
      }
    }
  }
  &__input {
    height: 4.5rem;
    width: 100%;
    border: none;
    outline: none;
    padding: 0 0 0 1rem;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(75, 0, 188, 0.08);
    -webkit-box-shadow: 0px 0px 8px rgba(75, 0, 188, 0.2);
    -webkit-appearance: none;
    border-radius: 8px;
    margin: 1rem 0;
  }
  .contact {
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: column;
    width: 100%;
    input {
      &:first-child {
        width: 8rem;
        margin-right: 2rem;
      }
    }
    .phone-validation-tooltip {
      top: -2rem;
    }
    > div.phone-number {
      width: 100%;
      display: flex;
    }
  }
  &.auth-sign-up {
    .contact {
      flex-direction: row;
    }
  }
  &__logo {
    width: 8rem;
  }
  .primary-button {
    margin: 2rem 0 0 0;
  }
  &__separator {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 3rem;
    span {
      height: 1px;
      width: 4.5rem;
      background-color: #949494;
    }
    p {
      color: #949494;
      font-size: 1.1rem;
    }
  }
  &__alternate-button {
    border: none;
    outline: none;
    background-color: transparent;
    color: #ff8671;
    cursor: pointer;
    width: max-content;
    &.inactive {
      cursor: not-allowed;
      color: #888;
    }

    span {
      color: #fff;
      background-color: #ff8671;
      height: 2rem;
      width: 2rem;
      border-radius: 5rem;
      display: inline-block;
      text-align: center;
      padding-top: 3px;
      padding-right: 1px;
    }
  }
  position: relative;
  &__change-number {
    position: absolute;
    top: 2rem;
    left: 3rem;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    align-items: center;
    img {
      height: 1.2rem;
      margin-right: 1rem;
      //   transform: translateY(-0.1rem);
      //   width: 100%;
    }
    // margin: 0;
  }
  &__resend-otp {
    font-size: 1.2rem;
    margin-top: -1rem;
    margin-bottom: 3rem;
    &.margin {
      margin-top: 0;
      margin-bottom: 0;
    }
    button {
      margin-left: 0.5rem;
      span {
        font-size: 1.1rem;
        padding-top: 4px;
      }
    }
  }
  &__close {
    position: absolute;
    width: 3rem;
    top: -11px;
    right: -10px;
    cursor: pointer;
  }
}

.global-modal-wrapper {
  position: fixed;
  top: -135px;
  left: 0;
  width: 100%;
  height: 140vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  @include bpm-xs {
    top: -42px;
  }
}

.checkbox-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  &__inputfield {
    accent-color: #000000;
    height: 20px; /* not needed */
    width: 20px; /* not needed */
  }
  &__label {
    font-family: Lato;
    font-size: 12px;
    display: flex;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: -0.14941634237766266px;
    text-align: left;
    color: #666666;
    padding: 0.3rem 0;
  }
  &__p-text {
    padding: 5px;
    font-size: 14px;
  }

  &__inputfield {
    // width: 90%;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    font-size: 12px;
    font-family: Lato;
  }
}

.spark-showcase-page-video {
  height: 235px !important;
  width: 400px !important;
  @include bpm-custom(420px) {
    height: 225px !important;
    width: 300px !important;
  }
}

.submit-feedback-primary-button {
  margin: 2rem 0px ;
}
