.single-bundle {
  &__wrapper {
    background: #fff7e7;
    border-radius: 2rem;
    padding: 2rem;
    @include bpm-md {
      max-width: 450px;
    }
    @include bpm-custom(600px) {
      max-width: 350px;
    }
    @include bpm-custom(425px) {
      max-width: 320px;
    }
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @include bpm-md {
    align-items: flex-start;
  }
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    .separator-plus {
      display: block;
      position: relative;
      margin: 0 2rem;
      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        background-color: #aaa;
        transform: translate(-50%, -50%);
      }
      &::before {
        height: 4px;
        width: 25px;
      }
      &::after {
        height: 25px;
        width: 4px;
      }
    }
    @include bpm-md {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      .separator-plus {
        margin: 2rem 0 2rem 1rem;
      }
    }
    @include bpm-custom(600px) {
      .separator-plus {
        margin: 2.5rem 0 2.5rem 1rem;
        &::before {
          height: 3px;
          width: 20px;
        }
        &::after {
          height: 20px;
          width: 3px;
        }
      }
    }
  }
  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &--promo-text {
      border: 1px solid #f3705b;
      border-radius: 0.8rem;
      max-width: 35rem;
      p {
        padding: 1rem;
        font-size: 1.3rem;
        font-weight: 500;
        text-transform: uppercase;
        color: #333;
        word-wrap: break-word;
        text-align: left;
        line-height: 2rem;
      }
    }
    &--action {
      display: flex;
      align-items: center;
      .price {
        display: flex;
        align-items: center;
        margin-right: 1rem;
        .actual-price {
          font-size: 3.2rem;
          font-weight: 900;
          color: #333;
          display: inline-block;
          margin-right: 1rem;
        }
        .slashed-price {
          font-size: 1.6rem;
          font-weight: 500;
          color: #666;
          display: inline-block;
        }
      }
    }
    @include bpm-md {
      flex-direction: column;
      align-items: flex-start;
      &--promo-text {
        max-width: 100%;
        margin-bottom: 1.5rem;
      }
      &--action {
        justify-content: space-between;
        width: 100%;
      }
    }
    @include bpm-custom(600px) {
      &--promo-text {
        p {
          padding: 0.7rem;
          font-size: 1.2rem;
          line-height: 1.6rem;
        }
      }
      &--action {
        .price {
          flex-direction: column;
          align-items: flex-start;
          .actual-price {
            font-size: 2rem;
            margin-bottom: 5px;
          }
          .slashed-price {
            font-size: 1.4rem;
          }
        }
      }
    }
    @include bpm-custom(425px) {
      &--promo-text {
        p {
          font-size: 1rem;
        }
      }
    }
  }
  &__course {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    &--left {
      padding: 1rem 2rem 1rem 0;
      height: 112px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;
      @include bpm-custom(600px) {
        height: 75px;
        padding: 0.5rem 1rem 0.5rem 0;
      }
    }
    &--right {
      img {
        border-radius: 1.5rem;
        width: 15rem;
        object-fit: cover;
      }
      @include bpm-custom(600px) {
        img {
          width: 10rem;
        }
      }
    }
    &--name {
      font-size: 2.4rem;
      font-weight: 900;
      color: #333;
      margin-bottom: 1.5rem;
      max-width: 20rem;
      word-wrap: break-word;
      text-align: left;
      @include bpm-custom(600px) {
        font-size: 2rem;
      }
    }
    &--category {
      display: flex;
      align-items: center;
      .separator {
        display: inline-block;
        height: 0.6rem;
        width: 0.6rem;
        background-color: #aaa;
        border-radius: 5rem;
        margin: 0 0.5rem;
        @include bpm-custom(600px) {
          height: 0.4rem;
          width: 0.4rem;
        }
      }
      .category,
      .age-group {
        font-size: 1.4rem;
        color: #666;
        text-transform: uppercase;
        @include bpm-md {
          font-size: 1.2rem;
        }
        @include bpm-custom(600px) {
          font-size: 1.1rem;
        }
      }
    }
  }
}
