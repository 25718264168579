.courses-outcome-popup {
  &__wrapper {
    max-width: 28rem;
    margin-top: 1rem;
    border-top: 1px solid #aaa;
    padding-top: 1rem;
    &.shadow {
      border-top: none;
      margin-top: 0;
      padding: 1.5rem;
      border-radius: 1rem;
      background-color: #fff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    }
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &__header {
    font-size: 2rem;
    font-weight: 900;
    color: #333;
  }
  &__session-details {
    margin: 1.5rem 0;
    display: flex;
    align-items: center;
    .session,
    .duration {
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      color: #666;
      font-weight: 700;
      svg {
        width: 1.5rem;
        margin: 0 0.5rem;
      }
    }
  }
  &__section-description {
    color: #666;
    font-size: 1.6rem;
    margin-bottom: 1rem;
    font-weight: 500;
  }
  &__outlines {
    padding-inline-start: 15px;
    li {
      font-size: 1.4rem;
      color: #333;
      font-weight: 700;
      margin-bottom: 0.7rem;
    }
  }
}
