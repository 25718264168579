.profile-info {
  &__wrapper {
    width: 249px;
    height: 289px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.inactive {
      background-color: transparent;
      box-shadow: none;
    }
  }
  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70%;
    padding: 0rem 1.5rem;
    &-imageWrapper {
      background-color: #ffffff;
      border: 1px solid rgba(153, 153, 153, 0.48);
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      border-radius: 100%;
      width: 124px;
      height: 124px;
      padding: 5px 5px 0px 5px;
      margin-top: 1rem;
    }
    &-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &-name {
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 24px;
      color: #333333;
      margin-top: 1.5rem;
      &.inactive {
        color: #ffffff;
      }
    }
  }
  &__bottom {
    height: max-content;
    padding-bottom: 1rem;
    &-wrapper {
      display: flex;
      flex-direction: row;
      background: #f2b65a;
      border-radius: 8px;
      padding: 1rem 1.2rem;
      margin: 0rem 1.5rem;
    }
    &-icon {
      margin-right: 0.5rem;
      width: 14px;
      height: 18px;
      margin-top: 3px;
    }
    &-text {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #333333;
    }
    &-boldText {
      font-weight: 900;
    }
    &-noClass {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #ffffff;
    }
  }
}
