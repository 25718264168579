.certificates {
  &__wrapper {
    padding: 0 10rem;
    margin: 10rem 0rem;
    @include bpm-xs {
      padding: 0 3rem;
    }
    h2 {
      @include h2-styling;
      font-size: 40px;
    }
    h3 {
      @include h3-styling;
    }
    p {
      @include p-styling;
    }
  }
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  @include bpm-md {
    flex-direction: column;
  }
  &__left {
    img{
      width: 100%;
    }
  }
  &__right {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;
    max-width: 50rem;
    @include bpm-md {
      margin-bottom: 4rem;
    }
    @include bpm-sm {
      max-width: 80vw;
    }
    p {
      margin-bottom: 2rem;
      font-size: 1.8rem;
      font-weight: 500;
    }
  }
}
