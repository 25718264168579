.summer-camp-page-strip {
  &__wrapper {
    background: #0e1232;
    padding: 2rem 2rem;
    display: flex;
    flex-direction: row;

    position: relative;
    margin-bottom: 4rem;
    @include bpm-lg {
      padding: 1.5rem 1.5rem;
    }
    @include bpm-xs {
      flex-direction: column-reverse;
      padding: 2rem 2rem 0rem 2rem;
    }
  }
  &__text-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 255px;
    @include bpm-xs {
      margin-left: 0px;
    }
  }
  &-headText {
    font-family: "Lato";
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 38px;
    color: #ffc727;
    margin-bottom: 1rem;
    @include bpm-lg {
      font-size: 28px;
    }
  }
  &-subText {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-transform: capitalize;
    color: #ffffff;
    @include bpm-lg {
      font-size: 20px;
    }
  }
  &-image {
    position: absolute;
    width: 238px;
    height: 180px;
    bottom: 0px;
    left: 2rem;
    @include bpm-xs {
      position: relative;
      margin: 0 auto;
      left: 0rem;
    }
  }
}
