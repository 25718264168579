.course-outcomes {
  &__wrapper {
    padding: 4rem 7rem;
    @include bpm-sm {
      padding: 4rem;
    }
  }
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &__left {
    &--title {
      font-size: 3.2rem;
      font-weight: 900;
      color: #333;
      @include bp-xl {
        font-size: 3.8rem;
      }
    }
    &--subtitle {
      font-size: 1.6rem;
      font-weight: 700;
      color: #333;
      margin: 2rem 0;
      @include bp-xl {
        font-size: 2rem;
      }
    }
    &--outcomes {
      display: flex;
      flex-direction: column;
      &-single-outcome {
        padding-left: 3rem;
        font-size: 1.6rem;
        color: #666;
        font-weight: 500;
        margin: 1.2rem 0;
        position: relative;
        @include bp-xl {
          font-size: 1.8rem;
        }
        &::before {
          content: "";
          background: url("../../../public/assets/bulletArrow.svg") center
            center/contain no-repeat;
          height: 2rem;
          width: 2rem;
          position: absolute;
          top: 0.2rem;
          left: 0;
          border-radius: 5rem;
        }
      }
    }
  }
  &__right {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 1rem;
    background-color: #999;
    grid-gap: 1px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 10rem;
      background-color: #fff;
    }
    &--usp {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 11rem;
      width: 17rem;
      font-size: 2.5rem;
      font-weight: 700;
      @include bp-xl {
        width: 25rem;
        height: 15rem;
        font-size: 3rem;
      }
      span {
        margin-top: 0.5rem;
        font-size: 1.6rem;
        font-weight: 500;
        color: #999;
        text-transform: uppercase;
        @include bp-xl {
          font-size: 1.8rem;
        }
      }
    }
  }
  @include bpm-sm {
    flex-direction: column;
    &__right {
      align-self: center;
      margin-top: 3rem;
    }
  }
}
