.spark-workshop-page {
  &__wrapper {
    margin-bottom: 7rem;
    .homepage-experts-section {
      .swiper-container {
        padding-bottom: 3rem;
      }
    }
  }
  > .primary-button {
    margin: 2rem auto;
    text-align: center;
    width: 30rem;
  }
}
