.gameIndexGuide {
  //! the wrapper div encompasses the whole component
  &__wrapper {
    margin-top: 20px;
    padding: 0rem 7rem;
    padding-top: 4rem;
    padding-bottom: 2rem;
    background: #fffbf1;
    @include bpm-xs {
      padding: 4rem 2rem;
    }
  }
  //! this is the div that contains the header of the component
  &--header {
    h1 {
      font-family: Lato;
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      line-height: 38px;
      color: #333333;
      margin-left: 50px;
      margin-bottom: 40px;
      @include bpm-xs {
        margin-left: 0px;
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
  }
  //! Content div wraps around the left and right sub divs
  &--content {
    display: flex;
    justify-content: space-between;
    @include bpm-xs {
      flex-direction: column;
    }
  }
  //! Play div contains the button that only appears on mobiles
  &--play {
    display: none;
    .primary-button {
      width: 150px;
      margin: 0 auto;
      padding: 20px 10px;
    }
    @include bpm-xs {
      display: block;
    }
  }
  &--left {
    display: flex;
    flex-direction: column;
    &__wrapper {
      display: flex;
      margin-bottom: 25px;
      @include bpm-xs {
        margin-bottom: 12px;
      }
    }
    &-index {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: 45px;

      p {
        height: 30px;
        width: 30px;
        text-align: center;
        padding-top: 5px;
        background-color: #ffb541;
        border-radius: 100px;
        font-family: Lato;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 18px;
      }
    }
    &-details {
      margin-left: 10px;
      h2 {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        @include bpm-xs {
          font-size: 16px;
        }
        line-height: 18px;
        /* identical to box height, or 90% */

        color: #000000;
      }
      p {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        margin-top: 5px;
        @include bpm-xs {
          font-size: 14px;
        }
        line-height: 22px;
        /* identical to box height, or 137% */

        color: #666666;
      }
    }
  }
  &--right {
    @include bpm-sm {
      display: none;
    }
  }
}
