.homepage-showcase {
  &__wrapper {
    min-height: 100rem;
    margin-top: 7rem;
    @include bp-md {
      margin-bottom: -4rem;
    }
    position: relative;
    &::before {
      content: "";
      background: url("../../../public/assets/Confetti.svg");
      width: 100px;
      height: 100px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
    }
    .primary-button {
      position: absolute;
      bottom: 4rem;
      left: 50%;
      transform: translateX(-50%);
    }
    @include bpm-lg {
      min-height: 90rem;
    }
    @include bpm-md {
      min-height: 80rem;
    }
    @include bpm-sm {
      min-height: 75rem;
    }
    @include bpm-xs {
      min-height: 65rem;
    }
  }
  &__elements {
    max-width: 940px;
    margin: 0 auto;
    position: relative;
    margin-top: 4rem;

    transform: scale(0.85);
    @include bpm-lg {
      transform: scale(0.75);
    }
    @include bpm-md {
      max-width: 700px;
      transform: scale(0.75);
    }
    @include bpm-custom(700px) {
      margin-top: 0;
      max-width: 500px;
    }
    @include bpm-custom(500px) {
      transform: scale(0.6);
      max-width: 375px;
    }
    .showcase-single-element {
      border-radius: 6.6rem;
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.4);
      -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.4);
      -webkit-appearance: none;
      position: absolute;
      overflow: hidden;
      cursor: pointer;
      display: flex;
      @include bpm-md {
        transform: scale(0.8);
      }
      @include bpm-custom(700px) {
        transform: scale(0.65);
      }
      img {
        width: 130%;
        object-fit: cover;
        object-position: center center;
      }
      &--video {
        cursor: pointer;
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 13;
          height: 10rem;
          width: 10rem;
          background-color: red;
          background: url("../../../public/assets/playButton.svg") center center/cover;
        }
      }
    }
    &--one {
      height: 410px;
      width: 410px;

      left: 0;
      top: 0;
      z-index: 5;
    }
    &--two {
      height: 290px;
      width: 290px;

      right: 12%;
      top: 5rem;
      z-index: 3;
    }
    &--three {
      height: 240px;
      width: 240px;

      top: 35rem;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &--four {
      height: 290px;
      width: 290px;

      right: 0;
      top: 30rem;
      z-index: 4;
    }
    &--five {
      height: 290px;
      width: 290px;

      left: 20rem;
      top: 45rem;
      z-index: 5;
    }
    @include bpm-md {
      &--one {
        left: -6rem;
        top: 0;
      }
      &--two {
        right: 5rem;
        top: 10rem;
      }
      &--three {
        top: 28rem;
        left: 30%;
      }
      &--four {
        right: -5rem;
        top: 30rem;
      }
      &--five {
        left: 5rem;
        top: 40rem;
      }
    }
    @include bpm-custom(700) {
      &--one {
        left: -13rem;
        top: 0;
      }
      &--two {
        right: -2rem;
        top: 10rem;
      }
      &--three {
        top: 24rem;
        left: 25%;
      }
      &--four {
        right: -10rem;
        top: 28rem;
      }
      &--five {
        left: 2rem;
        top: 36rem;
      }
    }
    @include bpm-custom(500) {
      &--one {
        left: -17rem;
        top: 0;
      }
      &--two {
        right: -10rem;
        top: 10rem;
      }
      &--three {
        top: 25rem;
        left: 17%;
      }
      &--four {
        right: -15rem;
        top: 28rem;
      }
      &--five {
        left: -6rem;
        top: 36rem;
      }
    }
  }
}
