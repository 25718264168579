.callback-requested_modal {
  &_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 120vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
    display: none;
  }
  &-close {
    width: 42px;
    height: 42px;
    position: absolute;
    right: 10px;
    top: -15px;
    z-index: 1000;
    cursor: pointer;
  }
  padding: 8px 24px 24px 24px;
  background-color: #000632;
  border-radius: 16px;
  opacity: 1;
  width: 312px;
  display: flex;
  flex-direction: column;
  position: relative;

  &__hero-image {
    width: 266px;
    height: 190px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  &__primary-text {
    margin-top: 16px;
    font-size: 24px;
    font-weight: 900;
    text-align: left;
    color: #fff;
  }
  &__secondary-text {
    margin-top: 8px;
    font-size: 17px;
    font-style: italic;
    text-align: left;
    color: #fff;
  }
}
