.recommended_slots_bottom_sheet {
  &_wrapper {
    padding: 2rem 2rem 1rem 2rem;
  }
  &-header {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #333333;
    margin-bottom: 1rem;
  }
  &-subHeader {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #666666;
  }
  &-body {
    padding: 1rem 1rem;
    &-wrapper {
      margin-bottom: 2rem;
    }
    &--date {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: -0.149416px;
      color: #333333;
      margin-bottom: 1rem;
    }
    &--card {
      box-shadow: 0px 4px 4px 1px rgba(51, 51, 51, 0.25);
      border-radius: 8px;
      width: 100%;
      padding: 2rem 2rem;
      &---title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.149416px;
        color: #333333;
        margin-bottom: 0.5rem;
      }
      &---timing {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.149416px;
        color: #666666;
      }
    }
  }
}
