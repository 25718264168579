@import url("https://fonts.googleapis.com/css2?family=Wendy+One&display=swap");
.studioHeader {
  &--left {
    &__text {
      font-size: 74px;
      font-family: "Wendy One" !important;
      // font-style: normal;
      font-weight: 900;
      line-height: 76px;

      background: linear-gradient(180deg, #e01886 0%, #3f016a 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      @include bpm-xs {
        font-size: 66px;
        padding: 1.7rem;
      }
    }
    img {
      @include bpm-xs {
        width: 300px;
      }
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    //! Margin bottom and top added to the wrapper
    margin-bottom: 50px;
    margin-top: 60px;
    @include bpm-lg {
      justify-content: space-around;
    }

    @include bpm-xs {
      margin-top: 15px;
      flex-direction: column;
    }

    //! Justify content set to center to compensate for hidden profile on ipad
    @include bpm-md {
      // justify-content: center;
      flex-direction: column;
    }
  }
  &--image {
    position: absolute;
    top: 90px;
    left: 20px;
    z-index: 0;
    max-width: 90%;
    height: auto;
    @include bpm-xs {
      left: -3rem;
      top: 14rem;
      min-width: 360px;
      height: 150px;
    }
    @include bpm-md {
      left: 0;
      max-width: 100%;
    }
  }

  &--mobileimage {
    position: absolute;
    top: 1;
    left: 0;
    z-index: 0;
    max-width: 100%;
    height: auto;
    @include bpm-xs {
      left: -3rem;
      top: 18rem;
    }
  }
  //! The active class thats enabled when studio contents are
  //! empty. Sets flex direction to column
  // &.active {
  //   @include bpm-lg {
  //     flex-direction: column;
  //   }
  // }

  //! This div holds the right side img plus button active when
  //! studio content is nill
  // &--emptyContent {
  //   margin-right: 100px;
  //   @include bpm-lg {
  //     margin-right: 0px;
  //   }
  //   @include bpm-lg {
  //     width: 90%;
  //     margin: 0 auto;
  //     margin-top: 20px;
  //   }
  //   padding: 13px;
  //   background: #ffffff;
  //   border-radius: 24px;
  //   img {
  //     width: 455px;
  //     border-radius: 24px;
  //     @include bpm-xl {
  //       width: 100%;
  //     }
  //   }
  // }
  //! The left section of the header, i.e, the profile section is hidden on mobile + ipad
  &--left {
    z-index: 1;
    padding-left: 300px;
    @include bpm-sm {
      padding-left: 0px;
      // display: none;
    }
    @include bpm-md {
      padding-left: 0px;
    }
    @include bpm-lg {
      padding-left: 0px;
    }
    //! Addon css class to get left margin only on pages with content
    &.nonEmpty {
      @include bpm-xl {
        margin-left: 100px;
      }
    }
    //! css for the div that appears on empty contents page
    &__wrapper {
      @include bpm-xl {
        margin-left: 100px;
        margin-right: 30px;
      }
      @include bpm-lg {
        margin: 0 auto;
      }
      .studioHeader--right {
        margin-right: 0px;
      }
    }
  }
  &--right {
    //! margin right for the right half of the header
    margin-right: 250px;
    max-width: max-content;

    //! This is for the right header section
    //! margin right is set to 0 and a margin left is added to add spacing on the left for ipads\
    @include bpm-lg {
      margin-right: 30px;
      display: contents;
    }
    //! Margin right set to since profile is hidden on ipad
    @include bpm-md {
      margin-right: 0px;
      margin-top: 30px;
      margin-left: 260px;
      display: contents;
    }
    //! margin right is set to 0 and a margin left is added to add spacing on the left for mobiles
    @include bpm-xs {
      margin-top: 15px;
      // margin-right: 0px;
      // margin-left: 25px;
      display: contents;
    }
    &-top {
      display: flex;
      //! Studio Header center on big devices
      justify-content: center;
      //! justify content left for header on mobiles
      @include bpm-xs {
        justify-content: left;
      }
      //! margin bottom for the top half of the header
      margin-bottom: 80px;
      //! Margin bottom reduced to fit accordingly on mobiles
      @include bpm-xs {
        margin-bottom: 20px;
      }
    }
    &-bottom {
      display: flex;
      justify-content: space-around;

      //! flex direction changed to column ( one below another) on mobiles
      @include bpm-xs {
        flex-direction: column;
      }
      //! margin bottom for the bottom half of the header
      //   margin-bottom: 50px;
      p {
        font-family: Lato;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
        color: #999999;
        width: max-content;
        //! Margin right set to p tag on ipad alone
        //! Since both p tag and button squishes together

        @include bpm-sm {
          margin-right: 16px;
        }
        //! spacing added below the p tag to accomodate the share button on mobiles
        //! margin right set to p tag is removed on mobiles
        @include bpm-xs {
          margin-bottom: 10px;
          margin-right: 0px;
        }
        span {
          color: #e47762;
        }
      }
    }
  }
}
