.about-card-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 60px;
  z-index: 1;
  // border: 1px solid #fff;
  width: 100%;
  min-height: 400px;
  padding: 1rem 0.5rem;
  // border-radius: 25px;
  // box-shadow: 0 3px 7px rgb(0 0 0 / 0.1);
  @include bp-md {
    background-color: rgba(255, 251, 241, 1);
    border-radius: 0px;
    justify-content: space-between;
    flex-direction: row;
    padding: 2rem;
  }

  @include bp-sm {
    padding: 3.5rem 4rem 2rem;
    min-height: 35rem;
    background-color: rgba(255, 251, 241, 1);
    border-radius: 0px;
    flex-direction: row;
    justify-content: space-between;
  }

  &--heading {
    margin: 1rem auto 2rem;
    font-size: 2rem;
    font-weight: 800;
    color: #333;
    font-family: Lato;

    @include bp-md {
      margin: 3rem auto 4rem 3rem;
      font-size: 2.2rem;
    }
    @include bp-lg {
      margin: 3rem auto 4rem 3rem;
      font-size: 2.5rem;
    }
    @include bp-sm {
      font-size: 2rem;
      margin: 3rem auto 4rem 3rem;
    }
  }
  &--subheading {
    margin: 1.5rem auto 3rem;
    font-size: 1.6rem;
    font-weight: 400;
    color: #333;
    font-family: Lato;
    opacity: 0.7;
  }

  .about-card {
    height: 600px;
  }
  .circle-icon {
    font-size: 1.5rem;
    display: flex;
    width: 100%;
    font-weight: 700;
    margin: 0rem 8% 1rem;
    cursor: pointer;
    color: #333;
    @include bpm-md {
      font-size: 1.4rem;
    }
    @include bp-xl {
      margin: 0rem 2% 3rem;
    }

    @include bp-sm {
      margin: 0rem;
    }
    &:hover {
      color: #333;
    }
    span {
      display: inline-block;
      text-align: center;
      font-size: 1.6rem;
      font-weight: 700;
      background-color: #ddd;
      height: 2.6rem;
      width: 2.6rem;
      border-radius: 5rem;
      padding: 0.3rem 0 0 0;
      margin: 0 0.5rem;
      color: #666;

      @include bpm-md {
        height: 2.6rem;
        width: 2.6rem;
        font-size: 1.4rem;
        padding: 0.2rem 0 0 0;
      }
    }
    &.active {
      span {
        background-color: #f2b65a;
        color: #000;
      }
    }
    &.done {
      span {
        background-color: #90d4a3;
        color: #fff;
      }
    }
  }
  .info-text {
    display: flex;
    flex-direction: column;
    font-size: 1.8rem;
    font-weight: 800;
    margin: 0.25rem 0rem 0rem 2%;
    @include bp-sm {
      margin: 0.25rem 0rem 1rem 2%;
      font-size: 1.6rem;
    }
  }
  .transpert-info-text {
    margin-top: 1rem;
    font-size: 1.6rem;
    font-weight: 400;
    color: #333;
    font-family: Lato;
    opacity: 0.7;
    @include bp-sm {
      font-size: 1.4rem;
    }
    &--img {
      margin-top: auto;
      padding: 2rem 0rem;

      @include bpm-custom(1065) {
        width: 50%;
      }
      @include bp-sm {
        margin-top: 6rem;
        width: 40%;
      }
    }
  }
}
