.play-banner {
  &__wrapper {
    padding: 0 7rem;
    @include bpm-xs{
      padding: 0 2rem;
    }
  }
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @include bpm-xs {
    flex-direction: column;
  }
  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &--pitch {
      color: #666;
      font-size: 1.4rem;
      font-weight: 500;
    }
    &--header {
      color: #333;
      font-size: 4.8rem;
      font-weight: 900;
      margin: 1rem 0 2rem 0;
    }
    &--sub-header {
      color: #666;
      font-size: 1.6rem;
      font-weight: 500;
      margin-bottom: 2rem;
    }
    .primary-button {
      @include bpm-xs {
        margin: 0 auto;
      }
    }
  }
  &__right {
    img {
      width: 100%;
      @include bpm-xs {
        margin-top: 20px;
      }
    }
  }
}
