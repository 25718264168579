.spark-testimonial {
  &__wrapper {
  }
  &__single-slide {
    position: relative;
    padding: 10rem 7rem 10rem 10rem;
    border-radius: 3rem;
    width: 70vw;
    min-height: 35rem;
    @include bpm-custom(1300px) {
      width: 80vw;
    }
    @include bpm-md {
      padding: 7rem 5rem 7rem 7rem;
      width: 85vw;
    }
    @include bpm-sm {
      padding: 5rem 3rem 4rem 5rem;
      //   width: 90vw;
      min-height: 25rem;
    }
    &--quotes {
      position: absolute;
      top: 5rem;
      left: 7rem;
      @include bpm-md {
        top: 4rem;
        left: 5rem;
      }
      @include bpm-sm {
        // width: 70%;
        transform: scale(0.7);
        top: 1rem;
        left: 2rem;
      }
    }
    &--content {
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 2.4rem;
      max-width: 70%;
      text-align: left;
      margin-bottom: 3rem;
      @include bpm-custom(1300px) {
        max-width: 90%;
      }
      @include bpm-sm {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
    &--source {
      font-size: 2.2rem;
      font-weight: 900;
      text-align: left;
      @include bpm-sm {
        font-size: 1.8rem;
      }
    }
  }
  &__swiper {
    padding-bottom: 0rem !important;
    .swiper-slide {
      width: 75vw !important;
      @include bpm-custom(1300px) {
        width: 85vw !important;
      }
      @include bpm-md {
        width: 90vw !important;
      }
      @include bpm-sm {
        width: 90vw !important;
      }
    }
    .swiper-pagination {
      margin-bottom: 0 !important;
    }
  }
}
