.spelling-bee-ftue {
  &_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: max-content;
    cursor: pointer;
  }
  &-lottie {
    position: relative;
    width: 600px;
    height: 600px;
    @include bpm-xs {
      width: 100%;
      height: 500px;
    }
  }
  &-handtap-lottie {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 470px;
    left: 260px;
    @include bpm-xs {
      top: 290px;
      left: 150px;
    }
  }
  &-text {
    position: absolute;
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    color: #666666;
    white-space: nowrap;
    top: 600px;
    left: 100px;
    @include bpm-xs {
      white-space: normal;
      text-align: center;
      left: 10px;
      right: 10px;
      top: 500px;
      font-size: 24px;
      margin: 0 auto;
    }
  }
}
