.student-section {
  &__wrapper {
  }
  &__header {
    font-size: 4rem;
    font-weight: 900;
    color: #333;
    margin: 3rem 0;
    @include bpm-sm {
      font-size: 3rem;
      margin: 2rem 0;
    }
    @include bpm-xs {
      font-size: 2.5rem;
    }
  }
  &__swiper {
    width: 100%;
    padding-bottom: 6rem !important;
    .swiper-slide {
      width: 38rem;
    }
    .swiper-pagination {
      margin-bottom: 0;
    }
    @include bpm-sm {
      .swiper-slide {
        width: 28rem;
      }
    }
  }
}
