//! The blog wrapper class encompasses the whole index blog page

.blog__wrapper {
  padding-top: 140px;
  text-align: center;
  @include bpm-md {
    padding-top: 100px;
  }
  //! the head class defines the page title and subtitle
  &--head {
    margin: 0 2rem;

    h1 {
      font-family: Lato;
      font-size: 56px;
      font-style: normal;
      font-weight: 800;
      line-height: 67px;
      letter-spacing: 0px;
      margin-bottom: 8px;
      @include bpm-xs {
        font-size: 30px;
      }
    }
    h2 {
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      @include bpm-xs {
        font-size: 15px;
      }
    }
    //! The sub class holds the CSS for content in the filter section (flex for items, and styling for p tag)
    &--sub {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 60px 0px;
      p {
        height: 40px;
        width: 200px;
        margin: 10px 10px;
        padding-top: 5px;
        border: 2px solid white;
        border-radius: 10px;
        background: linear-gradient(
          111.29deg,
          rgba(245, 245, 245, 0.371) -1.83%,
          rgba(243, 243, 243, 0) 109.95%
        );
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        cursor: pointer;
      }
      p:active {
        border: 3px solid orange;
      }
    }
  }
}
//! This section (blog-page) is the wraps around the entirety of the multiple blog pages.
.blog-page {
  //! Size Fix for page image and section images when being shrinked to smaller devices - doesnt affect nav link

  #header-image {
    width: 75%;
    margin-top: 10px;
    @include bpm-lg {
      width: 95%;
    }
  }
  #header-description {
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    color: #000632;
    margin: 25px 0px;
    @include bpm-lg {
      margin: 0px;
    }
    @include bpm-md {
      font-size: 14px;
    }
  }
  //! CSS for the wrapper
  text-align: center;
  padding-top: 150px;
  @include bpm-lg {
    padding-top: 130px;
  }
  //! CSS for header section of the blog page ..................
  &__header {
    h1 {
      font-family: Lato;
      font-size: 50px;
      font-style: normal;
      font-weight: 800;
      line-height: 50px;
      letter-spacing: 0px;
      color: #000632;
      margin: 0px 25px;
      // margin: 15px 0;
      @include bpm-md {
        font-size: 24px;
        margin: 5px 15px;
        line-height: 30px;
      }
      @include bpm-lg {
        font-size: 26px;
      }
    }
    h2 {
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: center;
      color: #000632;
      margin: 25px 0px;
      @include bpm-lg {
        margin: 0px;
      }
      @include bpm-md {
        font-size: 14px;
      }
    }
  }
  //! The data class holds the CSS for the data section of the blog ( styling -h1,p,figure) . This section comprises of the textual data
  //! and the figure which holds the section image and caption
  &__data {
    margin: 0px 250px;
    @include bpm-lg {
      margin: 0px 20px;
    }
    h1 {
      font-family: Lato;
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      margin-top: 80px;
      text-align: left;
      color: #000632;
      //! Margin Fix for h1 tags at smaller screens
      @include bpm-md {
        margin-top: 40px;
        font-size: 24px;
        line-height: 29px;
      }
    }
    p {
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      margin-top: 60px;
      text-align: left;
      white-space: pre-line;
      line-height: 36px;
      color: #000632;
      //! Margin fix for smaller screens

      @include bpm-md {
        margin-top: 10px;
        font-size: 16px;
        line-height: 32px;
      }
    }
    figure {
      margin: 100px 0px;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      //! Margin fix for smaller screens
      @include bpm-lg {
        margin: 50px 0;
      }
      img {
        border-radius: 10px;
        border: 3px solid white;
        width: 50%;
        //! CSS Fix for text image filling out at lower resolutions
        @include bpm-md {
          width: 100%;
        }
      }
      figcaption {
        font-size: 20px;
        color: #000632;
      }
    }
  }
  //! This section (classname shareBlog) holds the styling for the share component of the page ( icons + buttons)
  &__shareBlog {
    margin: 100px 0px;
    @include bpm-lg {
      margin: 40px 0px;
    }
    h2 {
      color: #666666;
    }
    button {
      margin: 10px;
    }
  }

  //! This section ( nav) holds the styling for the navigation links at the bottom ( single button for left and right , based on transform rotate)
  //! css for this section - (nav container class is set to flex with space-around)
  //! the arrow link and prev/next text are again encompassed in two seperate divs(--left and --right) and that is set to flex for alignment
  //! Also holds css for the button at the end of this section
  &__nav {
    display: flex;
    justify-content: space-around;

    margin-top: 50px;
    // p {
    //   font-family: Lato;
    //   font-size: 25px;
    //   font-style: normal;
    //   font-weight: 700;
    //   // padding-top: 10px;
    //   padding: 0px 7px;
    //   height: 50px;
    // }
    img {
      height: 19px;
    }
    p {
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
    }
    &--link--left {
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      img {
       
        margin-top: 2px;
        margin-right: 8px;
      }
    }
    &--link--right {
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      img {
        transform: rotate(180deg);
        margin-top: 6px;
        margin-left: 8px;
      }
    }
  }

  &-button {
    .primary-button.version-1 {
      width: 200px;
      margin: 50px auto;
    }
  }
}

//! This section (class blog-card ) holds CSS for the blog cards displayed in the index page.

.blog-card {
  max-width: 1600px;
  margin: 0 auto;
  //! This class (container) is the container that wraps around each card.
  &__container {
    margin: 40px 150px;
    display: grid;
    background: linear-gradient(
      112.79deg,
      #ffd492 -10.31%,
      rgba(255, 255, 255, 0.5) 97.4%
    );
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.4);
    -webkit-appearance: none;
    border-radius: 20px;
    grid-template-columns: 1fr 2fr;
    // border: 5px solid white;
    @include bpm-lg {
      margin: 40px 10px;
    }
    @include bpm-md {
      grid-template-columns: 1fr;
      margin: 20px 20px;
      overflow: hidden;
    }
  }
  //! This is the left inner class that holds the image
  &--left {
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      border-radius: 20px 0px 0px 20px;
      border-bottom-left-radius: 20px;

      @include bpm-sm {
        border-radius: 20px 20px 0px 0px;
      }
    }
  }
  //! This is the right inner class that holds the textual data
  &--right {
    padding: 5% 5%;
    border: 5px solid white;

    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-left: 5px solid white;
    //! border top right changes at md size - curve removed
    //! border bottom left - curve added
    @include bpm-md {
      border-top-right-radius: 0px;
      border-bottom-left-radius: 20px;
    }
    h1 {
      text-align: left;
      margin: 10px 0px;
      font-family: Lato;
      font-size: 32px;
      font-style: normal;
      font-weight: 900;
      @include bpm-xs {
        font-size: 20px;
      }
    }
    h2 {
      margin: 25px 0px;
      text-align: left;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      color: #333333;

      @include bpm-xs {
        font-size: 16px;
      }
    }
    p {
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      color: rgba(137, 137, 137, 1);
      margin: 25px 0px;
      @include bpm-xs {
        font-size: 14px;
      }
    }
    &--subHead {
      display: flex;
      justify-content: space-between;
    }
    // &--tags {
    //   display: flex;
    //   flex-wrap: wrap;
    //   justify-content: space-around;
    //   p {
    //     font-size: 14px;
    //     border: 2px solid white;
    //     border-radius: 20px;
    //     box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.4);
    //     padding: 7px;
    //     padding-right: 10px;
    //     list-style-type: disc;
    //     display: list-item;
    //     list-style-position: inside;
    //   }
    // }
    &--tags {
      display: flex;
      align-items: flex-start;

      flex-wrap: wrap;
      width: 100%;
      .course-tag {
        font-size: 1rem;
        margin-right: 10px;
        margin-top: 5px;
        padding: 0.3rem 0.8rem 0.3rem 2rem;
        border: 2px solid rgba(255, 255, 255, 1);
        position: relative;
        border-radius: 3rem;
        background-color: rgba(255, 255, 255, 0.5);
        &::before {
          content: "";
          position: absolute;
          width: 0.5rem;
          height: 0.5rem;
          background-color: black;
          border-radius: 2rem;
          left: 0.7rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
