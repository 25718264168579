.course-category-card {
  border-radius: 3rem;
  height: 450px;
  width: 300px;
  &__wrapper {
    position: relative;
    .ted-branding {
      position: absolute;
      top: 2rem;
      right: 2rem;
      width: 10rem;
    }
  }
  display: grid;
  grid-template-rows: 6fr 4.5fr;
  justify-items: self-start;
  transition: all 0.2s linear;
  cursor: pointer;
  @include bpm-sm {
    width: 250px;
    height: 380px;
    grid-template-rows: 6fr 5fr;
    margin: 1rem 0;
  }
  @include bp-md {
    &:hover {
      transition: all 0.1s linear;
      transform: scale(1.03);
    }
  }
  &__top {
    img {
      height: 100%;
      width: 100%;
      border-radius: 3rem 3rem 0 0;
      object-fit: cover;
    }
  }
  &__bottom {
    border-radius: 0 0 3rem 3rem;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    padding-bottom: 1.5rem;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    border: 5px solid rgba(255, 255, 255, 0.8);
    border-top: none;
    * {
      text-align: left;
    }
    &--liner {
      font-size: 1rem;
      color: #333;
      background-color: rgba(255, 255, 255, 0.7);
      width: 100%;
      padding: 0.3rem 2rem;
    }
    &--name {
      font-size: 2rem;
      font-weight: 800;
      color: #333;
      padding: 0 1.5rem;

      @include bpm-sm {
        font-size: 1.8rem;
      }
    }
    &--category {
      font-size: 1.4rem;
      padding: 0 1.5rem;
      color: #666;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .sessions {
        font-size: 1.2rem;
        color: #666;
      }
      @include bpm-sm {
        font-size: 1.2rem;
      }
    }
    &--details {
      font-size: 1.2rem;
      padding: 0 1.5rem;
      color: #333;
      @include bpm-sm {
        font-size: 1rem;
      }
    }
    .secondary-button {
      margin-left: 1.5rem;
    }
  }
}
