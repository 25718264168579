.result-grammar {
  &__wrapper {
    background: #fffbfb;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 2rem;
    @include bpm-sm {
      width: 100%;
      margin-top: 2rem;
    }
  }
  &-image {
    text-align: center;
    img {
      margin-top: 90px;

      @include bpm-xs {
        width: 230px;
        margin-top: 30px;
      }
    }
  }
  &__title {
    text-transform: uppercase;
    color: #e57862;
    font-size: 3.6rem;
    font-weight: 900;
    margin-bottom: 1.5rem;
    text-align: left;
    @include bpm-md {
      font-size: 2.4rem;
      margin-bottom: 1rem;
    }
  }
  &__content {
    color: #666;
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 2rem;
    text-align: left;
    @include bpm-md {
      font-size: 1.6rem;
      margin-bottom: 1rem;
    }
  }
  .grammar-rules {
    display: flex;
    align-items: center;
    background: #55263d;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 1.5rem;
    margin: 2rem 1rem;
    @include bpm-md {
      margin: 1rem 0.5rem;
    }
    &__left {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 3rem 0 2rem;
      position: relative;
      svg {
        width: 5rem;
      }
      &--points {
        font-size: 2.2rem;
        color: #e57862;
        font-weight: 900;
      }
    }
    &__right {
      &--title {
        color: #fff;
        font-size: 2.8rem;
        font-weight: 700;
        @include bpm-md {
          font-size: 2rem;
        }
      }
      &--rules {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-inline-start: 20px;
        margin: 1.5rem 0;
        .single-rule {
          font-size: 1.6rem;
          color: #eee;
          font-weight: 700;
          margin: 0.5rem 0;
        }
      }
    }
  }
}
