.homeAssignmentCard {
  &__wrapper {
    margin: 0 auto;
    margin-top: 50px;
    padding: 20px;
    display: inline-grid;
    grid-template-columns: auto auto;
    align-items: center;

    width: 95%;

    border-radius: 8px;
    @include bpm-xs {
      grid-template-columns: auto;
    }
  }
  //! CSS for the left half of the card
  &--left {
    #hiddenDesc{
     height: 40px;
     overflow: hidden;
    }
    text-align: left;
    h1 {
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #666666;
      margin: 7px 0px;
    }
    h2 {
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #333333;
    }
    p {
      max-width: 350px;
      @include bpm-xs {
        max-width: 280px;
      }
      font-family: Lato;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left;
      color: #666666;
      margin-top: 15px;
    }
    span{
    font-size: 12px ;
    margin-left: 3px;
    }
    .expandText{
      cursor: pointer;
    }
    //! the homework div has the learning materials
    //! div and the due date div
    &-homework {
      display: flex;
      align-items: center;
      // justify-content: center;
     @include bpm-xs{
       flex-direction: column;
       align-items: flex-start;
     }
      &-upload {
        display: flex;
        align-items: center;
        margin: 10px 0px;
        background: rgba(255, 255, 255, 0.6);
        border: 1px solid rgba(153, 153, 153, 0.5);
        border-radius: 32px;
        width: max-content;
        padding: 5px 15px;
        img {
          width: 20px;
          height: auto;
        }
        p {
          margin: 0px;
          margin-left: 5px;
          font-family: Lato;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 19px;
          letter-spacing: -0.20000000298023224px;
          color: #000632;
        }
      }
      &-due {
        margin-left: 10px;
        @include bpm-xs{
          margin-left: 10px;
          display: flex;
        }
        p {
          font-family: Lato;
          font-size: 20px;
          font-style: normal;
          font-weight: 300;
          line-height: 24px;
          letter-spacing: -0.20000000298023224px;
          color:#666666;
          margin-top: 0px;
          span {
            font-family: Lato;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: -0.20000000298023224px;
            color:#333333;
          }
        }
      }
    }
  }
  //! CSS for the right half of the card
  &--right {
    display: flex;
    flex-direction: column;
    align-items: center;
    //! css for the trumpet icon
    img {
      width: 80px;
    }
    h2 {
      width: 70%;
      font-family: Lato;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 14px;
      letter-spacing: 0em;
      margin: 12px 0px;
    }
    //! css for the primary button - set to fit the width of inner text
    .primary-button {
      width: max-content;
    }
  }
}
