.service-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 60px;
  z-index: 1;
  // border: 1px solid #fff;
  width: 100%;
  min-height: 400px;
  padding: 1rem 0.5rem;
  // border-radius: 25px;
  // box-shadow: 0 3px 7px rgb(0 0 0 / 0.1);
  &--heading {
    margin: 1rem auto 0rem;
    font-size: 2rem;
    font-weight: 800;
    color: #333;
    font-family: Lato;
  }
  &--subheading {
    margin: 1.5rem auto 3rem;
    font-size: 1.6rem;
    font-weight: 400;
    color: #333;
    font-family: Lato;
    opacity: 0.7;
  }

  .about-card {
    height: 600px;
  }

  .grid-icon-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .flex-container {
    padding: 0;
    margin: 0;
    list-style: none;
    border: 1px solid silver;
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
  }
}
