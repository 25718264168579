.parent-dashboard-referral_modal {
  &_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 120vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
    display: none;
  }
  &-close {
    width: 42px;
    height: 42px;
    position: absolute;
    right: 10px;
    top: -15px;
    z-index: 1000;
    cursor: pointer;
  }
  background-color: #ffffff;
  border-radius: 16px;
  opacity: 1;
  width: 30%;
  position: relative;
  @include bpm-lg {
    width: 40%;
  }
  @include bpm-sm {
    width: 65%;
  }
  @include bpm-xs {
    width: 94%;
  }
  display: flex;
  flex-direction: column;
  &-head_wrapper {
    width: 100%;
    padding: 16px 17px 24px 24px;
    &--main-text {
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      letter-spacing: -0.2px;

      color: #333333;
    }
  }

  &_input-box {
    &_wrapper {
      width: 100%;
      //   margin-bottom: 1.5rem;
      padding: 0px 16px 16px 16px;
    }
    position: relative;
    &__title {
      position: absolute;
      font-size: 1.3rem;
      color: #333;
      left: 2rem;
      font-weight: 500;
      background-color: #fff;
      top: -0.6rem;
      padding: 0 0.5rem;
    }
    .input-box {
      height: 5.5rem;
      width: 100%;
      border: none;
      font-size: 1.6rem;
      outline: none;
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(75, 0, 188, 0.08);
      -webkit-box-shadow: 0px 0px 8px rgba(75, 0, 188, 0.08);
      border-radius: 8px;
      padding: 0 1rem;
      &.active {
        border: 2px solid #f2b65a;
        &::placeholder {
          display: none;
          color: transparent;
        }
      }
      &.error {
        border-bottom: 4px solid #c84343;
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 24px 16px;
    .primary-button {
      width: 100%;
    }
  }
}
