.otp-verification {
  &__wrapper {
    width: 100%;
    margin-top: 2rem;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &__otp-receiver {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    width: 100%;
    &--content {
      color: #999;
    }
    &--change {
      color: #333;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  &__header {
    font-size: 3rem;
    font-weight: 900;
    margin-top: 2rem;
    span {
      display: block;
      color: #ff8671;
    }
  }
  .otp-input-boxes {
    justify-content: space-evenly;
    width: 100%;
    input {
      height: 3.5rem !important;
      width: 3.5rem !important;
      border: none;
      border-radius: 0px;
      outline: none;
      border-bottom: 1px solid #999;
      font-size: 2rem;
      color: #666;
      font-weight: 700;
    }
  }
  &__resend-otp {
    text-align: center;
    width: 100%;
    font-size: 1.4rem;
    font-weight: 500;
    span {
      font-weight: 700;
      display: inline-block;
      margin-left: 0.5rem;
      color: #ff8671;
      cursor: pointer;
    }
  }
  .primary-button {
    margin: 4rem auto 0 auto;
    // @include bpm-sm {
    //   position: fixed;
    //   bottom: 2.5rem;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   z-index: 99;
    // }
  }
}
