.curriculum-section {
  &__wrapper {
    min-height: 60vh;
    padding: 0 7rem;
    margin: 5rem 0;
    @include bpm-sm {
      padding: 0;
    }
  }
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @include bpm-md {
    flex-direction: column;
    align-items: center;
  }
  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 2.5rem;
    @include bp-md {
      padding-top: 5rem;
    }
    &--header {
      font-size: 5rem;
      font-weight: 900;
    }
    &--liner {
      font-size: 1.8rem;
      font-weight: 700;
      margin: 3rem 0 2rem 0;
    }
    &--list {
      list-style: none;
      padding-inline-start: 0;
      margin-bottom: 4rem;
      padding-left: 2.2rem;
      &-item {
        font-size: 1.8rem;
        position: relative;
        margin: 1rem 0;
        &::before {
          content: "";
          background: url("../../../public/assets/bulletArrow.svg") center
            center/contain no-repeat;
          height: 2rem;
          width: 2rem;
          position: absolute;
          top: 0.2rem;
          left: -2.5rem;
          border-radius: 5rem;
        }
      }
    }
    @include bpm-md {
      &--list {
        padding-left: 2.5rem;
      }
    }
  }
  &__right {
  }
}
