.sticker-select-modal {
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  .primary-button {
    width: 120px;
    margin: 10px auto;
  }
  h1 {
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
    /* identical to box height */
    color: #000632;
    text-align: left;
    margin-left: 35px;
    margin-top: 20px;
    @include bpm-xs {
    }
  }
  &__wrapper {
  }
  &--content {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    flex-wrap: wrap;
    max-height: 500px;
    @include bpm-xs {
      max-height: 350px;
    }
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    & {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
    &__wrapper {
      width: 300px;
      height: 390px;
      margin: 10px;
      position: relative;
      &.active {
        outline: 2px solid #fcb444;
        border-radius: 16px;
      }
      .stickers {
        // width: 300px;
        // height: 220px;
        width: 90%;
        height: auto;
        padding: 30px;
        // &:focus {
        //   outline: 2px solid #fcb444;
        //   border-radius: 16px;
        // }
        cursor: pointer;
      }
      .sticker-remove {
        position: absolute;
        z-index: 10000;
        right: -18px;
        top: -18px;
        cursor: pointer;
        &.hidden {
          display: none;
        }
      }
    }
  }
}
