.small-icon-container {
  display: flex;
  align-items: center;
  height: 5rem;
  // margin: 0rem auto;
  justify-content: space-between;
  flex-direction: column;
  .p-text {
    display: flex;
    justify-content: center;
    font-family: Lato;
    font-size: 1.4rem;
    margin-top: 2rem;
  }
  .margin-text {
    margin-top: 0.4rem;
  }
}
