.new-course-card {
  &__wrapper {
    padding: 4rem 7rem;
    @include bpm-md {
      padding: 4rem;
    }
    @include bpm-custom(630) {
      padding: 2rem 0;
    }
  }
  background-color: #fffdf8;
  border-radius: 1rem;
  &__top {
    padding: 3rem;
    display: grid;
    grid-template-columns: 2fr 1.2fr;
    grid-gap: 3rem;
    @include bpm-lg {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    @include bpm-custom(630) {
      padding: 3rem 2rem;
    }
    &__left {
      @include bp-lg {
        border-right: 1px solid #ffa724;
      }
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-right: 3rem;
      @include bpm-lg {
        padding-right: 0;
        padding-bottom: 3rem;
        border-bottom: 1px solid #ffa724;
      }
      &--details {
        padding-right: 4rem;
        @include bpm-custom(630) {
          padding-right: 0.5rem;
        }
        &-title {
          font-size: 2.8rem;
          color: #333;
          //   line-height: 6rem;
          margin-bottom: 2rem;
          font-weight: 900;
          @include bpm-custom(630) {
            font-size: 2.2rem;
            display: flex;
            margin-bottom: -2rem;
            width: 100%;
            justify-content: space-between;
          }
        }
        &-category {
          display: flex;
          align-items: center;
          margin: 1rem 0 2rem 0;
          .category,
          .age-group {
            font-size: 1.6rem;
            color: #666;
            line-height: 2rem;
            font-weight: 300;
            text-transform: uppercase;
            @include bpm-custom(630) {
              font-size: 1.3rem;
            }
          }
          .circle {
            margin: 0 1rem;
            display: inline-block;
            height: 5px;
            width: 5px;
            background-color: #333;
            @include bpm-custom(630) {
              margin: 0 0.5rem;
            }
          }
          .age-group {
          }
        }
        &-description {
          font-size: 1.6rem;
          color: #666;
          line-height: 2.4rem;
          font-weight: 500;
          margin-bottom: 2rem;
          @include bpm-custom(630) {
            font-size: 1.4rem;
            line-height: 2.2rem;
          }
          .pitch-title {
            color: #333;
            font-size: 1.8rem;
          }
          ol {
            padding-inline-start: 15px;
          }
        }
        &-session {
          display: flex;
          align-items: center;
          .sessions,
          .duration {
            display: flex;
            margin-right: 1rem;
            font-size: 1.6rem;
            color: #333;
            font-weight: 700;
            svg {
              margin-right: 1rem;
            }
            @include bpm-custom(630) {
              font-size: 1.4rem;
            }
          }
          .sessions {
            svg {
              width: 1rem;
            }
            @include bpm-custom(630) {
              svg {
                width: 0.8rem;
              }
            }
          }
          .duration {
            svg {
              width: 1.8rem;
              transform: translateY(2px);
            }
            @include bpm-custom(630) {
              svg {
                width: 1.2rem;
                transform: translateY(0);
              }
              transform: translateY(-2px);
            }
          }
        }
        &-action {
          display: inline-block;
          margin-top: 2rem;
          color: #f47759;
          cursor: pointer;
          text-decoration: underline;
          font-size: 1.8rem;
          font-weight: 700;
          padding: 0 0 3rem 3rem;
          .hide {
            display: none;
          }
        }
      }
      &--img {
        img {
          width: 25rem;
          border-radius: 1rem;
          @include bpm-sm {
            width: 18rem;
          }
          @include bpm-custom(630) {
            width: 10rem;
          }
        }
      }
    }
    &__right {
      &--batch-selection {
        margin-bottom: 2rem;
        &-label {
          font-size: 2.2rem;
          font-weight: 700;
          margin-bottom: 1.5rem;
          @include bpm-custom(630) {
            font-size: 1.8rem;
          }
        }
        &-single-batch {
          .MuiRadio-colorSecondary.Mui-checked {
            color: #f3705b;
          }
          .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
            font-size: 1.6rem;
            font-weight: 500;
            font-family: $lato;
            @include bpm-custom(630) {
              font-size: 1.4rem;
            }
          }
        }
        .no-batch {
          color: #f3705b;
          font-size: 1.6rem;
          margin-bottom: 2rem;
        }
      }
      &--action {
        display: flex;
        align-items: flex-start;
        &-price {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          width: 100%;
          span {
            font-size: 1.5rem;
            color: #999;
            margin-bottom: 1rem;
          }
          .price {
            display: flex;
            align-items: center;
            font-size: 2.8rem;
            font-weight: 900;
            margin-bottom: 0.5rem;
            .slashed-price {
              font-size: 1.8rem;
              font-weight: 500;
              margin-left: 2rem;
            }
            @include bpm-custom(630) {
              font-size: 2.2rem;
            }
            @include bpm-custom(450) {
              flex-direction: column;
              align-items: flex-start;
              .slashed-price {
                margin: 0.5rem 0;
              }
            }
          }
          .urgency {
            display: flex;
            align-items: center;
            font-size: 1.4rem;
            color: #e47762;
            svg {
              margin-right: 1rem;
            }
          }
        }
        .primary-button {
          width: 24rem;
        }
      }
    }
  }
  &__bottom {
    padding: 3rem;

    .curriculum-path__wrapper {
      max-width: 530px;
    }
    @include bpm-custom(630) {
      padding: 0;
      .curriculum-path__wrapper {
        padding: 4rem 0 8rem 0;
        &--header {
          margin-bottom: 3rem;
        }
        .curriculum-path {
          img {
            margin: 0 calc((100vw - 400px) / 2);
          }
          label {
            transform: translateX(calc((100vw - 400px) / 2));
          }
        }
      }
    }
  }

  .swiper-slide {
    width: 36rem !important;
  }

  @include bpm-xs {
    .swiper-slide {
      width: 33rem !important;
    }
  }
  .swiper-wrapper {
    margin-bottom: 6rem;
  }
  .swiper-pagination {
    // bottom: -3rem !important;
    margin-bottom: 0;
  }
  .swiper-button-next {
    transform: translate(7rem, -5rem);
    outline: none;
    border: none;
    background-color: rgba(255, 255, 255, 0.9);
    height: 10rem;
    width: 10rem;
    border-radius: 10rem;
    position: absolute;
    &::after {
      content: "";
      background: url("../../../public/assets/nextArrow.svg") center
        center/contain no-repeat;
      background-size: 2rem;
      transform: translateX(-2.3rem);
    }
  }
  .swiper-button-prev {
    transform: translate(-7rem, -5rem);
    outline: none;
    border: none;
    background-color: rgba(255, 255, 255, 0.9);
    height: 10rem;
    width: 10rem;
    border-radius: 10rem;
    position: absolute;
    &::after {
      content: "";
      background: url("../../../public/assets/nextArrow.svg") center
        center/contain no-repeat;
      background-size: 2rem;
      transform: rotate(180deg) translateX(-2.3rem);
    }
  }
  .swiper-button-disabled {
    opacity: 0 !important;
  }
}
