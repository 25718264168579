.spark-game-save {
  &__wrapper {
  }
  &__header {
    font-size: 4.8rem;
    margin-bottom: 2rem;
    color: #333;
    text-align: center;
    font-weight: 900;
    @include bpm-sm {
      margin-bottom: 1rem;
      font-size: 3.6rem;
    }
  }
  &__sub-header {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: #666;
    text-align: center;
    @include bpm-sm {
      margin-bottom: 1rem;
      font-size: 1.8rem;
    }
    font-weight: 500;
    span {
      color: #e47762;
    }
  }
}
