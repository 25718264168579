.spark-checkout-page {
  width: 100vw;
  display: grid;
  grid-template-columns: 4.5fr 5.5fr;
  min-height: calc(100vh - 6.9rem);
  margin-top: 6.9rem;
  @include bpm-md {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &.cart-empty {
    grid-template-columns: 1fr;
  }
  &__empty-cart {
    width: 100%;
    height: 100%;
    @include bp-md {
      background-color: #fff;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25),
        4px 4px 4px rgba(231, 231, 231, 0.25);
      -webkit-box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25),
        4px 4px 4px rgba(231, 231, 231, 0.25);
      -webkit-appearance: none;
      padding-top: 5rem;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    h1 {
      font-size: 2.2rem;
      text-align: center;
      padding: 0 2rem;
      margin: 2rem 0;
    }
  }
}
