.featuredIn {
  background-image: url("../../../public/assets/Featured.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 200px;
  margin: 4.8rem 0 4rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  img {
    cursor: pointer;
    width: 30rem;
  }
  &--p-heading {
    font-family: Lato;
    font-size: 64px;
    font-weight: 800;
    line-height: 60px;
    letter-spacing: 0px;
    text-align: center;
    color: #333333;
    margin-top: 4rem;
    @include bpm-xs {
      font-size: 40px;
    }
  }
  @include bpm-md {
    img {
      width: 22rem;
    }
  }
  @include bpm-sm {
    height: 150px;
    margin: 5rem 0;
    img {
      width: 16rem;
    }
  }
  @include bpm-xs {
    flex-direction: column;
    height: auto;
    padding: 3rem 0;
    margin: 1.6rem 0 0;
    img {
      width: 22rem;
      margin: 2rem 0;
    }
  }
}
.featured-heading {
  @include h1-styling;
  font-size: 20px;
  font-weight: 800;
}
