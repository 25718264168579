.homepage-course-card {
  display: grid;
  grid-template-rows: 1fr 1fr;
  width: 325px;
  height: 394px;
  margin: 1rem 2rem;
  border-radius: 3rem;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.4);
  -webkit-appearance: none;
  transition: all 0.2s linear;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  .free-tag {
    position: absolute;
    top: 0;
    left: 0;
    img {
      width: 10rem;
    }
  }
  @include bp-md {
    &:hover {
      transition: all 0.1s linear;
      transform: scale(1.03);
    }
  }
  @include bpm-xs {
    margin: 1rem 0;
    width: 310px;
  }
  &__top {
    width: 100%;
    img {
      width: 100%;
      border-radius: 3rem 3rem 0 0;
    }
  }
  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    justify-self: center;
    border-radius: 0 0 3rem 3rem;
    padding: 0 0rem 1.5rem 0rem;
    border: 5px solid rgba(255, 255, 255, 0.8);
    border-top: none;
    &--liner {
      font-size: 1rem;
      color: #333;
      background-color: rgba(255, 255, 255, 0.7);
      width: 100%;
      padding: 0.3rem 2rem;
      text-align: left;
    }
    &--title {
      font-size: 2.2rem;
      font-weight: 900;
      padding: 0 1.5rem;
      color: #333;
    }
    &--content {
      font-size: 1.4rem;
      font-weight: 400;
      padding: 0 1.5rem;
      text-align: left;
      color: #666;
    }
    &--tags {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      .course-tag {
        font-size: 1rem;
        padding: 0.3rem 0.8rem 0.3rem 2rem;
        border: 2px solid rgba(255, 255, 255, 1);
        position: relative;
        border-radius: 3rem;
        background-color: rgba(255, 255, 255, 0.5);
        &::before {
          content: "";
          position: absolute;
          width: 0.5rem;
          height: 0.5rem;
          background-color: black;
          border-radius: 2rem;
          left: 0.7rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
