.refferal-tree-component {
  &__wrapper {
    background: #fff;
    min-height: 84rem;
    border-radius: 1.6rem;
  }
  &__img {
    width: 100%;
    // height: 26rem;
    object-fit: cover;
    align-items: center;
    border-radius: 1.6rem 1.6rem 0 0rem;
  }
  display: flex;
  flex-direction: column;
  &__description {
    background: #fff533;
    min-height: 4.8rem;
    padding: 0.6rem 1.6rem;

    &__p-text {
      font-family: Lato;
      font-size: 22px;
      font-weight: 900;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(51, 51, 51, 1);
      &.center {
        text-align: center;
      }
    }
    &__s-text {
      font-family: Lato;
      font-size: 18px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0px;
      text-align: left;
      color: rgba(51, 51, 51, 1);
      @include bpm-lg {
        line-height: 20px;
      }
    }
  }
  &__hint-tooltip-container {
    margin-left: auto;
    height: fit-content;
    position: relative;
    cursor: pointer;
  }
  &__details {
    display: flex;
    padding: 2.4rem;
    &--img {
      height: 1.5rem;
      width: 1.5rem;
      object-fit: fill;
      margin-top: 5px;
      margin-left: auto;
    }
    &--line {
      position: absolute;
      width: 2px;
      height: 3.2rem;
      background-color: #ffb541;
      right: 3.2rem;
    }
    &--p-text {
      font-family: Lato;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(51, 51, 51, 1);
    }
    &__container {
      display: flex;
      align-items: center;
      margin-top: 0.8rem;
      justify-content: space-between;
      &--s-text {
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(153, 153, 153, 1);
        &.claimed {
          color: #000;
        }
        &.claim {
          font-family: Lato;
          font-size: 16px;
          font-weight: 700;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: left;
          color: rgba(242, 182, 90, 1);
        }
      }
      &--icon {
        width: auto;
        margin-left: 1rem;
      }
      &--button {
        font-family: Lato;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 1rem;
        background: linear-gradient(90deg, #f3705b 0%, #fcb444 100%);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
        cursor: pointer;
        &.underline {
          text-decoration: underline;
        }
      }
    }
  }
  &__shareContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1.6rem 0;
    padding: 0 1.6rem;
  }
  .swiper-slide {
    height: auto;
  }
}
