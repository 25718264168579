.spark-payment-success {
  &__wrapper {
    padding: 0;
    background: linear-gradient(
      180deg,
      #fee1e8 -6.98%,
      rgba(255, 253, 201, 0.4) 1
    );
  }
  display: flex;
  height: 100%;
  padding: 8.6rem 1.6rem 3.2rem 1.6rem;
  position: relative;
  @include bpm-sm {
    flex-direction: column;
  }

  &__left {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 50%;
    @include bpm-sm {
      width: 100%;
    }
    &-logo {
      width: 128px;
      position: absolute;
      top: 20px;
      left: 40px;
      @include bpm-xs {
        top: 15px;
        left: 30px;
      }
      cursor: pointer;
    }
    &__container {
      background: #ffffff;
      box-shadow: 0px 10px 32px -4px rgba(24, 39, 75, 0.1);
      border-radius: 24px;
      width: 100%;
      min-height: 19.6rem;
      margin: 0 4rem;
      padding: 2.4rem 0 0;
      @include bpm-sm {
        margin: 2.6rem 0 1.6rem;
      }

      &__p-container {
        display: flex;
        justify-content: space-between;
        padding: 0 2.4rem;
        @include bpm-md {
          display: none;
        }
        &--img {
          width: 13rem;
        }
        &__text-container {
          display: flex;
          flex-direction: column;
          &--p-text {
            font-family: Lato;
            font-size: 24px;
            font-weight: 800;
            line-height: 29px;
            letter-spacing: 0em;
            text-align: left;
            color: #333333;
            padding: 0 0 0 2.4rem;
            margin-bottom: 10px;
          }
          &--s-text {
            font-family: Lato;
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left;
            color: #666666;
            padding: 0 0 0 2.4rem;
          }
        }
      }
      &__s-container {
        &.mobile {
          @include bp-md {
            display: none;
          }
        }
        &.desktop {
          @include bpm-md {
            display: none;
          }
        }
        display: flex;
        justify-content: space-between;
        padding: 0 2.4rem;
        margin: 2.4rem 0 1.6rem;
        @include bpm-md {
          margin: 0;
        }
        &--p-text {
          font-family: Lato;
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: center;
          color: #333333;
          margin-bottom: 10px;
        }
        &--s-text {
          font-family: Lato;
          font-size: 16px;
          font-weight: 700;
          text-decoration: underline;
          line-height: 19px;
          cursor: pointer;
          letter-spacing: 0.20000000298023224px;
          text-align: left;
          background: linear-gradient(90deg, #f3705b 0%, #fcb444 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      &__t-container {
        &.mobile {
          @include bp-md {
            display: none;
          }
        }
        &.desktop {
          @include bpm-md {
            display: none;
          }
        }
        display: flex;
        justify-content: space-between;
        padding: 0 2.4rem;
        margin: 2.4rem 0;
        @include bpm-md {
          margin: 1.6rem 0;
        }
        &--p-text {
          font-family: Lato;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: center;
          color: rgba(102, 102, 102, 1);
        }
        &--s-text {
          font-family: Lato;
          font-size: 16px;
          font-weight: 700;
          // text-decoration: underline;
          line-height: 19px;
          letter-spacing: 0.20000000298023224px;
          text-align: left;
          color: rgba(153, 153, 153, 1);
          // color: linear-gradient(90deg, #f3705b 0%, #fcb444 100%);
        }
      }
      &__userDetails {
        &__wrapper {
          background-color: rgba(204, 204, 204, 0.25);
          border-radius: 0 0 2.4rem 2.4rem;
          padding: 2.4rem 2.4rem 0.8rem;
          @include bpm-md {
            padding: 0.8rem 1.6rem 2px;
          }
        }
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        &__left {
          width: 50%;
          @include bpm-md {
            width: 100%;
          }
          &--p-text {
            font-family: Lato;
            font-size: 18px;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
            color: #333333;
            // padding: 0 0 0 2.4rem;
            margin-bottom: 2.4rem;
            @include bpm-md {
              font-size: 14px;
              margin-top: 0.8rem;
            }
          }
          &--s-text {
            display: flex;

            font-family: Lato;
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0.20000000298023224px;
            text-align: left;
            color: #666666;
            @include bpm-md {
              font-size: 14px;
            }
            // padding: 0 0 0 2.4rem;
          }
          &--t-text {
            font-family: Lato;
            font-size: 24px;
            font-weight: 900;
            line-height: 29px;
            letter-spacing: 0em;
            text-align: left;
            color: #333333;
            @include bpm-md {
              font-size: 14px;
            }
            // padding: 0 0 0 2.4rem;
          }
        }
        &__right {
          width: 50%;
          padding-bottom: 10px;
          &--p-text {
            font-family: Lato;
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: right;
            color: #666666;
          }
          &--s-text {
            font-family: Lato;
            font-size: 20px;
            font-weight: 900;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: right;
            color: #f47759;
            // margin-top: 0.8rem;
          }
          &--t-text {
            font-family: Lato;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.20000000298023224px;
            text-align: right;
            color: #333333;
            margin-top: 0.8rem;
          }
          &--remaining-amount-text {
            color: #666666;
            font-family: Lato;
            font-size: 20px;
            line-height: 29px;
            text-align: right;
            font-weight: 500;
            margin-top: 0.8rem;
            color: #505050;
          }
          @include bpm-sm {
            display: none;
          }
        }
      }
    }
  }
  &__right {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    padding: 0 2.4rem;
    @include bpm-sm {
      width: 100%;
      padding: 0;
    }
    &__purchasedCourses {
      padding: 2.4rem;
      min-height: 23rem;
      @include bpm-md {
        min-height: 0;
      }
    }
    &__priceDetails {
      display: flex;
      padding: 1.6rem 2.4rem;

      @include bp-md {
        display: none;
      }
      &__right {
        width: 70%;
        &--p-text {
          font-family: Lato;
          font-size: 18px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0px;
          text-align: left;

          color: rgba(51, 51, 51, 1);
        }
        &--s-text {
          font-family: Lato;
          font-size: 15px;
          font-style: italic;
          font-weight: 700;
          line-height: 18px;
          letter-spacing: 0px;
          text-align: left;
          margin-right: 1.6rem;
          color: #f47759;
        }
        &--t-text {
          font-family: Lato;
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0.20000000298023224px;
          text-align: right;
          color: #333333;
          margin-top: 0.8rem;
        }
        &--remaining-amount-text {
          color: #666666;
          font-family: Lato;
          font-size: 18px;
          line-height: 29px;
          text-align: right;
          font-weight: 500;
          margin-top: 0.8rem;
          color: #505050;
        }
      }
      &__left {
        width: 50%;
      }
    }
    &__container {
      background: #ffffff;
      box-shadow: 0px 10px 32px -4px rgba(24, 39, 75, 0.1);
      border-radius: 24px;
      width: 100%;
      // min-height: 19.6rem;
      padding: 2.4rem 0 0;
      &__p-container {
        display: flex;
        justify-content: space-between;
        padding: 0 2.4rem;
        margin-bottom: 2rem;
        &--p-text {
          font-family: Lato;
          font-size: 24px;
          font-weight: 800;
          line-height: 29px;
          letter-spacing: 0em;
          text-align: left;
          color: rgba(51, 51, 51, 1);
          @include bpm-md {
            font-size: 20px;
          }
        }
        &--s-text {
          font-family: Lato;
          font-size: 20px;
          cursor: pointer;
          font-weight: 800;
          text-decoration: underline;
          line-height: 24px;
          letter-spacing: 0.20000000298023224px;
          text-align: left;
          background: linear-gradient(90deg, #f3705b 0%, #fcb444 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          @include bpm-md {
            display: none;
          }
        }
        &__img {
          width: 100%;
          margin: 2.4rem 1.6rem;
        }
      }
      &__description {
        background-color: rgba(255, 245, 51, 1);
        text-align: center;
        &--p-text {
          font-size: 24px;
          font-weight: 900;
          line-height: 34px;
          letter-spacing: 0em;
          text-align: center;
          color: black;
          padding: 0.4rem;
        }
      }
      &__p--text {
        margin: 2.4rem 0 0;
        font-family: Lato;
        font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(51, 51, 51, 1);
      }
    }
  }
  &__copy_link {
    @include bpm-md {
      display: none;
    }
  }
  &_container{
      width: 100%;
      display: flex;
      justify-content: center;
  &__bottom {
    width: 92%;
    // height: 100px;
    margin-bottom: 25px;
   background: #ffffff;
    box-shadow: 0px 10px 32px -4px rgba(24, 39, 75, 0.1);
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3px;
    padding: 15px;
    @include bpm-custom(800){
      flex-direction: column;
      gap: 10px;
    }
    &--h1 {
      // width: 30%;
      // height: 58px;
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 22px;
      line-height: 29px;
      color: #333333;
      @include bpm-md {
        font-size: 19px;
        line-height: 18px;
      }
      @include bpm-sm{
        font-size: 17px;
        line-height: 15px;
      }
    }
    &--h2 {
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      @include bpm-md {
        font-size: 17px;
        line-height: 18px;
      }
      @include bpm-sm{
        font-size: 15px;
        line-height: 15px;
      }
    }
    &--button {
      width: 150px;
      height: 39px;
      color: #ffffff;
      border: none;
      font-family: "Lato";
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      background: linear-gradient(90deg, #f3705b 0%, #fcb444 100%);
      box-shadow: 0px 4px 14px #eeeeee;
      border-radius: 48px;
      
    }
  }
}
}
