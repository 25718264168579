.homework-upload-modal {
  &__wrapper {
  }
  width: 600px;
  min-height: 100px;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 0px 8px rgba(75, 0, 188, 0.08);
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 0px 8px rgba(75, 0, 188, 0.08);
  -webkit-appearance: none;
  border-radius: 16px;
  padding: 42px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  display: none;
  overflow: visible;
  &__content {
    font-size: 2.2rem;
    text-align: center;
    margin-bottom: 2rem;
    color: #333;
    @include bpm-xs {
      font-size: 2.2rem;
    }
  }
  &__details {
    font-size: 1.6rem;
    color: #666;
    margin: 0.5rem 0;
    span {
      color: #333;
      font-weight: 700;
    }
  }
  .file-upload-input {
    margin: 1rem 0;
  }
  .primary-button {
    margin: auto;
  }
  &__close {
    position: absolute;
    background-color: #fff;
    height: 6rem;
    width: 6rem;
    border-radius: 10rem;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
    -webkit-appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -2rem;
    right: -2rem;
    cursor: pointer;
    img {
      width: 4rem;
    }
  }
  .uploads {
    margin-top: 2rem;
    .single-upload {
      font-size: 1.4rem;
      padding: 0.5rem 1rem;
      background-color: #fcb444;
      border-radius: 2rem;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
      margin-right: 1rem;
    }
  }
}
