@font-face {
  font-family: myFirstFont;
  src: url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
}
$lato: "Lato", sans-serif;
*,
*::after,
*::before {
  box-sizing: border-box;
  font-family: $lato !important;
} // ! changing the text selection color
// ::-moz-selection {
//   background-color: #f2715a;
// }

// ::selection {
//   background-color: #f2715a;
// }
html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
button {
  margin: 0;
  padding: 0;
}
a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
hr {
  border: none;
}
.iticks-last-container {
  display: none !important;
}
// ! Removing the arrows on num input box
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.onboarding-secondary-link {
  color: #e47762;
  font-size: 1.6rem;
  text-align: center;
  width: 100%;
  display: inline-block;
  text-decoration: underline;
  &:hover,
  &:active,
  &:visited {
    color: #e47762;
    text-decoration: underline;
  }
}

.tnc {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &__label {
    font-size: 1.3rem;
    color: #666;
  }
  &__checkbox {
    -moz-appearance: initial;
    visibility: hidden;
    position: relative;
    margin-right: 2rem;
    margin-bottom: 1.25rem;
    &.checked,
    &.unchecked {
      &::after {
        visibility: visible;
        cursor: pointer;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    &.checked {
      &::after {
        background: url("../../public/assets/newChecked.svg") center
          center/contain no-repeat;
      }
    }
    &.unchecked {
      &::after {
        background: url("../../public/assets/newUnchecked.svg") center
          center/contain no-repeat;
      }
    }
  }
  &__link {
    text-decoration: underline;
    margin-left: 3px;
  }
}
