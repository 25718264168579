.spark-courses {
  &__wrapper {
    padding: 7rem 7rem 0 7rem;
    @include bpm-md {
      padding: 7rem 3rem 0 3rem;
    }
    @include bpm-sm {
      padding: 7rem 1rem 0 1rem;
    }
    .homepage-callback__wrapper {
      margin-top: -20rem;
      @include bpm-md {
        margin-top: -10rem;
      }
    }
  }
  &__accordion {
    border: none !important;
    border-bottom: 2px solid rgba(0, 0, 0, 0.125) !important;
    &.Mui-expanded {
      border: none !important;
    }
    &--question {
      background-color: #fff !important;
      &.bg-color {
        background-color: rgba(255, 248, 238, 1) !important;
      }
      padding: 0 !important;
      h3 {
        font-size: 2rem;
        color: #333;
        font-weight: 700;
      }
    }
    &--answer {
      padding: 1.6rem 0 !important;
      &.bg-color {
        background-color: rgba(255, 248, 238, 1) !important;
      }
    }
  }
  &__header {
    color: #e47762;
    font-weight: 900;
    font-size: 4rem;
    margin: 3rem 0;
    @include bpm-sm {
      font-size: 3rem;
      margin: 1.5rem 0 0 0;
      &.new-margin {
        margin: 4.6rem 0 0 0;
      }
    }
    @include bpm-xs {
      font-size: 2.4rem;
    }
    @include bpm-xxs {
      font-size: 2.1rem;
    }
  }
}
