@import url("https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap");
.uploadCreation {
  &__wrapper {
    display: flex;
    padding: 2.5rem;
    background: linear-gradient(180deg, #e01886 0%, #3f016a 100%);
    border-radius: 16px;
    width: 77%;
    margin: 2.4rem 0 2.4rem 16%;
    align-items: center;
    // border-radius: 2.4rem;
    @include bpm-xl {
      flex-direction: column;
    }
    @include bpm-md {
      margin-top: 2.4rem;
      margin-left: auto;
      margin-right: auto;
    }
    @include bpm-sm {
    }
  }
  &_text {
    flex: 2;
    padding-right: 3rem;
    padding-left: 2rem;
    font-family: "Luckiest Guy" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 35px;
    color: #ffffff;
    text-align: left;
    letter-spacing: 2px;
    @include bpm-sm {
      font-size: 30px;
      line-height: 25px;
      padding-right: 0.5rem;
    }
    @include bpm-xl {
      text-align: center;
      margin-bottom: 14px;
    }
  }
  &_button {
    flex: 1;
    padding-left: 1rem;

    .primary-button {
      width: 268px;
      text-align: center;
      font-size: 20px;
      padding-right: 22px;
      margin-left: 70px;
      @include bpm-lg {
        margin-left: 0px;
      }
      @include bpm-md {
        margin-left: 0px;
      }
    }
  }
}
