.recording-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 450px;
  min-width: 360px;
  //   margin: 0 2rem;
  max-height: 550px;
  background-color: #fff;
  padding: 2rem 2rem 1rem 2rem;
  border-radius: 1rem;
  &__header {
    font-size: 2.1rem;
    font-weight: 700;
    align-self: flex-start;
    margin-bottom: 1.5rem;
  }

  &__logo {
    width: 8rem;
  }
  .primary-button {
    width: 1rem;
    height: 1.5rem;
    margin: 2rem 0 1rem auto;
  }
  &__separator {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 3rem;
    span {
      height: 1px;
      width: 4.5rem;
      background-color: #949494;
    }
    p {
      color: #949494;
      font-size: 1.1rem;
    }
  }
  &__alternate-button {
    border: none;
    outline: none;
    background-color: transparent;
    color: #ff8671;
    cursor: pointer;
    width: max-content;
    &.inactive {
      cursor: not-allowed;
      color: #888;
    }
    span {
      color: #fff;
      background-color: #ff8671;
      height: 2rem;
      width: 2rem;
      border-radius: 5rem;
      display: inline-block;
      text-align: center;
      padding-top: 3px;
      padding-right: 1px;
    }
  }
  position: relative;

  &__close {
    position: absolute;
    width: 2rem;
    top: 3rem;
    right: 2rem;
    cursor: pointer;
  }
}
