.booked-page {
  &__wrapper {
    background-color: #fff;
    height: 100vh;
    padding-top: 7rem;
    width: 100vw;
    overflow-x: hidden;
    @include bpm-custom(875px) {
      height: auto;
      position: relative;
    }
  }
  display: grid;
  grid-template-columns: 2fr 3fr;
  -ms-grid-column: 2fr 3fr;
  grid-column: 2fr 3fr;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
  height: 100%;
  @include bpm-lg {
    max-width: 100vw;
    margin: 0 2rem 0 5rem;
  }
  @include bpm-custom(875px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1.5rem;
  }
  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include bpm-custom(875px) {
      z-index: 10;
      margin: 0rem 0 10rem 0;
    }
    &--title {
      font-size: 4.5rem;
      font-weight: 700;
      color: #e47762;
      @include bpm-custom(875px) {
        font-size: 3.5rem;
      }
      @include bpm-xs {
        font-size: 3rem;
      }
    }
    &--content {
      font-size: 1.6rem;
      font-weight: 500;
      margin: 2rem 0;
      @include bpm-lg {
        margin: 1.5rem 0;
      }
      .bold {
        font-weight: 700;
      }
      &-bold {
        font-size: 1.8rem;
        font-weight: 700;
        margin-bottom: 1.5rem;
        display: inline-block;
      }
      line-height: 2.25rem;

      .emoji {
        display: inline-flex;
        align-items: center;
        img {
          transform: translateY(0.5rem);
          width: 2.25rem;
        }
      }
    }
    &--timezone {
      font-size: 1.6rem;
      padding-bottom: 1rem;
      font-weight: 500;
      color: #333;
    }
    &--booking-details {
      * {
        font-size: 1.6rem;
      }
      .title {
        margin-bottom: 1.5rem;
        font-weight: 500;
      }
      .single-slot {
        border-bottom: 1px solid #ddd;
      }
      h3 {
        margin: 0.5rem 0;
        font-weight: 700;
        font-size: 1.5rem;
        span {
          font-weight: 500;
        }
      }
      // margin-bottom: 2.5rem;
    }
    .primary-button {
      margin-top: 2rem;
    }
    .visit-live-link {
      text-decoration: underline;
      font-weight: 700;
    }
  }
  &__right {
    img {
      width: 100%;
    }
    @include bpm-custom(875px) {
      position: absolute;
      bottom: 7.5rem;
      opacity: 0.15;
      left: 10vw;
      z-index: 5;
    }
    @include bpm-sm {
      transform: scale(1.5);
      left: 30vw;
      bottom: 10rem;
    }
  }
}
