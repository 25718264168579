.spark-dashboard {
  //! Ftue Wrapper - >
  &-layout {
    &__ftue {
      list-style-type: none;
      position: fixed;
      z-index: 110;
      cursor: pointer;
      opacity: 1;
      background-color: rgba(51, 51, 51, 0.88);
      width: 100%;
      height: 100%;
      overflow: hidden;

      //! Css for all-classes tab ->
      &_allClassWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      &_spellingBeeWrapper {
        a {
          background: #fffaee;
          border-radius: 15px;
        }
        display: flex;
        flex-direction: row;
        align-items: center;
        &_red-dot {
          position: relative;
          bottom: 10px;
          right: 14px;
        }
        &_trophy-image {
          height: 32px;
          width: 26px;
          margin-top: 14px;
          position: relative;
          right: 6px;
        }
      }
      & a {
        position: relative;
        display: block;
        width: 100%;
        display: flex;
        text-decoration: none;

        .icon-active {
          position: relative;
          display: block;
          min-width: 60px;
          height: 60px;
          line-height: 70px;
          text-align: center;
          background: #fffaee;
          border-top-left-radius: 15px;
          border-bottom-left-radius: 15px;
        }

        .title-active {
          position: relative;
          display: block;
          padding: 0 10px;
          height: 60px;
          line-height: 60px;
          text-align: start;
          white-space: nowrap;
          background: #fffaee;
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;
          color: orange;
          font-family: Lato;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          letter-spacing: -0.20000000298023224px;
        }
      }
      &-tabBorder {
        padding: 0.5rem 0.5rem;
        border: 3px solid #e47762;
        border-radius: 15px;
        display: flex;
        flex-direction: row;
      }
      &-tooltip {
        position: relative;
        background-color: #fffaee;
        margin-left: 2rem;
        border-radius: 8px;
        padding: 1rem 1rem;
        &-text {
          font-family: "Lato";
          font-style: normal;
          font-weight: 900;
          font-size: 20px;
          line-height: 120%;
          color: #e47762;
        }
        &:after {
          z-index: 10;
          position: absolute;
          left: -10px;
          top: 15px;
          border-radius: 2px;
          transform: translate(-50%);
          color: #ffffff;
          content: "";
          width: 0;
          height: 0;
          border-top: solid 12px;
          border-left: solid 8px transparent;
          border-right: solid 8px transparent;
          transform: rotate(90deg);
        }
      }
    }
  }
}
