.ted-ed-banner {
  &__wrapper {
    padding: 10rem 7rem 0 7rem;
    @include bpm-xs {
      padding: 10rem 3rem 0 3rem;
    }
  }
  display: grid;
  grid-template-columns: 1fr 1fr;
  @include bpm-sm {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 4vw;
    @include bp-xs {
      padding-right: 3rem;
    }
    &--header {
      font-weight: 900;
      color: #333;
      font-size: 4.8rem;
      @include bpm-xs {
        font-size: 4rem;
      }
    }
    &--content {
      font-weight: 500;
      color: #666;
      font-size: 2rem;
      margin: 2rem 0 5rem 0;
      max-width: 50rem;
      @include bpm-xs {
        font-size: 1.8rem;
      }
    }
  }
  &__right {
    img {
      width: 100%;
    }
  }
}
