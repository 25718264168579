.spellingBee_challengeStartDate {
  &-container {
    margin-top: 64px;
    @include bpm-sm {
      margin-top: 24px;
    }
  }
  &--heading {
    text-align: center;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
  }
  &_calenderIcon {
    position: relative;
    &--month {
      font-size: 17px;
      font-weight: 900;
      line-height: 20px;
      letter-spacing: 0;
      text-align: center;
      color: #fff;
      position: absolute;
      top: 14px;
      left: 25px;
    }
    &--date {
      font-size: 58px;
      font-weight: 900;
      line-height: 70px;
      letter-spacing: 0;
      text-align: left;
      color: "#333333";
      position: absolute;
      bottom: 0;
      left: 22px;
    }
  }
  &--remainingTime {
    &_wrapper {
      display: flex;
      justify-content: center;
      margin-top: 16px;
    }
    border-radius: 8px;

    padding: 8px;
    background-color: #fff;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
  }
}
