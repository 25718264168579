.game-controller {
  &__wrapper {
  }
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .primary-button {
    margin-left: 2rem;
  }
  &__controls {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &--pause {
      font-size: 1.6rem;
      color: rgb(255, 165, 0);
      font-weight: 700;
      margin-top: 1rem;
      cursor: pointer;
      @include bpm-sm {
        margin-top: -1rem;
        font-size: 1.4rem;
      }
    }
    &--press {
      font-size: 1.8rem;
      color: rgb(255, 165, 0);
      font-weight: 700;
      margin-top: 0rem;
      @include bpm-sm {
        margin-top: -1rem;
        font-size: 1.4rem;
      }
    }
    &--listening {
      font-size: 1.8rem;
      letter-spacing: 3px;
      color: rgb(255, 165, 0);
      font-weight: 700;
      margin-bottom: 0rem;
      @include bpm-sm {
        margin-top: -1rem;
        font-size: 1.4rem;
      }
    }
    &--mic {
      width: 9rem;
      cursor: pointer;
      position: relative;
      margin-top: 1rem;
      &.highlet {
        z-index: 1500;
      }
      @include bpm-sm {
        margin-top: -1rem;
        transform: scale(0.7);
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 2px;
        transform: scale(1);
        // border: 9px solid rgba(255, 165, 0, 0.5);
        height: 8.5rem;
        width: 8.5rem;
        border-radius: 20rem;
        z-index: 145;
        // animation: ripple 1s linear infinite;
        // @keyframes ripple {
        //   0% {
        //     transform: scale(1);
        //   }
        //   50% {
        //     transform: scale(1.1);
        //   }
        // }
      }
    }
    &__help {
      font-size: 14px;
      font-weight: 800;
      position: absolute;
      z-index: 1500;
      width: 155px;
      height: 3.5rem;
      background-color: white;
      right: 5px;
      left: -31px;
      border-radius: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &--p-text {
        font-family: Poppins;
        font-size: 17px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
      }
      :before {
        z-index: 10;
        position: absolute;
        bottom: 90%;
        rotate: 180deg;
        left: 50%;
        transform: translate(50%);
        content: "";
        width: 0;
        height: 0;
        border-top: solid white 12px;
        border-left: solid 8px transparent;
        border-right: solid 8px transparent;
      }
    }
    &--pause {
      width: 9rem;
      cursor: pointer;
      position: relative;
      &.highlet {
        z-index: 1500;
        cursor: not-allowed;
      }
      @include bpm-sm {
        transform: scale(0.7);
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 2px;
        transform: scale(1);
        border: 9px solid rgba(255, 165, 0, 0.5);
        height: 8.5rem;
        width: 8.5rem;
        border-radius: 20rem;
        z-index: 5;
        animation: ripple 1s linear infinite;
        @keyframes ripple {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.1);
          }
        }
      }
    }
    &--stop {
      width: 9rem;
      cursor: pointer;
      position: relative;
      display: flex;
      // z-index: 1500;
      align-items: center;
      justify-content: center;
      margin-top: -1rem;
      &.highlet {
        z-index: 1500;
      }
      // margin: 3rem 0rem 0rem 2rem;
      @include bpm-sm {
        transform: scale(0.7);
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 2px;
        transform: scale(1);
        // background-color: red;
        // background-color: rgba(0, 0, 0, 0.2);
        // border: 9px solid rgba(22, 187, 44, 0.534);
        height: 8.5rem;
        width: 8.5rem;
        border-radius: 20rem;
        z-index: 5;
        // animation: ripple 1s linear infinite;
        @keyframes ripple {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.1);
          }
        }
      }
      &__dotted-line {
        position: absolute;
        border-left: 1px dotted black;
        height: 50px;
      }
    }
  }
}
