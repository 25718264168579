.payment-details {
  &-container {
    margin-top: 20px;
    width: 100%;
    max-height: 450px;
    box-shadow: 65.3166px 78.38px 130.633px rgba(0, 0, 0, 0.05);
    border-radius: 16px 16px 0px 0px;
    background: #fff;
  }
  &-header {
    padding: 24px;
    border-radius: 16px 16px 0px 0px;
    height: 77px;
    background: #fb8d6b;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    color: #333333;
    @include bpm-xs {
      padding: 12px;
    }

    &_terms-text {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &_body {
    max-height: 375px;
    overflow-y: scroll;
    padding: 25px;
    @include bpm-md {
      padding: 14px;
    }

    &_column-1 {
      width: 25%;
      @include bpm-md {
        width: 28%;
      }
    }
    &_column-2 {
      width: 45%;
      @include bpm-md {
        width: 35%;
      }
    }
    &_column-3 {
      width: 20%;
      @include bpm-xs {
        width: 25%;
      }
    }
    table,
    th,
    td {
      border-bottom: 1px solid #ccc6c6;
      border-collapse: collapse;
    }
    tr {
      display: table-row;
    }
    th {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #999999;
      padding: 10px 0;
      border: none;
      text-align: left;
    }
    td {
      padding: 24px 0;
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #333333;
      text-align: left;
    }
    &_send-text {
      span {
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        border-bottom: 2px solid #f3705b;
        background: linear-gradient(90deg, #f3705b 0%, #fcb444 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        cursor: pointer;
      }
    }
  }
}
