.spellingBee_sparkEdapp-banner-container,
.spellingBee_complete-faqWrapper {
  padding-left: 14%;
  padding-right: 14%;
  margin-top: 40px;
  @include bpm-sm {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 24px;
  }
  img {
    width: 100%;
  }
}
.spellingBee_sparkEdapp-banner-container {
  cursor: pointer;
}
