.profileEdit {
  &__wrapper {
    background: #ffffff;
    border-radius: 20px;
    @include bpm-xs {
      border-radius: 10px;
    }
    padding: 30px 60px;
    @include bpm-xs {
      padding: 30px 20px;
    }
  }

  &--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      font-family: Lato;
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height */

      color: #333333;
    }
    .primary-button {
      width: 102px;
      height: 40px;
    }
  }
  &--input {
    &__wrapper {
      margin-top: 20px;
      img {
        width: 25px;
      }
    }
    &-field {
      position: relative;
      display: flex;
      align-items: center;
      padding: 18px 0px;
      width: 540px;
      @include bpm-xs {
        max-width: 304px;
      }
      p {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 17px;

        color: #333333;
        margin-left: 22px;
      }
      &:nth-last-child(1) {
        border-bottom: none;
        cursor: pointer;
      }

      &-inputBox {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 17px;
        width: 400px;
        /* or 94% */
        color: #333333;
        border: none;
        margin-left: 20px;
        &:focus {
          outline: none;
        }
        &.disabled {
          color: #999999;
          cursor: not-allowed;
        }
        &.locked {
          &::placeholder {
            color: #999999;
          }
        }
      }

      border-bottom: 1px solid #ebebeb;

      .myProfile-lockedIcon {
        position: absolute;
        right: 0px;
      }
    }
  }
}
