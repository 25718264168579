.cart-preview {
  &__wrapper {
    background-color: #fff;
    @include bpm-md {
      background-color: transparent;
    }
  }
  max-width: 45rem;
  margin: 0 auto;
  padding: 5rem 0 10rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  @include bpm-md {
    padding: 5rem 0;
  }
  &__customer-name,
  &__liner,
  &__liner-2 {
    font-size: 18px;
    padding: 0 1rem;
    font-weight: 700;
    color: #333333;
  }
  &__cart-cards {
    margin: 2rem 0;
    padding: 0 1.5rem;
    max-height: 35rem;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__liner-2 {
    justify-self: flex-end;
    margin: 2rem 0 3rem 0;
  }

  &__promo-code {
    &--image{
      margin-left: 1rem;
    }
    &--container {
      h3 {
        font-size: 1.4rem;
        color: #333;
        margin-bottom: 1.5rem;
        margin-left: 1rem;
      }
      > p {
        color: #999;
        font-size: 1.2rem;
        margin-bottom: 1.5rem;
        margin-left: 1rem;
      }
      position: relative;
      .confirmation-lottie {
        position: absolute;
        right: -180px;
        top: 2rem;
      }
      @include bpm-md {
        width: 100%;
      }
    }
    display: flex;
    align-items: center;
    position: relative;
    &--input {
      height: 4rem;
      width: 25rem;
      margin-left: 1rem;
      background-color: #eee;
      border: none;
      outline: none;
      border-radius: 8px;
      padding: 0 2rem;
      @include bpm-md {
        width: 100%;
        margin-right: 1rem;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
        -webkit-appearance: none;
      }
      &.valid {
        border: 2px solid limegreen;
      }
      &.invalid {
        border: 2px solid #ed465a;
      }
    }
    .cross-icon {
      transform: translateX(-3rem);
      cursor: pointer;
      @include bpm-md {
        transform: translateX(-4rem);
      }
    }
    button {
      position: absolute;
      top: 50%;
      right: 2rem;
      transform: translateY(-50%);
      border: none;
      outline: none;
      background-color: transparent;
      color: #f3705b;
      font-size: 1.4rem;
      font-weight: 700;
      cursor: pointer;
    }
    &--validation-tooltip {
      font-size: 1.2rem;
      position: absolute;
      top: 5rem;
      left: 1rem;
      font-weight: 700;
      // @include bpm-md {
      //   bottom: -2.5rem;
      // }
      &.valid {
        color: limegreen;
      }
      &.invalid {
        color: #f3705b;
      }
    }
  }
  &__cart-cards--total {
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 2px solid #eee;
    span {
      font-size: 2rem;
      font-weight: 700;
      color: #333333;
    }
    &.discount {
      border-bottom: none;
      margin-bottom: 0rem;
      padding-bottom: 1rem;
      span {
        font-size: 1.8rem;
        font-weight: 500;
        color: #333333;
      }
    }
  }
}
