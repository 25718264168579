.parent-corner-page-container {
  &__wrapper {
    @include bpm-md {
      padding: 1.6rem;
    }
  }
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @include bpm-md {
    flex-direction: column;
    row-gap: 20px;
  }
  ::-webkit-scrollbar {
    width: 4px;
  }
  &__left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 25%;
    margin-left: 10%;
    @include bpm-md {
      width: 100%;
      margin-left: 0;
    }
  }
  &__right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60%;
    margin-bottom: auto;
    @include bpm-md {
      width: 100%;
    }
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: rgba(242, 182, 90, 0.4);
  }
}
