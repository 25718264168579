.schools-form {
  &__wrapper {
    padding: 0 4rem;
    margin-top: 6.4rem;
    @include bpm-xs {
      margin-top: 0;
      padding: 0;
    }
  }
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20%;
  position: relative;
  @include bpm-custom(1024) {
    justify-content: center;
    margin-right: 0px;
  }
  &--img {
    @include bpm-custom(1024) {
      display: none;
    }
  }
  &--p-heading {
    font-family: Lato;
    font-size: 64px;
    font-weight: 800;
    line-height: 85px;
    letter-spacing: 0px;
    text-align: center;
    color: #333333;
    margin: 6.2rem 0;
    @include bpm-xs {
      font-family: Lato;
      font-weight: 800;
      font-size: 40px;
      margin: 0 0 4rem;
    }
  }
}
