.spellingBee_rules {
  &_wrapper {
    margin-top: 24px;
    padding-left: 15%;
    padding-right: 15%;
    @include bpm-custom(1100) {
      padding: 0 10%;
    }
    @include bpm-sm {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  &-container {
    text-align: left;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    display: flex;
    margin: 0 auto;
    max-width: 992px;
    // height: 500px;

    li {
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      @include bpm-sm {
        font-size: 20px;
        line-height: 32px;
        display: flex;
        flex-direction: column-reverse;
      }
    }
    &--left {
      // flex: 0.333;
      // height: 100%;
      // max-width: 243px;
      width: 302px;
      height: 453px;
      flex-grow: 0;
      margin: 0 40px 36px 0;
      padding: 177.8px 71.8px 176.2px 106.2px;
      border-radius: 24px;
    }
    &--img {
      width: 100%;
      height: 100%;
      border-radius: 24px;
      @include bpm-sm {
        display: none;
      }
    }

    &--right {
      // text-align: left;
      // flex: 0.64;
      height: 100%;
      width: 100%;
      gap: 40px;
      padding: 0 0 1.6rem 0;

      @include bpm-sm {
        // gap: 16px;
        // background: rgba(255, 255, 255, 0.72);
        // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        // border-radius: 24px;
        // padding: 16px;
      }
      &-text {
        // width: 100%;
        height: 41px;
        margin: 40px 65px 40px 40px;
        font-family: Lato;
        font-size: 34px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #333;
        @include bpm-sm {
          font-size: 17px;
          margin: 40px 16px 20px 16px;
          // line-height: 6px;
        }
      }

      &-maintext {
        display: flex;
        flex-direction: column;
        gap: 35px;
        margin: 0px 65px 40px 40px;
        @include bpm-sm {
          font-size: 13px;
          gap: 15px;
          margin: 16px;
          // line-height: 9px;
        }
        label {
          font-size: 20px;
          font-weight: 600;
          color: #333;
          cursor: pointer;
          max-width: max-content;
          @include bpm-sm {
            font-size: 13px;
            display: flex;
            align-items: center;
            // line-height: 9px;
          }
        }
        input[type="radio"] {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          accent-color: black;
          @include bpm-sm {
            font-size: 13px;
            // line-height: 9px;
          }
        }
      }
    }
    &--rules {
      // padding: 24px 32px;
      padding: 24px 132px 0px 80px;
      @include bpm-sm {
        // padding: 16px;
        padding: 24px 24px 0 16px;
      }
    }

    &-primary-text {
      width: 498px;
      height: 41px;
      margin: 40px 65px 40px 40px;
      font-family: Lato;
      font-size: 34px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #333;
      @include bpm-sm {
        font-size: 24px;
        line-height: 29px;
      }

      &_challenge-live-text {
        display: flex;
        align-items: center;
        position: absolute;
        top: 25%;
        right: 0;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        @include bpm-sm {
          // left: 0;
          // top: -10px;
          display: none;
        }
        div {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #00a912;
          margin-right: 8px;
        }
      }
    }
    &-secondary-text {
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      color: #666666;
      @include bpm-sm {
        font-size: 22px;
        line-height: 26px;
      }
    }
    &--checkbox-input {
      height: 16px;
      width: 16px;
      margin-right: 8px;
    }

    &-agree-container {
      // margin-top: 16px;
      margin-top: 48px;
      padding-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      // gap: 9px;
      gap: 28px;
      color: #999999;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      flex-direction: column;

      img {
        height: 14px;
        width: 14px;
      }
      @include bpm-sm {
        font-size: 18px;
        gap: 16px;
        margin-top: 24px;
      }
    }
  }
}
