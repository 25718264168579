.nav-footer-layout {
  background: linear-gradient(
    180deg,
    rgba(255, 130, 205, 0.2) -59.43%,
    rgba(255, 253, 201, 0.4) 100%
  ) !important;
  &.no-bg {
    background: #fff !important;
  }
  &.yellow-bg {
    background: rgba(255, 248, 238, 1) !important;
  }
  &.is-app-booking {
    background: linear-gradient(180deg, #bffcea 0%, #64cfff 310%) !important;
    height: 100vh;
  }
  .spark-layout {
    &-navbar {
      display: flex;
      padding: 0 7rem;
      align-items: center;
      justify-content: space-between;
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 100;
      height: 7rem;
      &.bring-above {
        // ! To hide the chatbot when cart is open
        z-index: 2147483648;
      }

      background-color: rgba(255, 255, 255, 0.9);
      &.remove-bg {
        background-color: transparent;
      }
      // background-color: rgba(255, 255, 255, 0.4);
      // backdrop-filter: saturate(180%) blur(5px);
      &__left {
        img {
          width: 13rem;
        }
        &.app {
          pointer-events: none;
        }
      }
      @include bpm-sm {
        padding: 0 3rem;
        &__left {
          img {
            width: 10rem;
          }
        }
      }
      // padding-top: 4rem;
      &__alert-bar {
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        background-color: #ff8671;
        width: 100%;
        height: 4rem;
        text-align: center;
        font-size: 1.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          margin: 0 0.4rem;
          text-decoration: underline;
        }
        font-weight: 700;
        color: #fff;
        // position: relative;
        @include bp-sm {
          padding: 0 7rem;
          justify-content: space-between;
        }
        // .close {
        //   position: absolute;
        //   top: 50%;
        //   transform: translateY(-50%);
        //   right: 1rem;
        //   height: 100%;
        //   display: flex;
        //   align-items: center;
        //   img {
        //     width: 2.5rem;
        //     cursor: pointer;
        //   }
        // }
      }
      &__right {
        &--list {
          display: flex;
          list-style: none;
          align-items: center;
          padding-inline-start: 0;
          &-item {
            font-size: 1.6rem;
            color: #333;
            margin-left: 2.5rem;
            &:first-child {
              margin-left: 0;
            }
            cursor: pointer;
            &.cart-icon {
              position: relative;
              img {
                width: 2.5rem;
              }
              .cart-bubble {
                position: absolute;
                height: 1.7rem;
                width: 1.7rem;
                background-color: #fcb444;
                top: -0.3rem;
                right: -0.6rem;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1rem;
                font-weight: 800;
                border-radius: 5rem;
              }
              .cart-tooltip {
                position: absolute;
                right: 0;
                top: 5.5rem;
                height: 8rem;
                min-width: 20rem;
                background: #f3f3f3;
                border-radius: 1.2rem;
                transform: translateX(8%);
                @include bpm-sm {
                  transform: translateX(-1rem);
                }
                clip-path: polygon(
                  0% 0%,
                  0% 1rem,
                  81% 1rem,
                  88% 0%,
                  95% 1rem,
                  100% 1rem,
                  100% 100%,
                  0% 100%,
                  0% 0%
                );
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 1rem 2rem 0 2rem;
                img {
                  width: 3rem;
                }
                p {
                  line-height: 2.5rem;
                  font-weight: 600;
                  text-align: center;
                  padding-left: 2rem;
                  width: max-content;
                  @include bpm-xs {
                    font-size: 1.8rem;
                  }
                }
                opacity: 0;
                &.visible {
                  opacity: 1;
                  transition: all 0.2s linear;
                }
                &.hidden {
                  opacity: 0;
                  transition: all 0.2s linear;
                }
              }
            }
            &.roundedLogout,
            &.join-class {
              position: relative;
              padding: 15px 27px;
              display: flex;
              &::before {
                content: "";
                position: absolute;
                inset: 0;
                border-radius: 48px;
                padding: 2px; /* control the border thickness */
                background: linear-gradient(45deg, #f4735a, #fcaf46);
                -webkit-mask: linear-gradient(#fff 0 0) content-box,
                  linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
              }
            }
            &.join-class-text {
              background: linear-gradient(90deg, #f3705b 0%, #fcb444 100%);
              -webkit-text-fill-color: transparent;
              -webkit-background-clip: text;
            }
            &.roundedLogout_responsive {
              position: relative;
              padding: 4px 35px;
              display: flex;
              &::before {
                content: "";
                position: absolute;
                inset: 0;
                border-radius: 48px;
                padding: 2px; /* control the border thickness */
                background: linear-gradient(45deg, #f4735a, #fcaf46);
                -webkit-mask: linear-gradient(#fff 0 0) content-box,
                  linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
              }
              @include bpm-xs {
                padding: 0px 27px;
              }
            }
          }
        }
        @include bpm-sm {
          &--list {
            &-item {
              margin-left: 3rem;
            }
          }
        }
      }
      @include bpm-custom(860px) {
        padding: 0 3rem;
        height: 7rem;
        .menu-toggle {
          width: 4rem;
        }
        &__right {
          position: absolute;
          // height: calc(100vh - 7rem);
          padding: 2rem 3rem;
          width: 25rem;
          background-color: #fff;
          box-shadow: 0px 0px 11.8125px rgba(75, 0, 188, 0.08);
          -webkit-box-shadow: 0px 0px 11.8125px rgba(75, 0, 188, 0.08);
          -webkit-appearance: none;
          border-radius: 11.8125px;
          top: 7rem;
          left: 100%;
          &--list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            height: 100%;
            &-item {
              margin: 1rem 0;
              font-size: 1.4rem;
              font-weight: 700;
              color: #666;
            }
            > div {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            }
          }
        }
        .trial-hamburger {
          display: flex;
          align-items: center;
          .primary-button {
            margin-right: 2rem;
            @include bpm-custom(460px) {
              margin-right: 1rem;
              font-size: 1.4rem;
              padding: 1rem 2.2rem;
              font-weight: 400;
            }
          }
          .cart-icon {
            margin-left: 2rem;
            img {
              width: 3.2rem;
            }
            @include bpm-xs {
              margin-left: 1rem;
              img {
                width: 2.5rem;
              }
            }
          }
          @include bpm-xs {
            > img {
              width: 3rem;
            }
          }
        }
        &.visible {
          .spark-layout-navbar__right {
            transition: all 0.3s linear;
            transform: translateX(-25rem);
            opacity: 1;
          }
        }
        &.hidden {
          .spark-layout-navbar__right {
            transition: all 0.3s linear;
            transform: translateX(0);
            opacity: 0.3;
          }
        }
        .contact {
          &__header {
            font-size: 2.2rem;
            color: #fff;
            margin-bottom: 2rem;
            font-weight: 700;
          }
          &__content {
            font-size: 1.8rem;
            color: #333;
            opacity: 0.7;
            margin-bottom: 2rem;
            font-weight: 600;
          }
          &__call {
            display: flex;
            align-items: center;
            p {
              margin-left: 1rem;
              margin-bottom: 0.5rem;
              font-size: 2rem;
              color: #f3705b;
            }
            .call-icon {
              width: 2.2rem;
              fill: #f3705b !important;
            }
          }
          &__whatsapp {
            display: flex;
            align-items: center;
            p {
              margin-left: 1rem;
              margin-bottom: 0.5rem;
              font-size: 2rem;
              color: #f3705b;
            }
            .whatsapp-icon {
              width: 2.2rem;
              fill: #f3705b;
            }
          }
        }
      }
      @include bpm-xs {
        padding: 0 1.5rem;
      }
      &--cart-drawer {
        // height: calc(100vh - 7rem);
        width: 31rem;
        background-color: #f6f5f5;
        position: absolute;
        right: 0;
        top: 6.9rem;
        // overflow-y: scroll;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
          4px 4px 4px rgba(231, 231, 231, 0.25);
        -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
          4px 4px 4px rgba(231, 231, 231, 0.25);
        -webkit-appearance: none;
        &.visible {
          transition: all 0.3s linear;
          transform: translateX(0);
          opacity: 1;
        }
        &.hidden {
          transition: all 0.3s linear;
          transform: translateX(31rem);
          opacity: 0.3;
        }
      }
    }
  }
  &.show-alert {
    .spark-layout-navbar {
      padding: 7rem 7rem 3rem 7rem;
      @include bpm-sm {
        padding: 7rem 3rem 3rem 3rem;
      }
      @include bpm-custom(690px) {
        &__right {
          top: 10rem;
        }
      }
      @include bpm-xs {
        padding: 7rem 1.5rem 3rem 1.5rem;
      }

      &--cart-drawer {
        top: 9.9rem;
      }
      .cart-drawer__wrapper {
        height: calc(100vh - 9.9rem) !important;
      }
    }
  }
}
