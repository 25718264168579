
@mixin h1-styling{
    font-family: Lato;
  font-size: 50px;
  font-style: normal;
  font-weight: 900;
  line-height: 60px;
  letter-spacing: 0px;
  text-align: center;
  }
  @mixin h2-styling{
    font-family: Lato;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 60px;
  letter-spacing: 0px;
  text-align: center;
  
  }
  @mixin h3-styling{
    font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  margin: 4rem 0;
  
  }
  @mixin p-styling{
    font-family: Lato;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
  margin: 5rem 0;
  }