.spark-game-rules {
  &__wrapper {
    // padding-top: 5rem;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
  &__header {
    font-size: 4.8rem;
    margin-bottom: 2rem;
    color: #333;
    font-weight: 900;
    text-align: center;
    @include bpm-sm {
      margin-bottom: 1rem;
      font-size: 3.6rem;
    }
  }
  &__sub-header {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
    color: #666;
    font-weight: 500;
    @include bpm-sm {
      margin-bottom: 1rem;
      font-size: 1.8rem;
    }
    span {
      color: #e47762;
    }
  }
  &__rules {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 450px;
    margin: 3rem 0 4rem 0;
    padding-left: 4rem;
    @include bpm-xs {
      max-width: 360px;
      padding: 0 1.5rem 0 3.5rem;
    }
    &--single-rule {
      position: relative;
      &-title {
        text-align: left;
        font-size: 2.2rem;
        color: #333;
        font-weight: 700;
      }
      &-content {
        text-align: left;
        font-size: 1.8rem;
        color: #666;
        font-weight: 500;
        margin: 1rem 0 2rem 0;
      }
      .rule-count {
        position: absolute;
        height: 3rem;
        width: 3rem;
        border-radius: 5rem;
        font-size: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: -4rem;
        background: #a7e0eb;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
      @include bpm-xs {
        &-title {
          font-size: 2rem;
        }
        &-content {
          font-size: 1.6rem;
          margin: 1rem 0 1.5rem 0;
        }
        .rule-count {
          height: 2rem;
          width: 2rem;
          border-radius: 5rem;
          font-size: 1.6rem;
          left: -3rem;
          top: 0.3rem;
        }
      }
    }
  }
}
