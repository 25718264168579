.howItWorks {
  &_wrapper {
    width: 100%;
    height: 245px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 14px 24px;
    margin-bottom: 16px;
    @include bpm-md {
      width: 100%;
      height: 100%;
      // height: 474px;
    }
  }
  &_top-section {
    text-align: left;
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
    margin-bottom: 24px;
  }
  &_bottom-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include bpm-md {
      flex-direction: column;
      row-gap: 8px;
    }
    &_child {
      padding: 8px;
      width: 30%;
      height: 167px;
      background: rgba(253, 239, 216, 0.72);
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      @include bpm-md {
        padding: 8px 8px 8px 16px;
        width: 100%;
        height: 128px;
        border-radius: 0px;
      }

      &_top-section {
        height: 104px;
        display: flex;
        align-items: center;
        position: relative;
        &_number-container {
          position: absolute;
          bottom: 0;
          width: 28px;
          height: 58px;
          font-weight: 900;
          font-size: 48px;
          line-height: 58px;
          color: rgba(228, 119, 98, 0.48);
          @include bpm-md {
            margin-left: 8px;
          }
        }
        &_image-container {
          height: 104px;
          width: 104px;
          position: absolute;
          right: 0;
          @include bpm-md {
            bottom: -44px;
          }
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
      &_bottom-section {
        padding: 8px;
        font-family: "Lato";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        text-align: left;
        color: #666666;
      }
    }
  }
}
