.referPage-table {
  &_wrapper {
    margin-top: 20px;
    width: 100%;
    max-height: 500px;
    overflow-y: scroll;
    background: #ffffff;
    border-radius: 16px;
    padding: 24px;
    @include bpm-md {
      width: 550px;
    }
    @include bpm-sm {
      width: 468px;
    }
    @include bpm-xs {
      width: 360px;
    }
  }
  &_header {
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
    @include bpm-md {
      font-size: 16px;
    }
    @include bpm-xs {
      font-size: 14px;
    }
  }
  &_row {
    text-align: left;
    padding: 24px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(153, 153, 153, 0.4);
    &-data {
      &_column-1,
      &_column-2,
      &_column-3,
      &_column-4 {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }
      &_column-1 {
        width: 210px;

        &_primary-text {
          color: #333333;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          @include bpm-md {
            font-size: 14px;
          }
        }
        &_secondary-text {
          color: #666666;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          @include bpm-md {
            font-size: 14px;
          }
        }
      }
      &_column-2 {
        width: 200px;
        color: #333333;

        &_course-name-container {
          padding: 8px 24px;
          background: #fff9f1;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          border-radius: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          @include bpm-md {
            padding: 8px 12px;
          }
        }
      }
      &_column-3 {
        width: 88px;
        &_date-text {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #666666;
          @include bpm-md {
            font-size: 14px;
          }
        }
      }
      &_column-4 {
        width: 140px;

        &_status-container {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 16px;
          @include bpm-md {
            padding: 6.5px;
            font-size: 14px;
          }
          @include bpm-xs {
            text-align: center;
          }
          &_bought-course {
            color: #2b9664;
            background: #e7fbef;
            border: 0.5px solid #5aaf87;

            @include bpm-xs {
              border-radius: 10px;
            }
          }
          &_trial-booked,
          &_trial-attended {
            color: #09acff;
            background: #c5ebff;
            border: 0.5px solid #28b7ff;
            border-radius: 30px;
            @include bpm-xs {
              border-radius: 10px;
            }
          }
        }
      }
    }
  }
}
