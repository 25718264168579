@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Display:wght@600&display=swap");
.studio-interested-modal {
  &_wrapper {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 120vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
  }
  padding: 2rem;
  border-radius: 16px;
  background: #cff4ff;
  opacity: 1;
  width: 436px;
  min-height: 340px;
  position: relative;
  @include bpm-lg {
    width: 40%;
  }
  @include bpm-sm {
    width: 65%;
  }
  @include bpm-xs {
    width: 94%;
  }
  display: flex;
  flex-direction: column;
  &_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 10px;
    &__heading {
      display: flex;
      gap: 8px;
      align-items: center;
      span {
        color: #333;
        font-size: 56px;
        font-family: "Noto Serif Display" !important;
        font-weight: 700;
      }
    }
    &-profileImage {
      width: 52px;
      height: 52px;
      background-color: #fff;
      border-radius: 100%;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
    &--img {
      width: 95%;
      height: 100%;
      border-radius: 100%;
      object-fit: contain;
    }
  }

  &_container {
    border-radius: 16px;
    border: 4px solid #fff;
    max-width: 404px;
    min-height: 156px;
    // padding: 20px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    button {
      max-width: 154px;
      height: 45px;
      border-radius: 88px;
      border: 1px solid #fff;
      background: rgba(255, 255, 255, 0.48);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      color: #666;
      font-size: 18px;
      font-family: Lato;
      font-weight: 500;
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
      padding-left: 10px;
      padding-right: 20px;
      margin: 10px;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.studio-aboutme-modal {
  &_wrapper {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 120vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
  }
  padding: 2rem;
  border-radius: 16px;
  background: #d8edc8;
  opacity: 1;
  width: 382px;
  height: 356px;
  position: relative;
  @include bpm-lg {
    width: 40%;
  }
  @include bpm-sm {
    width: 65%;
  }
  @include bpm-xs {
    width: 94%;
  }
  display: flex;
  flex-direction: column;
  &_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 20px;
    margin-top: 20px;
    &__heading {
      color: #333;
      font-size: 28px;
      font-family: "Lato";
      font-weight: 900;
      text-align: left;
    }
  }
  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 1.6rem;
    width: 334px;
    height: 154px;
    &--input {
      width: 100%;
      min-height: 100%;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
      background: #ffffff;
      border-radius: 16px;
      border: none;
      outline: none;
      @include bpm-md {
        width: 100%;
        margin-left: 0;
        width: 100%;
      }
    }
    @include bpm-md {
      width: 95%;
    }
  }
}

.studio-bestfriend-modal {
  &_wrapper {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 120vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
  }
  padding: 2rem;
  border-radius: 16px;
  background: #cff4ff;
  opacity: 1;
  width: 358px;
  min-height: 310px;
  position: relative;
  @include bpm-lg {
    width: 40%;
  }
  @include bpm-sm {
    width: 65%;
  }
  @include bpm-xs {
    width: 94%;
  }
  display: flex;
  flex-direction: column;
  &_header {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 20px;
    margin-top: 20px;
    justify-content: space-between;
    &__heading {
      color: #333;
      font-size: 28px;
      font-family: "Lato";
      font-weight: 900;
      text-align: left;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    &--input {
      border-radius: 8px;
      background: #fff;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
      // max-width: 310px;
      height: 46px;
      border: none;
      outline: none;
      color: #999;
      font-size: 16px;
      font-family: Lato;
      padding-left: 20px;
      @include bpm-md {
        width: 100%;
        margin-left: 0;
        width: 100%;
      }

      @include bpm-md {
        width: 95%;
      }
    }
  }
}
