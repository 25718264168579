.spark-single-course {
  &__wrapper {
    // padding-top: 11rem;
    overflow-x: hidden !important;
    &.no-alert {
      padding-top: 7rem;
    }

    .student-section {
      &__wrapper {
        text-align: center;
      }

      &__swiper {
        .swiper-slide {
          margin: 0 auto;
        }
      }
    }
    .courses-usp-section__wrapper {
      text-align: center;
    }
    .homepage-callback__wrapper {
      margin-top: -19rem;
      @include bpm-md {
        margin-top: -10rem;
      }
    }
  }
  &-courseCardNew {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    padding: 50px 0px;
    @include bpm-sm {
      flex-direction: column;
     align-items: center;
    }
  }
}
