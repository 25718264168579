.video-showcase-modal {
  &__wrapper {
  }
  max-width: 70%;
  background: #f2b65a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 0px 8px rgba(75, 0, 188, 0.08);
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 0px 8px rgba(75, 0, 188, 0.08);
  -webkit-appearance: none;
  border-radius: 16px;
  padding: 42px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  display: none;

  @include bpm-custom(850px) {
    padding: 2rem 1.5rem;
    margin: 0 1.5rem;
    min-height: auto;
  }
  @include bpm-md {
    max-width: 85%;
  }
  video {
    width: 100%;
  }
  position: relative;
  overflow: visible;
  img {
    width: 100%;
  }
  &__details {
    margin-top: 1rem;
    &--title {
      @include bp-sm {
        font-size: 2rem;
      }
      font-size: 1.8rem;
      margin-bottom: 0.5rem;
    }
    &--name {
      @include bp-sm {
        font-size: 1.6rem;
      }
      font-size: 1.4rem;
    }
  }
  &__close {
    position: absolute;
    background-color: #fff;
    height: 6rem;
    width: 6rem;
    border-radius: 10rem;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
    -webkit-appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -2rem;
    right: -2rem;
    cursor: pointer;
    img {
      width: 4rem;
    }
  }
}
