.grammer-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 60px;
  // border: 1px solid #fff;
  width: 100%;

  padding: 1rem 0.5rem 3rem;
  // border-radius: 25px;
  // box-shadow: 0 3px 7px rgb(0 0 0 / 0.1);
  &--heading {
    margin: 1rem auto 1rem;
    font-size: 2rem;
    font-weight: 800;
    color: #333;
    font-family: Lato;
  }
  &--subheading {
    margin: 1.5rem auto 3rem;
    font-size: 1.6rem;
    font-weight: 400;
    color: #333;
    font-family: Lato;
    opacity: 0.7;
  }
  .nowrap {
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .about-card {
    height: 600px;
  }
  .flex-container {
    padding: 0;
    margin: 0;
    list-style: none;
    border: 1px solid silver;
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
  }

  .single-stage {
    font-size: 1.5rem;
    display: flex;
    width: 100%;
    font-weight: 700;
    margin: 0rem 8% 1rem;
    cursor: pointer;
    color: #333;
    @include bpm-md {
      font-size: 1.4rem;
    }
    &:hover {
      color: #333;
    }
    span {
      display: inline-block;
      text-align: center;
      font-size: 1.6rem;
      font-weight: 700;
      background-color: #ddd;
      height: 2.6rem;
      width: 2.6rem;
      border-radius: 5rem;
      padding: 0.3rem 0 0 0;
      margin: 0 0.5rem;
      color: #666;
      @include bpm-md {
        height: 2.2rem;
        width: 2.2rem;
        font-size: 1.4rem;
        padding: 0.2rem 0 0 0;
      }
    }
    &.active {
      span {
        background-color: #f2b65a;
        color: #fff;
      }
    }
    &.done {
      span {
        background-color: #90d4a3;
        color: #fff;
      }
    }
  }
  .info-text {
    display: flex;
    flex-direction: column;
    font-size: 1.8rem;
    font-weight: 800;

    margin-left: 4%;
  }
  .transpert-info-text {
    margin-top: 1rem;
    font-size: 1.6rem;
    font-weight: 400;
    color: #333;
    font-family: Lato;
    opacity: 0.7;
  }
}
