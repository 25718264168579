@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Display:wght@600&display=swap");
.studioDetails {
  //! The wrapper class encloses the head image div
  //! Display is set to flex so as to align the item to the left on small devices
  &__wrapper {
    padding: 0;
    margin: 0;
    z-index: 2;
  }
  display: flex;
  flex-direction: column;
  width: 77%;
  min-height: 20rem;
  margin: 2.4rem 0 2.4rem 16%;
  border-radius: 2.4rem;
  background-color: #ffffff;
  padding: 3.2rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @include bpm-md {
    padding: 1.6rem;
    margin-top: 2.4rem;
    margin-left: auto;
    margin-right: auto;
  }
  @include bpm-xs {
    margin-left: 22px;
    width: 89%;
  }

  &__interstedCard {
    display: flex;
    padding: 1.6rem 2.4rem;
    background: #aadeed;
    align-items: center;
    min-height: 9rem;
    border-radius: 1.6rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    @include bpm-md {
      flex-direction: column;
    }
    &--p-text {
      font-family: "Noto Serif Display" !important;
      font-size: 33px;
      font-weight: 800;
      line-height: 33px;
      letter-spacing: 1.7rem;
      text-align: center;
      color: #333333;
      margin-right: 2.8rem;
      margin-left: 20px;
      display: flex;
      align-items: center;
      @include bpm-md {
        margin: 0.8rem 0 1.6rem 0;
      }
    }
    &__container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 47px;
      // width: 60%;
      @include bpm-md {
        gap: 13px;
        // width: 104%;
      }
      @include bpm-xs {
        flex-direction: column;
        gap: 24px;
        // width: 72%;
      }

      &__tag {
        margin: 0 0.8rem 0 0.8rem;
        border-radius: 9.6rem;
        height: 4rem;
        background: #f4f7ff;
        // -webkit-justify-content: center;
        // justify-content: center;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

        &--p-text {
          all: unset;
          width: 100%;
          gap: 5px;
          display: flex;
          // justify-content: space-between;
          cursor: pointer;
          font-family: Lato;
          padding: 0.2rem 1.8rem;
          font-size: 1.6rem;
          font-weight: 700;
          line-height: 29px;
          letter-spacing: 0em;
          text-align: left;
          @include bpm-md {
            font-size: 1.8rem;
          }
          img {
            filter: grayscale(100%) !important;
            -webkit-filter: grayscale(100%);
          }
        }
      }
    }
  }

  &__aboutCard {
    display: flex;
    padding: 1.6rem 2.4rem;
    margin-top: 2.4rem;
    background: #d8edc8;
    align-items: center;
    min-height: 9rem;
    border-radius: 1.6rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    @include bpm-md {
      flex-direction: column;
      padding: 1.2rem;
    }
    &--p-text {
      font-family: Lato;
      font-size: 20px;
      font-weight: 900;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #333333;
      // margin-right: 1.2rem;
      @include bpm-md {
        margin: 0 0 1.2rem 0;
      }
    }
    &__container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 1.6rem;
      width: 80%;
      &--input {
        width: 97%;
        min-height: 7rem;
        margin-left: 4rem;
        background: #ffffff;
        box-shadow: none;
        border-radius: 8px;
        border-color: aliceblue;
        outline: none;
        @include bpm-md {
          width: 100%;
          margin-left: 0;
          width: 100%;
        }
      }
      @include bpm-md {
        width: 95%;
      }
    }
  }
  &__t-container {
    display: flex;
    width: 100%;
    margin-top: 2.4rem;
    justify-content: space-between;
    align-items: center;
    @include bpm-md {
      flex-direction: column;
    }
    @include bpm-xs {
      margin-top: 13px;
    }
    &__friendsCorner {
      width: 41.3%;
      background-color: #b4e0fa;
      border-radius: 1.6rem;
      min-height: 24rem;
      padding: 1.6rem 3.4rem;
      display: flex;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      // justify-content: space-between;
      flex-direction: column;

      @include bpm-md {
        width: 100%;
        margin: 1.2rem 0;
      }
      &__header {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
        margin-bottom: 10px;
        @include bpm-md {
          justify-content: center;
          flex-direction: column;
          align-items: center;
        }
        &__heading {
          padding-left: 21px;
          font-family: Lato;
          font-size: 22px;
          font-weight: 900;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: #333333;
          @include bpm-xs {
            font-size: 21px;
          }
        }
        &__action {
          cursor: pointer;
          display: flex;
          font-family: Lato;
          font-size: 22px;
          font-weight: 900;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          text-decoration: underline !important;
          color: black;
          img {
            margin-left: 7px;
            width: 23px;
            height: 23px;
          }
          @include bpm-md {
            margin: 0.8rem 0;
          }
        }
      }
      &__addcontainer {
        width: 166px;
        height: 147px;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
      }
      &--scroll {
        overflow-y: scroll;
        height: 16rem;
        &::-webkit-scrollbar {
          width: 0.4rem;
        }
        &::-webkit-scrollbar-track {
          background-color: #fff;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
      &__studentContainer {
        margin-top: 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include bpm-xs {
          gap: 8px;
        }
        &--p-text {
          font-family: Lato;
          font-size: 20px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          align-items: center;
          color: #333333;
          display: flex;
          img {
            border-radius: 50%;
          }
        }
        &--button {
          background: linear-gradient(
            rgba(242, 182, 90, 1),
            rgba(228, 119, 98, 1)
          );
          margin-top: 0.5rem;
          width: 10rem;
          height: 4.2rem;
          justify-content: center;
          align-items: center;
          font-family: Lato;
          font-size: 14px;
          font-weight: 700;
          line-height: 17px;
          letter-spacing: 0px;
          color: #ffffff;
          text-align: left;
          border-radius: 9.6rem;
          display: flex;
          border: none;
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
          cursor: pointer;
        }
      }
    }
    &__charContainer {
      width: 56.2%;
      height: 24rem;
      border-radius: 1.6rem;
      background: #ffe38b;
      position: relative;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      background-image: url("https://sparkstudiowebassets.blob.core.windows.net/assets-sparkstudio-co/development/studio_combine.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      @include bpm-md {
        width: 100%;
        margin: 1.2rem 0;
      }
      @include bpm-custom(700px) {
        background-image: url("https://sparkstudiowebassets.blob.core.windows.net/assets-sparkstudio-co/development/studio_bg_mobile.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      &-p {
        display: flex;
        justify-content: space-between;
        padding: 20px 60px 0 30px;
        @include bpm-xs {
          padding: 20px 10px 10px 10px;
        }
        &--text {
          color: #333;
          font-size: 22px;
          font-family: "Lato";
          font-weight: 800;
          @include bpm-xs {
            text-align: left;
            font-size: 20px;
          }
        }
        &--ptext {
          display: flex;
          color: #333;
          font-size: 22px;
          font-family: "Lato";
          font-weight: 800;
          text-decoration: underline;
        }
      }
      &--image {
        display: flex;
        padding-left: 25px;
      }
    }
    &__inviteCard {
    }
  }
  &__inviteContainer {
    display: flex;
    background: #ffde59;
    width: 100%;
    min-height: 15rem;
  }
}

.my-studio-invite-card {
  &__wrapper {
    flex-direction: column;
    width: 77%;
    min-height: 20rem;
    margin: 2.4rem 0 25% 16%;
    border-radius: 2.4rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    @include bpm-md {
      justify-content: center;
      flex-direction: column;
      margin-top: 2.4rem;
      margin-left: auto;
      margin-right: auto;
    }
    @include bpm-xs {
      margin: 2.4rem 0 2.4rem 22px;
      width: 89%;
    }
  }

  width: 100%;
  display: flex;
  background: #ffde59;
  min-height: 15rem;
  border-radius: 1.6rem;
  justify-content: space-around;
  @include bpm-md {
    justify-content: space-around;
    flex-direction: column;
  }
  @include bpm-md {
    padding: 0 2.4rem;
  }
  &__textContainer {
    display: flex;
    width: 35%;
    margin: 0 2.4rem;
    flex-direction: column;
    // justify-content: center;
    gap: 30px;
    padding-top: 35px;
    @include bpm-xs {
      gap: 0;
      padding-top: 7px;
    }

    @include bpm-md {
      width: 100%;
      margin: 0;
    }

    &--p-text {
      font-family: Lato;
      font-size: 40px;
      font-weight: 900;
      line-height: 48px;
      letter-spacing: 0em;
      text-align: left;
      color: #333333;
      // padding-bottom: 45px;
      @include bpm-md {
        font-family: Lato;
        font-size: 23px;
        font-weight: 800;
        line-height: 25px;
        letter-spacing: 1px;
        text-align: center;
        margin: 1.6rem 0;
      }
    }

    &--s-text {
      color: #666666;
      font-family: Lato;
      font-size: 28px;
      font-weight: 400;
      line-height: 34px;
      letter-spacing: 0em;
      word-spacing: 4px;
      text-align: left;
      @include bpm-md {
        font-family: Lato;
        font-size: 19px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0px;
        text-align: center;
        margin: 0.8rem 0;
      }
    }
  }
  &__shareContainer {
    margin-top: 23px;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    justify-content: center;
    @include bpm-xs {
      margin-top: 0;
    }
    &__copyButton {
      cursor: pointer;
      margin-top: 15px;
      width: 300px;
      height: 4.4rem;
      background-color: #ffffff;
      border-radius: 2.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1.2rem;
      @include bpm-md {
        width: 100%;
      }
      &--text {
        font-family: Lato;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        background: linear-gradient(
          rgba(243, 112, 91, 1),
          rgba(252, 180, 68, 1)
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }
  &__socialMedia {
    display: flex;
    margin-top: 15%;
    justify-content: space-evenly;
    @include bpm-xs {
      margin-top: 2%;
    }
    &--icon {
      justify-content: space-evenly;
    }
  }

  .whatsappShare-button {
    height: 4.7rem;
  }
}
