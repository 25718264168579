.bundle-section {
  &__wrapper {
  }
  &__header {
    font-size: 4rem;
    font-weight: 900;
    color: #333;
    margin: 3rem 0;
    @include bpm-sm {
      font-size: 3rem;
      margin: 2rem 0 3rem 0;
    }
    @include bpm-xs {
      font-size: 2.5rem;
    }
  }
  &__swiper {
    width: 100%;
    padding-bottom: 6rem !important;
    .swiper-slide {
      width: 90rem;
    }
    .swiper-pagination {
      margin-bottom: 0;
    }
    @include bpm-md {
      .swiper-slide {
        width: 47rem;
      }
    }
    @include bpm-custom(600px) {
      .swiper-slide {
        width: 36.5rem;
      }
    }
    @include bpm-custom(425px) {
      .swiper-slide {
        width: 33rem;
      }
    }
  }
}
