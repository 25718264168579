.support_modal {
  &_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 130vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
    display: none;
  }

  &-close {
    width: 42px;
    height: 42px;
    position: absolute;
    right: 10px;
    top: -15px;
    z-index: 1000;
    cursor: pointer;
  }
  background-color: #ffffff;
  border-radius: 16px;
  opacity: 1;
  width: 60%;
  position: relative;
  @include bpm-lg {
    width: 85%;
  }
  @include bpm-xs {
    width: 94%;
  }
  // overflow: hidden;

  display: flex;
  flex-direction: column;
  &-head_wrapper {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    width: 100%;
    padding: 20px 15px 20px 30px;
    text-align: left;
    &--span {
      position: relative;
      font-family: "Lato";
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 22px;
      &::before {
        content: "";
        position: absolute;
        top: 110%;
        width: 100%;
        left: 0;
        // height: 1px;
        border-radius: 2px;
        background: linear-gradient(89.83deg, #f2b65a 0.1%, #e47762 99.81%);
      }
      background: linear-gradient(89.83deg, #f2b65a 0.1%, #e47762 99.81%);
      background-size: 100%;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
    }
    &--subText {
      margin-top: 10px;
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #666666;
    }
  }
  &-body {
    width: 100%;
    padding: 20px 30px;
    max-height: 400px;
    overflow-y: scroll;
    text-align: left;
    &::-webkit-scrollbar {
      width: 4px; /* width of the entire scrollbar */
    }
    &::-webkit-scrollbar-track {
      /* color of the tracking area */
      margin: 20px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #fcb444; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      /* creates padding around scroll thumb */
    }
    // height: 100px;
  }
  &-footer {
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 0px 0px 16px 16px;
    padding: 15px 25px;
    text-align: left;
    &--head {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.2px;
      color: #000000;
      margin-bottom: 15px;
    }
    &--contactInfo {
      display: flex;
      flex-direction: row;
      @include bpm-xs {
        flex-direction: column;
      }
      &---innerWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        @include bpm-xs {
          margin-bottom: 20px;
        }
        &----phoneImage {
          width: 16px;
          height: 16px;
          @include bpm-xs {
            margin-right: 5px;
          }
        }
        &----emailImage {
          width: 20px;
          height: 16px;
        }
        &----whatsappImage {
          width: 22px;
          height: 22px;
        }
        &----text {
          font-family: "Lato";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.2px;
          text-decoration-line: underline;
          color: #666666;
          margin-left: 10px;
          margin-right: 20px;
        }
      }
    }
  }
}
