.certificate-section {
  &_wrapper {
    background: linear-gradient(113.87deg, #daffd6, #c8e8ff 97.11%);
    box-shadow: 0px 4px 4px rgba(208, 208, 208, 0.72);
    margin: 3.6rem 0;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 16px;
    padding: 20px;

    @include bpm-md {
      flex-direction: column;
      row-gap: 15px;
    }
  }
  &_text-container--desktop {
    display: flex;
    flex-direction: column;
    width: 30%;
    padding-left: 20px;
    text-align: left;
    @media (max-width: 677px) {
      display: none;
    }

    &-primary-text {
      font-style: normal;
      font-weight: 800;
      font-size: 40px;
      line-height: 48px;
      color: #333333;
      height: 200px;

      @include bpm-md {
        font-size: 24px;
        height: auto;
      }
    }
    &-secondary-text {
      font-size: 18px;
      color: #6d5f5f;
    }
    @include bpm-md {
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
  &_text-container--mobile {
    display: none;
    @media (max-width: 677px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &-primary-text {
      font-style: normal;
      font-weight: 800;
      font-size: 40px;
      line-height: 48px;
      color: #333333;
      height: 200px;

      @include bpm-md {
        font-size: 24px;
        height: auto;
      }
    }
    &-secondary-text {
      font-size: 18px;
      color: #6d5f5f;
    }
  }
  .swiper-wrapper {
    margin-bottom: 6rem;
  }
  .swiper-pagination {
    // bottom: -3rem !important;
    margin-bottom: 0;
  }
  .swiper-slide {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .swiper-button-next {
    transform: translate(7rem, -5rem);
    background: linear-gradient(90deg, #f3705b 0%, #fcb444 100%);
    opacity: 0.7;
    outline: none;
    border: none;
    background-color: rgba(255, 255, 255, 0.9);
    height: 10rem;
    width: 10rem;
    border-radius: 10rem;
    position: absolute;
    &::after {
      content: "";
      background: url("../../../../public/assets/nextArrow.svg") center
        center/contain no-repeat;
      background-size: 2rem;
      transform: translateX(-2.3rem);
    }
  }
  .swiper-button-prev {
    transform: translate(-7rem, -5rem);
    background: linear-gradient(90deg, #f3705b 0%, #fcb444 100%);
    opacity: 0.7;
    outline: none;
    border: none;
    background-color: rgba(255, 255, 255, 0.9);
    height: 10rem;
    width: 10rem;
    border-radius: 10rem;
    position: absolute;
    &::after {
      content: "";
      background: url("../../../../public/assets/nextArrow.svg") center
        center/contain no-repeat;
      background-size: 2rem;
      transform: rotate(180deg) translateX(-2.3rem);
    }
  }
  .swiper-button-disabled {
    opacity: 0 !important;
  }
  &_no-certificate-text-container {
    display: flex;
    justify-content: center;
    font-size: 25px;
    color: #6d5f5f;
    @include bpm-md {
      font-size: 18px;
    }
  }
  width: 70%;
  @include bpm-md {
    width: 100%;
  }
}
