.upcoming_classes_table {
  @include bpm-lg {
    overflow-x: scroll;
  }
  height: auto;
  &-wrapper {
    background: #ffffff;
    border-radius: 16px;
    border-spacing: 0px;
    padding: 10px 30px;
    width: 100%;
    white-space: nowrap;
    // margin-bottom: 20px;

    td,
    th {
      border-bottom: 2px solid rgba(242, 182, 90, 0.48);
      padding: 20px 0px;

      @include bpm-lg {
        padding-right: 20px;
      }
      @include bpm-xs {
        padding: 15px 0px;
        padding-right: 20px;
      }
    }
  }
  &-head_row {
    padding: 10px 0px;
    &-heading {
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 24px;
      line-height: 29px;
      color: #333333;
      text-align: left;
      @include bpm-xs {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
  &-content {
    &:last-child {
      td {
        border-bottom: none;
      }
    }
    &--class {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #333333;
      text-align: left;
      @include bpm-xs {
        font-size: 20px;
        line-height: 24px;
      }
    }
    &--classDetails {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      text-align: left;
      color: #666666;
      @include bpm-xs {
        font-size: 20px;
        line-height: 24px;
      }
      &---buttonWrapper {
        width: 118px;
      }
      &---reschedule {
        color: #333333;
        position: relative;
        padding: 14px 22px;
        width: fit-content;
        display: flex;
        &::before {
          content: "";
          position: absolute;
          inset: 0;
          border-radius: 48px;
          padding: 2px; /* control the border thickness */
          background: linear-gradient(45deg, #f4735a, #fcaf46);
          -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          mask-composite: exclude;
        }
      }
    }
    &--inviteFriend {
      text-align: left;
      &---image {
        padding-left: 45px;
        cursor: pointer;
      }
    }
  }
  .secondary-button {
    margin: 0;
    width: 10rem;
  }
}
