.spelling-bee-game-result-card {
  &_wrapper {
    display: flex;
    flex-direction: column;
    background: #013299;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 1.5rem 2rem;
    height: max-content;
    @include bpm-xs {
      width: 100%;
      margin: 0rem 2rem;
    }
  }
  &-total_words {
    width: 84px;
    height: 96px;
  }
  &-words_spelled_correct {
    width: 93px;
    height: 96px;
  }
  &-cefr_level {
    width: 49px;
    height: 96px;
  }
  &-mainText {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #ffffff;
    text-align: left;
    margin-top: 1rem;
  }
  &-subText {
    font-family: "Lato";
    font-style: normal;
    text-align: left;
    font-weight: 900;
    font-size: 64px;
    line-height: 77px;
    color: #ffffff;
    margin-top: 2rem;
  }
}
