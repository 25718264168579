.spark-booking {
  &__slots-form {
    &--day-selector {
      overflow-x: scroll;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding-bottom: 2rem;
      &--single-day {
        font-size: 1.6rem;
        font-weight: 500;
        margin: 0 2rem 0 0;
        color: #666666;
        cursor: pointer;
        input {
          display: none;
        }
        padding-bottom: 0.7rem;
        &.active {
          padding-bottom: 0.2rem;
          font-weight: 700;
          border-bottom: 2px solid #f2b65a;
          color: #333333;
        }
        &.app-booking-active {
          border-bottom: 2px solid #f2b65a;
          font-weight: 900;
          font-size: 18px;
          margin-bottom: -0.5px;
          color: #333333;
        }
      }
      &.app-booking {
        border-bottom: 1px solid #666666;
        padding-bottom: 0rem;
      }
    }
    &--slot-selector {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 2rem;
      @include bp-sm {
        min-height: 30rem;
        &.autoHeight {
          min-height: auto;
        }
      }
      &--single-course {
        > span {
          font-size: 1.6rem;
          font-weight: 700;
        }
      }
    }
    .slots-timezone {
      font-size: 1.2rem;
      font-weight: 700;
      display: inline-block;
      width: 95%;
      text-align: center;
      margin: 3rem auto 0.5rem auto;
    }
    .primary-button {
      margin-top: 1rem;
    }
    .custom-slot-text {
      display: inline-block;
      font-size: 1.4rem;
      font-weight: 700;
      margin: 1.5rem 0;
    }
  }
}
