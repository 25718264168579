.spellingBee_certificate-section {
  &_wrapper {
    padding-left: 14%;
    padding-right: 14%;
    @include bpm-sm {
      padding-left: 16px;
      padding-right: 16px;
    }
    margin-top: 14px;
  }

  &--desktop {
    height: 26rem;
    @include bpm-md {
      display: none;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &--mobile {
    display: none;

    @include bpm-md {
      display: block;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
