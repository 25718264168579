.dashboardCalendar{
    &__wrapper{
        width: 1000px;
     
        
        overflow-y: scroll;
        margin: 0 auto;
       
        background-color: white;
        padding: 20px;
        border-radius: 20px;
        &::-webkit-scrollbar {
            display: none;
          }
          
          /* Hide scrollbar for IE, Edge and Firefox */
          & {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
          }
        @include bpm-lg{
            width: auto;
        
        }
    }
}