.spark-booking-slot-card {
  &__wrapper {
    padding: 1.4rem 0.8rem 1.4rem 0.4rem;
    width: 90%;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(75, 0, 188, 0.08);
    -webkit-box-shadow: 0px 0px 8px rgba(75, 0, 188, 0.08) !important;
    -webkit-appearance: none !important;
    border-radius: 8px;
    margin: 1.5rem 0;
    cursor: pointer;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 1.2rem;
      transform: translateY(-50%);
      height: 2.4rem;
      width: 2.4rem;
      z-index: 10;
      background: url("../../../../public/assets/unchecked.svg") center
        center/contain no-repeat;
    }
    &.checked {
      border: 2px solid #f2b65a;
      &::after {
        background: url("../../../../public/assets/checked.svg") center
          center/contain no-repeat;
      }
    }
    &.disabled {
      background-color: #eee;
      // cursor: not-allowed;
    }
    &.not-allowed {
      cursor: not-allowed;
    }
    .co-booking-slot {
      position: absolute;
      display: inline-block;
      text-align: left;
      left: 22rem;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.1rem;
      color: #666;
      min-width: 10rem;
      max-width: 15rem;
      img {
        width: 0.9rem;
        margin-right: 0.3rem;
      }
    }
    .tap-here-tooltip {
      height: 2.5rem;
      width: 15rem;
      background-color: #eee;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
      -webkit-appearance: none;
      border-radius: 8px;
      padding: 0.5rem;
      text-align: center;
      position: absolute;
      // right: -11rem; 
      z-index: 50;
      &::after {
        content: "";
        position: absolute;
        top: -1rem;
        left: 1rem;
        height: 1rem;
        width: 2rem;
        background-color: #eee;
        -webkit-clip-path: polygon(50% 50%, 0% 100%, 100% 100%);
        clip-path: polygon(50% 20%, 0% 100%, 100% 100%);
      }
      span {
        display: inline-block;
        text-align: center;
        width: 100%;
        font-size: 1.2rem;
      }
    }
    &.app-booking {
      width: 100%;
      // background: linear-gradient(285.93deg, #fdcd4c -25.55%, #ffffff 70.2%);
      box-shadow: 0px 4px 4px 1px rgba(51, 51, 51, 0.25);
      border-radius: 8px;
      height: auto;
      &::after {
        display: none;
      }
    }
  }
  &_app-booking {
    display: flex;
    flex-direction: row;
    padding: 1rem 1rem;
    justify-content: space-between;
    align-items: center;
    &--time {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      display: flex;
      align-items: center;
      color: #333333;
    }
    &--checked {
      width: 24px;
      height: 24px;
    }
  }
  &--p-text {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(51, 51, 51, 1);
  }
  &__co-booking {
    padding-left: 1.1rem;
    margin-top: 4px;
    padding-top: rem;
    display: flex;
    align-items: center;
    &-text {
      font-family: Lato;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0px;
      text-align: left;
      margin-top: 1px;
      margin-left: 5px;
    }
    &--img {
      width: 1.2rem;
      margin-right: 4px;
    }
  }
  font-size: 1.4rem;
  padding: 0 1rem;
  color: #999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
