.spark-storybook {
  &__wrapper {
    height: 100vh;
    width: 100vw;
    &.show-bg {
      background-color: #444;
    }
  }
  height: 100%;
  width: 100%;
}
