.courses-usp-section {
  &__wrapper {
  }
  &__header {
    font-size: 4rem;
    font-weight: 900;
    color: #333;
    margin: 3rem 0;
    @include bpm-sm {
      font-size: 3rem;
      margin: 2rem 0;
    }
    @include bpm-xs {
      font-size: 2.5rem;
    }
  }
  &__cards {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .courses-usp-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 16rem;
    margin: 2rem 1rem;
    @include bpm-xs {
      margin: 1.5rem 0.5rem;
    }
    &__image {
      width: 5rem;
    }
    &__header {
      font-size: 1.6rem;
      color: #333;
      font-weight: 700;
      margin: 1.5rem 0;
      text-align: center;
    }
    &__content {
      font-size: 1.4rem;
      color: #666;
      font-weight: 500;
      text-align: center;
    }
  }
}
