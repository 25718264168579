.class-recording-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 450px;
  min-width: 360px;
  //   margin: 0 2rem;
  max-height: 550px;
  background-color: #fff;
  padding: 2.5rem 2rem 1rem 2rem;
  border-radius: 1rem;
  margin-top: 250px;
  @include bpm-xs {
    min-width: 360px;
    max-height: 500px;
    padding: 1.5rem 0.5rem 0.5rem 0.5rem;
  }

  &__p-text {
    font-family: Lato;
    font-size: 23px;
    font-weight: 900;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: #333333;
    @include bpm-xs {
      font-size: 22px;
    }
  }
  &__passwordContainer {
    margin-top: 1.6rem;
    width: 95%;
    height: 4.8rem;
    border-radius: 10rem;
    background: #afeafb;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0.8rem;
    @include bpm-xs {
      margin-top: 1.2rem;
      width: 95%;
    }
    &--p-text {
      font-family: Lato;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0px;
      color: #333333;
      text-align: left;
    }
    &--img {
      margin-top: 2.4rem;
      border-radius: 25px;
      width: 300px;
      @include bpm-xs {
        width: 300px;
      }
    }
    &--img1 {
      position: absolute;
      top: 45%;
      left: 40%;
    }
    &--ps {
      color: #666666;
      font-family: Lato;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
    }
    &--copy {
      display: flex;
      &--img {
        margin-right: 1.2rem;
      }
    }
  }
  &__t-text {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #e50300;
    margin-bottom: 2.4rem;
  }

  .primary-button {
    margin: 2.4rem 0 1.6rem 0;
  }

  &__close {
    position: absolute;
    width: 2rem;
    top: 3rem;
    right: 2rem;
    cursor: pointer;
  }
}
