.eligibleCoursesHeader {
    //! wrapper encloses the whole component
  &__wrapper {
    display: flex;
    margin-bottom: 80px;
    @include bpm-xs{
        margin-bottom: 30px;
        margin-left: 10px;
    }
  }
  //! title holds the title contents of the page
  //! left div in the wrapper
  &--title {
      text-align: left;
      // border-right: 1px solid #666666;
    h1 {
      font-family: Lato;
      font-size: 45px;
      font-style: normal;
      font-weight: 300;
      line-height: 54px;
      letter-spacing: 0em;
      color: #666666;
      max-width: 329px;
      @include bpm-xs{
          font-size: 28px;
          max-width: 220px;
          line-height: 33px;
      }
    }
    span {
      font-family: Lato;
      font-size: 45px;
      font-style: normal;
      font-weight: 900;
      line-height: 54px;
      letter-spacing: 0em;
      color: #333333;
      @include bpm-xs{
        font-size: 28px;
        line-height: 33px;
    }
    }
  }
  //! progress is the right half of the wrapper div
  &--progress{
      display: none;
  }
}
