.word_list {
  &-container {
    margin-top: 40px;
    @include bpm-sm {
      margin-top: 24px;
    }
  }
  &--heading {
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    @include bpm-sm {
      font-size: 24px;
      line-height: 29px;
    }
  }

  &_card {
    &-wrapper {
      margin-top: 40px;
      padding-left: 10%;
      padding-right: 10%;
      @include bpm-sm {
        padding-left: 16px;
        padding-right: 16px;
        margin-top: 24px;
      }
      // display: flex;
      // justify-content: center;
    }

    background: linear-gradient(#f9eaff);
    // box-shadow: 50px 60px 100px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(35px);
    border-radius: 16px;
    display: flex;
    justify-content: space-evenly;
    max-width: 992px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    @include bpm-sm {
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }

    &--left {
      @include bpm-sm {
        width: 100%;
      }
      &--img {
        border-radius: 16px 16px 0px 16px;
        width: 100%;
      }
      // width: 50%;
    }
    &--right {
      display: flex;
      flex-direction: column;
      padding: 24px;
      justify-content: space-around;
      width: 50%;
      @include bpm-sm {
        width: 100%;
        padding: 16px 36px;
        display: flex;
        gap: 16px;
      }

      &--p-text {
        font-family: Lato;
        font-size: 40px;
        font-weight: 800;
        line-height: 48px;
        letter-spacing: 0px;
        text-align: left;
        color: #333333;
        @include bpm-custom(1024) {
          font-size: 24px;
          font-weight: 800;
          line-height: 29px;
        }
      }
      &--s-text {
        font-family: Lato;
        font-size: 28px;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: 0px;
        text-align: left;
        color: #666666;
        // margin-bottom: 2.4rem;
        @include bpm-sm {
          font-size: 20px;
          font-weight: 400;
          line-height: 24px;
        }
      }

      &_practice {
        width: 168px;
        height: 22px;
        flex-grow: 0;
        margin: 10px 197px 0;
        background-image: linear-gradient(100deg, #ec7f57 -6%, #f2b65a 122%);
        font-family: Lato;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
