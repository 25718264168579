.story-telling-image-reveal {
  &__wrapper {
    padding: 0 3.6rem;
    @include bpm-xxl {
      padding: 0 1.2rem;
    }
  }
  display: flex;
  // flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  @include bpm-xxs {
    flex-direction: column;
  }

  &__container {
    display: flex;
    // flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10rem;
    @include bpm-xxs {
      flex-direction: column;
      margin-bottom: 3rem;
    }

    &__card {
      margin: 0 0 3.6rem;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      margin: 0 0 1.6rem;
      width: auto;
      // width: 100%;
      @include bpm-xxs {
        width: 90%;
      }
      &--img {
        width: 35rem;
        border-radius: 1.2rem;

        @include bpm-xxs {
          width: 100%;
          border-radius: 1.2rem;
          // height: 20rem;
          object-fit: contain;
        }
      }

      &-p-text {
        font-family: Poppins;
        font-weight: 800;
        line-height: 36px;
        letter-spacing: 0px;
        text-align: center;
        color: #333333;
        font-size: 20px;
        margin-top: 1.1rem;

        @include bpm-xxs {
          font-size: 18px;
          margin-top: 0.1rem;
          text-align: left;
        }
      }
      &-s-text {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0px;
        text-align: left;
        color: #666666;
        @include bpm-xxl {
          font-size: 14px;
          line-height: 14px;
        }
        //   margin-top: 0.8rem;
      }
    }
  }

  &__button {
    width: 25rem;
    margin: 3rem auto;
  }
}
