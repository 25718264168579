.spellingBee-levelsAndPrizes {
  &_wrapper {
    padding-left: 14%;
    padding-right: 14%;
    margin-top: 64px;
    @include bpm-sm {
      padding-left: 16px;
      padding-right: 16px;
      margin-top: 40px;
    }
  }
  &--heading-1 {
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #333333;
    @include bpm-sm {
      font-size: 24px;
      line-height: 29px;
    }
  }
  &--heading-2 {
    margin-top: 24px;
    font-weight: 400;
    font-size: 48px;
    line-height: 58px;
    color: #333333;
    @include bpm-sm {
      white-space: nowrap;
      font-size: 14px;
      line-height: 24px;
      white-space: nowrap;
      color: #000000;
    }
  }
  &_img-container {
    margin-top: 24px;
    @include bpm-sm {
      margin-top: 16px;
    }
    img {
      width: 100%;
    }
  }
}
