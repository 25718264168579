.spark-dashboard-home {
  &__wrapper {
    position: relative;
    width: max-content;
    margin: 0 auto;
    display: grid;
    grid-template-columns: auto auto;
    padding-bottom: 50px;
    margin-top: 20px;
    background-color: #f7eee7;

    @include bpm-xs {
      width: auto;
      margin: 0;
      display: block;
    }
    .dashboard-no-classes-footer {
      // position: absolute;
      // right: -100px;
      // bottom: -350px;
      top: 0;
      right: -60%;
      position: relative;
      @include bpm-lg {
        display: none;
      }
      &.hidden {
        display: none;
        position: relative;
        right: 0px;
        bottom: 0px;
        width: 100%;
        margin-top: 50px;
        @include bpm-lg {
          display: block;
        }
      }
    }
  }
  &--right {
    margin-left: 50px;
    @include bpm-lg {
      margin-left: 0px;
      display: none;
    }
  }
  &--left {
    margin-bottom: 20px;
    &-profileHidden {
      display: none;
      width: max-content;
      margin: 30px auto;

      @include bpm-lg {
        display: block;
      }
    }
    display: flex;
    flex-direction: column;
  }
}
