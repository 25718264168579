.classreport_modal {
  &_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 120vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
    display: none;
  }
  &--download-btn-container {
    position: relative;
    top: 40px;
    right: 30px;
    display: flex;
    justify-content: flex-end;
    background: linear-gradient(90deg, #f3705b, #fcb444);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    font-size: 20px;
    span {
      border-bottom: 2px solid #f3705b;
      cursor: pointer;
      @include bpm-sm {
        font-size: 14px;
      }
    }
  }
  &-close {
    width: 42px;
    height: 42px;
    position: absolute;
    right: 10px;
    top: -15px;
    z-index: 1000;
    cursor: pointer;
  }
  //padding: 3rem 2rem;
  //padding: 3rem 0;
  background-color: #ffffff;
  border-radius: 16px;
  opacity: 1;
  width: 40%;
  position: relative;
  @include bpm-lg {
    width: 50%;
  }
  @include bpm-sm {
    width: 65%;
  }
  @include bpm-xs {
    width: 94%;
    // padding: 1.5rem;
  }
  display: flex;
  flex-direction: column;
  &-head_wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    &--primary-text {
      font-weight: 900;
      font-size: 28px;
      @include bpm-xs {
        font-size: 20px;
      }
    }
    &--secondary-text {
      font-weight: 400;
      font-size: 20px;
      @include bpm-xs {
        font-size: 14px;
      }
      &_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  &_body {
    margin-top: 1.5rem;
    display: flex;
    column-gap: 1rem;
    &--left {
      min-height: 17.5rem;
      flex: 0.5;
      background: #afe6c5;
      border-radius: 8px;
      padding: 1.5rem;
      @include bpm-xs {
        padding: 1rem;
      }
      &_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &--text {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 700;
          font-size: 22px;
          line-height: 33px;
          flex: 0.5;
          @include bpm-xs {
            font-size: 16px;
            line-height: 20px;
          }
        }
        &--image-container {
          flex: 0.5;
          display: flex;
          justify-content: flex-end;

          img {
            height: 4rem;
            width: 4rem;
            @include bpm-xs {
              height: 3rem;
              width: 3rem;
            }
          }
        }
      }
      &_body {
        margin-top: 26px;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;

        &--skills {
          padding: 0.75rem 1rem;
          background-color: #fff;
          border-radius: 8px;
          font-weight: 700;
          font-size: 14px;
          @include bpm-xs {
            padding: 0.5rem 0.75rem;
            font-size: 12px;
          }
        }
      }
    }
    &--right {
      min-height: 17.5rem;
      flex: 0.5;
      background: rgba(228, 119, 98, 0.2);
      border-radius: 8px;
      padding: 1.5rem;
      @include bpm-xs {
        padding: 1rem;
      }
      &_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &--text {
          font-weight: 700;
          font-family: "Poppins";
          font-style: normal;
          font-size: 22px;
          line-height: 33px;
          @include bpm-xs {
            font-size: 16px;
            line-height: 20px;
          }
          // flex: 0.5;
        }
        &--image-container {
          //flex: 0.5;
          display: flex;
          justify-content: flex-end;

          img {
            height: 4rem;
            width: 4rem;
            @include bpm-xs {
              height: 3rem;
              width: 3rem;
            }
          }
        }
      }
      &_body {
        margin-top: 26px;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        &--skills {
          padding: 0.75rem 1rem;

          background-color: #fff;
          border-radius: 8px;
          font-weight: 700;
          font-size: 14px;
          @include bpm-xs {
            padding: 0.5rem 0.75rem;
            font-size: 12px;
          }
        }
      }
    }
  }
  &_participation-ability-container {
    margin-top: 1.5rem;
    display: flex;
    column-gap: 1rem;
  }
  &_participation-container {
    flex: 0.5;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    // padding: 1rem;
    background-color: #fff5bf;
    &--left {
      padding: 16px 3px 16px 16px;
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
      &_primary-text {
        font-family: "Inter";
        font-weight: 700;
        font-size: 20px;
        @include bpm-xs {
          font-size: 16px;
        }
      }
      &_secondary-text {
        font-family: "Poppins";
        font-weight: 400;
        font-size: 14px;
        color: #666666;
      }
    }
    &--right {
      &_image-container {
        padding-top: 11px;
        img {
          height: 71px;
          width: 70px;
          @include bpm-xs {
            height: 48px;
            width: 48px;
          }
        }
      }
    }
  }

  &_ability-container {
    flex: 0.5;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    // padding: 1rem;
    background-color: #f9eaff;
    &--left {
      padding: 16px 3px 16px 16px;
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
      &_primary-text {
        font-family: "Inter";
        font-weight: 700;
        font-size: 20px;
        @include bpm-xs {
          font-size: 16px;
        }
      }
      &_secondary-text {
        font-family: "Poppins";
        font-weight: 400;
        font-size: 14px;
        color: #666666;
      }
    }
    &--right {
      &_image-container {
        padding-top: 11px;
        padding-right: 24px;
        img {
          height: 66px;
          width: 64px;
          @include bpm-xs {
            height: 48px;
            width: 48px;
          }
        }
      }
    }
  }
}
