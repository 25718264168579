.live-class-login {
  &__wrapper {
    min-height: 100vh;
    margin-top: 7rem;
    position: relative;
    @include bpm-custom(875px) {
      margin: 7rem 0;
    }
  }
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &__header {
    margin: 4rem 0;
    font-size: 5rem;
    text-align: center;
    font-weight: 800;
    padding: 0 2rem;
    p {
      font-size: 3rem;
      font-weight: 500;
      max-width: 60rem;
      margin: 4rem auto 0 auto;
    }
    @include bpm-sm {
      font-size: 3.5rem;

      p {
        font-size: 2.2rem;
      }
    }
    &--small {
      font-size: 2rem !important;
    }
  }
  &__cards {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .live-class-login__student-card {
      margin: 2rem;
    }
  }
  &__student-card {
    min-height: 20rem;
    width: 30rem;
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
    -webkit-appearance: none;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 4rem;
    margin: 1rem 0 2rem 0;
    .class-name {
      font-size: 1.6rem;
      text-align: center;
      line-height: 2.5rem;
      font-weight: 500;
      span {
        margin-bottom: 2rem;
        line-height: 2.5rem;
        display: inline-block;
        font-size: 1.6rem;
        font-weight: 700;
        &.class-name__header {
          font-size: 2rem;
        }
      }
    }
    &.disabled {
      .primary-button {
        cursor: not-allowed;
        background: linear-gradient(90deg, #989898 0%, #b7b7b7 100%);
      }
    }
  }
  &__profiles {
    max-width: 106rem;
    margin: auto;
    padding: 0 3rem;
    @include bpm-xs {
      padding: 0 1.5rem;
    }
    &--single-profile {
      border-bottom: 1px solid #ccc;
      &:last-child {
        border: none;
      }
      margin-bottom: 2rem;
      @include bpm-custom(875px) {
        border-bottom: none;
      }
      &-name {
        font-size: 2rem;
        padding-bottom: 1rem;
        @include bpm-custom(875px) {
          text-align: center;
        }
      }
      &-no-classes {
        font-size: 1.4rem;
        padding-bottom: 1rem;
      }
      &-classes {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        @include bpm-custom(875px) {
          flex-direction: column;
          align-items: center;
        }
        .immediate-next-class {
          .button-activation-message {
            padding: 1rem 0;
            font-size: 1.4rem;
            text-align: center;
            color: #f3705b;
            font-weight: 700;
          }
        }
        .future-class {
          width: 100%;
          margin: 1rem 0rem 1rem 2rem;
          @include bpm-custom(875px) {
            margin: 1rem 0;
          }
          &__header {
            font-size: 1.8rem;
            margin-bottom: 1rem;
          }
          &__single {
            display: grid;
            grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
            @include bp-xs {
              grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr;
            }
            width: 100%;
            border-bottom: 1px solid #ccc;
            padding: 0.7rem 0;
            span {
              font-size: 1.6rem;
              font-weight: 500;
              @include bpm-xs {
                font-size: 1.4rem;
              }
            }
            &--status {
              span {
                display: inline-block;
                box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
                font-size: 1.2rem;
                padding: 0.3rem 0.7rem;
                border-radius: 5rem;
                @include bpm-xs {
                  font-size: 1rem;
                }
              }
              &.upcoming {
                span {
                  background-color: #fcb444;
                }
              }
              &.Join.Class {
                cursor: pointer;
                span {
                  background-color: #90d4a3;
                }
              }
              &.completed {
                span {
                  background-color: #777;
                  color: #fff;
                }
              }
              &.reschedule {
                // margin: 2px;
                padding-left: 4px;
                // display: flex;
                // justify-content: center;
                // height: 59%;
              }
              .reschedule-init {
                padding: 0.1rem 0.7rem;
                cursor: pointer;
                border: 2px solid #fcb444 !important;
              }
              @include bpm-xs {
                display: flex;
                flex-direction: column;
                span {
                  text-align: center;
                }
                .reschedule-init {
                  margin-top: 0.5rem;
                  padding: 0.1rem 0.5rem;
                  background-color: transparent !important;
                }
              }
            }
          }
          &__table-header {
            display: grid;
            grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
            @include bp-xs {
              grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr;
            }
            width: 100%;
            padding: 1rem 0;
            span {
              font-size: 1.6rem;
              font-weight: 700;
              @include bpm-xs {
                font-size: 1.4rem;
              }
            }
          }
        }
      }
    }
  }
  &__gameCard {
    max-width: 80rem;
    margin: 3rem auto;
  }
  &__invitation {
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 3rem 4rem;
    overflow: hidden;
    @include bpm-md {
      margin: 4rem;
    }
    @include bpm-custom(860px) {
      max-width: 500px;
      margin: auto;
    }
    @include bpm-xs {
      margin: 3rem 1.5rem;
    }
  }
  .primary-button {
    margin-bottom: 2rem;
  }
}
.live_class {
  //! Css for wrapper of live page ->
  &-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      #fee1e8 -6.98%,
      rgba(255, 253, 201, 0.4) 100%
    );
    padding: 0 60px;
    padding-top: 12rem;
    @include bpm-lg {
      padding: 10rem 30px;
    }
    @include bpm-lg {
      padding: 0 12px;
      padding-top: 10rem;
      height: 100%;
    }
  }
  &-head {
    &_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @include bpm-lg {
        flex-direction: column;
        align-items: center;
      }
    }
    &_heading {
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 56px;
      line-height: 72px;
      color: #000000;
      margin-right: 50px;
      @include bpm-lg {
        text-align: center;
        margin-bottom: 15px;
        margin-right: 0px;
      }
      @include bpm-xs {
        font-size: 40px;
        line-height: 44px;
        margin-bottom: 20px;
      }
    }
    &--lottie {
      width: 100%;
      // height: 200px;
      background-color: #ffffff;
      border-radius: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &---text {
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #333333;
      }
    }
  }
  &-head_empty {
    &-wrapper {
      display: flex;
      flex-direction: row;
      text-align: left;
      @include bpm-lg {
        flex-direction: column;
        align-items: center;
      }
    }
    &-left {
      width: 50%;
      display: flex;
      justify-content: center;
      &--image {
        @include bpm-xs {
          width: 142px;
          height: 104px;
        }
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      @include bpm-lg {
        align-items: center;
        text-align: center;
      }
      &--headText {
        font-family: "Lato";
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        line-height: 40px;
        color: #333333;
        @include bpm-lg {
          margin-top: 25px;
        }
        @include bpm-xs {
          font-size: 20px;
          line-height: 28px;
        }
      }
      &--subText {
        font-family: "Lato";
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 34px;
        color: #333333;
        margin-top: 20px;
        margin-bottom: 30px;
        @include bpm-xs {
          font-size: 18px;
          line-height: 22px;
        }
      }
      &--buttonWrapper {
        width: 407px;
        @include bpm-xs {
          width: 100%;
        }
      }
    }
  }
  &-body_title {
    font-family: "Lato";
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
    color: #333333;
    margin-top: 7rem;
    margin-bottom: 2rem;
    @include bpm-xs {
      font-size: 24px;
      line-height: 29px;
      margin-top: 4rem;
    }
  }
  &-body_booking_strip {
    background: #0e1232;
    width: calc(100% + 120px);
    margin-left: -60px;
    @include bpm-lg {
      width: calc(100% + 24px);
      margin-left: -12px;
      padding: 2rem 2rem;
    }
    @include bpm-xs {
      padding: 2rem 1rem;
    }
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    padding: 2rem 6rem;
    justify-content: space-between;
    align-items: center;

    &-text {
      font-family: "Lato";
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      color: #ffffff;
      @include bpm-lg {
        font-size: 30px;
      }
      @include bpm-xs {
        font-size: 14px;
      }
    }
  }
}
