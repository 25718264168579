.spark-trial-landing-page {
  &__wrapper {
    @include bp-xs {
      padding: 2rem 0;
    }
    padding-bottom: 5rem;
    min-height: 100vh;
    width: 100%;
    .nav-footer-layout {
      .spark-layout-navbar {
        display: none;
      }
      .spark-footer__wrapper {
        display: none;
      }
    }
    .global-modal-wrapper,
    .auth-modal,
    .stop-modal,
    .auth-login,
    .auth-signup {
      //   display: block !important;
    }
    position: relative;
    &--bg {
      // position: absolute;
      // margin-bottom: -90vh;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 10;
      opacity: 0.2;
    }
    .belief-section__wrapper {
      @include bpm-custom(1150px) {
        margin-bottom: -10rem;
      }
    }
    .primary-button {
      width: 25rem;
      margin: auto;
    }
    .spark-testimonial {
      margin-bottom: 2rem;
    }
  }
  margin: 0 auto;
  width: 375px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 18;
  @include bpm-xs {
    width: 100vw;
  }
  &__bg__wrapper {
    width: 100vw;
    margin-bottom: 3rem;
    // opacity: 0.5;
  }
  // opacity: 1 !important;
  &__top {
    width: 100%;
    padding: 2rem;
    background-color: #ff8671;
    display: flex;
    flex-direction: column;
    align-items: center;
    &--logo {
      width: 10rem;
    }
    &--header {
      font-size: 2.4rem;
      font-weight: 900;
      color: #fff;
      margin: 1.5rem 0;
      text-align: center;
    }
    &--keywords {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333;
      padding: 0rem 1rem;
      font-size: 1.6rem;
      font-weight: 700;
    }
  }
  &__bottom {
    background-color: #aee6f0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    &--header {
      text-align: center;
      font-size: 2.4rem;
      color: #333;
      padding-bottom: 1.5rem;
    }
    .booking-form input {
      height: 4.8rem !important;
      margin: 0.6rem 0;
    }
    .spark-booking__contact {
      .spark-booking-input-box__wrapper {
        &:first-child {
          width: 20%;
        }
        &:last-child {
          width: calc(80% - 1.5rem);
          margin-left: 1.5rem;
        }
      }
    }
    .secondary-button.version-5 {
      margin: 1rem auto;
    }
    .select-courses {
      margin: 1rem auto;
      //   display: inline-block;
    }
    .spark-booking-input-box__wrapper {
      width: 100%;
    }
    .input-box {
      width: 100%;
    }
  }
}
