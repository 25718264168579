.upcoming_class_table {
  width: 100%;
  table-layout: fixed;
  border-radius: 1rem;
  border-collapse: collapse;
  &__container {
    display: flex;
    flex-direction: row-reverse;
    padding: 1.2rem 9px 0 0;
    align-items: center;
    &--button {
      width: 6rem;
      height: 3rem;
      background: grey;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      border-radius: 0.8rem;
      font-size: 1.2rem;
      margin-right: 1.2rem;
      cursor: pointer;
    }
  }
}

.class_details_table {
  margin-bottom: 4.8rem;
}

.upcoming_class_table tbody {
  display: block;
  width: 100%;
  overflow: auto;
  height: 35rem;
}

.upcoming_class_table thead tr {
  display: block;
  border-radius: 2rem;
}

.upcoming_class_table__wrapper {
  background: #fed700;
  border-radius: 6rem;
  // position: fixed;
  color: #fff;
  width: 100%;
  height: 10rem;
  border-radius: 1.6rem 1.6rem 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.brown {
    background: #ffb541;
  }
  &.cream {
    background: #fdefd8;
  }
  &.blue {
    background: #aae4ff;
    padding: 0 0.6rem;
  }

  &__body {
    background: #fff;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid rgba(242, 182, 90, 0.48);
  }
  &__preparation {
    color: #f3705b !important;
    text-decoration: underline;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
  }
  &__paid {
    font-family: Lato;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
    color: #00a912 !important;
  }

  &__p-button {
    padding: 1rem 0rem !important;
    width: 80%;
    margin: auto;
  }
}

.upcoming_class_table tbody tr th {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  color: #333333;
}

.upcoming_class_table tbody tr th {
  text-align: center;
  position: relative;
  top: 0;
}

.upcoming_class_table th,
.upcoming_class_table td {
  padding: 1.6rem 5px;
  text-align: center;
  width: 250px;
}

.upcoming_class_table tr td {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  /* identical to box height */
  color: #666666;
}

table::-webkit-scrollbar {
  background: #fff;
}
table::-webkit-scrollbar-thumb {
  background-color: red;
}
