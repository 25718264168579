.spelling-bee-trial-book-card {
  &_wrapper {
    width: 1008px;
    height: 381px;
    margin: 40px auto 0 auto;
    border-radius: 24px;
    position: relative;
    @include bpm-md {
      width: 95%;
      height: fit-content;
    }
    @include bpm-xs {
      width: 328px;
      height: 400px;
    }
  }
  &-image {
    &_desktop {
      border-radius: 24px;
      width: 100%;
      @include bpm-xs {
        display: none;
      }
    }
    &_mobile {
      width: 328px;
      height: 400px;
      border-radius: 16px;
      display: none;
      @include bpm-xs {
        display: block;
      }
    }
  }
  &-button {
    &_desktop {
      width: 409px;
      position: absolute !important;
      bottom: 25px;
      left: 35px;
      @include bpm-md {
        bottom: 10px;
        left: 30px;
      }
      @include bpm-xs {
        display: none;
      }
    }
    &_mobile {
      width: 328px;
      position: absolute !important;
      bottom: 20px;
      left: 0px;
      display: none;
      @include bpm-xs {
        display: flex;
        justify-content: center;
      }
    }
  }
}
