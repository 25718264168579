.stripe-modal {
  .MuiDialog-container.MuiDialog-scrollPaper {
    .MuiPaper-root {
      align-items: center;
      padding: 3rem 0;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      width: 42.5rem;
    }
  }
  //   display: flex;
  //   width: 50rem;

  &__top {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
    padding: 0 0 0 2rem;
  }
  &__left {
    max-width: 20rem;
  }
}
