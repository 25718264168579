.game-window {
  &__wrapper {
    padding: 0 7rem 2rem 7rem;
    margin: 4rem auto 0 auto;
    max-width: 1100px;
    &.gamev2 {
      padding: 0 7rem 0 7rem;
      margin-top: 0px;
      @include bpm-sm {
        padding: 0 2rem 0 2rem;
      }
    }
    @include bpm-md {
      margin-top: 0;
    }
    @include bpm-sm {
      padding: 0 2rem 2rem 2rem;
    }
  }
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
  &.version-2 {
    grid-gap: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 400px;
    margin: auto;
  }

  @include bpm-md {
    grid-gap: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 400px;
    margin: auto;
  }
  &__left {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    // width: 500px;
    &--image {
      width: 100%;
      border-radius: 1.5rem;
      margin-bottom: 2rem;
    }
    &--title {
      color: #333;
      padding: 0 1.5rem;
      font-family: Poppins;
      font-size: 24px;
      font-weight: 800;
      line-height: 36px;
      letter-spacing: 0px;
      text-align: center;
    }
    &--prompt {
      text-align: left;
      padding: 0 1.5rem;
      font-size: 1.6rem;
      color: #666;
      font-weight: 500;
    }
    &__hintContainer {
      width: 100%;
      height: 3.5rem;
      display: flex;
      border-radius: 10rem;
      background-color: #e7e7e7;
      transition: width 2s;
      margin-top: 1.6rem;
      &.hide {
        background-color: #fff;
        transition: width 2s;
      }
      &__icon {
        width: 3.6rem;
        height: 3.6rem;
        background-color: #ededed;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &--p-text {
        font-family: Poppins;
        font-size: 14px;
        margin-left: 23px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: #333333;
        display: flex;
        align-items: center;
        &.hide {
          display: none;
        }
      }
    }

    @include bpm-md {
      position: relative;
      margin-bottom: 1rem;
      &--content {
        display: none;
        position: absolute;
        bottom: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 0 0 1.5rem 1.5rem;
        padding: 0.5rem;
      }
      &--image {
        margin-bottom: 0;
      }
      &--title {
        font-size: 1.6rem;
        color: #fff;
        padding: 0 0.5rem;
        margin-bottom: 0.5rem;
      }
      &--prompt {
        font-size: 1.2rem;
        color: #fff;
        padding: 0 0.5rem;
      }
    }
    &--hintIcon {
      position: absolute;
      top: -6rem;
      left: 1rem;
      max-width: 3rem;
      @include bpm-md {
        max-width: 2.75rem;
        max-width: 2.75rem;
        top: -3.75rem;
      }
    }
  }
  &__right {
    @include bpm-md {
      width: 100%;
    }
    &--textbox {
      overflow-y: hidden;
      background-color: #fff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
      -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
      border-radius: 1.5rem;
      padding: 2rem 1.5rem;
      height: 100%;
      @include bpm-md {
        box-shadow: none;
        -webkit-box-shadow: none;
        height: 20rem;
      }
      @include bpm-sm {
        height: 15rem;
      }
      &-transcript {
        font-size: 1.8rem;
        line-height: 2.5rem;
        color: #333;
        font-weight: 500;
        text-align: left;
        &--orange {
          color: #e47762;
        }
        &--red {
          color: #ff522f;
        }
      }
    }
    &--editbox {
      overflow-y: hidden;
      background-color: #fff;
      border: none;
      outline: none;
      resize: none;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
      -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
      border-radius: 1.5rem;
      padding: 2rem 1.5rem;
      height: 100%;
      width: 46.5rem;
      font-size: 1.8rem;
      line-height: 2.5rem;
      color: #333;
      font-weight: 500;
      text-align: left;
      @include bpm-md {
        box-shadow: none;
        -webkit-box-shadow: none;
        height: 20rem;
        width: 100%;
      }
      @include bpm-sm {
        height: 15rem;
        width: 100%;
      }
      &-transcript {
        font-size: 1.8rem;
        line-height: 2.5rem;
        color: #333;
        font-weight: 500;
        text-align: left;
        &--orange {
          color: #e47762;
        }
        &--red {
          color: #ff522f;
        }
      }
    }
  }
}
