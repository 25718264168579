.spark-booking-input-box {
  &__wrapper {
    width: 95%;
    margin-bottom: 1.5rem;
  }
  position: relative;
  &__title {
    position: absolute;
    font-size: 1.3rem;
    color: #333;
    left: 2rem;
    font-weight: 500;
    background-color: #fff;
    top: -0.6rem;
    padding: 0 0.5rem;
  }
  .input-box {
    height: 5.5rem;
    width: 100%;
    border: none;
    font-size: 1.6rem;
    outline: none;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(75, 0, 188, 0.08);
    -webkit-box-shadow: 0px 0px 8px rgba(75, 0, 188, 0.08);
    -webkit-appearance: none;
    border-radius: 8px;
    padding: 0 1rem;
    &.active {
      border: 2px solid #f2b65a;
      &::placeholder {
        display: none;
        color: transparent;
      }
    }
    &.error {
      border-bottom: 4px solid #c84343;
    }
  }
  &__error-text {
    color: #c84343;
    display: inline-block;
    font-size: 1.2rem;
    margin: 0.5rem 0;
  }
  &.number-input {
    &::after {
      content: "";
      position: absolute;
      height: 2rem;
      width: 2rem;
      top: 1.7rem;
      right: 1.5rem;
      background: url("../../../../public/assets/whatsapp.svg") center
        center/contain no-repeat;
    }
  }
}
