.cart-card {
  &__wrapper {
    height: 12rem;
    margin: 1rem auto;
    padding: 1rem 2rem;
    border-radius: 0.8rem;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    -webkit-appearance: none;
    &--small {
      width: 100%;
    }
    &--large {
      width: 100%;
      padding: 1rem;
    }
    &--no-price-qty {
      margin: 1rem 0;
      width: 32rem;
      height: 10rem;
      padding: 2rem;
    }
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  &__name {
    font-size: 1.6rem;
    color: #333;
    font-weight: 800;
  }
  &__category {
    font-size: 1.5rem;
    font-weight: 400;
    color: #666;
  }
  &__age-group {
    font-size: 1.3rem;
    font-weight: 700;
    color: #666;
    // margin: 16px 0;
  }
  &__qty-price {
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .quantity {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 8rem;
      img {
        width: 2.2rem;
        cursor: pointer;
      }
      span {
        font-size: 1.4rem;
        color: #666;
        font-weight: 800;
      }
    }
    .price {
      font-size: 1.6rem;
      font-weight: 800;
      display: flex;
      align-items: center;
      .currency-icon {
        margin-right: 5px;
        height: 1.6rem;
      }
    }
  }
  &--large {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    &-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .cart-card__name {
      font-size: 1.7rem;
      margin-top: 1.5rem;
      span {
        color: #666;
        font-size: 1.7rem;
        // padding-left: 1.2rem;
        position: relative;
       
        // &::before {
        //   content: "";
        //   position: absolute;
        //   left: 0.2rem;
        //   top: 50%;
        //   height: 5px;
        //   width: 5px;
        //   border-radius: 3rem;
        //   background-color: #666;
        //   transform: translateY(-50%);
        // }
      }
    }
    .cart-card__sessions {
      color: #666;
        font-size: 1.5rem;
        // padding-left: 1.2rem;
        position: relative;
        margin: 12px 0;

    }
    .cart-card__course-duration {
      margin-top: 3rem;
    }
    .cart-card__batch {
      margin-top: 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      span {
        font-size: 1.5rem;
        padding: 0.3rem 0;
        color: #333;
        &:first-child {
          font-weight: 700;
        }
        &:last-child {
          font-weight: 700;
        }
      }
    }

    .cart-card__qty-price {
      flex-direction: column;
      width: max-content;
      height: 100%;
      justify-content: space-between;
      .quantity {
        width: 7.5rem;
      }
      .price {
        font-size: 1.8rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        .currency-icon {
          height: 1.4rem;
          margin-right: 0.2rem;
        }
        .slashed-price {
          font-size: 1.8rem;
          transform: scale(0.9);
          font-weight: 500;
          position: relative;
          &::after {
            content: "";
            height: 1px;
            width: 130%;
            background-color: #000;
            position: absolute;
            top: 50%;
            left: -15%;
          }
        }
      }
    }
    .cart-card__category {
      margin: 0.25rem 0;
    }
  }
  &--no-price-qty {
    .cart-card__course-duration {
      margin-top: 1rem;
    }
  }
}
