.spelling-bee-check-results {
  &_wrapper {
    &-desktop {
      display: flex;
      flex-direction: column;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 24px;
      margin: 4rem auto 2rem auto;
      width: 992px;
      //   height: 476px;
      @include bpm-md {
        width: 742px;
        // height: 216px;
        margin: 2rem auto 2rem auto;
      }
      @include bpm-xs {
        display: none;
      }
    }
    &-mobile {
      display: none;
      @include bpm-xs {
        display: flex;
        flex-direction: column;
      }
    }
  }
  &-desktop {
    &-top {
      width: 100%;
      height: 305px;
      @include bpm-md {
        height: 216px;
      }
      &-image {
        width: 100%;
        height: 100%;
        border-radius: 24px 24px 0px 0px;
      }
    }
    &-bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 2rem 4rem;
      &-left {
        &-inputBox {
          &:focus {
            outline: none;
          }
          &::placeholder {
            font-family: "Lato";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: #999999;
          }
          background: #ffffff;
          border: 1px solid rgba(153, 153, 153, 0.4);
          box-shadow: 0px 4px 5px rgba(51, 51, 51, 0.08);
          border-radius: 8px;
          width: 252px;
          height: 56px;
          font-family: "Lato";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          color: #333333;
          padding-left: 1.5rem;
          margin-bottom: 2rem;
        }
      }
      &-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &-top {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          &-text {
            font-family: "Lato";
            font-style: normal;
            font-weight: 900;
            font-size: 28px;
            line-height: 34px;
            color: #333333;
          }
          &-profileIcon {
            width: 50px;
            height: 50px;
            border-radius: 100%;
            object-fit: contain;
          }
        }
        &-bottom {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          &-id {
            &_wrapper {
              height: 52px;
              background: #666666;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 8px;
              display: flex;
              flex-direction: row;
              align-items: center;
              cursor: pointer;
            }
            &-text {
              font-family: "Lato";
              font-style: normal;
              font-weight: 700;
              font-size: 30px;
              line-height: 36px;
              color: #ffffff;
              height: 100%;
              vertical-align: middle;
              background-color: #333333;
              border-radius: 8px;
              padding: 5px 1rem 0rem 1rem;
            }
            &-icon {
              width: 24px;
              height: 24px;
              margin: 0rem 2rem;
            }
          }
        }
      }
    }
  }
  &-mobile {
    &-checkFriend {
      display: flex;
      flex-direction: column;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 24px;
      margin: 2rem auto 2rem auto;
      max-width: 328px;
      &-topImage {
        width: 100%;
        height: 254px;
        border-radius: 24px 24px 0px 0px;
      }
      &-bottom {
        padding: 2rem 1rem;
      }
    }
    &-shareDetails {
      display: flex;
      flex-direction: column;
      padding: 2rem 2rem;
      margin: 1rem auto;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 24px;
      width: 100%;
      &-topWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &-leftIcon {
          width: 40px;
          height: 48px;
        }
        &-rightIcon {
          width: 48px;
          height: 48px;
        }
      }
      &-mainText {
        font-family: "Lato";
        font-style: normal;
        font-weight: 900;
        font-size: 32px;
        line-height: 38px;
        color: #333333;
        text-align: center;
        margin: 2rem 0rem;
      }
      &-idWrapper {
        margin: 0 auto 2rem auto;
      }
    }
  }
}
