.spark-booking {
  &__wrapper {
    &.confirmation-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      // margin-top: 2.4rem;
      margin-top: 0;
      padding: 0rem 1.5rem 1rem;
      .MuiCircularProgress-colorPrimary {
        color: #f3705b;
        svg {
          circle {
            stroke-width: 5;
          }
        }
      }
      .confirmation-timer {
        display: flex;
        align-items: center;
        font-size: 1.6rem;
        margin-top: 3rem;
        color: #333;
        > div {
          margin-left: 1rem;
        }
      }
      .primary-button {
        margin-top: 1rem;
      }
    }
  }
  &__booking-confirmation {
    width: 100%;
    margin-top: 0.4rem;
    &--header {
      font-size: 2rem;
      font-weight: 700;
      display: block;
      margin: 1.5rem 0;
      color: #333;
    }
    &--content {
      font-size: 1.6rem;
      color: #666;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.3rem 1.1rem 0.3rem 0;
      span {
        span {
          // font-size: 1.4rem;
          color: #333;
          font-weight: 700;
        }
        a {
          color: #e57b62;
          font-size: 1.4rem;
          font-weight: 700;
          &:hover {
            color: #e57b62;
          }
        }
      }
    }
    &--time-to {
      font-size: 2rem;
      font-weight: 700;
      margin: 1.5rem 0;
      color: #333;
      span {
        display: block;
      }
    }
    &--bookings {
      background: #f7eee7;
      box-shadow: 0px 2.98833px 2.98833px rgba(0, 0, 0, 0.25);
      -webkit-box-shadow: 0px 2.98833px 2.98833px rgba(0, 0, 0, 0.25);
      -webkit-appearance: none !important;
      border-radius: 16px;
      padding: 2.5rem 3rem;
      .title {
        font-size: 1.4rem;
        color: #333;
        font-weight: 700;
        margin: 0.5rem 0;
        span {
          font-weight: 500;
          color: #999;
        }
      }
      &--slots {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        .single-slot {
          width: 100%;
          font-size: 1.4rem;
          color: #333;
          padding: 2rem 0;
          border-bottom: 1px solid #aaa;
          span {
            font-weight: 700;
          }
          &:last-child {
            border: none;
            padding-bottom: 1rem;
          }
        }
      }
    }
    &--edit {
      cursor: pointer;
      font-size: 1.6rem;
      font-weight: 700;
      color: #e47762;
      text-decoration: underline;
      text-align: center;
      transform: translateX(-35%);
      margin-top: 2.5rem;
      &:hover {
        color: #e47762;
        text-decoration: underline;
      }
    }
    &--app_booking {
      width: 90%;
      padding: 2rem 3.5rem;
      background: #ffffff;
      border-radius: 16px;
      margin-top: 2rem;
      &---headText {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.149416px;
        color: #333333;
      }
      &---media {
        display: flex;
        flex-direction: row;
        align-items: center;
        &----text {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.149416px;
          color: #666666;
          &.bold {
            font-weight: 700;
            color: #333333;
          }
        }
        &----link {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.149416px;
          color: #e57b62;
          margin-left: 0.5rem;
        }
      }
    }
  }
}
