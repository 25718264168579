@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Display:wght@600&family=Purple+Purse&display=swap");
.studio-upload-modal {
  &_wrapper {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 120vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
  }
  padding: 2rem;
  border-radius: 16px;
  background: #f6eedb;
  opacity: 1;
  max-width: 524px;
  // min-height: 558px;
  position: relative;
  display: flex;
  flex-direction: column;
  &_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    // gap: 5px;
    align-items: center;
    span {
      color: #333;
      text-align: center;
      font-size: 32px;
      font-family: Lato;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
    p {
      color: #333;
      text-align: center;
      font-size: 20px;
      font-family: Lato;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    img {
      max-width: 280px;
      max-height: 350px;
      flex-shrink: 0;
    }
    &-text {
      align-items: center;
      color: #000632;
      text-align: center;
      font-size: 40px;
      font-family: Purple Purse !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &-uploadbtn {
      border-radius: 56px;
      border: 1px solid rgba(153, 153, 153, 0.5);
      background: rgba(255, 255, 255, 0.6);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      max-width: 432px;
      height: 52px;
    }
  }
}
