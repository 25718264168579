.rubrics_tooltip {
  &_wrapper {
    position: absolute;
    right: 5px;
    bottom: -5px;
    @include bpm-xs {
      bottom: -10px;
    }

    z-index: 1000;
  }
  &_icon {
    cursor: pointer;
    width: 12px;
    height: 12px;
    z-index: 1000;
  }
  &_content {
    margin-top: 1rem;
    position: relative;

    &-body {
      position: absolute;
      left: -1rem;
      width: 151px;
      //   padding-top: 1rem;
      &--text {
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.3),
          0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);
        padding: 1rem 1rem;
        font-family: "Lato";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #333333;
        &::before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          bottom: 100%;
          left: 0.5em; // offset should move with padding of parent
          border: 0.75rem solid transparent;
          border-top: none;
          // looks
          border-bottom-color: #fff;
          filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
        }
      }
    }
  }
}
