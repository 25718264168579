.certificate-preview {
  &-container {
    height: 230px;
    width: 300px;
    position: relative;
    img {
      border-radius: 16px;
      width: 100%;
      height: 100%;
    }
  }
  &-info-container {
    position: absolute;
    top: 110px;
    left: 15px;
    font-size: 8px;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .certificate-info_holder-name {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;

      color: #41495a;
    }
  }

  &-download-section {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 8px;
    position: absolute;
    bottom: 0px;
    .primary-button {
      border: none;
      width: 100%;
      padding: 10px;
      box-shadow: none;
    }
  }

  &_overlay-container {
    position: absolute;
    bottom: 0;
    background-color: rgb(51 51 51 / 65%);
    color: #fff;
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 16px;
    overflow: hidden;
    &-child {
      position: absolute;
      bottom: 10px;
      font-size: 16px;
      text-align: left;
      font-weight: 700;
      width: 100%;
      &_button-container {
        width: 100%;
        padding-bottom: 10px;
      }
      &_thin-text {
        padding-bottom: 10px;
        font-weight: 500;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
      &_bold-text {
        font-weight: 800px;
        padding-bottom: 10px;
        line-height: 25px;
        letter-spacing: 0.5px;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
    .primary-button {
      border: none;
      width: 85%;
      padding: 10px;
      box-shadow: none;
    }
  }
  &_progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 75%;
    height: 8px;
    background: linear-gradient(90deg, #f3705b 0%, #fcb444 100%);
    border-radius: 16px;
  }
}
