.usp-section {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4rem;
    @include bp-sm {
      margin-bottom: 10rem;
    }
    .square {
      height: 30rem;
      width: 30rem;
      background-color: red;
    }
    .usp-card {
      position: relative;
      max-width: 40rem;
      &__header {
        position: absolute;
        font-size: 2.2rem;
        top: 50%;
        left: 25%;
        text-align: center;
      }
      &__content {
        position: absolute;
        font-size: 1.4rem;
        top: 60%;
        left: 23%;
        width: 20rem;
        text-align: center;
      }
      @include bpm-lg {
        &__header {
          font-size: 2rem;
          top: 50%;
          left: 24%;
        }
        &__content {
          top: 60%;
          left: 19%;
        }
      }
      &-reverse {
        .usp-card__header {
          top: 30%;
        }
        .usp-card__content {
          top: 40%;
        }
        @include bpm-md {
          img {
            transform: rotate(180deg);
          }
          .usp-card__header {
            top: 50%;
          }
          .usp-card__content {
            top: 60%;
          }
        }
      }
    }
    .swiper-slide {
      margin-bottom: 6rem;
    }
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__top,
  &__middle,
  &__bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  &__top {
    &--card-1 {
      img {
        width: 40rem;
      }
    }
    &--card-2 {
      img {
        width: 40rem;
      }
      .usp-card__header {
        left: 30%;
      }
      .usp-card__content {
        left: 24%;
      }
    }
    &--card-3 {
      img {
        width: 40rem;
      }

      .usp-card__header {
        left: 26%;
      }
      .usp-card__content {
        left: 27%;
      }
    }
    @include bpm-lg {
      &--card-1 {
        img {
          width: 33rem;
        }
      }
      &--card-2 {
        img {
          width: 33rem;
        }
        .usp-card__header {
          left: 28%;
        }
        .usp-card__content {
          left: 21%;
        }
      }
      &--card-3 {
        img {
          width: 33rem;
        }

        .usp-card__header {
          top: 47%;
          left: 23%;
        }
        .usp-card__content {
          left: 23%;
        }
      }
    }
    @include bpm-md {
      &--card-2 {
        img {
          width: 32rem;
        }
      }
      &--card-3 {
        .usp-card__header {
          top: 47%;
          left: 23%;
        }
        .usp-card__content {
          top: 58%;
          left: 21%;
        }
      }
    }
  }
  &__middle {
    transform: translateY(-10rem);
    &--card-1 {
      img {
        width: 40rem;
      }
      .usp-card__header {
        top: 54%;
        left: 28%;
      }
      .usp-card__content {
        top: 64%;
      }
    }
    &--card-2 {
      img {
        width: 33rem;
      }
      .usp-card__header {
        top: 55%;
        left: 24%;
      }
      .usp-card__content {
        top: 65%;
        left: 20%;
      }
    }
    @include bpm-lg {
      transform: translateY(-7rem);
      &--card-1 {
        img {
          width: 35rem;
        }
        .usp-card__header {
          top: 50%;
        }
        .usp-card__content {
          top: 60%;
        }
      }
      &--card-2 {
        img {
          width: 29rem;
        }
        .usp-card__header {
          top: 55%;
          left: 22%;
        }
        .usp-card__content {
          top: 65%;
          left: 17%;
        }
      }
    }
    @include bpm-md {
      &--card-1 {
        img {
          width: 31rem;
        }
        .usp-card__header {
          top: 52%;
          left: 23%;
        }
        .usp-card__content {
          top: 62%;
          left: 15%;
        }
      }
      &--card-2 {
        img {
          width: 27rem;
        }
        .usp-card__header {
          top: 54%;
          left: 20%;
        }
        .usp-card__content {
          top: 64%;
          left: 12%;
        }
      }
    }
  }
  &__bottom {
    transform: translateY(-25rem);
    margin-bottom: -20rem;
    &--card-1 {
      img {
        width: 35rem;
      }
      .usp-card__header {
        top: 55%;
        left: 33%;
      }
      .usp-card__content {
        top: 65%;
        left: 22%;
      }
    }
    @include bpm-lg {
      &--card-1 {
        img {
          width: 30rem;
        }
        .usp-card__header {
          top: 55%;
          left: 30%;
        }
        .usp-card__content {
          top: 65%;
          left: 17%;
        }
      }
    }
    @include bpm-md {
      &--card-1 {
        img {
          width: 28rem;
        }
        .usp-card__header {
          top: 55%;
          left: 29%;
        }
        .usp-card__content {
          top: 65%;
          left: 13%;
        }
      }
    }
  }
}
