.studentBioCard {
  &__wrapper {
    //! Padding added on desktops
    padding: 50px 100px;
    display: flex;
    //! Set to display on center since left align seems weird
    margin: 0 auto;
    margin-bottom: 50px;
    //! Flex direction set to column on mobiles
    @include bpm-lg {
      flex-direction: column-reverse;
    }
    //! Resizing padding on the wrapper for mobiles
    //! items aligned to center
    @include bpm-xs {
      padding: 10px 20px;
      align-items: center;
    }
    width: max-content;
    background: linear-gradient(
      114.28deg,
      #fffbf1 -12.61%,
      rgba(255, 255, 255, 0) 94.49%
    );
    filter: drop-shadow(50px 60px 100px rgba(0, 0, 0, 0.05));
    backdrop-filter: blur(70px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 24px;
    border: 2px solid white;
  }
  //! this is the left div of the card
  &--left {
    width: 500px;
    @include bpm-xs {
      width: 300px;
    }
    //! CSS for the image
    .studentBioCard-image {
      //! Width of image set to 263px on mobiles
      // width: 400px;

      @include bpm-xs {
        width: 263px;
      }
    }
    p {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.2px;
      text-transform: capitalize;
    }
  }
  &--right {
    display: flex;
    flex-direction: column;
    text-align: left;
    //! Margin left added on desktops
    margin-left: 100px;
    //! Margin left removed on ipads and lower
    //! Text aligned to center on mobiles and ipads
    @include bpm-lg {
      margin-left: 0px;
      //  align-items: center;
      margin: 0 auto;
      align-items: center;
    }
    @include bpm-xs {
      margin-bottom: 20px;
      // align-items: baseline;
      align-items: flex-start;
    }

    // text-align: left;
    h1 {
      font-family: Lato;
      font-size: 32px;
      font-style: normal;
      font-weight: 900;
      line-height: 38px;
      letter-spacing: 0.20000000298023224px;
      width: max-content;
      padding-bottom: 10px;
      margin-bottom: 15px;
      border-bottom: 2px solid #666666;

      @include bpm-xs {
        font-size: 24px;
      }
    }
    h2 {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.2px;
      color: #333333;
      margin-top: 20px;
    }
    p {
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.20000000298023224px;
      //! word break added for p tag
      word-wrap: break-word;
      //! font size reduced on mobiles and max width set for word break to work
      @include bpm-xs {
        font-size: 16px;
        max-width: 290px;
      }

      &:focus {
        outline: none;
      }
    }

    .primary-button {
      margin: 20px 0px !important;
      width: 110px;
      padding: 15px 10px;
    }
    &-hobby_Motto {
      font-family: Lato;
      font-style: italic;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: #e68673;
    }
    &-present {
      &-hobby {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;

        letter-spacing: 0.2px;
        text-transform: capitalize;

        color: #333333;
      }
      &-motto {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.2px;
        text-transform: capitalize;
        color: #999999;
        margin-top: 5px;
        &.qoutes {
          &:before {
            content: open-quote;
          }
          &:after {
            content: close-quote;
          }
        }
      }
    }
    &-inputBox {
      border: none;
      background-color: #f7eee7;
      @include bpm-sm {
        background: linear-gradient(
          114.28deg,
          #fffbf1 -12.61%,
          rgba(255, 255, 255, 0) 94.49%
        );
      }
      width: 300px;
      margin-top: 15px;
      //!Font for input text
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.2px;
      color: #333333;
      margin-top: 18px;
      //! Styling for placeholder
      &::placeholder {
        font-family: Lato;
        font-style: italic;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        /* identical to box height */
        letter-spacing: 0.2px;
        color: #999999;
      }
      //! removing input box outline when focusing
      &:focus {
        outline: none;
      }
      //! disabled class shows not-allowed cursor when hovering
      &.disabled {
        cursor: not-allowed;
      }
    }
  }
}
