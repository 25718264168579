.live_class_app_download_card {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: #28b7ef;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  //   padding: 25px 30px;
  padding: 15px 70px 15px 30px;
  margin-bottom: 30px;
  @include bpm-md {
    flex-direction: column;
    align-items: center;
    padding: 30px 10px;
    transform: scale(0.9);
    margin-top: -50px;
  }
  @include bpm-xs {
    transform: scale(1);
    margin-top: 0px;
  }

  &-left {
    width: 50%;
    @include bpm-md {
      width: 100%;
    }
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    text-align: center;
    &--image {
      width: 65%;
    }
  }
  &-right {
    margin-left: 100px;
    padding-top: 20px;
    text-align: left;
    flex: 1;
    @include bpm-xl {
      margin-left: 70px;
    }
    @include bpm-lg {
      margin-left: 65px;
    }

    @include bpm-md {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0px 40px;
    }

    &--mainText {
      font-family: "Lato";
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      display: flex;
      align-items: center;
      color: #ffffff;
      margin-bottom: 10px;
      @include bpm-xs {
        font-size: 20px;
        text-align: center;
      }
    }
    &--image {
      width: 214px;
      height: 64px;
      margin-top: 30px;
      @include bpm-xs {
        margin-top: 15px;
      }
      cursor: pointer;
    }
  }
}
