.purchasedOrderCard {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(
      114.28deg,
      #fdcd4c -12.61%,
      rgba(255, 255, 255, 0) 94.49%
    );

    box-shadow: 50px 60px 100px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 15px 15px;
    margin-bottom: 15px;
  }
  &--left {
    display: flex;
    img {
      width: 91px;
      height: 72px;
      border-radius: 5px;
    }
    &-details {
      display: flex;

      flex-direction: column;
      margin-left: 2rem;
      h2 {
        font-family: Lato;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 22px;
        /* identical to box height */
        color: #333;
        @include bpm-xs {
          font-size: 20px;
          line-height: 19px;
        }
      }
      p {
        font-family: Lato;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px;
        color: #333;
        margin-top: 0.9rem;
      }
    }
  }
  &--right {
    display: flex;
    align-items: flex-end;
    p {
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0px;
      color: #666666;
      @include bpm-xs {
        font-size: 13px;
      }
    }
  }
}
