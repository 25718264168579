.faq-section {
  &__wrapper {
    margin: 0 7rem 7rem 7rem;
    text-align: left;
    @include bpm-sm {
      margin: 0 3rem 7rem 3rem;
    }
  }
  &__header {
    font-size: 5rem;
    color: #333;
    font-weight: 900;
    text-align: center;
    margin-bottom: 5rem;
    @include bpm-xs {
      font-size: 3.5rem;
      padding: 0 2rem;
    }
  }
  &__accordion {
    * {
      font-family: "Lato", sans-serif !important;
    }
    // margin: 2rem 0;
    // border-radius: 3rem !important;
    border: none !important;
    border-bottom: 1px solid #666 !important;

    background: transparent !important;
    // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
    @include bpm-xs {
      // margin: 1.5rem 0;
    }
    &--question {
      //   background: linear-gradient(90deg, #f3705b 0%, #fcb444 100%) !important;
      // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12) !important;
      background: transparent !important;
      color: #333 !important;
      // border-radius: 10rem !important;
      padding-left: 0 !important;
      border: none !important;
      @include bpm-xs {
        min-height: 4rem !important;
        > div {
          margin: 1rem 0 !important;
        }
      }
      p {
        font-weight: 700 !important;
        font-size: 1.8rem;
        @include bpm-xs {
          font-size: 1.6rem;
        }
      }
      // &-83DEF0 {
      //   background: linear-gradient(
      //     111.29deg,
      //     #83def088 -1.83%,
      //     rgba(255, 255, 255, 0) 109.95%
      //   ) !important;
      // }
      // &-8469C0 {
      //   background: linear-gradient(
      //     111.29deg,
      //     #8469c088 -1.83%,
      //     rgba(255, 255, 255, 0) 109.95%
      //   ) !important;
      // }
      // &-FFC142 {
      //   background: linear-gradient(
      //     111.29deg,
      //     #ffc14288 -1.83%,
      //     rgba(255, 255, 255, 0) 109.95%
      //   ) !important;
      // }
    }
    &--answer {
      border: none !important;
      // padding: 2rem !important;
      background: transparent !important;
      p {
        font-size: 1.6rem;
        @include bpm-xs {
          font-size: 1.4rem;
        }
      }
    }
  }
}
