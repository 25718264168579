//! CSS For The NavBar *****************************************

.spark-dashboard {
  //! Height set to 100vh for ipads and 100% for mobiles to fill out the page
  &-layout__wrapper {
    background-color: #f7eee7;
    height: 100%;
    padding-bottom: 50px;

    @include bp-xxs {
      height: 100vh;
      padding-bottom: 10px;
    }
    // margin-bottom: 100px;
  }
  //! CSS for the login div ( button)
  &-login {
    padding-top: 300px;
    width: max-content;
    margin: 0 auto;
  }
  &-navbar {
    position: fixed;
    z-index: 100;
    opacity: 0.9;
    width: 100%;
    background: #ffffff;
    height: 70px;
    display: flex;
    align-items: center;
    box-shadow: 0px 1px 4px 0px #00000026;
    justify-content: space-between;
    // border-bottom-left-radius: 12px;
    // border-bottom-right-radius: 12px;
    top: 0px;
    &--rightWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      li {
        list-style-type: none;
        @include bpm-xs {
          display: none;
        }
      }
    }
    &__affiliate-right {
      display: flex;
      justify-content: space-around;
      gap: 3rem;
      margin-right: 4rem;
      @include bpm-xs {
        gap: 1.2rem;
        margin-right: 2rem;
      }
      &--support {
        font-family: Lato;
        font-size: 17px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        color: #333333;
        cursor: pointer;
        @include bpm-xs {
          font-size: 15px;
        }
      }
      &--logout {
        font-family: Lato;
        font-size: 17px;
        font-weight: 700;
        cursor: pointer;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        color: #333333;
        @include bpm-xs {
          font-size: 15px;
        }
      }
    }

    &.gsv-welcome-page {
      background-color: transparent;
      box-shadow: none;
      justify-content: flex-start;
      margin-top: 2rem;
    }
    //! CSS element ( hamburg) For toggling the sidebar ******************
    &--toggle {
      margin-left: 30px;
      cursor: pointer;
      filter: invert(61%) sepia(11%) saturate(4929%) hue-rotate(322deg)
        brightness(100%) contrast(80%);
      @include bpm-md {
        width: 3rem;
        margin-left: 20px;
      }
    }
    //! CSS Element ( logo on the navbar)****************
    &--logo {
      width: 13rem;
      margin-left: 50px;
      &.game {
        width: auto;
      }
      cursor: pointer;
      @include bpm-md {
        width: 10rem;
        margin-left: 20px;
      }
    }
    &--coin {
      background: #fbf8f9;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 7rem;
      margin-left: 10px;
      height: 4.2rem;
      span {
        color: #fcb260;
        font-weight: 700;
        font-size: 1.6rem;
        display: inline-block;
        margin-left: 1rem;
      }
      svg {
        width: 3rem;
      }
    }
    &--play {
      display: flex;
      align-items: center;
      &.game {
        margin-right: 3rem;
        @include bpm-xxs {
          margin-right: 2rem;
        }
      }
    }
    //! CSS For Right Section Of the Navbar ( Uses Flex and list for items)
    &--right {
      // width: 100%;
      &--list {
        display: flex;
        align-items: center;

        &-element {
          //! This section is the css for the first element on the navbar ( the notification button)
          //! This is being suppressed right now and the navbar notif is hidden
          //! nth child 1 is set to profile button
          // &:nth-child(1) {
          //   cursor: pointer;
          //   @include bpm-xs {
          //     display: none;
          //   }
          //   .dropdown {
          //     display: none;
          //     flex-direction: column;
          //     position: absolute;
          //     background: white;
          //     border-radius: 12px;
          //     padding: 5px 15px;
          //     box-shadow: 0px 2px 6px rgba(91, 22, 170, 0.12),
          //       inset 1px 0px 3px rgba(255, 255, 255, 0.5);
          //     margin-top: 10px;
          //     //! css that reveals the notification/profile dropdown
          //     &.active {
          //       display: flex;
          //     }
          //   }
          // }
          a {
            font-size: 1.6rem;
            font-weight: 700;
            // color: #e47762;
          }
          //! This section is the css for the last element on the navbar ( the profile button)
          &:nth-child(1) {
            margin: 0px 20px;
            @include bpm-xs {
              margin: 0px 10px;
            }
            .profile__wrapper {
              display: flex;
              align-items: center;
              padding: 5px 15px 5px 5px;
              background: linear-gradient(
                270deg,
                #e47762 2.51%,
                #f2b65a 97.49%
              );
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 72px;
              cursor: pointer;
              p {
                font-family: "Lato";
                font-style: normal;
                font-weight: 900;
                font-size: 14px;
                line-height: 17px;
                color: #ffffff;
                padding: 0rem 1.5rem;
                @include bpm-lg {
                  margin: 5px 0px;
                }
              }
              .profile-person {
                &_wrapper {
                  width: 35px;
                  height: 35px;
                  background-color: #ffffff;
                  border-radius: 100%;
                }
                &-image {
                  width: 100%;
                  height: 100%;
                  border-radius: 100%;
                  object-fit: contain;
                }
              }
              .profile-arrow {
                transition: 0.5s;
                &.active {
                  transition: 0.5s;
                  transform: rotate(180deg);
                }
              }
            }
            .dropdown {
              display: none;
              flex-direction: column;
              position: absolute;
              background: white;
              border-radius: 12px;
              padding: 5px 15px;
              box-shadow: 0px 2px 6px rgba(91, 22, 170, 0.12),
                inset 1px 0px 3px rgba(255, 255, 255, 0.5);
              margin-top: 22px;
              margin-left: 10px;
              max-height: 500px;
              @include bpm-xs {
                max-height: 350px;
              }

              overflow-y: scroll;
              /* width */
              &::-webkit-scrollbar {
                width: 2px;
              }

              /* Track */
              &::-webkit-scrollbar-track {
                background: #f1f1f1;
                margin: 8px;
              }

              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: #fcb444;
              }

              /* Handle on hover */
              &::-webkit-scrollbar-thumb:hover {
                background: orange;
              }
              //! Scrollbar customization for firefox
              //! Limited customization
              scrollbar-width: thin;
              scrollbar-color: #fcb444 #f1f1f1;
              //! css that reveals the notification/profile dropdown
              &.active {
                display: flex;
                width: 120px;
                word-wrap: break-word;
                text-align: center;
                p {
                  margin: 10px 0px;
                  font-family: Lato;
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 14px;
                  letter-spacing: 0em;
                  &:hover {
                    background: -webkit-linear-gradient(
                      90deg,
                      #e47762 0%,
                      #f2b65a 98.07%
                    );
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
              }
              //! Css for highlighting active profile in profiles dropdown on navbar
              .profile-active {
                color: #f2b65a;
              }
            }
          }
        }
      }
    }
    &--hint {
      // position: relative;
      position: absolute;
      cursor: pointer;
    }

    &_spellingBee-challeng-live-text {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      div {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #00a912;
        margin-right: 8px;
      }
      @include bpm-sm {
        display: none;
      }
    }

    &_spellingBee-challenge-last-date {
      display: flex;
      gap: 8px;
      color: #000;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      &__text {
        color: "#ed0000";
        text-align: center;
      }
      @include bpm-sm {
        display: none;
      }
    }
  }

  &-content {
    // margin-top:70px;
    padding-top: 100px;
    text-align: center;
    background-color: #f7eee7;
    padding-bottom: 1.2rem;
    &.gsv-welcome-page {
      background-color: transparent;
    }
  }
}
.spark-play-content {
  text-align: left;
  height: 120vh;
  @include bpm-xxs {
    height: 100vh;
  }
  &.low-padding {
    padding: 0;
  }
}
.spark-gsv-content {
  text-align: left;
  height: auto;
}

//! Navigation is the default css for the hidden sidebar with only icons showing
//! navigation active is the fully expanded one / hover too

.navigation--sidebar {
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 80px;
  //! set height to 85 percent when all icons are back
  height: 770px;
  @include bpm-xs {
    height: 85%;
  }
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  transition: 0.5s;
  margin-top: 80px;
  // overflow: hidden;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  background: white;
  //! This is the css for the standalone logo on the bottom of the sidebar
  .sidebar-spark-logo {
    margin-left: 15px;
    margin-top: 25px;
  }
  //! WIDTH set to 0 percent to completely hide sidebar on mobile , can be toggled still with hamburg on navbar
  @include bpm-xl {
    width: 0%;
  }
  &-logo {
    display: none;
  }
  &-profile {
    display: none;
    &__wrapper {
      border-radius: 50%;
      text-align: center;
      margin-right: 15px;
      padding: 2px 7px;
      background-color: white;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      width: 55px;
    }
    &--profileDetails {
      p {
        // margin-top: 5px;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: -0.20000000298023224px;
        text-align: left;
        color: #999999;
      }
      h2 {
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.20000000298023224px;
        text-align: left;
        color: #000632;
      }
    }
    &--profilePic {
      width: 100%;
      border-radius: 0px;
    }
  }
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  //! Scrollbar removal for firefox
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  //! CSS for hover action over sidebar
  //! Padding Changed for ul to accomodate the profile details uptop
  //! Logo Changed from display none to block
  //! Profile change to grid to enable visibility
  &:hover {
    width: 270px;
    ul {
      padding: 0px 30px;
      transition: 0.5s;
      // margin: 40px;
    }
    //! This particular section of css is to remove the excessive padding that appears on top when the profile section of the sidebar is removed
    // .home {
    //   padding-top: 20px;
    // }
    //! CSS margin removal ( extra margin that was added for borders around icon when inactive)
    li {
      margin: 0px;
      transition: 0.5s;
    }
    .navigation--sidebar {
      &-logo {
        display: block;
        width: 10rem;
        margin-top: 50px;
        margin-left: 30px;
      }
      &-profile {
        display: grid;
        grid-template-columns: 0.3fr 0.7fr;
        margin-left: 30px !important;
        padding: 20px 0px;
      }
      //! support div enabled on hover
      &-support {
        &.active {
          display: flex;
        }
      }
    }
    //! HERE the logo on the bottom of the sidebar is hidden when on hover
    .sidebar-spark-logo {
      display: none;
      transition: 0.5s;
    }
    //! activeelement class targets the currently active li and sets bg color
    .activeLiElement {
      border-radius: 15px;
      background-color: #fffaee;
    }
  }
  //! CSS for When the sidebar is active - Triggered by clicking the hamburg icon on navbar
  //! Set width to full (i.e 270px) and change padding for ul to accomodate profile div uptop
  //! the profile div has a pic plus text (uses grid)

  &.active {
    z-index: 100;
    width: 280px;
    //! the logo at the bottom is hidden when active and the full logo is displayed
    .sidebar-spark-logo {
      display: none;
      transition: 0.5s;
    }
    ul {
      padding: 10px 30px 15px 30px;
      transition: 0.5s;
      // margin: 40px;
    }
    //! This particular section of css is to remove the excessive padding that appears on top when the profile section of the sidebar is removed
    // .home {
    //    padding-top: 20px;
    // }
    //! CSS margin removal ( extra margin that was added for borders around icon when inactive)
    li {
      margin: 0px;
      transition: 0.5s;
    }

    .navigation--sidebar {
      &-logo {
        display: block;
        width: 10rem;
        margin-top: 50px;
        margin-left: 30px;
      }
      &-profile {
        display: grid;
        grid-template-columns: 0.3fr 0.7fr;
        margin-left: 30px !important;
        padding: 20px 0px;
        align-items: center;
      }
    }
    //! activeelement class targets the currently active li and sets bg color
    .activeLiElement {
      border-radius: 15px;
      background-color: #fffaee;
    }
  }
  //! ***********************************************
}
//! CSS for the sidebar text and icons (made using unordered list (ul / li and a tag)
.navigation--sidebar {
  z-index: 100;
  .logout-icon {
    cursor: pointer;
  }
  & ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-left: 0px;
    // padding-top: 20px;
    transition: 0.5s;

    & li {
      position: relative;
      width: 100%;
      list-style: none;
      //! Margin set to 10px for implementing border-radius on icons
      margin: 10px;
    }
    & a {
      position: relative;
      display: block;
      width: 100%;
      display: flex;
      text-decoration: none;

      .icon {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 60px;
        height: 60px;
        line-height: 70px;
        text-align: center;

        //! CSS for border radius on sidebar icons when inactive - margin set for li to facilitate this
        // border: 1px solid orange;
        // border-radius: 16px;
        &.active {
          // filter: invert(74%) sepia(66%) saturate(1803%) hue-rotate(350deg) brightness(103%) contrast(95%);
          border-radius: 15px;
          background-color: #fffaee;
        }
      }

      .title {
        position: relative;
        display: block;
        padding: 0 10px;
        height: 60px;
        line-height: 60px;
        text-align: start;
        white-space: nowrap;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        color: #666666;
        &.active {
          color: orange;
          font-family: Lato;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          letter-spacing: -0.20000000298023224px;
        }
      }
      .refer-new-badge {
        width: 54px;
        height: 27px;
        background: #e68160;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 18px 22px 18px 18px;
        padding: 12px;
        border-radius: 8px;
        font-family: "Lato";
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.2px;
        color: #ffffff;
      }
      .spelling-bee-trophy-image {
        height: 32px;
        width: 26px;
        margin-top: 14px;
        position: relative;
        right: 6px;
      }
      .spelling-bee-red-dot {
        position: relative;
        bottom: 10px;
        right: 14px;
      }
    }
    //! CSS for support details div on sidebar
    .navigation--sidebar-support {
      display: flex;
      align-items: center;
      margin-top: 2rem;
      margin-left: 2rem;
      &-icons {
        display: flex;
        flex-direction: column;
        &-phoneIcon {
          margin-bottom: 1rem;
        }
      }
      &-details {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;
        &-phoneNumber {
          margin-bottom: 2rem;
        }
        &-email {
          text-decoration: underline;
        }
      }
      p {
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */
        letter-spacing: 0.2px;
        color: #666666;
      }
      &.active {
        display: none;
      }
    }
  }
}

.spark-game-hint {
  &__wrapper {
    width: 24rem;
    height: 8rem;
    position: absolute;
    top: -16rem;
    left: 5.5rem;
    background-color: rgba(237, 233, 255, 1);
    padding: 0.5rem;
    border-radius: 1rem;
    right: 0;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    @include bpm-md {
      // left: 45%;
      left: 18rem;
      top: -1rem;
      transform: translateX(-50%);
    }
  }
  &__swiper {
    .swiper-slide {
      // width: 20rem !important;
      // display: flex;
      // justify-content: center;
      // align-items: center;

      .single-hint {
        font-size: 1.4rem;
        color: #333;
        font-weight: 700;
        padding: 0 2.5rem;
        span {
          color: #e78261;
          font-weight: 500;
        }
      }
    }

    .swiper-pagination {
      top: 5.85rem;
      left: 0;
      width: 100%;
    }
  }
}
// ! for gsv banner
.spark-gsv-page {
  //! Height set to 100vh for ipads and 100% for mobiles to fill out the page
  &-layout__wrapper {
    background-color: transparent;
    height: 110vh;
    padding-bottom: 50px;

    @include bp-xxs {
      padding-bottom: 10px;
      height: 100vh;
    }
    // margin-bottom: 100px;
  }
  &__line {
    border-left: 1.2px solid #000000;
    height: 5rem;
    margin-left: 3rem;
  }

  &-navbar {
    position: fixed;
    z-index: 100;
    opacity: 0.9;
    width: 100%;
    background: #ffffff;
    height: 70px;
    display: flex;
    align-items: center;
    box-shadow: 0px 1px 4px 0px #00000026;
    justify-content: space-between;
    // border-bottom-left-radius: 12px;
    // border-bottom-right-radius: 12px;
    top: 0px;
    //! CSS element ( hamburg) For toggling the sidebar ******************
    &--toggle {
      margin-left: 30px;
      cursor: pointer;
      filter: invert(61%) sepia(11%) saturate(4929%) hue-rotate(322deg)
        brightness(100%) contrast(80%);
      @include bpm-md {
        width: 3rem;
        margin-left: 20px;
      }
    }
    //! CSS Element ( logo on the navbar)****************
    &--logo {
      width: 13rem;
      margin-left: 50px;
      cursor: pointer;
      @include bpm-md {
        width: 10rem;
        margin-left: 20px;
      }
    }
    &--coin {
      background: #fbf8f9;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 7rem;
      margin-left: 10px;
      height: 4.2rem;
      span {
        color: #fcb260;
        font-weight: 700;
        font-size: 1.6rem;
        display: inline-block;
        margin-left: 1rem;
      }
      svg {
        width: 3rem;
      }
    }
    &--play {
      display: flex;
      align-items: center;
    }

    &--hint {
      // position: relative;
      position: absolute;
      cursor: pointer;
    }
  }
  &-content {
    // margin-top:70px;
    padding-top: 100px;
    text-align: center;
    background-color: #f7eee7;
  }
}

.spark-game-page {
  padding: 4rem 5px;
  margin: 10rem 0 0;

  @include bpm-xxs {
    margin: 6rem 0 0;
    &.padding {
      padding: 3.2rem 5px;
    }
  }
  //! Height set to 100vh for ipads and 100% for mobiles to fill out the page
  &-layout__wrapper {
    // background-color: #f7eee7;
    height: 100%;
    padding-bottom: 50px;

    @include bp-xxs {
      height: 100vh;
      padding-bottom: 10px;
      margin: 4rem 0 0;
    }
    // margin-bottom: 100px;
  }
  //! CSS for the login div ( button)
  &-login {
    padding-top: 300px;
    width: max-content;
    margin: 0 auto;
  }
  &-navbar {
    position: fixed;
    z-index: 100;
    opacity: 0.9;
    width: 100%;
    background: #fbf8f9;
    height: 70px;
    display: flex;
    align-items: center;
    box-shadow: 0px 1px 4px 0px #00000026;
    justify-content: space-between;
    // border-bottom-left-radius: 12px;
    // border-bottom-right-radius: 12px;
    top: 0px;
    &.game {
      height: 65px;
    }
    &__p-heading {
      margin-left: 14px;
      font-family: Lato;
      font-size: 20px;
      font-weight: 800;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      margin-bottom: 5px;
    }
    &__affiliate-right {
      display: flex;
      justify-content: space-around;
      gap: 3rem;
      margin-right: 4rem;
      @include bpm-xs {
        gap: 1.2rem;
        margin-right: 2rem;
      }
      &--support {
        font-family: Lato;
        font-size: 17px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        color: #333333;
        cursor: pointer;
        @include bpm-xs {
          font-size: 15px;
        }
      }
      &--logout {
        font-family: Lato;
        font-size: 17px;
        font-weight: 700;
        cursor: pointer;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        color: #333333;
        @include bpm-xs {
          font-size: 15px;
        }
      }
    }

    &.gsv-welcome-page {
      background-color: transparent;
      box-shadow: none;
      justify-content: flex-start;
      margin-top: 2rem;
    }
    //! CSS element ( hamburg) For toggling the sidebar ******************
    &--toggle {
      margin-left: 30px;
      cursor: pointer;
      filter: invert(61%) sepia(11%) saturate(4929%) hue-rotate(322deg)
        brightness(100%) contrast(80%);
      @include bpm-md {
        width: 3rem;
        margin-left: 20px;
      }
    }
    //! CSS Element ( logo on the navbar)****************
    &--logo {
      width: 13rem;
      margin-left: 50px;
      cursor: pointer;
      @include bpm-md {
        width: 10rem;
        margin-left: 20px;
      }
    }
    &--coin {
      background: #fbf8f9;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 7rem;
      margin-left: 10px;
      height: 4.2rem;
      span {
        color: #fcb260;
        font-weight: 700;
        font-size: 1.6rem;
        display: inline-block;
        margin-left: 1rem;
      }
      svg {
        width: 3rem;
      }
    }
    &--play {
      display: flex;
      align-items: center;
    }
    //! CSS For Right Section Of the Navbar ( Uses Flex and list for items)
    &--right {
      // width: 100%;
      &--list {
        display: flex;
        align-items: center;

        &-element {
          //! This section is the css for the first element on the navbar ( the notification button)
          //! This is being suppressed right now and the navbar notif is hidden
          //! nth child 1 is set to profile button
          // &:nth-child(1) {
          //   cursor: pointer;
          //   @include bpm-xs {
          //     display: none;
          //   }
          //   .dropdown {
          //     display: none;
          //     flex-direction: column;
          //     position: absolute;
          //     background: white;
          //     border-radius: 12px;
          //     padding: 5px 15px;
          //     box-shadow: 0px 2px 6px rgba(91, 22, 170, 0.12),
          //       inset 1px 0px 3px rgba(255, 255, 255, 0.5);
          //     margin-top: 10px;
          //     //! css that reveals the notification/profile dropdown
          //     &.active {
          //       display: flex;
          //     }
          //   }
          // }
          a {
            font-size: 1.6rem;
            font-weight: 700;
            // color: #e47762;
          }
          //! This section is the css for the last element on the navbar ( the profile button)
          &:nth-child(1) {
            margin: 0px 20px;
            @include bpm-xs {
              margin: 0px 10px;
            }
            .profile__wrapper {
              display: flex;
              align-items: center;
              padding: 10px;
              border-radius: 100px;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              p {
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0px;
                background: -webkit-linear-gradient(
                  90deg,
                  #e47762 0%,
                  #f2b65a 98.07%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-left: 10px;
                margin-right: 15px;
                @include bpm-lg {
                  margin: 5px 0px;
                }
                width: 80px;
                text-align: center;
              }
              .profile-arrow {
                cursor: pointer;
                transition: 0.5s;
                &.active {
                  transition: 0.5s;
                  transform: rotate(180deg);
                }
              }
            }
            .dropdown {
              display: none;
              flex-direction: column;
              position: absolute;
              background: white;
              border-radius: 12px;
              padding: 5px 15px;
              box-shadow: 0px 2px 6px rgba(91, 22, 170, 0.12),
                inset 1px 0px 3px rgba(255, 255, 255, 0.5);
              margin-top: 22px;
              margin-left: 10px;
              max-height: 500px;
              @include bpm-xs {
                max-height: 350px;
              }

              overflow-y: scroll;
              /* width */
              &::-webkit-scrollbar {
                width: 2px;
              }

              /* Track */
              &::-webkit-scrollbar-track {
                background: #f1f1f1;
                margin: 8px;
              }

              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: #fcb444;
              }

              /* Handle on hover */
              &::-webkit-scrollbar-thumb:hover {
                background: orange;
              }
              //! Scrollbar customization for firefox
              //! Limited customization
              scrollbar-width: thin;
              scrollbar-color: #fcb444 #f1f1f1;
              //! css that reveals the notification/profile dropdown
              &.active {
                display: flex;
                width: 120px;
                word-wrap: break-word;
                text-align: center;
                p {
                  margin: 10px 0px;
                  font-family: Lato;
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 14px;
                  letter-spacing: 0em;
                  &:hover {
                    background: -webkit-linear-gradient(
                      90deg,
                      #e47762 0%,
                      #f2b65a 98.07%
                    );
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
              }
              //! Css for highlighting active profile in profiles dropdown on navbar
              .profile-active {
                color: #f2b65a;
              }
            }
          }
        }
      }
    }
    &--hint {
      // position: relative;
      position: absolute;
      cursor: pointer;
    }
  }
  &-content {
    // margin-top:70px;
    padding-top: 100px;
    text-align: center;
    background-color: #f7eee7;
    &.gsv-welcome-page {
      background-color: transparent;
    }
  }
}
