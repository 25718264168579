.spellingBee_aboutSpellingBee-container {
  padding-left: 14%;
  padding-right: 14%;
  @include bpm-sm {
    padding-left: 16px;
    padding-right: 16px;
  }
  margin-top: 94px;
  display: flex;
  @include bpm-sm {
    flex-direction: column-reverse;
    align-items: center;
    gap: 24px;
    margin-top: 24px;
  }
  gap: 64px;
  //   height: 28rem;
  &--left {
    width: 350px;
    max-width: 300px;
    @include bpm-sm {
      display: none;
    }
    &--img {
      width: 100%;
      height: 100%;
      border-radius: 24px;
      border-radius: 16px 0px 0 16px;
      @include bpm-sm {
        display: none;
      }
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 16px 0px 0px 16px;
    }
  }
  &--right {
    text-align: left;
    flex: 0.64;
    height: 100%;
    display: flex;

    flex-direction: column;
    gap: 40px;
    @include bpm-sm {
      gap: 16px;
      background: rgba(255, 255, 255, 0.72);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 24px;
      padding: 16px;
    }
    &_heading {
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 40px;
      line-height: 48px;
      color: #333333;
      @include bpm-sm {
        font-size: 24px;
        line-height: 29px;
      }
    }
    &_textInfo {
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 40px;
      color: #333333;
      @include bpm-sm {
        font-size: 20px;
        line-height: 33px;
        font-weight: 400;
        color: #666666;
      }
    }
    &_start-btn {
      background: linear-gradient(94.85deg, #ec7f57 -7.32%, #f2b65a 122.67%);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
      border: none;
      width: 457px;
      @include bpm-sm {
        width: 100%;
      }
    }
  }
}
