.ted-ed-about {
  &__wrapper {
  }
  @include bp-custom(1200px) {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  &__card {
    height: 40rem;
    width: 30rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: flex-end;
    &--header {
      font-size: 2rem;
      font-weight: 900;
      text-align: center;
      color: #333;
      max-width: 70%;
      margin-top: 18rem;
      margin-bottom: 2rem;
    }
    &--sub-header {
      font-size: 1.4rem;
      font-weight: 500;
      text-align: center;
      color: #333;
      max-width: 70%;
    }
  }
  .swiper-container {
    padding-bottom: 5rem;
  }
  .swiper-pagination {
    margin-bottom: 0;
  }
  .swiper-slide {
    width: 35rem;
    @include bpm-xs {
      width: 30rem;
    }
  }
}
