.spelling-bee-game {
  &__wrapper {
    padding: 0;
    margin: 0;
  }
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 40rem;
  height: 65rem;
  margin: 2.4rem auto;
  color: #f8de8d;
  margin: auto;
  background-color: #f8de8d;
  border-radius: 1.6rem;
}
