.certificate-container {
  height: 500px;
  width: 650px;
  position: relative;

  img {
    height: 100%;
    width: 100%;
  }
  .certificate-info {
    &-container {
      position: absolute;
      line-height: 35px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      left: 30px;
      top: 240px;
      font-size: 16px;
    }
    &_holder-name {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;

      color: #41495a;
    }
    font-family: "Avenir";
    font-style: normal;
    color: #41495a;
  }
}
