.joinlink-modal {
  &__wrapper {
  }
  width: 360px;
  min-height: 100px;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 0px 8px rgba(75, 0, 188, 0.08);
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 0px 8px rgba(75, 0, 188, 0.08);
  -webkit-appearance: none;
  border-radius: 10px;
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  display: none;
  overflow: visible;
  @include bpm-custom(400) {
    padding: 2.4rem 1.6rem;
    width: 330px;
  }
  @include bpm-custom(320) {
    width: 290px;
  }
  &--p-text {
    font-family: Lato;
    font-size: 18px;
    font-weight: 900;
    line-height: 22px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #333333;
  }
  &--s-text {
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    margin-top: 0.8rem;
    line-height: 17px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #666666;
    .p {
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0.20000000298023224px;
      text-align: left;
      color: #ffb541;
      text-decoration: underline;
    }
    &--span {
      color: #ffb541;
      text-decoration: underline;
    }
  }
  &--zoom-img {
    margin: 1.6rem auto;
  }
  &--or-text {
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #666666;
  }
  .primary-button {
    margin: auto;
    padding: 1.5rem 9rem;
  }
  &--t-text {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #666666;
    margin: 0.4rem 0 1.6rem;
  }
  &__close {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -2rem;
    right: -2rem;
    cursor: pointer;
    img {
      width: 4rem;
    }
  }
}
