.pendingAssignmentCard {
  &_wrapper {
  }
  //   &-left-thick-border {
  //     height: 100%;
  //     width: 3rem;
  //   }
  border-radius: 16px;
  //border-left-width: 48px;
  @include bpm-sm {
    border-radius: 8px;
  }
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &-content-container {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    padding: 2rem 2.5rem 2rem 2rem;
    @include bpm-sm {
      padding: 8px;
    }
    &_left {
      &--courseName {
        color: #333333;
        font-weight: 900;
        font-size: 32px;
        @include bpm-sm {
          font-size: 14px;
        }
      }
      &--session {
        margin-top: 8px;
        color: #666666;
        font-weight: 400;
        font-size: 24px;
        @include bpm-sm {
          font-size: 11px;
        }
      }
      &--dueText {
        margin-top: 16px;
        color: #e47762;
        font-size: 20px;
        font-weight: 500;
        font-style: italic;
        @include bpm-sm {
          font-size: 10px;
          margin-top: 8px;
        }
      }
    }
    &_right {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      @include bpm-sm {
        row-gap: 8px;
      }
      .primary-button {
        padding: 12px 24px;
        font-size: 18px;
        font-weight: 400;
        background: linear-gradient(90deg, #e47762 0%, #f2b65a 98.07%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        white-space: nowrap;
        @include bpm-sm {
          font-size: 14px;
          padding: 4px 16px;
        }
      }
    }
  }
}
.view-assignment-btn {
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  column-gap: 1rem;
  color: #333333;
  font-weight: 300;
  font-size: 18px;
  border: 2px soild rgba(153, 153, 153, 0.6);
  border-radius: 2.5rem;
  background-color: rgba(255, 255, 255, 0.8);
  @include bpm-sm {
    font-size: 14px;
    padding: 4px 16px;
  }
  img {
    height: 22px;
    width: 22px;
    @include bpm-sm {
      height: 14px;
      width: 14px;
    }
  }
}
