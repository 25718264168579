.enrolledCoursesCard {
  //! Wrapper wraps both the top card and the hidden drawer
  //! set to flex with max width of content and direction column(one below
  //! another)
  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    // padding:10px 20px;
  }
  //! top of the card ( visible by default)
  //! display flex used with direction row (side by side)
  //! Has sub divs- head, description, tags and progress
  &--top {
    display: flex;
    flex-direction: row;
    gap: 4rem;

    //! On mobiles, the flex direction is changed to column
    @include bpm-xs {
      flex-direction: column;
      gap: 1.2rem;
    }
    padding: 15px 20px;
    // background: linear-gradient(
    //   180deg,
    //   #3ebeff 0%,
    //   rgba(0, 170, 255, 0) 271.17%
    // );
    border-radius: 20px 20px 0px 0px;
    //! position set to relative and z index added to acheieve overlay of bottom
    // position: relative;
    z-index: 20;
    //! left of the top section of the card
    //! text align set to left for all content
    &-left {
      text-align: left;
      //! Margin right added for spacing between the white line
      margin-right: 10px;
      //! removed on mobiles
      @include bpm-xs {
        margin-right: 0px;
      }
      //! Head div in the left section
      //! width set to 270px on desktops
      //! Done so as to get a border bottom
      &--head {
        width: 400px;
        @include bpm-xs {
          width: 270px;
        }
        border-bottom: 1px solid #333333;
        padding-bottom: 15px;
        p {
          font-family: Lato;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          letter-spacing: 0.2em;
          color: #333333;
          margin-bottom: 7px;
        }
        h1 {
          font-family: Lato;
          font-size: 28px;
          font-style: normal;
          font-weight: 800;
          line-height: 34px;
          letter-spacing: -0.20000000298023224px;
          color: #333333;
          margin-bottom: 7px;
        }
        h2 {
          font-family: Lato;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0em;
          color: #666666;
          margin-bottom: 7px;

          span {
            font-family: Lato;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(51, 51, 51, 0.4);
          }
        }
        //! this coursetime is displayed only on mobiles
        //! This is a copy of the coursetime div on the right half of the card
        //! here, it displays on mobile while on the other it displays on ipads and higher
        &--courseTime {
          display: none;
          @include bpm-xs {
            display: flex;
          }
          margin-top: 20px;
          //! Left div of the coursttime
          //! holds the text
          .dateText {
            text-align: left;
            h2 {
              font-family: Lato;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 17px;
              color: #666666;
              margin-bottom: 3px;
            }
          }
          //! right child div of the coursetime div
          //! holds the numerical time data
          .actualDate {
            text-align: left;
            margin-left: 5px;
            h1 {
              font-family: Lato;
              font-style: normal;
              font-weight: 900;
              font-size: 14px;
              line-height: 17px;
              color: #333333;
              margin-bottom: 3px;
            }
          }
        }
      }
      //! the description div holds the description of the course

      &--description {
        &--p-text {
          font-family: Lato;
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: -0.20000000298023224px;
          text-align: left;
          color: #333333;
          margin: 1.6rem 0;
          @include bpm-md {
            font-size: 14px;
            line-height: 18px;
            text-align: left;
            margin: 0.8rem 0;
          }
          /* Hide scrollbar for Chrome, Safari and Opera */
          &::-webkit-scrollbar {
            display: none;
          }
        }
        p {
          font-family: Lato;
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: -0.20000000298023224px;
          text-align: left;
          color: #333333;
          /* Hide scrollbar for Chrome, Safari and Opera */
          &::-webkit-scrollbar {
            display: none;
          }
          /* Hide scrollbar for IE, Edge and Firefox */
          & {
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
          }
          //! Max width reduced on mobiles
          @include bpm-lg {
            max-width: 400px;
          }
          @include bpm-xs {
            max-width: 280px;
          }
        }
      }
      //! the tags div is set to flex
      &--tags {
        margin-top: 15px;
        display: flex;
        @include bpm-xs {
          display: none;
        }
        p {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          color: #333333;
          margin-left: 4px;
        }
        &-container {
          display: flex;
          align-items: center;
          margin-right: 5px;
        }
      }
      //! The progress div is set to flex with alignment of center vertical
      &--progress {
        display: flex;
        align-items: center;
        margin: 0.8rem 0;
        @include bpm-xs {
          flex-direction: column;
          display: none;
        }
        p {
          background: linear-gradient(90deg, #f3705b 0%, #fcb444 100%);
          padding: 1rem;
          border-radius: 4.6rem;
          color: #ffffff;
          font-family: Lato;
          font-size: 13px;
          font-weight: 600;
          line-height: 19px;
          letter-spacing: 0px;
          text-align: left;
          cursor: pointer;
        }
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      //! Padding left added on desktops
      // padding-left: 30px;
      //! removed on mobiles
      @include bpm-xs {
        padding-left: 0px;
      }
      //! Course time div is the top half of the right section of card
      &--courseTime {
        display: flex;
        @include bpm-xs {
          display: none;
        }
        //! Left div of the coursttime
        //! holds the text
        .dateText {
          text-align: left;
          h2 {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #666666;
            margin-bottom: 3px;
          }
        }
        //! right child div of the coursetime div
        //! holds the numerical time data
        .actualDate {
          text-align: left;
          margin-left: 5px;
          h1 {
            font-family: Lato;
            font-style: normal;
            font-weight: 900;
            font-size: 14px;
            line-height: 17px;
            color: #333333;
            margin-bottom: 3px;
          }
        }
      }

      //! courseImage is the lower half div of the right section
      //! holds the image
      &--courseImage {
        margin-top: 25px;
        // border-right: 1px solid #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        //! border removed on mobiles
        @include bpm-xs {
          border: none;
        }
        img {
          width: 220px;
          border: 3px solid #ffffff;

          border-radius: 10px;
        }
        &--date {
          position: absolute;
          bottom: 3px;
          border-radius: 1rem;
          left: 2px;
          width: 98%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid white;
          background: orange;
          height: 3rem;
          font-family: Lato;
          font-size: 14px;
          font-weight: 700;
          line-height: 19px;
          letter-spacing: 0.2em;
          text-align: left;
        }
        //! this is a copy of the course progress but only displayed on mobiles
        //! The course progress div is repositioned to be below the image
        //! To do so, the original one in top left div is hidden while this
        //! is active
        &--progress {
          display: none;
          align-items: center;
          margin-top: 15px;
          @include bpm-xs {
            flex-direction: column;
            display: flex;
          }
          p {
            margin-left: 10px;
            font-family: Lato;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 19px;
            letter-spacing: 0em;
            cursor: pointer;
          }
        }
      }
    }
  }
  //! The bottom/ dropdown div is hidden by default
  //! Triggered/ set to display flex on click
  &--bottom {
    //! This wrapper covers the entirety of the bottom hidden card
    &__wrapper {
      max-height: 400px;
      //! Extra padding to compensate for negative margin
      padding-top: 20px;

      //! z-index set to 10, lower than top div
      z-index: 10;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      &::-webkit-scrollbar-track {
      }

      &::-webkit-scrollbar-thumb {
        background: #ffffff;
        border-radius: 20px;
      }
      //! Set to display none but changes on adding active class
      //! on onclick
      display: none;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      box-shadow: 0px 2px 6px rgba(228, 119, 98, 0.2), 0px 4px 14px #eeeeee,
        inset 1px 0px 3px rgba(237, 237, 237, 0.5);
      background: #faf8ff;
      //! set to display block onclick
      &.active {
        display: block;
      }
    }

    padding: 10px 20px;
    flex-direction: column;

    //! Css for the dropdown header
    &-header {
      display: flex;
      justify-content: space-between;
      h1 {
        font-family: Lato;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: #000632;
        text-align: left;
        margin-left: 12px;
        @include bpm-md {
          font-size: 12px;
          line-height: 15px;
        }
      }
      &-progressStatus {
        display: flex;
        align-items: center;
        margin-right: 20px;
        &--p-text {
          font-size: 14px;
          font-weight: 900;
          line-height: 16px;
          letter-spacing: 0em;
          text-align: center;
        }
        p {
          margin-left: 5px;
          font-family: Lato;
          font-style: normal;
          font-weight: 900;
          font-size: 16px;
          line-height: 16px;
          /* identical to box height, or 100% */
          @include bpm-xs {
            font-size: 13px;
          }
          color: #333333;
          span {
            color: #999999;
          }
        }
      }
    }

    //! Css for the sessions div
    &-sessions {
      display: flex;
      margin-top: 20px;
      //! The left div has the image/icon of the session
      &-left {
        img {
          width: 120px;
          height: 130px;
          object-fit: cover;
          border-radius: 1rem;
        }
      }
      //! The right div has the header/text/links
      &-right {
        text-align: left;
        margin-left: 20px;
        width: 100%;
        //! The header div has the head tag and p tag
        &-header {
          &__container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &--p-text {
              font-family: Lato;
              font-size: 16px;
              font-weight: 700;
              line-height: 19px;
              letter-spacing: 0.2em;
              text-align: left;
              color: #333333;
            }
          }
          //! this class wraps around the session name and tick img
          &--attended {
            display: flex;
            align-items: center;
            margin-bottom: 0.8rem;
            .session-attended {
              margin-left: 10px;
            }
          }
          h1 {
            font-family: Lato;
            font-size: 20px;
            font-style: normal;
            font-weight: 900;
            line-height: 16px;
            letter-spacing: 0em;
            @include bpm-xs {
              font-size: 16px;
            }
          }
          p {
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0px;
            max-width: 590px;
            @include bpm-xs {
              max-width: 160px;
            }
          }
          span {
            cursor: pointer;
          }
          #hidden {
            height: 26px;
            overflow-y: hidden;
          }
        }
        //! The links div is only visible on desktops
        //! different div used on mobiles for proper alignment
      }
    }
    //! The links div holds the links to view the session details
    //! link to the related games and the learning mats
    &-links {
      display: flex;
      align-items: center;
      padding-left: 140px;
      border-bottom: 2px solid #eaeaea;
      padding-bottom: 7px;
      @include bpm-xs {
        padding-left: 0px;
        flex-direction: column;
      }
      .primary-button {
        width: 110px;
        height: 35px;
        margin-left: 15px;
        font-weight: 600;
      }
      //! div for materials ( learning mat link + button)
      &-materials {
        display: flex;
        align-items: center;
      }
      //! Div that contains the games links
      &-games {
        margin-left: 20px;
        @include bpm-xs {
          margin-left: 0px;
        }
      }
    }
  }
}
