.summercamp-tndc {
  &__wrapper {
    padding: 0;
    margin: 0 0 5.6rem 0;
  }
  display: flex;
  flex-direction: column;
  background-color: rgba(51, 51, 51, 1);
  width: 100%;
  min-height: 48rem;
  padding: 3.2rem 2.4rem 2.4rem;
  @include bpm-xs {
    padding: 3.2rem 2rem 2.4rem;
  }
  &.dashboard-padding {
    padding-left: 90px;
    @include bpm-xl {
      padding: 3.2rem 2.4rem 2.4rem;
    }
  }
  &--p-heading {
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    width: 100%;
    line-height: 25px;
    letter-spacing: 0em;
    margin-bottom: 2.4rem;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    @include bpm-xs {
      margin-bottom: 1.6rem;
    }
  }
  &--s-heading {
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    margin-top: 1.6rem;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
  }
  &--t-subheading {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    margin-top: 0.8rem;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    &.offers {
      font-weight: 800;
      font-size: 18px;
    }
  }
  ul {
    list-style-type: numeric;
    padding-left: 3rem;
    margin-top: 1rem;
  }

  ul li {
    line-height: 1.5;
    font-family: Lato;
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0.8rem 0;
  }
  ol li {
    line-height: 1.5;
    font-family: Lato;
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
  }

  ol {
    padding-left: 1.5rem;
    list-style-type: lower-alpha;
  }
  li {
    line-height: 1.5;
    font-family: Lato;
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 3%;
  }
}
