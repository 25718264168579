.spark-auth-form {
  &__wrapper {
    background: #ffffff;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    width: 450px;
    margin: 0 auto;
    @include bpm-custom(480px) {
      width: 95%;
      //   margin: 0 1.5rem;
    }
    .tnc {
      justify-content: flex-start;
      margin-bottom: 2rem;
    }
  }
  padding: 4rem 3rem;
  @include bpm-custom(480px) {
    padding: 2rem 1.5rem;
  }
  &__header {
    margin-bottom: 1rem;
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;
    &--primary {
      font-size: 2.8rem;
      color: #02002c;
      font-weight: 900;
    }
    &--secondary {
      cursor: pointer;
      color: #ff8671;
      font-size: 1.8rem;
      font-weight: 700;
    }
    @include bpm-custom(480px) {
      &--primary {
        font-size: 2.2rem;
      }
    }
  }
  &__sub-header {
    font-size: 1.8rem;
    color: #666;
    font-weight: 500;
    margin-bottom: 2rem;
    span {
      color: #ff8671;
      font-size: 2rem;
      display: inline-block;
      margin-left: 0.7rem;
    }
    &_book-free-trial-link-text {
      cursor: pointer;
      text-decoration: underline;
    }
    @include bpm-custom(480px) {
      font-size: 1.4rem;
      span {
        font-size: 1.6rem;
      }
    }
  }
  &__inputs-container {
    margin: 3rem 0;
  }
}
