.teacher-dashboard-redirection_modal {
  &_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 120vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
    display: none;
  }
  &-close {
    width: 42px;
    height: 42px;
    position: absolute;
    right: 10px;
    top: -15px;
    z-index: 1000;
    cursor: pointer;
  }
  background-color: #ffffff;
  border-radius: 16px;
  opacity: 1;
  width: 30%;
  position: relative;
  @include bpm-lg {
    width: 40%;
  }
  @include bpm-sm {
    width: 65%;
  }
  @include bpm-xs {
    width: 94%;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 12px;
  padding: 1rem 2rem;
  &--warning-image {
    height: 100px;
  }
  &--primary-text {
    font-weight: 700;
    font-size: 2.25rem;
    text-align: center;
  }
  &--secondary-text {
    font-size: 1rem;
    text-align: center;
    font-size: 1.75rem;
    color: #333333;
    b {
      color: black;
    }
  }

  .primary-button {
    border: none;
  }
}
