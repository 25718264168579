.affiliate-registered-users-comp {
  &__wrapper {
    padding: 0;
  }
  display: flex;
  flex-direction: column;
  @include bpm-md {
    padding: 0, 1.2rem;
  }
  &--heading {
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 29px;
    margin-left: 3rem;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(102, 102, 102, 1);
  }
  &--sub-heading {
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 38px;
    letter-spacing: 0em;
    margin: 0.8rem 0 2.4rem 3rem;
    text-align: left;
    color: rgba(51, 51, 51, 1);
  }
  &__filter-container {
    margin-left: auto;
    &__dropdownContainer {
      width: 13.2rem;
      z-index: 10;
    }
  }
  &--refresh-text {
    font-family: Lato;
    font-size: 13px;
    font-style: italic;
    font-weight: 300;
    margin-bottom: 2.4rem;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(102, 102, 102, 1);
  }
}
