//! this is the wrapper component for the purchased order components
.coursePurchaseOrderCard {
  //! wraps around the whole component
  &__wrapper {
    background: #ffffff;
    border-radius: 16px;
    padding: 20px;
  }
  //! the top div holds the headers, purchased course component and the right side image
  &--top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //! The left div holds the purchased course component and header
    &-left {
      display: flex;
      flex-direction: column;
      text-align: left;
      &-header {
        h1 {
          font-family: Lato;
          font-style: normal;
          font-weight: 800;
          font-size: 20px;
          line-height: 24px;
          /* identical to box height */
          color: #333333;
          margin-bottom: 13px;
        }
        p {
          font-family: Lato;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          /* identical to box height */
          color: #666666;
        }
      }
      &-cards {
        max-height: 230px;
        overflow-y: scroll;
        padding-right: 10px;
        &::-webkit-scrollbar {
          width: 3px;
          height: 5px;
        }
        &::-webkit-scrollbar-track {
        }

        &::-webkit-scrollbar-thumb {
          background: LightGray;
          border-radius: 20px;
        }
      }
    }
    //! the right div holds the image alone
    &-right {
      margin-left: 20px;
      @include bpm-sm {
        margin-left: 15px;
      }
      @include bpm-xs {
        display: none;
      }
      &-cardImage {
        width: 398px;
        @include bpm-sm {
          width: 350px;
        }
      }
    }
  }
  //! the bottom div is simply a text  div
  &--bottom {
    text-align: left;
    margin-top: 20px;
    p {
      font-family: Lato;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height */

      color: #e47762;
    }
  }
}
