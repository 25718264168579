.homepage-courses {
  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 1rem;
    margin-bottom: 4rem;
    @include bpm-xs {
      margin: 2.5rem 0;
    }
  }
  &__liner {
    font-size: 1.6rem;
    color: #333;
    margin-bottom: 2rem;
  }
  &__age-filter {
    list-style: none;
    padding-inline-start: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    @include bpm-md {
      margin-bottom: 2rem;
    }
    &-item {
      padding: 0.5rem 0;
      width: 10rem;
      text-align: center;
      background: linear-gradient(
        111.29deg,
        rgba(234, 234, 234, 0.53) -1.83%,
        rgba(255, 255, 255, 0) 109.95%
      );
      // filter: drop-shadow(50px 60px 100px rgba(0, 0, 0, 0.05));
      // backdrop-filter: blur(70px);
      //   filter: drop-shadow(50px 60px 100px rgba(0, 0, 0, 0.05));
      // backdrop-filter: blur(70px);
      font-size: 1.6rem;
      border-radius: 8px;
      border: 3px solid #fff;
      //   box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
      margin: 1rem 1.5rem;
      cursor: pointer;
      font-weight: 500;
      color: #666;
      @include bpm-md {
        margin: 1rem;
      }
      &--see-all {
        position: absolute;
        right: 8rem;
        font-size: 1.8rem;
        font-weight: 600;
        color: #666;
        cursor: pointer;
        // margin: 1rem 1.5rem;

        @include bpm-custom(1071px) {
          font-size: 2rem;
          right: 5rem;
          top: 7rem;
        }
        @include bpm-custom(628px) {
          font-size: 1.8rem;
          right: 2rem;
          top: 11.6rem;
        }
      }
      &.active {
        border: 3px solid #fcb444;
      }
    }
  }
  .primary-button {
    margin: 8rem auto;
  }
  .primary-button {
    margin: 4rem auto 8rem auto;
  }
  .swiper-slide {
    width: 36rem !important;
  }

  @include bpm-xs {
    .swiper-slide {
      width: 33rem !important;
    }
  }
  .swiper-wrapper {
    margin-bottom: 6rem;
  }
  .swiper-pagination {
    // bottom: -3rem !important;
    margin-bottom: 0;
  }
  .swiper-button-next {
    transform: translate(7rem, -5rem);
    outline: none;
    border: none;
    background-color: rgba(255, 255, 255, 0.9);
    height: 10rem;
    width: 10rem;
    border-radius: 10rem;
    position: absolute;
    &::after {
      content: "";
      background: url("../../../public/assets/nextArrow.svg") center
        center/contain no-repeat;
      background-size: 2rem;
      transform: translateX(-2.3rem);
    }
  }
  .swiper-button-prev {
    transform: translate(-7rem, -5rem);
    outline: none;
    border: none;
    background-color: rgba(255, 255, 255, 0.9);
    height: 10rem;
    width: 10rem;
    border-radius: 10rem;
    position: absolute;
    &::after {
      content: "";
      background: url("../../../public/assets/nextArrow.svg") center
        center/contain no-repeat;
      background-size: 2rem;
      transform: rotate(180deg) translateX(-2.3rem);
    }
  }
  .swiper-button-disabled {
    opacity: 0 !important;
  }
}
