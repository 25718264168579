.results-pie-chart {
  &__wrapper {
    padding: 1.2rem 2.4rem 1rem;
    margin-top: 1.6rem;
  }
  display: flex;
  justify-content: center;
  @include bpm-sm {
    margin: 2.4rem 0 0.8rem;
  }
  margin: 1rem 0 0rem;
}
