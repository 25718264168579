.new-courses-card {
  &__wrapper {
    width: 32rem;
    border-radius: 2rem;
    overflow: hidden;
    position: relative;
    min-height: 495px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 290px;
  padding: 0 1.5rem;

  &__top,
  &__bottom {
    height: 100%;
    width: 100%;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }

  &__tag {
    position: absolute;
    top: -1rem;
    left: -4.7rem;
    height: 6rem;
    width: 14rem;
    background-color: #e47762;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    transform: rotate(-45deg);

    &--content {
      padding-bottom: 0.5rem;
      font-size: 1.5rem;
      color: #fff;
      font-weight: 700;
    }
  }

  &__age-tag {
    position: absolute;
    right: 0;
    top: 102px;

    &--content {
      position: absolute;
      top: 50px;
      right: 9px;
      transform: rotate(323deg);
      font-size: 1.5rem;
      color: #fff;
      font-weight: 700;
    }
  }

  * {
    text-align: left;
  }

  &__image {
    width: 100%;
    border-radius: 2rem;
    height: 190px;
    object-fit: cover;
  }

  &__title {
    font-size: 2.4rem;
    font-weight: 900;
    color: #333;
    margin: 1rem 0;
    cursor: pointer;
  }

  &__category {
    display: flex;
    align-items: center;

    .separator-dot {
      display: inline-block;
      height: 0.6rem;
      width: 0.6rem;
      background-color: #aaa;
      border-radius: 5rem;
      margin: 0 0.5rem;
    }
    .vertical-separator {
      margin: 0 8px;
    }

    &--category,
    &--sessions,
    &--age-bucket {
      font-size: 1.4rem;
      color: #666;
      text-transform: uppercase;
    }

    margin-bottom: 1rem;
  }

  &__description {
    font-size: 1.4rem;
    color: #666;

    max-height: 35px;
    overflow: hidden;
    &.active {
      max-height: none;
    }
  }
  &__pitchExpansion {
    font-size: 1.2rem;
    color: #333;
    text-decoration: underline;
    cursor: pointer;
    margin: 0.3rem 0 1rem 0;
  }

  &__rating {
    display: flex;
    align-items: center;
    color: #333;
    font-size: 1.2rem;

    span {
      display: inline-block;
      margin-left: 0.5rem;
    }

    margin-bottom: 1.5rem;
  }

  &__certificate {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    color: #333;

    span {
      display: inline-block;
      margin-left: 0.5rem;
    }

    margin-bottom: 1rem;
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0.5rem 0;
    align-self: flex-end;

    &--price {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .actual-price {
        font-size: 2rem;
        font-weight: 900;
        margin-right: 1rem;
        color: #333;
        margin-bottom: 5px;
      }

      .slashed-price {
        color: #666;
        font-size: 1.6rem;
      }
    }

    &--discount {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      &-text {
        position: absolute;
        font-size: 1.3rem;
        top: -2.2rem;
        font-weight: 700;
        color: #e47762;
      }
    }
  }

  &__see-more {
    cursor: pointer;

    @include bp-md {
      visibility: hidden;
    }

    margin: 1rem 0;
    display: flex;
    align-items: center;
    font-size: 1.3rem;

    img {
      margin-left: 0.5rem;
      width: 1.5rem;
    }
  }

  &__discountPriceContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-text {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      margin: 16px 0;

      &_discounted {
        & > h1 {
          font-family: Lato;
          font-weight: 400;
          font-size: 24px;
          line-height: 29px;
          color: #999999;
          text-decoration: line-through;
          text-decoration-color: #f47759;
        }

        & > p {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 15px;
          line-height: 19px;
          margin: 8px 0;
          color: #f47759;
          font-style: italic;
        }
      }

      &_original {
        & > h1 {
          font-family: Lato;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
        }
      }
    }
  }
  &_newbutton {
    display:flex;
    justify-content: space-between;

    &__btn {
      // border-radius: 50%;
      color:white;
      font-weight: 800;
      font-size: 28px;
      height: 45px;
      width:45px;
      background: linear-gradient(90deg, #F3705B 0%, #FCB444 100%);
      border-radius: 50px;
      border: none;
      text-align: center;
      cursor: pointer;
    }
    &__btn1{
      color:white;
      font-weight: 750;
      font-size: 25px;
      height: 45px;
      width:180px;
      background: linear-gradient(90deg, #F3705B 0%, #FCB444 100%);
      border-radius: 30px;
      border: none;
      text-align: center;
      cursor: pointer;
    }

  }
}
