.course-card-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 60px;
  z-index: 1;
  border: 1px solid #fff;
  width: 100%;
  min-height: 400px;
  padding: 1rem 0.5rem;
  border-radius: 25px;
  box-shadow: 0 3px 7px rgb(0 0 0 / 0.1);
  // box-shadow: 4px 4px 8px rgba(75, 0, 188, 0.08);
  // Webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  &--heading {
    margin: 1rem auto 0rem;
    font-size: 2rem;
    font-weight: 800;
    color: #333;
    font-family: Lato;
  }
  &--subheading {
    margin: 1.5rem auto 1rem;
    font-size: 1.6rem;
    font-weight: 400;
    color: #333;
    font-family: Lato;
    opacity: 0.7;
  }

  .about-card {
    height: 600px;
  }

  .swiper-slide {
    width: 33rem !important;
  }

  // @include bpm-xs {
  //   .swiper-slide {
  //     width: 33rem !important;
  //   }
  // }
  .swiper-wrapper {
    margin-bottom: 6rem;
  }
  .swiper-pagination {
    // bottom: -3rem !important;
    margin-bottom: 0;
  }
  .swiper-button-next {
    transform: translate(7rem, -5rem);
    outline: none;
    border: none;
    background-color: rgba(255, 255, 255, 0.9);
    height: 10rem;
    width: 10rem;
    border-radius: 10rem;
    position: absolute;
    &::after {
      content: "";
      background: url("../../../../public/assets/nextArrow.svg") center
        center/contain no-repeat;
      background-size: 2rem;
      transform: translateX(-2.3rem);
    }
  }
  .swiper-button-prev {
    transform: translate(-7rem, -5rem);
    outline: none;
    border: none;
    background-color: rgba(255, 255, 255, 0.9);
    height: 10rem;
    width: 10rem;
    border-radius: 10rem;
    position: absolute;
    &::after {
      content: "";
      background: url("../../../../public/assets/nextArrow.svg") center
        center/contain no-repeat;
      background-size: 2rem;
      transform: rotate(180deg) translateX(-2.3rem);
    }
  }
  .swiper-button-disabled {
    opacity: 0 !important;
  }
}
