.spellingBee-categories {
  &_wrapper {
    padding-left: 14%;
    padding-right: 14%;
    margin-top: 48px;
    @include bpm-sm {
      padding-left: 16px;
      padding-right: 16px;
      margin-top: 24px;
    }
  }
  &--heading {
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    @include bpm-sm {
      font-size: 24px;
      line-height: 29px;
    }
  }
  &-card {
    &_container {
      margin-top: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include bpm-md {
        flex-direction: column;
        margin-top: 24px;
      }
    }
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;
    color: #333333;
    padding: 25px 72px;
    white-space: nowrap;
    @include bpm-md {
      padding: 16px 28px;
      font-size: 20px;
      line-height: 24px;
      border-radius: 8px;
    }

    &--joiner {
      width: 100px;
      overflow: hidden;
      @include bpm-md {
        width: 24px;
      }
      &-line {
        width: 100%;
        border: 2px dashed #000000;
        @include bpm-md {
          transform: rotate(-90deg);
          // width: 14px;
          border: 1.38612px dashed #000000;
        }
      }
    }
  }
}
