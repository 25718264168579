.homework-review-table {
  width: 100%;
  table-layout: fixed;
  border-radius: 1rem;
  border-collapse: collapse;
  margin: 2.4rem 0;
  &__headerbar {
    width: 100%;
    min-height: 8rem;
    background: rgba(170, 228, 255, 1);
    border-radius: 2rem 2rem 0rem 0rem;
    display: flex;
    justify-content: space-between;
    padding: 2.4rem 4rem 2.4rem;
    &__p-heading {
      font-family: Lato;
      font-size: 40px;
      font-weight: 800;
      line-height: 48px;
      letter-spacing: 0px;
      text-align: left;
      color: rgba(51, 51, 51, 1);
    }
    &__s-heading {
      font-family: Lato;
      font-size: 24px;
      font-style: italic;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(250, 62, 62, 1);
    }
  }
  &__session_header {
    width: 100%;
    min-height: 8rem;
    background: rgba(255, 181, 65, 0.24);
    padding: 1.6rem 4.8rem;
    display: flex;
    justify-content: space-between;
    &__container-1 {
      display: flex;
      flex-direction: column;
      width: 33.33%;
      &__wrapper {
        display: flex;
        &--p-text {
          font-family: Lato;
          font-size: 20px;
          font-weight: 700;
          line-height: 29px;
          letter-spacing: 0px;
          text-align: left;
          color: rgba(102, 102, 102, 1);
          &.bold {
            color: rgba(51, 51, 51, 1);
            margin-left: 0.8rem;
          }
        }
      }
    }
    &__downloadContainer {
      display: flex;
      justify-content: center;
      cursor: pointer;
      &--p-text {
        font-family: Lato;
        font-size: 24px;
        cursor: pointer;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0px;
        text-align: left;
        color: rgba(51, 51, 51, 1);
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &--img {
        margin-left: 0.4rem;
      }
    }
  }
}

.class_details_table {
  margin-bottom: 4.8rem;
}

.homework-review-table tbody {
  display: block;
  width: 100%;
  overflow: auto;
  max-height: 35rem;
}

.homework-review-table thead tr {
  display: block;
  border-radius: 2rem;
}

.homework-review-table__wrapper {
  background: #fed700;
  border-radius: 6rem;
  // position: fixed;
  color: #fff;
  /* width: 100%; */
  height: 10rem;
  border-radius: 1.6rem 1.6rem 0 0;
  &.brown {
    background: #ffb541;
  }
  &.cream {
    background: #fdefd8;
  }
  &.blue {
    background: #aae4ff;
  }
  &.lightblue {
    background: rgba(153, 222, 255, 0.16);
  }

  &__body {
    background: #fff;
  }
  &__preparation {
    color: #f3705b !important;
    text-decoration: underline;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
  }
  &__paid {
    font-family: Lato;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
    color: #00a912 !important;
  }

  &__p-button {
    padding: 1rem 0rem !important;
    width: 80%;
    margin: auto;
  }
}

.homework-review-table tbody tr th {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  color: #333333;
}

.homework-review-table tbody tr th {
  text-align: center;
  position: relative;
  top: 0;
}

.homework-review-table th,
.homework-review-table td {
  padding: 1.6rem 5px;
  text-align: center;
  width: 250px;
  // border-bottom: 2px solid rgba(242, 182, 90, 0.48);
}

.homework-review-table tr td {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  /* identical to box height */
  color: #666666 !important;
}

table::-webkit-scrollbar {
  background: #fff;
}
table::-webkit-scrollbar-thumb {
  background-color: red;
}

.star-icon {
  --icon-size: 32px;
  height: var(--icon-size);
  width: var(--icon-size);
}

.star-icon-filled {
  fill: yellow;
}

.start-rating-container {
  display: flex;
  justify-content: flex-end;
}
