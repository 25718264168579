.teacher-card {
  &__wrapper {
    max-width: 33rem;
  }
  display: flex;
  flex-direction: column;
  .single-teacher-card {
    display: flex;
    align-items: flex-start;
    margin: 1rem 0;
    &__left {
      width: 12rem;
      img {
        width: 100%;
      
      }
    }
    &__right {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding-left: 2rem;
      &--teacher-name {
        font-size: 2rem;
        font-weight: 700;
        color: #333;
        justify-self: flex-start;
      }
      &--teacher-position {
        font-size: 1.6rem;
        font-weight: 500;
        color: #666;
        justify-self: flex-start;
      }
      &--teacher-pointers {
        justify-self: flex-end;
        padding-inline-start: 0px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .single-pointer {
          font-size: 1.4rem;
          font-weight: 500;
          color: #666;
          position: relative;
          list-style: none;
          text-align: left;
          margin: 3px 0;
          &::before {
            content: "";
            position: absolute;
            left: -1.3rem;
            top: 7px;
            // transform: translateY(-50%);
            height: 8px;
            width: 8px;
            border-radius: 2rem;
          }
          &--0 {
            &::before {
              background-color: #ffa724;
            }
          }
          &--1 {
            &::before {
              background-color: #9048ff;
            }
          }
          &--2 {
            &::before {
              background-color: #4dc4fe;
            }
          }
        }
      }
    }
  }
}
