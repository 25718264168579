.callback-requested {
  &__wrapper {
    padding: 0 22%;
    margin: 24px 0;
    margin-left: 80px;
    @include bpm-md {
      padding: 0;
      margin-left: 0;
    }
  }
  background: #000632;
  border-radius: 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 123px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    height: 100%;
    width: 171px;
  }
  &--text-container {
    text-align: left;
    &__primary-text {
      font-size: 24px;
      font-weight: 900;
      color: #fff;
    }
    &__secondary-text {
      margin-top: 8px;
      font-size: 18px;
      font-weight: 400;
      color: #fff;
      font-style: italic;
    }
  }
}
