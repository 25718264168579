.primary-button {
  color: #fff;
  background: linear-gradient(90deg, #f3705b 0%, #fcb444 100%);
  box-shadow: 0px 2px 6px rgba(91, 22, 170, 0.12), 0px 4px 14px #eeeeee,
    inset 1px 0px 3px rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0px 2px 6px rgba(91, 22, 170, 0.12), 0px 4px 14px #eeeeee,
    inset 1px 0px 3px rgba(255, 255, 255, 0.5);
  -webkit-appearance: none;
  border-radius: 5rem;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: #fff;
  }
  &.version-1 {
    padding: 1.5rem 4rem;
    justify-content: center;
    font-weight: 600;
  }
  &.version-2 {
    padding: 1.5rem 0 1.5rem 2.2rem;
    justify-content: space-between;
    position: relative;
    font-size: 18px;
    font-weight: 400;
    box-shadow: 0px 2px 6px rgba(252, 176, 70, 0.64), 0px 4px 14px #eeeeee,
      inset 1px 0px 3px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0px 2px 6px rgba(252, 176, 70, 0.64),
      0px 4px 14px #eeeeee, inset 1px 0px 3px rgba(255, 255, 255, 0.5);
    -webkit-appearance: none;
    width: 22rem;
    img.primary-button-arrow {
      position: absolute;
      width: 4rem;
      right: 0.6rem;
    }
  }
  &.version-3 {
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    box-shadow: 0px 1px 8px rgba(63, 63, 63, 0.3);
    -webkit-box-shadow: 0px 1px 8px rgba(63, 63, 63, 0.3);
    -webkit-appearance: none;
  }
  &.version-5 {
    justify-content: center;
    padding: 0rem;
    width: 13rem;
    height: 4rem;
  }
  &.version-6 {
    position: relative;
    padding: 15px 27px;
    background: none;
    box-shadow: none;
    -webkit-box-shadow: none;

    p {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      color: #333333;
    }
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 48px;
      padding: 2px; /* control the border thickness */
      background: linear-gradient(45deg, #f4735a, #fcaf46);
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0) !important;
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }
  &.shine {
    animation: shine 3000ms ease-out infinite;
    background: linear-gradient(
      -45deg,
      #f3705b 0%,
      #fcb444 48%,
      #ffffff22 49.5%,
      #ffffff22 50.5%,
      #fcb444 52%,
      #f3705b 100%
    );
    background-size: 400px 1500px;
    @keyframes shine {
      0% {
        background-position: top left;
      }
      100% {
        background-position: bottom right;
      }
    }
  }
  &.disabled {
    background: linear-gradient(90deg, #909090 0%, #d7d7d7 98.07%);
    cursor: not-allowed;
    img.primary-button-arrow {
      width: 5.5rem !important;
      right: -0.2rem;
      top: 0.3rem;
    }
  }

  &.no-shadow {
    box-shadow: none;
    -webkit-box-shadow: none;
  }
}
