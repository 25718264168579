.spelling-bee-check-results-modal {
  &_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 130vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
    display: none;
    margin: 0 auto;
    text-align: center;
  }

  display: flex;
  flex-direction: column;
  &-close {
    width: 42px;
    height: 42px;
    position: absolute;
    right: -10px;
    top: 25px;
    z-index: 1000;
    cursor: pointer;
    @include bpm-md {
      right: -10px;
      top: 12.5px;
    }
  }
  position: relative;
}
