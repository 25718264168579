.certificate-section {
  &__wrapper {
  }
  &__swiper {
    width: 100%;
    padding-bottom: 6rem !important;
    .swiper-slide {
      width: 45rem;
    }
    .swiper-pagination {
      margin-bottom: 0;
    }
    @include bpm-xs {
      .swiper-slide {
        width: 30rem;
      }
    }
  }
  &__image {
    width: 90%;
    margin: auto;
  }
  &__header {
    font-size: 4rem;
    font-weight: 900;
    color: #333;
    margin: 3rem 0 2rem 0;
    @include bpm-sm {
      font-size: 3rem;
      margin: 2rem 0 1rem 0;
    }
    @include bpm-xs {
      font-size: 2.5rem;
    }
  }
  &__sub-header {
    font-size: 2rem;
    font-weight: 500;
    color: #666;
    margin-bottom: 4rem;
    @include bpm-sm {
      font-size: 1.6rem;
      margin-bottom: 3rem;
    }
  }
}
