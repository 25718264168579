.friends-unlocked-referral {
  &_wrapper {
    width: 328px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;

    .image-container {
      height: 108px;
      width: 240px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .sharing-caring-text {
      margin-top: 16px;
      font-weight: 900;
      font-size: 18px;
      line-height: 22px;
      color: #333333;
    }
    .secondary-text-container {
      margin-top: 16px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #666666;
      width: 242px;
    }
    .yellow-bg-text-container {
      margin-top: 16px;
      width: 328px;
      padding: 8px 45px;
      background-color: #fff533;
      font-weight: 900;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
    }
  }
  &-header {
    color: #333333;
    padding: 24px 13px 24px 13px;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
  }
  &-share-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1.6rem 0;
  }
}
