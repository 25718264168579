.summercamp-courseAccordian {
  &__wrapper {
    padding: 0 4rem;
    background-color: rgba(255, 248, 238, 1);
    @include bpm-xs {
      padding: 0 3rem;
    }
    @include bpm-xxs {
      padding: 0 2rem;
    }
  }
}
