.add-to-calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  //do we need space?
  // justify-content: space-between;
  &__action {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .onboarding-secondary-link {
      margin-top: 1.5rem;
    }
  }
}

.onboarding-joining {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    height: 100%;
  }
}
.onboarding-course-outline {
  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  &__header {
    font-size: 1.6rem;
    font-weight: 900;
    margin: 2rem 0 3rem 0;
  }
  &__single-course {
    margin: 1.5rem 0 2.5rem 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    &--title {
      font-size: 1.8rem;
      font-weight: 900;
      display: flex;
      align-items: flex-start;
      color: #333;
      span {
        text-align: center;
        display: inline-block;
        margin-right: 1rem;
        background-color: #f2b65a;
        height: 2.3rem;
        width: 2.3rem;
        border-radius: 3rem;
      }
    }
    &--description {
      font-size: 1.4rem;
      color: #666;
      font-weight: 500;
      margin: 1.5rem 0 2.5rem 0;
    }
    .primary-button {
      align-self: center;
      align-self: center;
      width: 15.4rem;
      height: 4.2rem;
      font-size: 1.35rem;
      padding: 0;
    }
  }
}

.onboarding-personalise {
  width: 100%;
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0 !important;
  }
  &__accordion {
    background: #ffffff;
    border: 1px solid rgba(237, 237, 237, 0.8) !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08) !important;
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08) !important;
    border-radius: 16px !important;
    &-summary {
      padding: 0.5rem 2rem !important;
      &--top {
        &--title {
          font-size: 1.8rem;
          font-weight: 900;
          color: #e47762;
          margin-bottom: 0.7rem;
        }
        &--details {
          font-size: 1.4rem;
          font-weight: 500;
          color: #666;
        }
      }
    }
  }
  &__accordion-details {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    &--header {
      font-size: 1.8rem;
      font-weight: 700;
      margin: 1.5rem 0 2rem 0;
      color: #333;
    }
    &--sub-header {
      font-size: 1.3rem;
      font-weight: 500;
      color: #666;
      margin-bottom: -0.5rem;
    }
  }
  .personalise-card {
    &__wrapper {
    }
    padding: 0 1.5rem;
    background: #ffffff;
    margin-bottom: 1rem;
    box-shadow: 0px 0px 8px rgba(75, 0, 188, 0.08);
    -webkit-box-shadow: 0px 0px 8px rgba(75, 0, 188, 0.08);
    border-radius: 8px;
    cursor: pointer;
    height: 5rem;
    max-width: 25rem;
    display: flex;
    align-items: center;

    span {
      font-size: 1.6rem;
      font-weight: 500;
      color: #666;
    }
    &__checked {
      border: 2px solid #f2b65a;
      span {
        color: #f2b65a;
      }
    }
  }
  .primary-button {
    margin-top: 2.5rem;
  }
}

.onboarding-all-set {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__header {
    font-size: 2.2rem;
    font-weight: 700;
    color: #333;
    text-align: center;
  }
  &__content {
    font-size: 1.6rem;
    font-weight: 500;
    color: #666;
    text-align: center;
    margin: 1.5rem 0 2rem 0;
    line-height: 2.4rem;
    span {
      color: #333;
      font-weight: 700;
    }
  }
  .primary-button {
    margin-bottom: 2rem;
  }
}
