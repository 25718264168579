.live_class_storytelling_card {
  width: 100%;
  padding: 15px 70px 10px 30px;
  margin-bottom: 40px;
  margin-top: 4rem;
  background: linear-gradient(113.87deg, #faffb9 -9.5%, #ffffff 97.11%);
  box-shadow: 0px 4px 4px 1px rgba(208, 208, 208, 0.72);
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  @include bpm-md {
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 10px;
    transform: scale(0.9);
  }
  @include bpm-xs {
    align-items: flex-start;
    padding: 20px 10px;
    margin-bottom: 25px;
    transform: scale(1);
  }
  &-left {
    width: 50%;
    @include bpm-md {
      width: 100%;
    }
    &--image {
      width: 100%;
    }
  }
  &-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 100px;
    text-align: left;

    @include bpm-md {
      align-items: center;
      margin: 0px 50px;
    }
    @include bpm-xs {
      padding: 0px 5px;
      margin: 0px 0px;
    }
    &--header {
      font-family: "Lato";
      font-style: normal;
      font-weight: 800;
      font-size: 40px;
      line-height: 48px;
      color: #333333;
      margin-bottom: 10px;
      @include bpm-md {
        text-align: left;
        width: 100%;
        margin-top: 25px;
      }
      @include bpm-xs {
        font-size: 24px;
        line-height: 29px;
      }
    }
    &--subHeader {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 31px;
      color: #666666;
      max-width: 620px;
      margin-bottom: 25px;
      @include bpm-lg {
        max-width: 500px;
      }
      @include bpm-md {
        max-width: 100%;
      }
      @include bpm-xs {
        font-size: 16px;
        line-height: 28px;
      }
    }
    &--buttonWrapper {
      width: 220px;
      @include bpm-md {
        width: 100%;
      }
    }
  }
}
