.studioLink {
  &-notification {
   
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23px;
    height: 22px;
    background-color: #e47762;
    border-radius: 50%;
    position: absolute;
    top: -13px;
    right: -3px;
    p {
     color:#FFFFFF;
      margin-right: 0px !important;
    }
  }
  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 21px;
    background-color: white;
    box-shadow: 0px 0px 21.938579559326172px 0px #00000033;
    padding: 7px 12px;
    width: max-content;
    p {
      font-family: Lato;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0em;
      margin-right: 10px;
    }
    .studioIcon {
      margin-right: 10px;
    }
    
  }
}
