.on-boarding-confirmation {
  &__wrapper {
    // padding: 2rem 1.5rem;
    width: 100%;
    // margin: 0rem 2rem 1.5rem;
  }
  justify-content: center;
  //   display: flex;
  //   flex-direction: column;
  &__heading {
    font-family: Lato;
    font-size: 2.4rem;
    margin-top: 0.4rem;
    font-style: normal;
    font-weight: 800;
    line-height: 26px;
    letter-spacing: -0.14px;
    text-align: center;
    color: rgba(51, 51, 51, 1);
  }
  &__sub-heading {
    font-family: Lato;
    font-size: 1.6rem;
    margin-top: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.14px;
    text-align: center;
    color: rgba(102, 102, 102, 1);
  }
  &__ticket-wrapper {
    margin-top: 3.2rem;
  }
  // .primary-button {
  //   width: 20rem;
  // }
}
