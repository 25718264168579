.fixed_header {
  width: 100%;
  table-layout: fixed;
  border-radius: 1rem;
  border-collapse: collapse;
}

.class_details_table {
  margin-bottom: 4.8rem;
}

.no-table-data-available-wrapper {
  background: #fff;
  width: 100%;
  padding: 3.2rem;
  &__p-text {
    font-family: Lato;
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0px;
    text-align: center;
    color: #333333;
  }
}

.fixed_header tbody {
  display: block;
  width: 100%;
  overflow: auto;
  // height: 35rem;
}

.fixed_header thead tr {
  display: block;
  border-radius: 2rem;
}

.fixed_header__wrapper {
  background: #fed700;
  border-radius: 6rem;
  // position: fixed;
  color: #fff;
  /* width: 100%; */
  height: 10rem;
  border-radius: 1.6rem 1.6rem 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.brown {
    background: #ffb541;
  }
  &.cream {
    background: #fdefd8;
  }
  &.blue {
    background: #aae4ff;
  }
  &.lightblue {
    background: rgba(153, 222, 255, 0.32);
  }
  &__body {
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid rgba(242, 182, 90, 0.48);
  }
  &__preparation {
    color: #f3705b !important;
    text-decoration: underline;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
    cursor: pointer;
  }
  &__paid {
    font-family: Lato;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
    color: #00a912 !important;
  }
  &__inputfield {
    border-color: #f9dcb0;
    width: 15rem;
    height: 3.6rem;
    border-radius: 0.4rem;
  }

  &__p-button {
    padding: 1rem 0rem !important;
    width: 80%;
    margin: auto;
    &.disable {
      cursor: not-allowed;
      background: linear-gradient(89.83deg, #7e7e7e 0.1%, #b0b0b0 99.81%);
    }
  }
}

.fixed_header tbody tr th {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  color: #333333;
}

.fixed_header tbody tr th {
  text-align: center;
  position: relative;
  top: 0;
}

.fixed_header th,
.fixed_header td {
  padding: 1.6rem 5px;
  text-align: center;
  width: 200px;
}

.fixed_header tr td {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  /* identical to box height */
  color: #666666;
}

table::-webkit-scrollbar {
  background: #fff;
}
table::-webkit-scrollbar-thumb {
  background-color: red;
}
