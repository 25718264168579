.homework-view-modal {
  &__wrapper {
    display: none;
  }
  //! Max width is 80% for the whole container
  max-width: 80%;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 0px 8px rgba(75, 0, 188, 0.08);
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 0px 8px rgba(75, 0, 188, 0.08);
  -webkit-appearance: none;
  border-radius: 16px;
  padding: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  //   overflow: hidden;
  display: none;
  @include bpm-custom(850px) {
    padding: 2rem 1.5rem;
    margin: 0 1.5rem;
    min-height: auto;
  }
  @include bpm-md {
    max-width: 85%;
  }
  //! swiper div contrainer
  &-content {
    .assignmentView-Swiper{
      // width: 250px;
    }
    height: 700px;
    width: 700px;
    @include bpm-xs {
      height: 400px;
    }
    overflow: hidden;
  
    h1 {
      // width: 700px;
    }
    // .homeWorkViewSwiper {
    //   width: 700px;
    //   .swiper-slide {
    //     width: 680px;
    //   }
    // }
  }
  //! css for the close button
  &__close {
    position: absolute;
    background-color: #fff;
    height: 4rem;
    width: 4rem;
    border-radius: 10rem;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
    -webkit-appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -2rem;
    right: -1.5rem;
    cursor: pointer;
    img {
      width: 3rem;
    }
  }
}
