.homeWorkPendingCard {
  &__wrapper {
    display: inline-grid;
    grid-template-columns: auto auto;
    margin: 20px;
    padding: 25px;

    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    width: 95%;
    @include bpm-xs {
      grid-template-columns: auto;
      width: 320px;
    }
  }
  //! CSS for the left half of the card
  &--left {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;

    //! CSS for the top half of the left section of the Card
    &-top {
      border-bottom: 1px solid black;
      word-break: break-all;
      h1 {
        font-family: Lato;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px;
        letter-spacing: -0.20000000298023224px;
        color: #333333;
        margin-bottom: 10px;
        word-break: normal;
      }
      h2 {
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.20000000298023224px;
        padding-bottom: 8px;
      }
      span {
        font-family: Lato;
        font-size: 28px;
        font-style: normal;
        font-weight: 300;
        line-height: 34px;
        letter-spacing: -0.20000000298023224px;
        display: inline-block;
      }
    }

    //! CSS for the bottom half of the left section of the Card
    &-bottom {
      h2 {
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: 24px;
        letter-spacing: -0.20000000298023224px;
        color: #ffffff;
        margin-top: 10px;
      }
      .assignment-description-text {
        // width: 570px;
        // @include bpm-sm {
        //   max-width: 300px;
        // }
        width: 570px;
        @include bpm-sm {
          width: 100%;
        }
      }
      p {
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.20000000298023224px;
        max-width: 570px;
        margin-top: 5px;
        @include bpm-sm {
          max-width: 480px;
        }
      }
      //! The css for the homeworkupload element on the bottom
      //! Overflow scroll added for the homework file element
      &-homeworkUpload {
        display: flex;
        align-items: center;
        margin: 10px 0px;
        background: rgba(255, 255, 255, 0.6);
        border: 1px solid rgba(153, 153, 153, 0.5);
        border-radius: 32px;
        width: max-content;
        padding: 5px 15px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
          display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        & {
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
        }
        @include bpm-xs {
          max-width: 200px;
          line-break: none;
        }
        img {
          width: 20px;
          height: auto;
        }
        p {
          margin: 0px;
          margin-left: 5px;
          font-family: Lato;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 19px;
          letter-spacing: -0.20000000298023224px;
          color: #000632;
        }
      }
    }
  }
  //! CSS for the right half of the card
  &--right {
    display: flex;
    flex-direction: column;
    text-align: left;
    @include bpm-xs {
      flex-direction: row;
    }
    justify-content: center;
    //! CSS for the top half of the right section of the Card
    //! To align the button were setting position to relative and utilizing negative top/right
    //! the values are reset at smaller devices (phones)
    &-top {
      position: relative;
      top: -25%;
      right: -20%;
      @include bpm-xs {
        top: 0%;
        right: 0%;
        display: flex;
        align-items: center;
        margin-right: 10px;
      }

      .primary-button {
        box-shadow: none;
        width: 116px;
        padding: 10px 0px;
      }
    }
    //! CSS for the bottom half of the right section of the Card
    &-bottom {
      border-left: 1px solid white;
      padding: 10px 0px;
      padding-left: 20px;
      h2 {
        font-family: Lato;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 29px;
        letter-spacing: -0.20000000298023224px;
        color: #333333;
      }
      #overdue {
        padding-right: 30px;
        @include bpm-xs {
          padding-right: 0px;
        }
      }
      h1 {
        font-family: Lato;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: -0.20000000298023224px;
        color: #333333;
      }
    }
  }
}
