.postpayment_modal {
  &_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 120vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
    display: none;
  }
  &-close {
    width: 42px;
    height: 42px;
    position: absolute;
    right: 10px;
    top: -15px;
    z-index: 1000;
    cursor: pointer;
  }
  background-color: #ffffff;
  border-radius: 16px;
  opacity: 1;
  width: 30%;
  position: relative;
  @include bpm-lg {
    width: 40%;
  }
  @include bpm-sm {
    width: 65%;
  }
  @include bpm-xs {
    width: 94%;
  }
  display: flex;
  flex-direction: column;
  &-head_wrapper {
    width: 100%;
    padding: 20px 15px 20px 30px;
    &--main-text {
      font-family: "Lato";
      font-weight: 900;
      font-size: 20px;
      line-height: 24px;
      color: #333333;
    }
    &--off-details {
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 22px;
      color: #e47762;
      margin-top: 16px;
    }
  }
  &-body {
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
  &-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    padding: 0 2.4rem;
  }
}
