.results-speech-card {
  &__wrapper {
    padding: 2.4rem;
    width: 45%;
    height: 45rem;
    border-radius: 2.4rem;
    background-color: rgba(34, 87, 126, 1);
    @include bpm-custom(1230) {
        padding: 1.6rem 2.4rem;
        width: 48%;
      }
      @include bpm-custom(2560) {
        height: 66rem;
      }
      @include bpm-custom(2000) {
        height: 57rem;
      }
      @include bpm-custom(1680) {
        height: 51rem;
      }
      @include bpm-custom(1410) {
        height: 45rem;
      } 
    @include bpm-md {
      width: 100%;
      height: 55.2rem;
      margin: 2rem auto;
      overflow-y: scroll;
      padding: 0;
    }
  }
  &__badge-icon {
    // position: absolute;
    // left: 35rem;
    object-fit: contain;
  }
  display: flex;
  flex-direction: column;
  min-width: 48%;
  min-height: 48rem;
  @include bpm-md {
    padding: 3.2rem 2.4rem;
  }
  @include bpm-xxs {
    padding: 3.2rem 2.4rem 12rem;
  }
  @include bpm-custom(375) {
    padding: 3.2rem 1rem 12rem;
  }
  &__primary-container {
    display: flex;
    justify-content: space-between;
    &__left {
      &--p-heading {
        font-family: Lato;
        font-size: 24px;
        position: relative;
        font-style: normal;
        text-transform: capitalize;
        font-weight: 800;
        line-height: 29px;
        letter-spacing: 0px;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        @include bpm-md {
          font-size: 20px;
          font-weight: 00;
          text-transform: capitalize;
        }
      }
      &--s-heading {
        font-family: Lato;
        font-size: 18px;
        margin-top: 0.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        color: rgba(255, 255, 255, 1);
      }
      &--p-text {
        color: rgba(255, 255, 255, 1);
        font-family: Lato;
        position: relative;
        font-size: 18px;
        margin-top: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
      }
    }
    &__right {
    }
  }
  &--p-text {
    color: rgba(255, 255, 255, 1);
    font-family: Lato;
    position: relative;
    font-size: 18px;
    margin-top: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
  &--s-text {
    font-family: Lato;
    font-size: 20px;
    margin-top: 2.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(255, 181, 65, 1);
  }
  &--broken-rules {
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-top: 1.6rem;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    &.margin-left {
      margin-left: 1.5rem;
      margin-top: 2px;
    }
  }
  &--rules {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    // margin-top: 3.2rem;
    line-height: 22px;
    @include bpm-custom(1230) {
      font-size: 16px;
      margin-top: 2.8rem;
    }
    @include bpm-custom(1024) {
      font-size: 14px;
    }
    @include bpm-md {
      font-size: 18px;
      // font-weight: 700;
    }
    letter-spacing: 0px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
  }
  &--download {
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 2.8rem;
    text-decoration: underline;
    color: rgba(255, 255, 255, 1);
  }
}
