.single-slide {
  position: relative;
  &__background {
    width: 100vw;
    @include bpm-xs {
      transform: scaleY(0.8);
    }
  }
  &__content {
    text-align: left;
    position: absolute;
    color: #000e6d;
    font-size: 2.5rem;
    font-weight: 600;
    width: 80rem;
    top: 25%;
    left: 20%;
    z-index: 10;
  }
  &__source {
    position: absolute;
    font-size: 3rem;
    color: #fff;
    bottom: 30%;
    left: 20%;
    z-index: 10;
  }
  @include bpm-lg {
    &__content {
      font-size: 2.2rem;
      top: 20%;
      width: 50rem;
    }
    &__source {
      font-size: 2.2rem;
      bottom: 27%;
    }
  }
  @include bpm-md {
    &__content {
      font-size: 2rem;
      width: 50rem;
    }
    &__source {
      font-size: 2.2rem;
    }
  }
  @include bpm-sm {
    &__content {
      font-size: 1.6rem;
      width: 70vw;
      top: 20%;
      left: 15%;
    }
    &__source {
      font-size: 1.8rem;
      bottom: 25%;
      left: 15%;
    }
  }
  @include bpm-custom(600px) {
    &__content {
      top: 15%;
    }
    &__source {
      font-size: 1.8rem;
      bottom: 23%;
    }
  }
  @include bpm-xs {
    &__content {
      font-size: 2.2rem;
      text-align: left;
      font-weight: 600;
      max-width: 85vw;
      top: 30%;
      left: 10%;
      z-index: 10;
    }
    &__source {
      font-size: 2.5rem;
      color: #fff;
      text-align: left;
      max-width: 85vw;
      bottom: 25%;
      left: 10%;
      z-index: 10;
    }
  }
  @include bpm-custom(460px) {
    &__content {
      font-size: 1.8rem;
      top: 22%;
      left: 10%;
    }
    &__source {
      font-size: 2.2rem;
      bottom: 30%;
      left: 10%;
    }
  }
  @include bpm-custom(400px) {
    &__content {
      font-size: 1.8rem;
      top: 20%;
      left: 10%;
    }
    &__source {
      font-size: 2rem;
      bottom: 30%;
      left: 10%;
    }
  }
}
.testimonial-slider {
  &__wrapper {
    overflow: hidden;
    position: relative;
    @include bpm-sm {
      margin-bottom: -10rem;
    }
    .swiper-pagination {
      transform: translate(-28%, 15%);
      @include bpm-md {
        transform: translate(-27%, 100%);
      }
      @include bpm-sm {
        transform: translate(-31%, 150%);
      }
      @include bpm-custom(620px) {
        transform: translate(-30%, 150%);
      }
      @include bpm-xs {
        transform: translate(-33%, -7rem);
      }
      @include bpm-custom(400px) {
        transform: translate(-33%, -5rem);
      }
    }
    .swiper-pagination-bullet {
      background-color: #4f4fff !important;
    }
    .swiper-pagination-bullet-active {
      background-color: #fff !important;
    }
  }
}
