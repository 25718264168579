.address-form {
  &__wrapper {
    background-color: #fff;
    @include bp-md {
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25),
        4px 4px 4px rgba(231, 231, 231, 0.25);
      -webkit-box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25),
        4px 4px 4px rgba(231, 231, 231, 0.25);
      -webkit-appearance: none;
      padding-top: 5rem;
    }
    @include bpm-md {
      background-color: transparent;
    }
    padding-bottom: 8rem;
  }
  display: flex;
  flex-direction: column;
  //   align-items: center;
  max-width: 30rem;
  margin: 0 auto;
  &__header {
    margin-bottom: 2rem;
  }
  input[type="text"] {
    // width: 31rem;
    height: 5rem;
    border: none;
    outline: none;
    padding: 0 1.5rem;
    &:focus,
    &:active {
      border: none;
      outline: none;
    }
    box-shadow: 0px 0px 8px rgba(75, 0, 188, 0.08);
    -webkit-box-shadow: 0px 0px 8px rgba(75, 0, 188, 0.2);
    -webkit-appearance: none;
    border-radius: 0.8rem;
    margin: 0.7rem 0;
    @include bpm-xs {
      width: 100%;
    }
  }
  &__separator {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.25rem 0;
    span {
      height: 1px;
      width: 8rem;
      background-color: #ccc;
    }
    p {
      font-size: 1.2rem;
      color: #aaa;
    }
  }
  &__state-and-pincode {
    display: flex;
    align-items: center;
    input {
      &:first-child {
        width: 100%;
        // width: 17rem;
        @include bpm-xs {
          width: 65%;
        }
      }
      // &:last-child {
      //   margin-left: 1.4rem;
      //   width: 11.6rem;
      //   @include bpm-xs {
      //     width: 32%;
      //   }
      // }
    }
  }
  label {
    margin: 2rem 0;
    align-self: center;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
  }
  .pay-button {
    align-self: center;
    color: #fff;
    background: linear-gradient(90deg, #f3705b 0%, #fcb444 100%);
    box-shadow: 0px 2px 6px rgba(91, 22, 170, 0.12), 0px 4px 14px #eeeeee,
      inset 1px 0px 3px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0px 2px 6px rgba(91, 22, 170, 0.12),
      0px 4px 14px #eeeeee, inset 1px 0px 3px rgba(255, 255, 255, 0.5);
    -webkit-appearance: none;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    cursor: pointer;
    border: none;
    outline: none;

    padding: 1.5rem 0 1.5rem 4rem;
    justify-content: space-between;
    position: relative;
    font-size: 18px;
    font-weight: 400;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
    -webkit-appearance: none;
    width: 22rem;
    img {
      position: absolute;
      width: 4rem;
      right: 0.6rem;
    }
    .tooltip {
      display: none;
    }
    &.disabled {
      background: linear-gradient(90deg, #989898 0%, #b7b7b7 100%);
      cursor: not-allowed;
      position: relative;
      .tooltip {
        display: none;
        transition: all 0.3s linear;
        position: absolute;
        top: 6rem;
        left: 50%;
        transform: translateX(-50%);
        color: #333;
        width: 20rem;
      }
      &:hover {
        .tooltip {
          display: block;
          transition: all 0.3s linear;
          background-color: #fff;
          border: 2px solid #fcb444;
          border-radius: 0.8rem;
          padding: 0.5rem 2rem;
          font-size: 1.4rem;
        }
      }
    }
  }
  .promo-code {
    display: none;
    position: relative;
    width: 31rem;
    transform: translateX(0.5rem);
    @include bpm-sm {
      width: 100%;
      transform: translateX(0);
    }
    margin: 0.7rem 0;
    overflow: hidden;
    box-shadow: 0px 0px 8px rgba(75, 0, 188, 0.08);
    -webkit-box-shadow: 0px 0px 8px rgba(75, 0, 188, 0.2);
    -webkit-appearance: none;
    &::after {
      content: "PROMO CODE";
      color: #fff;
      position: absolute;
      top: 0;
      right: 0;
      min-height: 1rem;
      width: 15rem;
      background-color: rgb(171, 33, 33);
      font-size: 1rem;
      font-weight: 700;
      text-align: center;
      padding: 0.6rem 0;
      transform: rotate(30deg) translate(1.5rem, 0.5rem);
    }
    &--input {
      width: 100%;
      margin: 0 !important;
    }
  }
  .alternate-sign-in {
    border: none;
    outline: none;
    background-color: transparent;
    color: #f3705b;
    font-size: 1.6rem;
    margin-bottom: 2rem;
    font-weight: 700;
    cursor: pointer;
  }
}
