.homeBookATrialCard {
  &__wrapper {
    display: flex;
    background: #ffffff;
    border-radius: 8px;
    padding: 20px;
    padding-bottom: 0px;
    text-align: left;
    width: 95%;
    margin: 0 auto;
    margin-top: 30px;
    justify-content: space-between;
    @include bpm-xs {
      align-items: flex-end;
      padding-bottom: 10px;
    }
  }
  &--left {
    h1 {
      font-family: Lato;
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      @include bpm-xs {
        font-size: 18px;
      }
      line-height: 24px;
      /* identical to box height */

      color: #333333;
    }
    h2 {
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      overflow: visible;

      @include bpm-xs {
        font-size: 15px;
        max-width: 196px;
      }
      line-height: 22px;
      /* identical to box height */
      margin-top: 10px;

      color: #666666;
    }
    .primary-button.version-2 {
      margin-top: 20px;
      @include bpm-xs {
        // margin: 0 auto;
        margin-top: 20px;
        width: 165px;
      }
    }
    &-bottom {
      display: flex;
      flex-direction: row;
      
      .dashboard-no-classes-mobile {
        display: none;
        @include bpm-xs {
          display: block;
          width: 72px;
        margin-left: 80px;
        }
      }
    }
  }
  &--right {
    margin-left: 40px;
    .dashboard-no-classes {
      @include bpm-xs {
        display: none;
      }
    }
  }
}
