.ambassador_wrapper {
  padding-top: 12rem;
  background: linear-gradient(
    180deg,
    #fee1e8 -6.98%,
    rgba(255, 253, 201, 0.4) 100%
  );
  .spark-about-us__strip {
    margin: -2rem 0 5rem 0;
  }
  .belief-section__wrapper {
    margin-bottom: 6rem;
  }
}
.head-usp {
  @include h1-styling;
  margin: 4rem 0;
}
