.coupon_modal {
    &_wrapper {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 120vh;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 100;
      display: none;
    }
    background-color: #ffffff;
    border-radius: 16px;
    opacity: 1;
    width: 30%;
    position: relative;
    @include bpm-lg {
      width: 40%;
    }
    @include bpm-sm {
      width: 55%;
    }
    @include bpm-xs {
      width: 80%;
    }
    display: flex;
    flex-direction: column;
    &-head_wrapper {
      width: 100%;
      padding: 15px;
      &--main-text {
        font-family: "Lato";
        font-weight: 700;
        font-size: 22px;
        line-height: 24px;
        color: #333333;
        text-align: center;
      }
      &--off-details {
        font-family: "Lato";
        text-align: center;
        background: rgba(242, 201, 76, 0.24);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #F3705B;
        margin-top: 16px;
        padding: 7px;
        border-radius: 2px;
      }
    }
    &-body {
        width: 100%;
        display:flex;
        justify-content: center;
        &--lottie{
      width: 250px;
      height: 250px;
        }
    
    }
    &-footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;
      padding: 0 2.4rem;
    }
  }
  