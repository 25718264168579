.spark-404-page {
  &__wrapper {
    padding: 7rem;
    min-height: 100vh;
    @include bpm-sm {
      padding: 7rem 2rem;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    .primary-button {
      width: 27rem;
    }
  }
  &__header {
    font-size: 6rem;
    margin: 5rem 0 2rem 0;
    color: #333;
    text-align: center;
    font-weight: 800;
    @include bpm-sm {
      font-size: 4rem;
    }
  }
  &__span {
    margin: 1.6rem 0;
    color: #666;
    font-weight: 500;
    max-width: 70rem;
    text-align: center;
    font-size: 1.8rem;
  }
  &__sub-header {
    font-size: 2.2rem;
    color: #666;
    font-weight: 500;
    max-width: 70rem;
    text-align: center;
    margin-bottom: 5rem;
    @include bpm-sm {
      max-width: 85vw;
      font-size: 1.8rem;
    }
  }
  &__heart {
    width: 7rem;
    margin: 0 0.5rem;
    transform: translateY(1.5rem);
    @include bpm-sm {
      width: 4rem;
      margin: 0 0.5rem;
      transform: translateY(0.6rem);
    }
  }
}
