.book-a-trial {
  &-wrapper {
    padding-top: 7rem;
    background-color: #fff;
  }
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2fr 3fr;
  @media screen and (max-width: 1200px) {
    max-width: 800px;
    grid-template-columns: 2fr 2fr;
  }
  @media screen and (max-width: 850px) {
    grid-template-columns: 1fr;
    max-width: 375px;
  }
  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 6rem;
    @media screen and (max-width: 850px) {
      padding: 0 2rem 6rem 2rem;
    }
    .booking {
      &-nav {
        width: 100%;
        margin-top: 2rem;
        border-bottom: 1px solid lightgrey;
        position: relative;
        .progress {
          content: "";
          position: absolute;
          bottom: -2px;
          // width: 100%;
          height: 2px;
          background-color: #90d4a3;
        }
        ul {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-inline-start: 0px;
          @media screen and (max-width: 1200px) {
            margin-bottom: 1.5rem;
          }
          @media screen and (max-width: 850px) {
            justify-content: space-between;
          }
          li + li {
            margin-left: 5rem;
            @media screen and (max-width: 1200px) {
              margin-left: 1.5rem;
            }
            @media screen and (max-width: 850px) {
              margin-left: 0;
            }
          }
          li {
            list-style: none;
            font-size: 1.6rem;
            display: flex;
            align-items: center;
            cursor: pointer;
            span {
              padding: 5px 11px;
              border-radius: 5px;
              color: #fff;
              margin-right: 1rem;
              @media screen and (max-width: 850px) {
                margin-right: 0;
              }
            }
            span.pending {
              background-color: lightgrey;
            }
            span.current {
              background-color: #f2b65a;
            }
            span.done {
              background-color: #90d4a3;
            }
          }
        }
      }
    }
  }
  &__right {
    align-self: center;
    img {
      width: 100%;
    }
    @media screen and (max-width: 850px) {
      display: none;
    }
  }
}
