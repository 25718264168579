.schoolpage-banner {
  &__wrapper {
    // padding-left: 7rem;
    // padding-top: 7rem;
    display: grid;
    @include bpm-custom(2560px) {
      // margin-bottom: -7rem;
      // height: 65vw;
      // grid-template-columns: 600px 1fr;
      padding-left: 8rem;
      padding-top: 20.5rem;
      margin-bottom: -12rem;
      height: 55vw;
      grid-template-columns: 817px 1fr;
    }
    @include bpm-xxl {
      // margin-bottom: -7rem;
      // height: 60vw;
      // grid-template-columns: 600px 1fr;
      padding-left: 5rem;
      padding-top: 15.5rem;
      margin-bottom: -12rem;
      height: 61vw;
      grid-template-columns: 558px 1fr;
    }
    @include bpm-custom(1660px) {
      height: 54vw;
    }
    @include bpm-xl {
      //! New properties
      padding-left: 5rem;
      padding-top: 11rem;
      //! *****
      margin-bottom: -5rem;
      // height: 63vw;
      // grid-template-columns: 425px 1fr;
      height: 62vw;
      grid-template-columns: 489px 1fr;
    }
    @include bpm-lg {
      margin-bottom: -5rem;
      grid-template-columns: 375px 1fr;
      height: 58vw;
    }
    @include bpm-md {
      grid-template-columns: 300px 1fr;
      height: 63vw;
    }
    @include bpm-custom(875px) {
      height: 58vw;
    }
    @include bpm-custom(800px) {
      height: 63vw;
    }
    @include bpm-sm {
      grid-template-columns: 250px 1fr;
      height: 78vw;
      padding-left: 3rem;
      // padding: 4rem 0 0 0;
      // min-height: 92rem;
      // grid-template-columns: 1fr;
      // grid-template-rows: 0.5fr 1fr;
    }
    @include bpm-custom(600px) {
      padding: 7rem 0 0 0;
      // min-height: 90rem;
      min-height: 95rem;
      grid-template-columns: 1fr;
      grid-template-rows: 0.5fr 1fr;
    }
    @include bpm-custom(460px) {
      // min-height: 75rem;
      padding: 7rem 0 0 0;
      min-height: 82rem;
      grid-template-columns: 1fr;
      grid-template-rows: 0.5fr 1fr;
      width: 100%;
    }
  }
  &__left {
    &--header {
      // font-size: 6.5rem;
      // margin-bottom: 2rem;
      // margin-top: 8rem;
    }
    &--subheader {
      font-size: 3rem;
      line-height: 3.2rem;
      font-weight: 600;
      margin-bottom: 4rem;
      color: #666;
    }
    &__buttonContainer {
      display: flex;
      justify-content: space-between;
      width: 95%;
      margin-top: 0.4rem;
      @include bpm-xs {
        align-items: flex-start;
        flex-direction: column;
      }
      &__s-button {
        padding: 1.5rem 3rem;
        justify-content: center;
        font-weight: 600;
        cursor: pointer;
        align-items: center;
        font-size: 1.6rem;
        border-radius: 4.8rem;
        border-color: #fcb444;
        border-width: 1px;
        border-style: solid;
        font-family: Lato;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0px;
        text-align: left;
        @include bpm-xs {
          margin-top: 1.6rem;
          padding: 1.3rem 1.5rem;
          font-size: 1.5rem;
        }
      }
    }
    @include bp-lg {
      .primary-button {
        // font-size: 2.8rem;
        // padding-left: 6rem;
        // width: 40rem;
        // height: 8rem;
      }
    }
    margin-top: 3rem;
    @include bpm-custom(2560px) {
      //! New properties
      width: 69.3rem;
      height: 30rem;
      &--header {
        // line-height: 10.5rem;
        // color: #000632;
        // font-size: 7.8rem;
        font-weight: 800;
        //! New properties
        color: #000632;
        font-size: 7.8rem;
        line-height: 9.8rem;
        margin-bottom: 2.4rem;
      }
      &--subheader {
        font-size: 3rem;
        line-height: 3.2rem;
        font-weight: 600;
        margin-bottom: 4rem;
        color: #666666;
      }
      &--subheader-gradient {
        display: inline;
        font-size: 3rem;
        line-height: 3.2rem;
        font-weight: 600;
        margin-bottom: 4rem;
        color: #666666;
      }
    }
    @include bpm-xxl {
      //! new properties
      // 1442 x 900 Machines +
      width: 59.3rem;
      height: 24rem;
      &--header {
        // line-height: 10.5rem;
        // color: #000632;
        // font-size: 9.6rem;
        font-weight: 800;
        //! new properties
        color: #000632;
        font-size: 6.4rem;
        line-height: 8rem;
        margin-bottom: 2.4rem;
      }
      &--subheader {
        font-size: 2.4rem;
        line-height: 2.88rem;
        font-weight: 600;
        margin-bottom: 4rem;
        color: #666666;
      }
      &--subheader-gradient {
        display: inline;
        font-size: 2.4rem;
        line-height: 2.88rem;
        font-weight: 600;
        margin-bottom: 4rem;
        color: #666666;
      }
    }
    @include bpm-xl {
      //! New properties
      // Narrower Machines
      width: 51.1rem;
      height: 21rem;
      //! *******
      &--header {
        // font-size: 7.2rem;
        // line-height: 8.5rem;
        // margin-bottom: 5rem;
        color: #000632;
        font-size: 5.6rem;
        line-height: 7rem;
        margin-bottom: 2.4rem;
        font-weight: 800;
      }
      &--subheader {
        font-size: 2.2rem;
        line-height: 2.64rem;
        font-weight: 600;
        margin-bottom: 4rem;
        color: #666666;
      }
      &--subheader-gradient {
        display: inline;
        font-size: 2.2rem;
        line-height: 2.64rem;
        font-weight: 600;
        margin-bottom: 4rem;
        color: #666666;
      }
    }
    @include bpm-lg {
      //! New
      width: 51.1rem;
      height: 21rem;
      &--header {
        // font-size: 6.5rem;
        // line-height: 7.5rem;
        color: #000632;
        font-size: 5.6rem;
        line-height: 7rem;
        margin-bottom: 2.4rem;
        font-weight: 800;
      }
      &--subheader {
        font-size: 2.2rem;
        line-height: 2.64rem;
        font-weight: 600;
        margin-bottom: 4rem;
        color: #666666;
      }
      &--subheader-gradient {
        display: inline;
        font-size: 2.2rem;
        line-height: 2.64rem;
        font-weight: 600;
        margin-bottom: 4rem;
        color: #666666;
      }
    }
    @include bpm-md {
      margin-top: 6rem;
      &--header {
        line-height: 6rem;
        font-size: 5rem;
      }
    }
    @include bpm-sm {
      //! New
      // padding-left: 2rem;
      // width: 51.1rem;
      // height: 21rem;
      //!
      &--header {
        color: #000632;
        font-size: 4rem;
        line-height: 5rem;
        //!
        margin-bottom: 2.4rem;
        font-weight: 800;
      }
      &--subheader {
        font-size: 2.1rem;
        line-height: 2.64rem;
        font-weight: 600;
        margin-bottom: 4rem;
        color: #666666;
        width: 250px;
      }
      &--subheader-gradient {
        display: inline;
        font-size: 1.8rem;
        line-height: 2.64rem;
        font-weight: 600;
        margin-bottom: 4rem;
        color: #666666;
      }
    }
    @include bpm-custom(600px) {
      margin-top: 3rem;
      margin-bottom: 12rem;
      &--header {
        //!
        color: #000632;
        line-height: 7rem;
        //!
        margin-bottom: 3rem;
        font-size: 4.5rem;
        font-weight: 800;
      }
      &--subheader {
        font-size: 2rem;
        line-height: 2.64rem;
        font-weight: 600;
        margin-bottom: 4rem;
        color: #666666;
      }
      &--subheader-gradient {
        display: inline;
        font-size: 2rem;
        line-height: 2.64rem;
        font-weight: 600;
        margin-bottom: 4rem;
        color: #666666;
      }
      padding: 0 3rem;
    }
    @include bpm-custom(460px) {
      &--header {
        font-size: 4rem;
        font-weight: 800;
        line-height: 5rem;
        margin-bottom: 2rem;
      }
      &--subheader {
        margin-bottom: 3.2rem;
        width: 100%;
      }
    }
    @include bpm-xs {
      width: 100%;
    }
  }
  &__right {
    position: relative;
    overflow: hidden;

    &--image-1 {
      position: absolute;
      right: 0rem;
      top: 0;
      width: 100%;
    }
    &--image-2 {
      position: absolute;
      right: -25rem;
      bottom: 0rem;
      width: 85%;
      z-index: 2;
    }
    @include bpm-custom(2560px) {
      &--image-1 {
        width: 80%;
      }
      &--image-2 {
        right: -15rem;
        width: 50%;
      }
    }
    @include bpm-xxl {
      &--image-1 {
        width: 80%;
      }
      &--image-2 {
        right: -15rem;
        width: 50%;
      }
    }
    @include bpm-custom(1660px) {
      &--image-1 {
        width: 80%;
      }
      &--image-2 {
        right: -15rem;
        width: 50%;
      }
    }
    @include bpm-xl {
      &--image-1 {
        width: 80%;
      }
      &--image-2 {
        right: -9rem;
        width: 50%;
      }
    }
    @include bpm-lg {
      &--image-1 {
        width: 70%;
      }
      &--image-2 {
        right: -7rem;
        width: 50%;
      }
    }
    @include bpm-md {
      &--image-1 {
        width: 80%;
      }
      &--image-2 {
        width: 50%;
      }
    }

    @include bpm-custom(800px) {
      &--image-1 {
      }
      &--image-2 {
        width: 60%;
        right: -7rem;
      }
    }
    @include bpm-sm {
      &--image-1 {
        width: 100%;
        top: 0rem;
        // width: 77%;
        // top: 9.2rem;
        // left: -5rem;
      }
      &--image-2 {
        width: 70%;
        // width: 60%;
      }
    }
    @include bpm-custom(600px) {
      &--image-1 {
        top: 7rem;
        left: -4rem;
      }
      &--image-2 {
        // width: 70%;
        width: 66%;
      }
    }
  }
}
