.teacher-live-page {
  &__wrapper {
    padding: 5rem 4rem;
    margin: 8rem 0 4rem;
    height: 100%;
    @include bpm-md {
      padding: 5rem 2.4rem;
    }
  }
  &__joinclass-container {
    &__wrapper {
      padding: 0 0.8rem;
      margin-bottom: 4.8rem;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include bpm-md {
      flex-direction: column;
    }
    &__left {
      display: flex;
      flex-direction: column;
      &__calander_container {
        &.desktop {
          @include bpm-md {
            display: none;
          }
        }
        &.mobile {
          @include bp-md {
            display: none;
          }
          width: 100%;
          padding: 2rem 3rem;
        }
      }
      &--p-text {
        font-family: "Lato";
        font-style: normal;
        font-weight: 900;
        font-size: 40px;
        line-height: 50px;
        color: #000000;
        @include bpm-md {
          font-family: Lato;
          font-size: 24px;
          font-weight: 900;
          line-height: 32px;
          letter-spacing: 0px;
          text-align: center;
          margin-bottom: 1.6rem;
        }
      }
      &__calander_icon {
        position: relative;
        &__date {
          font-family: Lato;
          font-size: 58px;
          font-weight: 900;
          line-height: 70px;
          letter-spacing: 0px;
          text-align: left;
          color: "#333333";
          position: absolute;
          top: 30px;
          left: 10px;
        }
        &__month {
          color: "#000";
          font-family: Lato;
          font-size: 17px;
          font-weight: 900;
          line-height: 20px;
          letter-spacing: 0px;
          text-align: center;
          color: #ffffff;
          position: absolute;
          top: 17px;
          left: 25px;
        }
      }
    }
  }
  &__table__wrapper {
    padding-left: 80px;
    margin-bottom: 3.2rem;
    @include bpm-lg {
      padding-left: 0px;
    }
  }
  &__tablecontainer {
    border-radius: 1.6rem;
    margin-bottom: 3.6rem;
    background: #fdefd8;
    padding: 0.2rem 2.4rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    &.blue {
      background: #aae4ff;
    }
    justify-content: space-between;
    &.expand {
      background: none;
    }

    &__left {
      display: flex;
      align-items: center;
      // margin-bottom: 3.6rem;
      // border-radius: 1.6rem;
      // margin-bottom: 3.6rem;
      // background: #fdefd8;
      // padding: 0.2rem 2.4rem;
      &__img {
        width: 1.6rem;
        height: 1.6rem;
        object-fit: contain;
        margin-left: 1.4rem;
        @include bpm-xs {
          width: 1.2rem;
          height: 1.2rem;
          object-fit: contain;
          margin-left: 2rem;
        }
      }
      &__s-text {
        font-family: Lato;
        font-size: 24px;
        font-style: italic;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        color: #fa3e3e;
        margin-left: 1.6rem;
      }
    }
    &__right {
      font-family: Lato;
      font-size: 24px;
      font-weight: 700;
      line-height: 34px;
      letter-spacing: 0px;
      text-align: left;
      color: #333333;
      text-decoration: underline;
      @include bpm-xs {
        font-size: 20px;
      }
    }
  }
  &__p-heading {
    font-family: "Lato";
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
    margin: 1.6rem 0;
    padding: 0 0.8rem;
    @include bpm-xs {
      padding: 0 0;
      font-size: 20px;
      line-height: 25px;
    }
  }
}
