.profileEditButton {
  display: flex;
  align-items: center;
  width: 102px;
  height: 40px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  justify-content: center;
  border: 1px solid#FFB541;
  cursor: pointer;
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #ffb541;
    margin-left: 10px;
  }
}
