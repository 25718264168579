.spellingBee_masterClass {
  &-container {
    margin-top: 40px;
    @include bpm-sm {
      margin-top: 24px;
    }
  }
  &--heading {
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    @include bpm-sm {
      font-size: 24px;
      line-height: 29px;
    }
  }
  &_card {
    &-wrapper {
      margin-top: 40px;
      padding-left: 15%;
      padding-right: 15%;
      @include bpm-sm {
        padding-left: 16px;
        padding-right: 16px;
        margin-top: 24px;
      }
      // display: flex;
      // justify-content: center;
    }

    background: linear-gradient(
      113.87deg,
      #a0dce7 -9.5%,
      rgba(255, 255, 255, 0.5) 97.11%
    );
    box-shadow: 50px 60px 100px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(35px);
    border-radius: 16px;

    display: flex;
    @include bpm-sm {
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }

    &--left {
      width: 25%;
      img {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        // width: 270px;
        @include bpm-sm {
          height: 226px;
        }
      }
      @include bpm-sm {
        width: 100%;
      }
    }
    &--right {
      display: flex;
      padding: 24px;
      justify-content: space-between;
      @include bpm-sm {
        flex-direction: column;
        padding: 0 24px;
        text-align: left;
      }
      &--left {
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 16px;
        &_courseName {
          font-weight: 900;
          font-size: 26px;
          line-height: 31px;

          color: #333333;
          @include bpm-sm {
            font-size: 26px;
            line-height: 31px;
          }
        }
        &_hostName {
          font-weight: 300;
          font-size: 16px;
          line-height: 21px;
        }
        &_joiningInfo {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 11px;
          line-height: 13px;
          color: #999999;
          margin-top: 21px;
          p {
            @include bpm-sm {
              white-space: nowrap;
            }
          }
          img {
            height: 16px;
            width: 16px;
            margin-right: 8px;
          }
        }
        &--reschedule {
          font-size: 16px;
          text-decoration: underline;
          cursor: pointer;
          @include bpm-sm {
            font-size: 14px;
          }
        }
        @include bpm-sm {
          margin-top: 0;
          padding-bottom: 24px;
        }
      }
    }
    &--right {
      display: flex;

      @include bpm-sm {
        margin: 16px 0 24px 0;
        flex-direction: column;
      }
    }
  }
}
