.bottom-sheet {
  &__wrapper {
    padding: 2rem 1.5rem 0rem;
    z-index: 1000;
    @include bp-md {
      display: none;
    }
    @include bpm-md {
      padding: 0rem 0rem 0rem;
      position: fixed;
      border-radius: 0 0 2.4rem 2.4rem;
      bottom: 2rem;
      width: 100%;
      z-index: 1000;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  display: flex;
  flex-direction: column;
  padding: 0 2rem 1rem;
  &__container {
    display: flex;
    // align-items: center;
    // flex-direction: column;
    justify-content: flex-end;

    margin-top: 2rem;
    &--p-text {
      font-family: Lato;
      font-size: 15px;
      font-style: normal;
      text-decoration: underline;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: rgba(255, 255, 255, 1);
      @include bpm-xxs {
        font-size: 13px;
      }
    }
    // &--navigation-button {
    //   //

    // }
  }
  &__state-level {
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    @include bp-md {
      display: none;
    }
  }
  .primary-button {
    box-shadow: none;
    padding: 0;
    width: 16.7rem;
    height: 4rem;
    font-size: 1.3rem;
    @include bp-md {
      display: none;
    }
    @include bpm-xxs {
      width: 14rem;
      font-size: 1.2rem;
    }
  }
}
