.spelling-bee-certificate {
  &_wrapper {
    height: 500px;
    width: 650px;
    position: relative;

    img {
      height: 100%;
      width: 100%;
    }
  }
  &-childName {
    font-family: Lato;
    font-size: 28px;
    font-weight: 900;
    line-height: 39px;
    letter-spacing: 0em;
    position: absolute;
    top: 211.5px;
    left: 31px;
    color: #333333;
  }
  &-childGrade {
    font-family: Lato;
    font-size: 18px;
    font-weight: 900;
    line-height: 39px;
    letter-spacing: 0em;
    position: absolute;
    top: 265px;
    left: 98px;
    color: #333333;
  }
}
