.teacher-preparation {
  &__wrapper {
    padding: 4.8rem 7.2rem;
    margin: 2.4rem 0;
    @include bpm-xs {
      padding: 4.8rem 3rem;
    }
  }
  // display: flex;
  &__headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.8rem;
    @include bpm-md {
      flex-direction: column;
    }
    &--p-text {
      font-family: Lato;
      font-size: 40px;
      font-weight: 800;
      line-height: 48px;
      letter-spacing: 0px;
      text-align: left;
      @include bpm-xs {
        font-size: 28px;
        text-align: center;
        align-items: center;
        display: flex;
      }
      @include bpm-custom(375) {
        font-size: 2.4rem;
      }

      // margin-top: 4.8rem;
    }
    &--img {
      margin-right: 1.2rem;
      cursor: pointer;
    }
    &__button {
      // background: #f68974;
      -webkit-box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
      box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
      border-radius: 20rem;
      // width: 60.8rem;
      // height: 7.2rem;
      border: 3px solid #f4735a;
      -webkit-justify-content: center;
      justify-content: center;
      text-align: center;
      display: -webkit-flex;
      display: flex;
      font-size: 1.6rem;
      -webkit-align-items: center;
      align-items: center;
      padding: 1.2rem 1.6rem;
      cursor: pointer;

      &.desktop {
        @include bpm-md {
          display: none;
        }
      }
      &.mobile {
        @include bp-md {
          display: none;
        }
        margin: 10px;
        width: 100%;
      }
      &--text {
        font-family: Lato;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
        color: #000000;
        margin-right: 1.6rem;
      }
    }
  }

  &__batchDetailsContainer {
    &__wrapper {
      padding: 1.6rem 4.8rem;
      background-color: #fff;
      margin-top: 4.8rem;
      border-radius: 1.6rem;
      @include bpm-md {
        padding: 2.4rem;
        margin-top: 2.4rem;
      }
    }
    display: flex;
    justify-content: space-between;
    border-radius: 1.6rem;
    width: 100%;
    // height: 20.6rem;
    &__left {
      &__batchDetailsContainer {
        display: flex;
        margin-top: 1.6rem;
        &--keyText {
          font-family: Lato;
          font-size: 24px;
          font-weight: 900;
          line-height: 29px;
          letter-spacing: 0px;
          text-align: left;
          color: #333333;
          @include bpm-xs {
            font-size: 22px;
            width: 50%;
          }
          @include bpm-custom(400) {
            font-size: 20px;
          }
        }
        &--valueText {
          font-family: Lato;
          font-size: 24px;
          font-weight: 700;
          line-height: 29px;
          letter-spacing: 0px;
          text-align: left;
          color: #666666;
          margin-left: 1.6rem;
          @include bpm-xs {
            font-size: 20px;
          }
        }
      }
      &__showcaseContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #0e1232;
        height: 6rem;
        width: 34rem;
        border-radius: 1.6rem;
        padding: 0.4rem 0.8rem;
        margin: 1.6rem 0;
        &__img {
          margin-left: 0.4rem;
        }
        &--p-text {
          font-family: Lato;
          font-size: 14px;
          font-weight: 700;
          line-height: 18px;
          margin-left: 1.4px;
          letter-spacing: 0px;
          text-align: left;
          color: #fff;
        }
      }
    }
    &__right {
      @include bpm-md {
        display: none;
      }
      display: flex;
      width: 50%;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__joinclass {
        margin: 1.6rem 0.8rem;
        width: 63%;
        &.disable {
          cursor: not-allowed;
          background: linear-gradient(89.83deg, #7e7e7e 0.1%, #b0b0b0 99.81%);
        }
        &.mobile {
          @include bp-md {
            display: none;
          }
        }
        @include bpm-md {
          margin: 2.4rem 0.8rem 1.6rem;
          width: 100%;
        }
      }
      &__submitFeedbackButton {
        border: #ffb300;
      }
    }
  }

  &__materialContainer {
    display: flex;
    justify-content: space-around;
    margin: 2.4rem 0;
    &__button {
      background: #f68974;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 208px;
      width: 60.8rem;
      height: 7.2rem;
      justify-content: center;
      text-align: center;
      display: flex;
      align-items: center;
      cursor: pointer;
      &.yellow {
        background: #f2b65a;
      }
      @include bpm-md {
        width: 100%;
        height: 5rem;
      }
      &__buttonText {
        font-family: Lato;
        font-size: 32px;
        font-weight: 400;
        line-height: 38px;
        letter-spacing: 0px;
        text-align: left;
        color: #333333;
        @include bpm-md {
          font-size: 24px;
        }
      }
      &--icon {
        width: 4.2rem;
        height: 4.2rem;
        object-fit: contain;
        margin-right: 1.6rem;
        @include bpm-md {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }
    @include bpm-md {
      flex-direction: column;
      gap: 2rem;
    }
  }

  &__previousContainer {
    background: #fff;
    border-radius: 1.6rem;
    width: 100%;
    min-height: 7.2rem;
    &__header {
      background-color: #ffd77c;
      width: 100%;
      min-height: 7.2rem;
      padding: 0.8rem 4.2rem;
      border-radius: 1.6rem 1.6rem 0 0;
      @include bpm-xs {
        min-height: 5rem;
        padding: 0.8rem 2.4rem;
      }
      &.grey {
        background-color: rgba(153, 153, 153, 0.32);
      }
      &--p-text {
        font-family: Lato;
        font-size: 40px;
        font-weight: 800;
        line-height: 48px;
        letter-spacing: 0px;
        text-align: left;
        color: #333333;
        @include bpm-xs {
          font-size: 20px;
        }
      }
    }
    &--p-heading {
      font-family: Lato;
      font-size: 24px;
      font-weight: 800;
      line-height: 29px;
      letter-spacing: 0px;
      text-align: left;
      color: #333333;
      padding: 0.8rem 3.6rem 1.6rem;
    }
    &--s-heading {
      font-family: Lato;
      font-size: 24px;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: 0px;
      text-align: left;
      padding: 0.8rem 3.6rem 1.6rem;
      color: #333333;
    }
    &--warning {
      font-family: Lato;
      font-size: 24px;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: 0px;
      text-align: left;
      color: rgba(250, 62, 62, 1);
      padding: 2.8rem;
      @include bpm-xs {
        padding: 0.8rem 2.4rem 1.6rem;
        font-size: 20px;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0px;
      }
    }
  }
}
