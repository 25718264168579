.brand-new-card {
  &__wrapper {
    max-width: 100%;
    min-height: 45rem;
    padding: 7rem 4% 7rem 8%;
    margin-bottom: 2rem;
    background-color: #f7eee7;
    // background: url("../../../public/assets/brandnewCard_bg.png") center
    //   center/contain no-repeat;
    @include bpm-md {
      min-height: 80rem;
    }
  }
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 3%;
  //   grid-auto-flow: column;
  &__left {
    &__wrapper {
      padding: 0;
    }
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @include bpm-md {
      align-items: flex-start;
    }

    &--p-heading {
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 52px;
      letter-spacing: 0px;
      text-align: left;
      color: rgba(51, 51, 51, 1);
      .underline {
        text-decoration: underline;
        font-weight: 800;
        @include bpm-md {
          font-size: 24px;
          line-height: 29px;
        }
      }
      @include bpm-md {
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        text-align: left;
      }
    }

    &--sub-heading {
      font-family: Lato;
      font-size: 16px;
      width: 60%;
      font-style: normal;
      margin-top: 2rem;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0px;
      text-align: left;
      color: rgba(153, 153, 153, 1);
      @include bpm-md {
        width: 100%;
      }
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    position: relative;
    // background: url("../../../public/assets/brandnewCard_bg.png") center
    //   center/contain no-repeat;
    &--background-img {
    }
    @include bpm-md {
      align-items: center;
      margin-top: 4rem;
    }
    gap: 2rem;
    &__card {
      display: flex;
      background-color: rgba(255, 255, 255, 1);
      //   padding: 1.5rem 3rem;
      padding: 0.5rem 2rem;
      align-items: center;

      min-height: 7.6rem;
      border-radius: 1.6rem;
      justify-content: "space-between";
      width: 40rem;
      &--p-text {
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 28px;
        margin-left: 2rem;
        letter-spacing: 0px;
        text-align: left;
        color: rgba(0, 0, 0, 1);
        @include bpm-xxs {
          font-size: 13px;
        }
      }
      &--card-icon {
        @include bpm-xxs {
          width: 3.7rem;
          height: 3.7rem;
          object-fit: contain;
        }
      }
      @include bpm-xxs {
        width: 98%;
      }
    }
  }
  @include bpm-md {
    grid-template-rows: 55%;
    grid-template-columns: 100%;
  }
  .primary-button {
    width: 20rem;
    padding: 1.5rem 1rem;
    font-weight: 500;
  }
}
