.custom-selector {
  &_wrapper {
    // width: 100%;
    margin-bottom: 1rem;
    border-radius: 8px;
    // overflow: hidden;
    // padding: 1rem 1rem;
    position: relative;
  }
  &_header {
    display: flex;
    border-radius: 8px;
    justify-content: space-between;
    align-items: center;
    background: #fff0d8;
    padding: 1rem 1rem;
    position: relative;

    cursor: pointer;
    &-icon {
      width: 18px;
      height: 18px;
    }
    &-selected {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      color: #333333;
    }
  }
  &_dropdown {
    position: absolute;
    z-index: 100;
    padding: 0rem 0rem;
    width: max-content;
    &-item {
      font-family: "Lato";
      font-style: normal;
      padding: 0.7rem 1rem;
      cursor: pointer;
      font-size: 13px;
    }
  }
}
