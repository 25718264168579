.ambassador-form {
  .PhoneInput {
    margin-bottom: 1.5rem;
    &Country {
      justify-content: space-evenly;
      width: 7rem;
      max-height: 5.5rem;
      &Icon {
        width: 3.8rem;
        height: 2.5rem;
        border: none;
        outline: none;
        background-color: transparent;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
        -webkit-appearance: none;
      }
      .PhoneInputCountrySelectArrow {
        width: 0.8rem;
        height: 0.8rem;
      }
    }
    width: 100%;
    .spark-booking-input-box__wrapper {
      width: 100%;
      margin: 0;
    }
  }
  &-split-image1 {
    display: none;
  }
  &-split-image2 {
    display: none;
  }
  .testing {
    // padding-top: 100px;
    .primary-button.version-1 {
      width: 60%;
      margin: 0 auto;
      margin-top: 2rem;
    }
  }

  .title {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: center;
    &.schoolsform {
      font-size: 22px;
    }
  }
  &-input {
    padding: 40px 30px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 30px;
    width: 415px;
    margin: 0 auto;
    position: relative;
    box-shadow: 0px 2px 6px rgba(91, 22, 170, 0.12),
      inset 1px 0px 3px rgba(255, 255, 255, 0.5);
    @include bpm-xs {
      padding: 40px 20px;
    }
    .spark-booking-input-box__wrapper {
      width: 100%;
    }
  }
  &-image {
    position: absolute;
    img {
      width: 100%;
    }
  }

  input[type="tel"] {
    width: 100%;
  }

  text-align: center;

  @include bpm-md {
    &-image {
      display: none;
    }
    &-input {
      width: 90%;
    }
    &-split-image2 {
      height: auto;
      width: 100%;
      display: block;
    }
    &-split-image1 {
      display: block;
    }
    .testing {
      padding-top: 0px;
    }
  }
}
