.eligibleCoursesTeacherCard {
  //! The teacher card is set to flex with direction column

 &__wrapper{
   
 }
 //! CSS for the react player on the eligible courses card
 &--reactplayer{
   border-radius: 20px;
   overflow:hidden;
   border: 2px solid white;
   //! At sizes including ipad pros and lower, width is set to 300px
   @include bpm-lg{
    width: 300px !important;
    height: 230px !important;
   }
 }
  // &__wrapper {
  //   display: flex;
  //   flex-direction: column;
  //   border-radius: 24px;
  //   overflow: hidden;
  //   margin-left: 25px;
  //   width: 400px;
  //   //! Set max size for the image on phones
  //   @include bpm-xs {
  //     // max-width: 290px;
  //   }
  //   //! Set margin left to 0 on ipads and lower
  //   @include bpm-sm {
  //     margin-left: 0px;
  //   }
  //   background: linear-gradient(
  //     114.28deg,
  //     #fdcd4c -12.61%,
  //     rgba(255, 255, 255, 0) 94.49%
  //   );

  //   img {
  //   }
    
    
  // }
  // //! The text is set to left align
  // &--bottom {
  //   text-align: left;
  //   padding: 20px;
  //   h1 {
  //     font-family: Lato;
  //     font-size: 15px;
  //     font-style: normal;
  //     font-weight: 900;
  //     line-height: 18px;
  //     letter-spacing: 0px;
  //     color: #000632;
  //     margin-bottom: 10px;
  //   }
  //   h2 {
  //     font-family: Lato;
  //     font-size: 9px;
  //     font-style: normal;
  //     font-weight: 400;
  //     line-height: 14px;
  //     letter-spacing: 0px;
  //     color: #333333;
  //   }
  // }
}
