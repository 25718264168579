.social-alert {
  border-bottom: 1px solid lightgrey;
  width: 100vw;

  ul {
    max-width: 1100px;
    margin: 0 auto;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.7rem 0;
    li + li {
      margin-left: 1.5rem;
    }
  }
}
.twitter,
.instagram,
.facebook {
  width: 2rem;
  fill: rgb(96, 98, 100);
  cursor: pointer;
  transition: all 0.15s linear;
  &:hover {
    transition: all 0.15s linear;
    fill: rgb(56, 192, 192);
  }
}
.facebook {
  width: 1.8rem;
}
