.spark-loader {
  &__wrapper {
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__header {
    font-size: 4rem;
    text-align: center;
    margin: 4rem 0;
    color: #ff8671;
    @include bpm-sm {
      font-size: 2.2rem;
      margin: 3rem 0 2rem 0;
    }
  }
  &__image {
    width: 100%;
  }
  &__logo {
    width: 15rem;
    margin-top: 3rem;
    @include bpm-sm {
      width: 10rem;
      margin-top: 1rem;
    }
  }
}
