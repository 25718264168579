.onboarding-invitecard {
  &__wrapper {
    margin: 2rem auto 3.5rem;
    padding: 2.4rem 2rem 2rem;
    width: 32.8rem;
    font-family: Lato;
    border-radius: 1rem;
    background: linear-gradient(143.91deg, #2274ff -33.59%, #ff36d3 109.08%);
  }
  h2 {
    font-family: Lato;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;
    color: linear-gradient(90deg, #e47762 0%, #f2b65a 98.07%);
  }

  &__viewButton {
    margin: 1.2rem auto;
    // width: 18rem;
    width: 15.6rem;
    font-weight: 600;
    height: 4.3rem;
    display: flex;
    font-size: 1.4rem;
    justify-content: center;
  }
  // background-color: #2274ff;
  min-height: 42.5rem;

  justify-content: center;
  &--header {
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.20000000298023224px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
  }
  &--ticket {
    margin: 2.4rem 0 3.2rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  h1 {
    font-family: Lato;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    // padding: 0 3rem;
    line-height: 35px;
    letter-spacing: -0.20000000298023224px;
    text-align: center;

    color: rgba(236, 236, 236, 1);
    span {
      font-family: Lato;
      font-size: 2rem;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: rgba(255, 199, 0, 1);
      letter-spacing: -0.20000000298023224px;
    }
  }
  &__shareContainer {
    display: flex;
    justify-content: center;
    padding: 1.4rem 1.3rem 1.3rem;
    border-radius: 3.2rem;
    margin-top: 3.2rem;
    height: 4.6rem;
    width: 29rem;
    background-color: rgba(255, 255, 255, 1);
    &--whatsapp-icon {
      width: 1.8rem;
      height: 1.6rem;
    }
    &--title {
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      margin-left: 1rem;
      font-weight: 700;
      color: rgba(72, 200, 87, 1);
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  &__copy-url-container {
    display: flex;
    justify-content: center;
    border-radius: 3.2rem;
    margin-top: 1.6rem;
    cursor: pointer;
    height: 5rem;
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    &--url {
      width: 62%;
      font-family: Lato;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      color: rgba(64, 134, 255, 1);
      letter-spacing: 0em;
      text-align: left;
      margin: 2rem 2rem 1.4rem 2.4rem;
    }
    &__copy-link {
      width: 38%;
      height: 100%;
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f2b65a;
      margin-left: auto;
      border-radius: 0 3.2rem 3.2rem 0;
      h2 {
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        color: rgba(255, 255, 255, 1);
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
  &__progressBar-wrapper {
    margin: 0rem 2rem 2rem;
    padding: 0rem 1rem;
    width: 95%;
    font-family: Lato;
    border-radius: 1rem;
  }
}
.onboarding-invitecard-b {
  &__wrapper {
    margin: 0rem auto 0rem;
    padding: 2rem 1.5rem;
    // @include bpm-md {
    //   margin: 0;
    //   padding: 1.5rem 1.5rem 2rem;
    // }
    width: 95%;
    font-family: Lato;
    border-radius: 1rem;
    // background: linear-gradient(143.91deg, #2274ff -33.59%, #ff36d3 109.08%);
  }
  // background-color: #2274ff;
  // min-height: 50rem;
  h2 {
    font-family: Lato;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;
    color: #f2b65a;
  }

  justify-content: center;
  &--header {
    font-family: Lato;
    font-size: 26px;
    font-style: normal;
    font-weight: 900;
    line-height: 32px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
    color: rgba(0, 39, 4, 1);
  }
  &--ticket {
    // margin: 2rem 0;
    display: flex;
    justify-content: center;
    margin: 1.6rem 0 2.4rem;
    &-img {
      width: 70%;
    }
  }
  &--offer-text {
    font-family: Lato;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    // padding: 0 3rem;
    line-height: 3.5rem;
    letter-spacing: -0.20000000298023224px;
    text-align: center;

    color: rgba(102, 102, 102, 1);
    span {
      font-family: Lato;
      font-size: 2rem;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: rgba(255, 199, 0, 1);
      letter-spacing: -0.20000000298023224px;
    }
  }
  &__shareContainer {
    display: flex;
    justify-content: center;
    padding: 1.4rem 1.3rem 1.3rem;
    border-radius: 3.2rem;
    margin-top: 3rem;
    height: 4.6rem;
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    &--whatsapp-icon {
      width: 1.8rem;
      height: 1.6rem;
    }
    &--title {
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      margin-left: 1rem;
      font-weight: 700;
      color: rgba(72, 200, 87, 1);
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  &__share-container {
    display: flex;
    justify-content: space-between;
    @include bpm-lg {
      justify-content: space-around;
    }
    margin-top: 3rem;
    &__copyLinkButton {
      width: 15.4rem;
      height: 4.6rem;
      cursor: pointer;
      display: flex;
      background-color: rgba(242, 182, 90, 1);
      align-items: center;
      justify-content: center;
      border-radius: 3.2rem;
      &--p-text {
        font-family: Lato;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
      }
    }
    &__whatsappButton {
      width: 15.4rem;
      cursor: pointer;
      height: 4.6rem;
      display: flex;
      background-color: rgba(86, 208, 98, 1);
      align-items: center;
      justify-content: center;
      border-radius: 3.2rem;
      &.affilate-share {
        width: 26.4rem;
        @include bpm-md {
          width: 100%;
        }
      }
      &--p-text {
        font-family: Lato;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
      }
      &--whatsapp-icon {
        width: 1.8rem;
        height: 1.6rem;
        margin-bottom: -4px;
        /* margin-left: -2px; */
        margin-right: 5px;
      }
    }
  }

  &__progressBar-wrapper {
    margin: 0rem 2rem 2rem;
    padding: 0rem 1rem;
    width: 95%;
    font-family: Lato;
    border-radius: 1rem;
  }
}
