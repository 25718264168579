.spark-booking {
  &__booked {
    &--header {
      font-family: Lato;
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 900;
      line-height: 26px;
      margin-bottom: 3.2rem;
      letter-spacing: -0.14941634237766266px;
      text-align: left;
      color: rgba(51, 51, 51, 1);
    }
    &--liner {
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      opacity: 0.8;
      margin-bottom: 0.5rem;
      color: rgba(154, 154, 154, 1);
      line-height: 2.4rem;
      letter-spacing: -0.14941634237766266px;
      text-align: left;
    }
    &__contact-container {
      display: flex;
      justify-content: space-between;
      &--contact-info {
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        // margin-bottom: 2rem;
        color: rgba(153, 153, 153, 1);
        line-height: 2.4rem;
        letter-spacing: -0.14941634237766266px;
        text-align: left;
        span {
          color: rgba(51, 51, 51, 1);
          font-size: 1.2rem;
          font-style: normal;
          font-weight: 600;
          margin-left: 1.2rem;

          line-height: 2.4rem;
          letter-spacing: -0.14941634237766266px;
          text-align: left;
        }
      }
      &--change-status {
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: -0.14941634237766266px;
        text-align: left;
        color: rgba(229, 123, 98, 1);
      }
    }
    &__classinfo-card {
      &--wrapper {
        // margin: 2rem 0 0 0;
        width: 100%;
      }
    }
    .primary-button.version-1 {
      margin-top: 3rem !important;
    }
    &--content {
      font-size: 1.6rem;
      margin: 1rem 0;
      &-1 {
        color: #666;
        font-weight: 300;
      }
      &-2 {
        color: #333;
        font-weight: 500;
        margin-bottom: 2rem;
      }
      &-3 {
        font-weight: 500;
        color: #999;
      }
    }
    &--slots {
      margin-bottom: 2rem;
      .single-slot {
        font-size: 1.6rem;
        font-weight: 900;
        margin-bottom: 1rem;
      }
    }
    &--joining {
      font-size: 1.8rem;
      font-weight: 900;
      margin: 1.5rem 0;
      &-link {
        height: 7rem;
        width: 100%;
        background: url("../../../public/assets/brush-stroke.svg") center
          center/contain no-repeat;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &--href {
          padding-left: 3rem;
          padding-bottom: 1rem;
          font-size: 2.5rem;
          font-weight: 900;
          color: #4086ff;
          text-decoration: underline;
          &:hover {
            color: #4086ff;
            text-decoration: underline;
          }
        }
      }
      &-steps {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-top: 1rem;
        .single-step {
          display: grid;
          grid-template-columns: 6rem 1fr;
          margin-bottom: 1rem;
          > span {
            font-size: 1.6rem;
            font-weight: 700;
            display: block;
            width: max-content;
          }
          p {
            line-height: 2.2rem;
            font-size: 1.5rem;
            font-weight: 500;
            color: #666;
            span {
              font-weight: 700;
              display: inline-block;
            }
          }
        }
      }
    }
    .expect {
      font-size: 2.4rem;
      font-weight: 900;
      margin: 1rem 0 1.5rem 0;
    }
    &--ending {
      p {
        font-size: 1.6rem;
        margin: 0.5rem 0;
        &.first {
          color: #999;
          font-weight: 300;
        }
        &.second {
          color: #666;
          font-weight: 500;
          margin-bottom: 2rem;
        }
      }
      .outline {
        font-size: 1.6rem;
        font-weight: 700;
        color: #e47762;
        text-decoration: underline;
        text-align: center;
        &:hover {
          color: #e47762;
          text-decoration: underline;
        }
      }
    }
    &--referral {
      img {
        width: 100%;
      }
      .sharing {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2rem;
        padding: 1.2rem 1.5rem;
        border: 2px dashed #000;
        border-radius: 10rem;
        span {
          color: #4086ff;
          font-size: 1.4rem;
          font-weight: 700;
          padding-right: 0.5rem;
          display: inline-block;
          width: 100%;
          text-align: left;
        }
        img {
          width: 2.5rem;
          cursor: pointer;
        }
      }
    }
    .sharing__icons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 2rem 0 1rem 0;
      > button {
        margin: 0 0.5rem;
      }
    }
  }
}
