.age-selection-modal {
  .MuiDialog-container.MuiDialog-scrollPaper {
    .MuiPaper-root {
      align-items: center;
      padding: 3rem 0;box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 8px;
    }
  }
  &__top {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
    padding: 0 0 0 2rem;
  }
  &__left {
    max-width: 20rem;

    &--title {
      font-size: 1.8rem;
      font-weight: 900;
      color: #333;
      margin-bottom: 1rem;
    }
    &--description {
      font-size: 1.4rem;
      font-weight: 500;
      color: #666;
      margin-bottom: 2rem;
    }
    &--selector {
      width: 12rem;
      border: 2px solid #f3705b;
      border-radius: 10rem;
      background-color: transparent !important;
      &::after,
      &::before {
        display: none;
      }
      select {
        font-size: 1.4rem;
        padding: 1rem 2rem;
        background-color: transparent !important;
      }
      .MuiSelect-icon {
        top: 10px;
        right: 12px;
        transform: scale(1.5);
      }
    }
  }
}
