.unlockOffer {
  &_wrapper {
    margin-bottom: 20px;
    min-height: 82px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.6rem 0;
    justify-content: space-between;
    padding: 1.2rem 2.4rem;
    @include bpm-md {
      flex-direction: column;
      align-items: flex-end;
      min-height: 200px;
      width: 100%;
      padding: 16px 16px 16px 24px;
      min-width: 328px;
    }

    .primary-button {
      width: 90px;
      height: 48px;
      background: linear-gradient(89.83deg, #f2b65a 0.1%, #e47762 99.81%);
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
      border-radius: 32px;
      border: none;
      @include bpm-md {
        width: 139px;
        height: 48px;
      }
    }
  }
  &_text-section {
    width: 60%;
    @include bpm-md {
      width: 100%;
      text-align: left;
    }
    &_primary-text {
      // width: 320px;
      // height: 24px;
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 24px;
      color: #333333;
      text-align: left;
      margin-bottom: 8px;

      @include bpm-md {
        width: 256px;
        height: 48px;
      }
    }
    &_secondary-text {
      // width: 448px;
      // height: 18px;
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      text-align: left;
      color: #666666;
      @include bpm-md {
        line-height: 24px;
        margin-bottom: 16px;
        width: 291px;
        height: 48px;
      }
    }
  }
  &_mobile-number {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 42px;
    border: 1px dashed #000000;
    border-radius: 8px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    margin-left: 15px;
    margin-right: 12px;
    color: #000000;
    background: #ffebd8;
    @include bpm-md {
      width: 141px;
      height: 48px;
    }
  }
}
