.single-course-banner {
  &__wrapper {
    // height: 70vh;
    // padding: 0 7rem;
    // @include bpm-xl {
    //   height: 60vh;
    // }
    @include bpm-md {
      height: auto;
      // padding: 5rem;
    }
    @include bpm-sm {
      padding: 0;
    }
  }
  display: grid;
  @include bp-xl {
    grid-template-columns: 45vw 1fr;
  }
  @include bpm-xl {
    grid-template-columns: 1fr 1fr;
  }
  @include bpm-md {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  height: 100%;
  // @include bp-sm {
  //   margin-top: 4rem;
  //   border-radius: 3rem;
  //   border: 5px solid #fff;
  // }
  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    padding: 4rem 0 4rem 7rem;
    @include bpm-md {
      order: 2;
      align-items: center;
      padding: 4rem 0;
    }
    @include bpm-xs {
      padding: 0 0 2rem 0;
    }
    &--header {
      font-size: 5rem;
      font-weight: 900;
      // text-transform: capitalize;
      @include bpm-lg {
        font-size: 3.5rem;
      }
      @include bpm-md {
        text-align: center;
      }
      @include bpm-sm {
        font-size: 4rem;
      }
      @include bpm-xs {
        font-size: 2.4rem;
      }
    }
    &--content {
      font-size: 2rem;
      font-weight: 500;
      margin: 3rem 0;
      padding-right: 2rem;
      @include bpm-lg {
        font-size: 1.8rem;
        margin-bottom: 0rem;
      }
      @include bpm-md {
        max-width: 70vw;
        text-align: center;
      }
      @include bpm-sm {
        max-width: 80vw;
      }
      @include bpm-xs {
        font-size: 1.4rem;
        margin: 2rem 0;
      }
    }
    &--usp {
      display: flex;
      align-items: center;
      margin-top: 1.5rem;
      img {
        width: 3rem;
        margin-right: 1rem;
        margin-top: 0.5rem;
      }
      @include bpm-md {
        flex-direction: column;
        margin-bottom: 2.5rem;
        img {
          width: 5rem;
          margin-bottom: 1rem;
        }
      }
      font-size: 1.8rem;
      font-weight: 500;
      span {
        font-weight: 700;
        margin-right: 0.5rem;
      }
      @include bpm-xs {
        font-size: 1.6rem;
        img {
          width: 4rem;
        }
      }
    }
    &--tags {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      width: 100%;
      list-style: none;
      padding-inline-start: 0;
      margin: 2.5rem 0;
      @include bpm-md {
        justify-content: center;
        margin-top: 2rem;
        order: 2;
      }
      li {
        font-size: 1rem;
        padding: 0.3rem 0.8rem 0.3rem 2rem;
        border: 2px solid rgba(255, 255, 255, 1);
        position: relative;
        border-radius: 3rem;
        background-color: rgba(255, 255, 255, 0.5);
        margin-right: 1rem;
        width: max-content;
        flex-wrap: wrap;
        &::before {
          content: "";
          position: absolute;
          width: 0.5rem;
          height: 0.5rem;
          background-color: black;
          border-radius: 2rem;
          left: 0.7rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    &--book-trial {
      font-size: 1.6rem;
      font-weight: 700;
      margin-top: 1.5rem;
      color: #f3705b;
      &:hover {
        color: #f3705b;
      }
      @include bpm-xs {
        margin: 1.5rem auto;
      }
      &.left-aligned {
        @include bpm-xs {
          margin: 1.5rem 0;
        }
      }
    }
  }
  &__right {
    display: flex;
    align-items: flex-start;
    @include bpm-md {
      margin-top: 4rem;
    }
    @include bp-md {
      padding: 4rem 0;
    }
    justify-content: center;
    img.fallback-image {
      width: 70%;
      border-radius: 2rem;
      @include bpm-md {
        margin-bottom: 4rem;
        width: 90%;
      }
    }
  }

  .swiper-slide {
    width: 58rem !important;
    @include bp-md {
      padding-bottom: 4rem;
    }
    @include bpm-sm {
      width: 42rem !important;
    }
    @include bpm-xs {
      width: 34rem !important;
    }
  }
}
.single-course-banner-slider {
  width: 58rem !important;
  @include bpm-sm {
    width: 100vw !important;
  }
  @include bpm-md {
    padding-bottom: 5rem !important;
  }
  .swiper-pagination {
    margin-bottom: 0rem !important;
    @include bpm-md {
      margin-bottom: 1rem !important;
    }
    &-bullet {
      background-color: #fcb444 !important;
    }
    &-bullet-active {
      background-color: #000 !important;
    }
  }
  .swiper-button-next {
    transform: translate(7rem, -5rem);
    outline: none;
    border: none;
    background-color: rgba(255, 255, 255, 0.9);
    height: 10rem;
    width: 10rem;
    border-radius: 10rem;
    position: absolute;
    &::after {
      content: "";
      background: url("../../../public/assets/nextArrow.svg") center
        center/contain no-repeat;
      background-size: 2rem;
      transform: translateX(-2.3rem);
    }
  }
  .swiper-button-prev {
    transform: translate(-7rem, -5rem);
    outline: none;
    border: none;
    background-color: rgba(255, 255, 255, 0.9);
    height: 10rem;
    width: 10rem;
    border-radius: 10rem;
    position: absolute;
    &::after {
      content: "";
      background: url("../../../public/assets/nextArrow.svg") center
        center/contain no-repeat;
      background-size: 2rem;
      transform: rotate(180deg) translateX(-2.3rem);
    }
  }
  .swiper-button-disabled {
    opacity: 0 !important;
  }
}
.single-course-banner-card {
  height: 40rem;
  width: 56rem;
  background-color: #fff;
  border-radius: 0.8rem;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
  -webkit-appearance: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 2rem;
  overflow: hidden;
  margin: 2rem 0;
  &__showcase-video {
    width: 100%;
    height: 80%;
    video {
      object-fit: cover;
    }
  }
  img {
    width: 100%;
    height: 80%;
    object-fit: cover;
  }
  h1 {
    font-size: 2rem;
    font-weight: 800;
    padding: 1rem 2rem 0 2rem;
    @include bpm-xs {
      font-size: 1.8rem;
    }
  }
  p {
    font-size: 1.6rem;
    font-weight: 500;
    padding: 0 2rem;
    @include bpm-xs {
      font-size: 1.4rem;
      padding: 0.5rem 2rem 0 2rem;
    }
  }
  @include bpm-xl {
    width: 44rem;
    height: 33rem;
  }
  @include bpm-sm {
    width: 40rem;
    height: 30rem;
  }
  @include bpm-xs {
    width: 32rem;
    height: 24rem;
  }
}
