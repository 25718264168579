//! The component when in desktop mode uses inline grid with auto column allocation (for dynamic width)
//! On smaller devices to achieve scroll and overflow, we use a mix of template columns and auto columns ( for implicit and explicit )
//! Scrollbar views are disabled
.homeCalendar {
  display: inline-grid;
  grid-template-columns: auto auto auto auto;
  width: 95%;
  &__wrapper {
    @include bpm-sm {
      margin-left: 10px;
    }
  }
  @include bpm-xs {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    grid-auto-flow: column;
    grid-auto-columns: minmax(220px, 1fr);
    overflow-x: scroll;
    width: 100%;
  }
  //! This is to fix ipad alignment issues . not for ipad pro
  // @include bp-sm{
  //   margin-left: 50px;
  //   margin-right: 60px;
  // }

  &::-webkit-scrollbar {
    display: none;
  }
  //! Scrollbar removal for firefox
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  //! This section defines the styling for individual grid columns/elements
  &--element {
    flex-shrink: 0;
    height: 100px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    //! Scrollbar removal for firefox
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    //! text inside element
    &-text {
      width: 100px;
    }
    //! Default Styling For h1 and p tags used in the columns
    h1 {
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0em;
      width: 70%;
      // overflow-wrap: anywhere;

      text-align: left;
    }
    p {
      font-family: Lato;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0em;
      color: #616161;
      width: 70%;
      text-align: left;
      margin-bottom: 5px;
    }
    //! The individual columns are selected using added classnames (upcoming for first, next for 2nd, submission for third and gotoCalendar 4th
    //! slight width static fix added for second element ( next). change to better if found
    &.upcoming {
      // width: 220px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      flex-direction: row;
      padding-left: 10px;
      background-color: white;
      h1 {
        width: 90%;
      }
      //! For the calendar icon on the left , we use z index coupled with position absolute to overlay the text
      //! The sidebars z-index is set to a higher value than this ones, to prevent overlay
      .homeCalendar--element-icon {
        &__wrapper {
          #weekTag {
            width: max-content;
          }
        }
        &--image {
          margin-right: 10px;
          width: 80px;
        }

        margin-right: 10px;
        margin-top: 7px;
        position: relative;
        &-text {
          p {
            font-family: Lato;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0em;
            color: black;
          }
          h1 {
            font-family: Lato;
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: 16px;
            letter-spacing: 0em;
          }
          position: absolute;
          z-index: 1;
          top: 16%;
          left: 26%;
          transition: 0.5s;
        }
      }
    }

    &.next {
      padding-top: 20px;
      // width: 110px;
      // @include bpm-xs{
      //   width: 100%;
      // }
    }
    &.submission {
      padding-top: 20px;
      span {
        color: red;
      }
    }
    &.gotoCalendar {
      background: #f9b215;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 0px 15px;

      // width: max-content;

      //! This is for when grid auto columns expands the implicit grid cells,
      //! i.e, ones that arent part of the regular container(overflow - 3 and 4)
      @include bpm-sm {
        // padding: 0px 45px;
      }
      flex-direction: row;
      h1 {
        color: #ffffff;
        width: max-content;
      }
      img {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
}
