.results-report-card {
  &__wrapper {
    // padding: 2rem;
    background-color: #4069a8;
    margin: 2rem auto;
    width: 75%;
    border-radius: 2.4rem;
    @include bpm-md {
      width: 100%;
      height: 55.2rem;
      overflow-y: scroll;
    }
  }
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  @include bpm-md {
    width: 100%;
  }
  &__left {
    display: flex;
    flex-direction: column;
    width: 55%;
    @include bpm-md {
      width: 100%;
    }
    &__primaryContainer {
      display: flex;
      flex-direction: column;
      @include bpm-md {
        width: 100%;
        flex-direction: row;
      }
      &__left {
        display: flex;
        flex-direction: column;
      }
      &__right {
        @include bp-md {
          display: none;
        }
        &--img {
          object-fit: contain;
        }
      }
    }
    &--p-heading {
      font-family: Lato;
      color: rgba(255, 255, 255, 1);
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 29px;
      letter-spacing: 0px;
      text-align: left;
    }
    &--sub-heading {
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      margin-top: 0.8rem;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      color: rgba(255, 255, 255, 1);
    }
    &__reportCard {
      padding: 3.2rem 2.4rem 1.6rem;
      border-radius: 0.8rem;
      display: flex;
      margin-top: 3.2rem;
      background-color: rgba(251, 248, 249, 1);
      // width: 52.9rem;
      width: 100%;
      min-height: 20rem;
      @include bpm-md {
        min-height: 26rem;
        margin-bottom: 7.2rem;
        padding: 2rem 1.6rem 1.6rem;
      }
      &__left {
        width: 75%;
        @include bpm-xxs {
          width: 70%;
        }
        &--p-heading {
          font-family: Lato;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: 0px;
          text-align: left;
          color: rgba(51, 51, 51, 1);
        }

        &__best-words-container {
          display: flex;
          // flex-direction: column;
          flex-wrap: wrap;
          margin-left: 0.8rem;
          width: 100%;
          &--text {
            // position: relative;
            display: flex;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0px;
            position: relative;
            text-align: left;
            color: #333333;
          }
          &--score {
            display: flex;
            color: rgba(229, 120, 98, 1);
            font-family: Lato;
            margin-left: 5px;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0px;
            text-align: left;
          }
        }
        &--s-heading {
          font-family: Lato;
          font-size: 14px;
          font-style: italic;
          padding-top: 1.6rem;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: left;
          color: rgba(102, 102, 102, 1);
        }
      }
      &__right {
        display: flex;
        width: 25%;
        justify-content: center;
        @include bpm-md {
          justify-content: flex-end;
          align-items: center;
          justify-content: center;
        }
      }
    }
    &--s-text {
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      margin-top: 2.4rem;
      margin-left: 1.6rem;
      color: rgba(251, 248, 249, 1);
      text-decoration: underline;
    }
  }
  &__right {
    display: flex;
    width: 19.6rem;
    height: 22.4rem;
    margin-top: 10rem;
    margin-right: 4rem;
    justify-content: flex-end;
    @include bpm-md {
      display: none;
    }
    &--image {
      width: 100%;
      object-fit: contain;
    }
  }
}
