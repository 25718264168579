.homeWorkCompleteCard {
  //! This is the css for the wrapper for the whole card
  //! The card is made using grid
  //! on Large devices, it has two columns, auto auto, and a single column on smaller ones
  &__wrapper {
    display: grid;
    position: relative;
    z-index: 10;
    grid-template-columns: auto auto;
    // margin: 20px;
    margin: 0px 20px;
    margin-top: 20px;
    padding: 25px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    background: linear-gradient(
      178.11deg,
      #3ebeff 16.24%,
      rgba(48, 186, 255, 0) 191.61%
    );
    width: 95%;
    //! For small devices (i.e, phones)
    @include bpm-xs {
      grid-template-columns: auto;
      width: 320px;
    }
  }
  //! CSS for the left half of the card
  //! all elements are flex based on columns
  &--left {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    //! CSS for the top half of the left section
    &-top {
      border-bottom: 1px solid black;
      // word-break: break-all;
      h1 {
        font-family: Lato;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px;
        letter-spacing: -0.20000000298023224px;
        color: #333333;
        margin-bottom: 10px;
        max-width: 410px;
        @include bpm-xs{
          max-width: 300px;
        }
        // white-space: nowrap;
      }
      h2 {
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.20000000298023224px;
        padding-bottom: 8px;
      }
      span {
        font-family: Lato;
        font-size: 28px;
        font-style: normal;
        font-weight: 300;
        line-height: 34px;
        letter-spacing: -0.20000000298023224px;
      }
      &-share {
        display: flex;
        align-items: center;
        @include bpm-xs{
          flex-direction: column;
          align-items: flex-start;
        }
        p {
          font-family: Lato;
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: 34px;
          letter-spacing: -0.20000000298023224px;
          color: #333333;
          margin-left: 10px;
          max-width: content;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
    &-bottom {
      display: flex;
      align-items: center;
      margin-top: 10px;
      #toggleAssignments{
        margin-right: 5px;
        font-size: 14px;
        cursor: pointer;
        @include bpm-xs{
          font-size: 10px;
        }
      }
      //! css for the uploaded assignment preview
      //! width and height are set in advance
      &-assignmentPreview {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        width: 170px;
        max-height: 95px;
        border: 1px solid white;
        margin-right: 10px;
        img {
          width: 25px;
          height: 25px;
        }
      }
      &-assignmentDescription {
        h2 {
          font-family: Lato;
          font-size: 18px;
          font-style: normal;
          font-weight: 900;
          line-height: 24px;
          letter-spacing: -0.20000000298023224px;
          color: #ffffff;
          margin-top: 10px;
        }
        p {
          font-family: Lato;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.20000000298023224px;
          max-width: 250px;
          margin-top: 5px;

          @include bpm-xs {
            max-width: 300px;
          }
        }

        #hiddenTwo {
          max-height: 40px;
          overflow: hidden;
        }
        span {
          color: #757575;
          padding-bottom: 1px;
          border-bottom: 1px solid #757575;
          cursor: pointer;
          font-size: 14px;
        }
      }
    }
  }
  //! CSS for the right half of the card
  //! Again, all elements are set to flex column
  &--right {
    display: flex;
    flex-direction: column;
    text-align: left;
    //! On mobiles the column order is reversed to get the button at bottom and text uptop
    @include bpm-xs {
      flex-direction: column-reverse;
    }
    justify-content: center;
    &-top {
      margin-bottom: 20px;
      //! Specific css for the button used on this page
      //! position set to relative and adjusted on large devices
      .primary-button {
        box-shadow: none;
        width: 116px;
        padding: 10px 0px;
        position: relative;
        right: -55%;
        @include bpm-xs {
          right: 0%;
        }
      }
    }
    &-bottom {
      //! This is the css for the right bottom section
      //! Here the p tag that holds the assignment description is set a max width of 200px on big devices ( ipads + )
      //! and 300px on smaller ones
      padding: 10px 0px;
      padding-left: 20px;
      //! Padding left removed on smaller devices
      @include bpm-xs {
        padding-left: 0px;
      }
      h2 {
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: 24px;
        letter-spacing: -0.20000000298023224px;
        color: #ffffff;
        margin-top: 10px;
      }
      p {
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.20000000298023224px;
        max-width: 200px;
        margin-top: 5px;

        @include bpm-xs {
          max-width: 260px;
        }
      }
      //! This span is used to trigger the read more / read less
      span {
        color: #757575;
        padding-bottom: 1px;
        border-bottom: 1px solid #757575;
        cursor: pointer;
      }
      //! this id is added to the p tag when the span element is clicked
      //! sets max height for p tag and hides overflow
      #hidden {
        max-height: 40px;
        overflow: hidden;
      }

      //! The css for the homeworkupload element on the bottom
      //! Overflow scroll added for the homework file element
      &-homeworkUpload {
        display: flex;
        align-items: center;
        margin: 10px 0px;
        background: rgba(255, 255, 255, 0.6);
        border: 1px solid rgba(153, 153, 153, 0.5);
        border-radius: 32px;
        width: max-content;
        padding: 5px 15px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
          display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        & {
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
        }
        @include bpm-xs {
          max-width: 200px;
          line-break: none;
        }
        img {
          width: 20px;
          height: auto;
        }
        p {
          margin: 0px;
          margin-left: 5px;
          font-family: Lato;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 19px;
          letter-spacing: -0.20000000298023224px;
          color: #000632;
        }
      }
      //! This class is a wrapper for the right bottom half of the card
      //! This is made to enable us to set a left border for those elements alone, exclusive of the remaining elements
      &-wrapper {
        border-left: 1px solid white;
        padding-left: 20px;
        @include bpm-xs {
          padding-left: 0px;
          border: none;
        }
      }
    }
  }
  //! wrapper around the whole card
  //! done for dropdown
  &-container {
    display: flex;

    flex-direction: column;
    //! dropdown wrapper
    .homeWorkCompleteCard-dropdown {
      z-index: 1;
      position: relative;
      width: 95%;
      margin: 0 auto;
      padding-top: 30px;
      top: -10px;
      background-color: white;
      max-height: 140px;
      overflow-y: scroll;
      border-bottom-left-radius:32px ;
      border-bottom-right-radius:32px ;
      padding-bottom: 20px;
      padding-left: 25px;
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      &::-webkit-scrollbar-track {
      }

      &::-webkit-scrollbar-thumb {
        background: LightGray;
        border-radius: 20px;
      }
      display: none;
      //! set to display block onclick
      &.active {
        display: block;
      }
      @include bpm-xs {
        width: 320px;
      }
      //! the content div is the container fothe homeworkview component
      &-Content{
       display: flex;
       align-items: center;
      padding-bottom: 10px;
        width: 95%;
      margin-bottom: 5px;
        border-bottom: 1px solid #666666;
       h1{
        margin-left: 10px;
         width: 200px;
       }
       h2{
         cursor: pointer;
         text-decoration: underline;
       }
      }
    }
  }
}
