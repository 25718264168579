.VERTICAL-TABS {
  display: flex;
  font-family: "Lato";
  font-size: 14spx;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  cursor: pointer;

  .text {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 25px;
    text-align: justify;
    text-transform: capitalize;
    width: 100%;
    color: black;
    text-align: justify;
  }
  .txt {
    font-family: Lato, sans-serif;
    text-align: left;

    width: auto;

    padding: 100px;
    display: block;
    font-style: normal;
    font-weight: 800;
    font-size: 300%;

    color: white;
  }
  .box {
    width: 100%;
    height: 180px;

    background: #fcb444;
    .txt {
      padding: 3rem 2rem;
    }
  }
  .text1 {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
    text-transform: capitalize;
    width: 93%;
    color: #909090;
    margin-top: -2%;
  }

  &__label__element:focus {
    outline: 0;
    color: orange;
  }
  &__label {
    width: 25%;
    margin-left: 5%;
    margin-right: 20px;
    border-bottom: none;
    border: solid none;
  }

  &__label__element {
    padding: 10px 20px;
    text-align: right;
    opacity: 0.5;
  }

  &__label__element--active {
    opacity: 1;
  }

  &__content {
    width: 65%;
    border: none;
  }

  &__content__catalog {
    display: none;
    padding: 30px;
  }

  &__content__element {
    padding: 30px;
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;

    &__label {
      width: 100%;
      padding: unset;
    }

    &__label__element {
      border-bottom: 2px solid grey;
      text-align: center;
    }

    &__content {
      display: none;
    }

    &__content__catalog {
      display: flex;
    }
  }
}
