.save-game-window {
  &__wrapper {
    padding: 0 7rem;
    margin: 4rem auto;
    max-width: 1400px;
  }
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
  @include bpm-md {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__swiper {
    width: 100%;
    padding-bottom: 6rem !important;
    .swiper-slide {
      width: 21rem;
    }
    .swiper-pagination {
      margin-bottom: 0;
    }
  }
  &__left {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @include bpm-md {
      max-width: 100vw;
    }
    &--story-title {
      margin-left: 1rem;
      padding: 0 1rem;
      height: 4.5rem;
      width: 30rem;
      border: none;
      outline: none;
      border-radius: 1rem;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
      -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
      margin-bottom: 1.5rem;
      font-size: 1.6rem;
      color: #666;
      font-weight: 700;
      &::placeholder {
        font-size: 1.6rem;
        color: #999;
        font-weight: 700;
      }
      @include bpm-md {
        margin: 0 auto 2rem auto;
      }
    }
    &--images {
      display: flex;
      flex-wrap: wrap;
      .single-image {
        width: 20rem;
        margin: 1rem;
        border-radius: 1.5rem;
      }
      @include bpm-md {
        width: 100%;
      }
    }
    .onboarding-secondary-link {
      text-align: left;
      margin-left: 1rem;
      margin-top: 1.5rem;
      width: auto;
    }
  }
  &__right {
    &--textbox {
      background-color: #fff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
      -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
      border-radius: 1.5rem;
      padding: 2rem 1.5rem;
      height: 25vw;
      overflow-y: scroll;
      overflow-x: hidden;
      @include bpm-md {
        height: 25rem;
        padding: 1rem;
        width: 40rem;
      }
      @include bpm-sm {
        width: 30rem;
      }
      &--title {
        padding: 0 1.5rem;
        font-size: 2rem;
        text-align: left;
        font-weight: 700;
        color: #666;
        margin: 1rem 0;
        @include bpm-md {
          font-size: 1.6rem;
          margin: 0.5rem 0;
        }
        @include bpm-sm {
          padding-left: 0;
        }
      }
      &-transcript {
        width: 100%;
        height: 12rem;
        overflow-y: scroll;
        border-radius: 1.5rem;
        margin: 1rem 1rem;
        background-color: #f5f5f5;
        border: 2px solid #ccc;
        outline: none;
        padding: 1rem;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: #333;
        font-weight: 500;
        text-align: left;
        &:focus,
        &:active {
          border: 2px solid #e47762;
        }
        @include bpm-md {
          height: 8rem;
          font-size: 1.4rem;
          line-height: 2rem;
          padding: 0.5rem;
        }
        @include bpm-sm {
          margin: 1rem 0;
        }
      }
    }
    &--action {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;
      .secondary-button {
        margin: 0;
        margin-right: 2rem;
      }
      .primary-button {
        width: max-content;
      }
    }
  }
}
