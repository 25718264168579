.result-speech {
  &__wrapper {
    padding: 2rem;
    background-color: #f8fcfe;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &__title {
    text-transform: uppercase;
    color: #e57862;
    font-size: 3.6rem;
    font-weight: 900;
    margin-bottom: 1.5rem;
    text-align: left;
    @include bpm-md {
      font-size: 2.4rem;
      margin-bottom: 1rem;
    }
  }
  &__content {
    color: #666;
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 2rem;
    text-align: left;
    @include bpm-md {
      font-size: 1.6rem;
      margin-bottom: 1rem;
    }
  }
  &__donut {
    width: 80%;
    margin: auto;
  }
  &__affirmation {
    color: #333;
    font-size: 2.2rem;
    font-weight: 700;
    margin-top: 2rem;
    @include bpm-md {
      font-size: 1.8rem;
      margin-top: 1rem;
    }
  }
}
