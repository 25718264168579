.spark-payment-successful {
  &__wrapper {
    height: 100vh;
  }
  display: flex;
  height: 100%;
  @include bpm-lg {
    flex-direction: column;
  }
  &__left {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    //! Setting the width  to 100%
    @include bpm-lg {
      width: 100%;
      padding-top: 100px;
      background: #fffdf9;
    }
    &-logo {
      width: 128px;
      position: absolute;
      top: 20px;
      left: 40px;
      @include bpm-xs {
        top: 15px;
        left: 30px;
      }
      cursor: pointer;
    }
    &-image {
      max-width: 580px;
      max-height: 400px;
      //! Reducing the image height on ipads
      @include bpm-lg {
        width: 300px;
        display: none;
      }
    }
  }
  &__right {
    padding-top: 100px;
    width: 50%;
    //! For vh100 height of the wrapper
    height: 100%;
    @include bpm-lg {
      width: 100%;
      padding-top: 0px;
      box-shadow: none;
    }
    display: flex;
    flex-direction: column;
    align-items: center;

    background: #fffdf9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
      4px 4px 4px rgba(231, 231, 231, 0.25);
    border-radius: 8px;
    &-mobile-image {
      .spark-payment-successful-image {
        display: none;
        @include bpm-lg {
          display: block;
          width: 273px;
          margin-top: 3rem;
        }
      }
    }
    //! Top right div that holds header information
    &-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include bpm-lg {
        text-align: center;
        // padding: 0rem 1rem;
      }

      &--heading {
        font-family: Lato;
        font-size: 32px;
        font-style: normal;
        font-weight: 900;
        line-height: 38px;
        letter-spacing: 0.20000000298023224px;
        color: #000632;
        margin-bottom: 1.5rem;
        span {
          color: #ffb541;
        }
        @include bpm-xs {
          font-family: Lato;
          font-size: 20px;
          font-style: normal;
          font-weight: 900;
          line-height: 24px;
          letter-spacing: 0.20000000298023224px;
        }
      }
      &--subHeading {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        /* identical to box height */
        color: #666666;
        margin-bottom: 1.5rem;
        @include bpm-xs {
          max-width: 280px;
        }
      }
      &--orderInfo {
        .seperator {
          padding: 0px 2px;
          color: #ffb541;
          font-size: 20px;
        }
      }
    }
    //! Top middle div (order) that holds order summary / order cards
    &-order {
      width: 500px;
      @include bpm-xs {
        width: 320px;
      }
      &--summary {
        display: flex;
        @include bpm-xs {
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-around;
        }
        justify-content: space-between;
        align-items: center;
        background: #f7f6f9;
        border-radius: 10px;
        height: 72px;
        padding: 0px 20px;
        margin-bottom: 3rem;
        margin-top: 5rem;
        &-checked {
          color: #7ec412;
        }
        h1 {
          font-family: Lato;
          font-style: normal;
          font-weight: 800;
          font-size: 20px;
          line-height: 24px;
          color: #000000;
        }
        &-amount {
          display: flex;
          align-items: center;
          font-family: Lato;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          letter-spacing: 0.2px;
          color: #000632;
        }
      }

      &--terms {
        //! The wrapper div that holds the terms and conditions div
        &__wrapper {
          display: flex;
          justify-content: flex-end;
        }
        display: flex;
        //! Css for the pdf icon
        &-termsIcon {
          width: 16px;
          height: 16px;
        }
        a {
          margin-left: 0.5rem;
          font-family: Lato;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          text-decoration-line: underline;
          color: #333333;
          cursor: pointer;
        }
      }
      //! This div holds the email/phone div of the user
      &--personalDetails {
        &__wrapper {
          display: flex;
        }
        &-icons {
          display: flex;
          flex-direction: column;
          &-emailIcon {
            margin-bottom: 1rem;
          }
        }
        &-info {
          .email-info {
            margin-bottom: 1.5rem;
          }
          p {
            font-family: Lato;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.2px;
            color: #333333;
            margin-left: 1rem;
          }
        }
      }
    }
    &-support {
      &__wrapper {
        width: 500px;
        margin-top: 3rem;

        @include bpm-xs {
          width: 100%;
          padding: 0rem 3rem;
          padding-bottom: 2rem;
        }
        h2 {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.2px;
          color: #000000;
        }
      }
      margin-top: 2rem;
      display: flex;
      flex-direction: row;
      &--icons {
        display: flex;
        flex-direction: column;
        align-items: center;
        &-contactNumber {
          width: 13.3px;
          margin-bottom: 1rem;
        }
        &-email {
          width: 19.6px;
        }
      }
      &--details {
        display: flex;
        flex-direction: column;

        p {
          font-family: Lato;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.2px;
          color: #666666;
          margin-left: 2rem;
          margin-bottom: 1rem;
        }
        &-email {
          text-decoration: underline;
        }
      }
    }
  }
  // &__wrapper {
  //   margin-top: 6.9rem;
  //   min-height: calc(100vh - 6.9rem);
  //   width: 100vw;
  //   padding-right: 7rem;
  //   background-color: #fff;
  //   @include bpm-xs {
  //     padding-right: 0;
  //   }
  // }
  // display: grid;
  // grid-template-columns: 4.5fr 5.5fr;

  // &__left {
  //   img {
  //     width: 100%;
  //     object-fit: cover;
  //   }
  // }
  // @include bpm-md {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   &__left {
  //     display: none;
  //   }
  // }
  // &__right {
  //   padding-left: 5rem;
  //   padding-top: 3rem;
  //   position: relative;
  //   @include bpm-xs {
  //     padding-left: 2rem;
  //   }
  //   &--top {
  //     &-header {
  //       font-size: 2rem;
  //       padding-left: 8rem;
  //       font-weight: 700;
  //       position: relative;

  //       img {
  //         position: absolute;
  //         top: 50%;
  //         transform: translateY(-50%);
  //         height: 3rem;
  //         width: 3rem;
  //         left: 2.8rem;
  //       }
  //       @include bpm-xs {
  //         padding-left: 0;
  //         margin-top: 4rem;
  //         img {
  //           width: 4rem;
  //           height: 4rem;
  //           left: 0;
  //           top: -5rem;
  //           transform: translateY(0);
  //         }
  //       }
  //     }
  //     &-order-id {
  //       font-size: 2.2rem;
  //       padding-left: 8rem;
  //       font-weight: 700;
  //       margin-top: 2rem;
  //       @include bpm-xs {
  //         padding-left: 0;
  //       }
  //     }
  //     &-status {
  //       display: flex;
  //       align-items: center;
  //       justify-content: space-between;
  //       width: 100%;
  //       padding-left: 8rem;
  //       margin-top: 2rem;
  //       padding-right: 5rem;
  //       span {
  //         font-size: 1.6rem;
  //         color: #666;
  //       }
  //       @include bpm-xs {
  //         flex-direction: column;
  //         align-items: flex-start;
  //         padding-left: 0;
  //         span {
  //           margin: 0.5rem 0;
  //         }
  //       }
  //     }
  //     &-email-box {
  //       width: 100%;
  //       height: 8.5rem;
  //       display: flex;
  //       align-items: flex-start;
  //       justify-content: space-between;
  //       margin-top: 2rem;
  //       .email-info {
  //         font-size: 1.6rem;
  //         span {
  //           color: #fcb444;
  //           font-size: 1.8rem;
  //           margin-top: 1rem;
  //           padding-top: 1rem;
  //         }
  //         @include bpm-sm {
  //           text-align: center;
  //         }
  //         @include bpm-xs {
  //           text-align: left;
  //         }
  //       }

  //       flex-direction: column;

  //       button {
  //         outline: none;
  //         border: none;
  //         color: #fcb444;
  //         font-size: 1.4rem;
  //         font-weight: 700;
  //         background-color: #fff;
  //         cursor: pointer;
  //         width: max-content;
  //       }
  //     }
  //   }
  //   &--bottom {
  //     padding-left: 8rem;
  //     @include bpm-md {
  //       padding: 0;
  //     }
  //     &-header {
  //       font-size: 2rem;
  //       margin: 2rem 0 1rem 0;
  //     }
  //     &-courses {
  //       overflow-y: scroll;
  //       max-height: 37rem;
  //       width: max-content;
  //       padding: 0 1rem;
  //       transform: translateX(-1rem);
  //       &::-webkit-scrollbar {
  //         display: none;
  //       }
  //       @include bpm-md {
  //         width: auto;
  //         padding: 0 2rem;
  //         .cart-card__wrapper {
  //           width: 100%;
  //         }
  //       }
  //     }
  //   }
  //   .primary-button {
  //     margin: 3rem 0;
  //     width: 20rem;
  //     @include bpm-md {
  //       position: static;
  //       margin: 4rem auto;
  //     }
  //   }
  //   &--last-liner {
  //     font-size: 1.6rem;
  //   }
  // }
}
