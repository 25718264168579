.result-vocabulary {
  &__wrapper {
    padding: 2rem;
    background-color: #f8fcfe;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  }
  &__title {
    text-transform: uppercase;
    color: #e57862;
    font-size: 3.6rem;
    font-weight: 900;
    margin-bottom: 1.5rem;
    text-align: left;
    @include bpm-md {
      font-size: 2.4rem;
      margin-bottom: 1rem;
    }
  }
  &__content {
    color: #666;
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 2rem;
    text-align: left;
    @include bpm-md {
      font-size: 1.6rem;
      margin-bottom: 1rem;
    }
  }
  &__main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
    justify-content: space-between;
    width: 100%;
    @include bpm-sm {
      grid-gap: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &__left {
    &--title {
      font-size: 2.8rem;
      font-weight: 500;
      margin: 3rem 0 2rem 0;
      text-align: center;
    }
    &--results {
      max-width: 500px;
      margin: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1.5rem;
      .single-result {
        background: #fbf8f9;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        padding: 1.5rem 2rem;
        &__title {
          font-size: 1.8rem;
          font-weight: 500;
          color: #666;
          margin-bottom: 1rem;
        }
        &__content {
          font-size: 2rem;
          font-weight: 700;
          color: #333;
        }
      }
    }
  }
  &__right {
    &--radar-chart {
      margin: auto;
    }
    @include bpm-sm {
      width: 100%;
      margin-top: 2rem;
    }
  }
}
