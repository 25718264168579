.zoom_modal {
  &_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 120vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
    display: none;
  }

  &-close {
    width: 42px;
    height: 42px;
    position: absolute;
    right: 8px;
    top: -16px;
    z-index: 1000;
    cursor: pointer;
  }

  background-color: #ffffff;
  border-radius: 16px;
  opacity: 1;
  width: 40%;
  position: relative;

  @include bpm-lg {
    width: 85%;
  }

  @include bpm-xs {
    width: 94%;
    top: -50px;
  }

  display: flex;
  flex-direction: column;

  &-main_wrapper {
    width: 100%;
    padding: 20px 15px;
    align-items: center;
    display: flex;
    flex-direction: column;

    &--span {
      position: relative;
      font-family: "Lato";
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 22px;

      &::before {
        content: "";
        position: absolute;
        top: 110%;
        width: 100%;
        left: 0;
        // height: 1px;
        border-radius: 2px;
        background: linear-gradient(89.83deg, #f2b65a 0.1%, #e47762 99.81%);
      }

      background: linear-gradient(89.83deg, #f2b65a 0.1%, #e47762 99.81%);
      background-size: 100%;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
    }

    &-heading {
      margin-top: 8px;

      font-family: "Lato";
      font-style: normal;
      font-weight: 800;
      font-size: 22px;
      line-height: 26px;
      text-align: center;
      color: #333333;
    }

    &-description {
      &_wrapper {
        margin-top: 8px;
      }

      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #666666;

      &-highlight {
        font-weight: 700;
        color: #333333;
      }
    }
    &__videoplayer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1.6rem 0 0.8rem;
    }

    &-image {
      margin: 0 auto;
      &.gif {
        width: 10rem;
      }

      &_wrapper {
        display: flex;
        margin: 8px auto 0 auto;
        width: 100%;
      }
    }

    &-emailbox {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 400px;
      margin: 16px auto;

      &-inputset {
        display: flex;
        flex-direction: row;
        width: 100%;
        background: #fff0d8;
        border-bottom: 2px solid #fff0d8;
        border-radius: 8px;
        cursor: pointer;
      }

      &-inputset-err {
        border-bottom: 2px solid #c84343;
      }

      &-err {
        font-family: "Lato";
        font-weight: 600;
        font-size: 12px;
        color: #c84343;
        margin: 4px 8px;
        height: 12px;
      }

      &-icon {
        margin: auto 16px;
        height: 60%;
      }

      &-input {
        font-family: "Lato";
        font-weight: 500;
        font-size: 16px;
        line-height: 17px;
        border: none;
        outline: none;
        height: 8vh;
        width: 100%;
        background-color: transparent;
        display: flex;
        align-items: center;
        color: #333333;
      }
    }

    &-button_wrapper {
      margin: 8px auto 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-ctaText {
      &_wrapper {
        margin: 20px auto 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        &2 {
          margin-top: 8px;
        }
      }

      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;

      background: linear-gradient(93.3deg, #ff8671 -5.92%, #f6a428 133.38%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      cursor: pointer;

      &2 {
        border-style: solid;
        border-width: 2px;
        border-image-source: linear-gradient(
          93.3deg,
          #ff8671 -5.92%,
          #f6a428 133.38%
        );
        border-image-slice: 0 0 30 0;
      }
    }

    &-ctaText2 {
      &_wrapper {
        margin: 20px auto 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;

      background: linear-gradient(93.3deg, #ff8671 -5.92%, #f6a428 133.38%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      cursor: pointer;
    }

    &-help {
      &_wrapper {
        margin-top: 16px;
        display: flex;
        justify-content: center;
      }

      font-family: "Lato";
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      text-decoration-line: underline;
      color: #333333;
      cursor: pointer;
    }
  }
}
