//! This is the internal course card that displays the bought courses
//! used inside the CoursePurchaseOrderCard component
.purchasedCourseCard {
  //! Wraps around the whole component
  &__wrapper {
    padding: 15px;
    display: flex;
    margin-top: 15px;

    border-radius: 8px;
  }
  //! left div holds the image alone
  &--left {
    filter: drop-shadow(50px 60px 100px rgba(0, 0, 0, 0.05));

    &-image {
      width: 90px;
      border-radius: 5px;
      overflow: hidden;
    }
  }
  //! right div holds the h1 and p tags
  &--right {
    margin-left: 25px;
    h1 {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      /* identical to box height */
      color: #000632;
    }
    p {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
      /* identical to box height */
      color: #666666;

      &:nth-child(2) {
        margin-bottom: 10px;
      }
    }
  }
}
