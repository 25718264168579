.confirming-loader {
  &__wrapper {
    width: 100%;
    min-height: 90vh;
    margin: 0;
    padding: 0;
  }
  display: flex;
  flex-direction: column;
  &__image-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    &__app-image {
      width: 100%;
    }
  }

  &__heading {
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: -0.14941634237766266px;
    text-align: center;
    color: rgba(102, 102, 102, 1);
  }
  &__sub-heading {
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    margin-top: 2rem;
    letter-spacing: -0.14941634237766266px;
    text-align: center;
    color: rgba(153, 153, 153, 1);
  }
}
