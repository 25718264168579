.live_class_invite_friend_card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 70px 0px 30px;
  @include bpm-lg {
    padding-right: 20px;
  }
  @include bpm-md {
    flex-direction: column;
    padding-bottom: 25px;
    transform: scale(0.9);
    margin-top: -30px;
  }
  @include bpm-xs {
    padding: 15px 10px 25px 15px;
    transform: scale(1);
    margin-top: 0px;
  }
  background: linear-gradient(
    89.9deg,
    #50aff7 -1.04%,
    #a940ea 31.04%,
    #f9d649 99.92%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  margin-bottom: 40px;

  &-left {
    width: 50%;

    @include bpm-md {
      width: 100%;
    }
    &--image {
      width: 100%;
      height: auto;
      margin-bottom: -2px;
    }
  }
  &-right {
    flex-grow: 1;
    margin-left: 100px;
    text-align: left;
    @include bpm-md {
      margin: 0px 50px;
    }
    @include bpm-xs {
      margin: 0px 0px;
      margin-left: 10px;
    }
    &--mainText {
      font-family: "Lato";
      font-style: normal;
      font-weight: 800;
      font-size: 40px;
      line-height: 48px;
      color: #333333;
      margin-top: 30px;
      @include bpm-md {
        margin-top: 30px;
      }
      @include bpm-xs {
        margin-top: 20px;
        font-size: 24px;
        line-height: 29px;
      }
    }
    &--subText {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      color: #ffffff;
      margin: 25px 0px;
      @include bpm-xs {
        margin: 5px 0px;
        font-size: 16px;
        line-height: 19px;
      }
    }
    &--shareIcon {
      width: 53.46px;
      height: 56px;
      margin-right: 15px;
      cursor: pointer;
      @include bpm-xs {
        width: 38.08px;
        height: 40px;
        margin-top: 15px;
      }
    }
    &--share_link_wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 40px;
      padding: 10px 15px;
      width: max-content;
      cursor: pointer;
      margin: 25px 0px;

      &---copy_to_clipboard {
        width: auto;
        height: 27px;
        @include bpm-xs {
          height: 20px;
          margin-right: 2px;
        }
        margin-right: 10px;
      }
      &---text {
        font-family: "Lato";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #4086ff;
        margin-right: 25px;
        @include bpm-xs {
          font-size: 11px;
          line-height: 17px;
          margin-right: 5px;
        }
      }
      &---subText {
        font-family: "Lato";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #fe9b00;
        margin-left: 10px;
        @include bpm-xs {
          font-size: 13px;
          line-height: 19px;
          margin-left: 3px;
        }
      }
    }
  }
}
