.child-completed-class_details_table {
  border-radius: 16px;
  overflow: scroll;
  //height: auto;
  //max-height: 50rem;
  color: #333333;
  &-wrapper {
    background: #ffffff;
    border-radius: 16px;
    border-spacing: 0px;
    //padding: 10px 30px;
    width: 100%;
    white-space: nowrap;
    // margin-bottom: 20px;
    td,
    th {
      border-bottom: 2px solid rgba(242, 182, 90, 0.48);
      padding: 20px 20px;

      @include bpm-lg {
        padding-right: 20px;
      }
      @include bpm-xs {
        padding: 15px;
        padding-right: 20px;
      }
    }
    thead tr th {
      background-color: #fed700;
      font-weight: 900;
      font-size: 24px;
      line-height: 29px;
    }
  }
  &-head_row {
    padding: 10px 0px;
    &-heading {
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 24px;
      line-height: 29px;
      color: #333333;
      text-align: left;
      @include bpm-xs {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
  &-content {
    &:last-child {
      td {
        border-bottom: none;
      }
    }
    &--class {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #666;
      @include bpm-xs {
        font-size: 20px;
        line-height: 24px;
      }
      &.download {
        background: linear-gradient(90deg, #f3705b, #fcb444);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        span {
          border-bottom: 2px solid #f3705b;
          cursor: pointer;
        }
      }
      &--disable{
        background: #666;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .primary-button {
    border: none;
    padding: 12px 24px;
    background: linear-gradient(89.83deg, #f2b65a 0.1%, #e47762 99.81%);
  }
  .btn-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
