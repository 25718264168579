.results-bar-graph {
  &__wrapper {
    padding: 1.2rem 2.4rem 1rem;
    @include bpm-md {
      padding: 1.2rem 0rem 1rem;
    }
  }
  display: flex;
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &--p-text {
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      width: 8rem;
      text-align: left;
      color: rgba(255, 255, 255, 1);
    }
    &--s-text {
      margin-top: 0.2rem;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      color: rgba(255, 255, 255, 1);
    }
  }
  &__bar {
    position: relative;
    width: 4px;
    height: 2.4rem;
    border-radius: 0.4rem;
    margin-left: 3rem;
    margin-top: 1rem;
    background-color: rgba(255, 255, 255, 1);
    @include bpm-custom(400) {
      margin-left: 0.3rem;
      margin-top: 1rem;
    }
    &--first-horizontal-bar {
      width: 7px;
      //   height: 10.9rem;
      position: absolute;
      left: 0.4rem;
      top: 10.5px;
      transform: rotate(-90deg);
      border-radius: 0 0 4px 4px;
      transform-origin: top left;
      background: linear-gradient(342.08deg, #f93099 -918.32%, #ffd31a 441.3%);
    }
    &--second-horizontal-bar {
      width: 7px;
      //   height: 15.9rem;
      position: absolute;
      left: 0.4rem;
      top: 20px;
      transform: rotate(-90deg);
      border-radius: 0 0 4px 4px;
      transform-origin: top left;
      background: linear-gradient(315deg, #f93099 -12325.42%, #ffd31a 752.08%);
    }
    &--first-score {
      position: absolute;
      left: 12.5rem;
      top: -5px;
      color: rgba(255, 255, 255, 1);
      font-family: Lato;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
    }
    &--secondary-score {
      position: absolute;
      left: 17.5rem;
      top: 8px;
      color: rgba(255, 255, 255, 1);
      font-family: Lato;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
    }
  }
}
