.schoolpage-secondarybanner {
  &__wrapper {
    padding: 0 8rem 5rem;
    margin-top: 4rem;
    @include bpm-xs {
      padding: 0 3rem 2rem;
    }
  }
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @include bpm-xs {
    flex-direction: column;
  }
  &.reverseContainer {
    flex-direction: row-reverse;
    @include bpm-xs {
      flex-direction: column;
    }
  }
  &__left {
    display: flex;
    flex-direction: column;
    &.reverseContainer {
      align-items: end;
      @include bpm-xs {
        align-items: flex-start;
      }
    }
    &__buttonContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    &--p-text {
      font-family: Lato;
      font-size: 36px;
      font-weight: 900;
      line-height: 43px;
      letter-spacing: 0px;
      text-align: left;
      color: rgba(51, 51, 51, 1);
    }
    &--s-text {
      font-family: Lato;
      font-size: 18px;
      margin-top: 1.6rem;
      font-weight: 500;
      width: 60%;
      line-height: 25px;

      letter-spacing: 0px;
      text-align: left;
      color: rgba(102, 102, 102, 1);
      @include bpm-xs {
        width: 100%;
      }
      &.reverseContainer {
        text-align: right;
        @include bpm-xs {
          text-align: left;
        }
      }
    }
    &--img {
      width: 170px;
      height: 50px;
      object-fit: cover;
      margin-left: 50px;
      margin-top: 50px;
      cursor: pointer;
      @include bpm-xs {
        margin-top: 2rem;
        margin-left: 20px;
        width: 140px;
        height: 50px;
      }
    }
  }
  &__right {
    &--img {
      @include bpm-lg {
        width: 80%;
        height: 80%;
        object-fit: contain;
      }
      @include bpm-xs {
        width: 100%;
      }
      &.margin {
        @include bpm-xs {
          margin-top: 3.2rem 0;
        }
      }
    }
  }
  .primary-button {
    margin: 5rem 0 0;
    padding: 2rem 1rem;
    width: 20rem;
    height: 5rem;
    @include bpm-xs {
      margin: 2rem 0 0;
    }
  }
}
