.spark-auth-screen {
  &__wrapper {
    background-color: #f7eee7;
    padding-top: 10rem;
    min-height: 100vh;
    background: url("../../public/assets/login_bg.webp") center center/contain
      no-repeat;
    @include bpm-custom(480px) {
      padding-top: 3rem;
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__spark-logo {
    margin-bottom: 5rem;
    img {
      width: 15rem;
    }
    @include bpm-custom(480px) {
      margin-bottom: 3rem;
      img {
        width: 10rem;
      }
    }
  }
}
