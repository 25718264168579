.homepage-callback {
  &__wrapper {
    // position: relative;
  }
  &__artwork {
    display: flex;
    justify-content: center;
    img {
      width: 45rem;
    }
    transform: translateY(15rem);
  }
  display: grid;

  grid-template-columns: 2fr 1fr;
  background: linear-gradient(
    rgba(255, 255, 255, 0.95),
    rgba(255, 255, 255, 0.95)
  );
  filter: drop-shadow(50px 60px 100px rgba(0, 0, 0, 0.05));
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.4);
  -webkit-appearance: none;
  border-radius: 4.8rem;
  border: 5px solid rgb(233, 233, 233);
  margin: 0 7rem 7rem 7rem;
  min-height: 35rem;
  padding: 0 7rem;
  @include bpm-md {
    padding: 0 4rem;
    min-height: 28rem;
    &__artwork {
      display: flex;
      justify-content: center;
      img {
        width: 30rem;
      }
      transform: translateY(10rem);
    }
  }
  @include bpm-sm {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 0.7fr;
    padding: 0 2rem;
    margin: 0 1rem 7rem 1rem;
  }
  &__left {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    &--header {
      font-size: 4rem;
      font-weight: 800;
      &.pointer {
        cursor: pointer;
      }
      span {
        color: #f3705b;
      }
    }
    &--liner {
      font-weight: 400;
      font-size: 2.6rem;
      color: #666;
    }
    @include bpm-md {
      &--header {
        font-size: 3rem;
        font-weight: 700;
      }
      &--liner {
        font-weight: 400;
        font-size: 2.2rem;
      }
      .primary-button {
        font-size: 2rem;
        height: 5rem;
        width: 24rem;
        img {
          width: 4rem;
          right: 0.5rem;
        }
      }
    }
    @include bpm-sm {
      padding-top: 3rem;
      align-items: center;
      &--header {
        text-align: center;
      }
      &--liner {
        text-align: center;
        margin: 2rem 0;
      }
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    & > input {
      width: 400px;
    }
    input {
      height: 66px;
      border-radius: 8px;
      border: none;
      outline: none;
      box-shadow: 0px 0px 10px rgba(75, 0, 188, 0.2);
      -webkit-box-shadow: 0px 0px 8px rgba(75, 0, 188, 0.2);
      -webkit-appearance: none;
      padding: 0 1rem;
      font-size: 1.6rem;
    }

    &--contact {
      //   display: flex;
      &-country-code {
        width: 120px;
      }

      &-number {
        width: 260px;
        margin-left: 2rem;
      }
      label {
        position: relative;
      }
      @include bpm-md {
        &-country-code {
          width: 80px;
        }

        &-number {
          width: 170px;
          margin-left: 2rem;
        }
      }
    }
    @include bpm-md {
      & > input {
        width: 270px;
      }
      input {
        height: 50px;
      }
      &--contact {
        &-country-code {
          width: 80px;
        }

        &-number {
          width: 170px;
          margin-left: 2rem;
        }
      }
      .primary-button {
        margin: 2rem 0;
      }
    }
    @include bpm-sm {
      &--contact {
        &-number {
          margin: 1.5rem 0 0 2rem;
        }
      }
      align-items: center;
      .contact__whatsapp {
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
        -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
        -webkit-appearance: none;
        background-color: #25d366;
        // background-image: linear-gradient(
        //   to bottom right,
        //   #1ac559,
        //   #4ddb81,
        //   #41ea7f
        // );
        padding: 1.5rem 3rem;
        border-radius: 10rem;
        color: #fff;
        display: flex;
        align-items: center;
        font-size: 2rem;
        .whatsapp-icon {
          margin-right: 1rem;
          width: 4rem;
          fill: #fff;
        }
      }
    }
    @include bpm-xs {
      .contact__whatsapp {
        padding: 1.25rem 3rem;
        font-size: 1.8rem;
        .whatsapp-icon {
          width: 3rem;
        }
      }
    }
  }
}
.phone-validation-tooltip {
  position: absolute;
  top: -6rem;
  transform: translateY(-50%);
  right: 0;
  background-color: #fff;
  padding: 0.5rem 2rem;
  border-radius: 5rem;
  border: 1px solid #e47762;
  box-shadow: 0px 0px 8px rgba(75, 0, 188, 0.08);
  -webkit-box-shadow: 0px 0px 8px rgba(75, 0, 188, 0.08);
  -webkit-appearance: none;
  display: none;
  span {
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media screen and (max-width: 850px) {
    right: 0;
    top: -25%;
  }
  &.visible {
    display: block;
  }
}
