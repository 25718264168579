@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Display:wght@600&family=Poppins:wght@700&family=Purple+Purse&display=swap");
.spelling-bee-level-card {
  &-wrapper {
    margin-top: 40px;
    padding-left: 10%;
    padding-right: 10%;
    @include bpm-sm {
      padding-left: 16px;
      padding-right: 16px;
      margin-top: 24px;
    }
  }
  background: #c8e8bc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 992px;
  border-radius: 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-left: auto;
  margin-right: auto;
  @include bpm-md {
    flex-direction: column-reverse;
  }
  &_left {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;
    @include bpm-xs {
      gap: 14px;
      padding-top: 5px;
    }
    &-heading {
      color: #333;
      font-family: "Poppins" !important;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      @include bpm-xs {
        font-size: 24px;
        margin-top: 13px;
        text-align: left;
        padding-left: 15px;
      }
    }
    h1 {
      color: #666;
      font-family: Poppins;
      font-size: 23px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @include bpm-xs {
        font-size: 18px;
        text-align: left;
        padding-left: 15px;
      }
    }
    button {
      width: 349px;
      height: 42px;
      flex-shrink: 0;
    }
    p {
      cursor: pointer;
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      background: linear-gradient(142deg, #ec7f57 0%, #f2b65a 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &_right {
    img {
      @include bpm-xs {
        width: 100%;
      }
    }
  }
}

.spelling-bee-courses-container {
  margin-left: 90px;
  @include bpm-xs {
    margin-top: 20px;
    margin-left: 20px;
  }
}

.spelling-bee-courses--heading {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #333333;
  margin: 6rem 0rem 3rem 0rem;
}
