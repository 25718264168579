.enrolledCoursesHeader {
  //! The wrapper div encompasses the whole component
  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    @include bpm-sm {
      margin-left: 10px;
    }
  }
  //! The title div holds the title elements
  //! left side of the header component
  &--title {
    display: flex;

    @include bpm-xs {
      align-items: center;
    }
    text-align: left;
    h1 {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 45px;
      line-height: 54px;
      margin-right: 10px;
      color: #333333;
      @include bpm-xs {
        font-size: 28px;
        line-height: 34px;
      }
    }
    h2 {
      font-family: Lato;
      font-style: normal;
      font-weight: 300;
      font-size: 45px;
      line-height: 54px;
      margin-right: 10px;
      color: #666666;
      @include bpm-xs {
        font-size: 28px;
        line-height: 34px;
      }
    }
    //! the grouped div is a wrapper for isolating
    //! The h1 and h2 text tag from the numerical
    // &-grouped {
    //   @include bpm-xs {
    //     margin-right: 20px;
    //   }
    // }
  }
  //! The progress div is the right section of the component
  //! hidden on mobiles
  &--progress {
    display: none;
    @include bpm-xs {
      display: none;
    }
  }
}
