.friendInvitation {
  &__wrapper {
    // width: max-content;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 0 auto;
  }
  &--top {
    h2 {
      font-family: Lato;
      font-size: 32px;
      font-style: normal;
      font-weight: 900;
      line-height: 38px;
      letter-spacing: 0.20000000298023224px;
      margin-bottom: 10px;
      @include bpm-lg {
        font-size: 24px;
      }
    }
    h4 {
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.20000000298023224px;
      margin-bottom: 20px;
      @include bpm-lg {
        font-size: 14px;
      }
      //! Max width set on mobile phones
      @include bpm-xs {
        max-width: 300px;
      }
    }
  }
  &--bottom {
    display: flex;
    align-items: center;
    @include bpm-sm {
      flex-direction: column;
    }
    &-left {
      &--link {
        display: flex;
        align-items: center;
        padding: 15px 15px;
        background: #fff9f1;
        border-radius: 32px;
        margin-right: 10px;
        p {
          font-family: Lato;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */
          color: #838383;
        }
        img {
          margin-left: 15px;
          cursor: pointer;
        }
        span {
          font-family: Lato;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */
          color: #333333;
          margin-left: 15px;
          cursor: pointer;
          @include bpm-xs {
            display: none;
          }
        }
      }
    }
    &-right {
      display: flex;
      justify-content: space-around;
      padding: 0 20px;
      margin: 1.2rem 0 2.4rem 0;
      &.studioPage {
        @include bpm-md {
          justify-content: center;
          margin: 1.6rem 0;
          // display: flex;
          // flex-direction: column;
        }
      }
      img {
        max-width: 60px;
      }
    }
  }
}
