.spellingBee_levelAndPrizes {
  &-container {
    margin-top: 64px;
    @include bpm-sm {
      margin-top: 24px;
    }
    // margin-left:14%;
    // margin-right: 14%;
    padding-left: 12%;
    padding-right: 12%;
    @include bpm-sm {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  &_heading-text {
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #333333;
    @include bpm-sm {
      font-size: 24px;
      line-height: 29px;
    }
  }
  &_prizes {
    background: #333;
    border-radius: 16px;
    // box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    &_wrapper {
      border-radius: 16px;
      scroll-snap-align: center;
    }

    color: #ffffff;
    // height: 18rem;
    width: 305px;
    display: flex;
    flex-direction: column;
    img {
      width: 100%;
      height: 166px;
    }
    &--top {
      // border-radius: 16px 16px 0 0;
      padding: 4px;
      text-align: center;
      width: 100%;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      // background-color: #333333;
    }
    &--bottom {
      //border-radius: 0 0 16px 16px;
      padding: 8px 12px;
      text-align: center;
      width: 100%;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      // background-color: #333333;
    }
    img {
      position: relative;
    }

    &-container {
      margin-top: 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 80px;
      overflow: scroll;
      scroll-snap-type: x;
      &::-webkit-scrollbar {
        display: none;
        scrollbar-width: none;
      }
      @include bpm-sm {
        gap: 24px;
        margin-top: 24px;
      }
    }
  }
  &_remainingPrizes-strip {
    background: #333333;
    padding: 9px;
    font-weight: 700;
    font-size: 24px;
    color: white;
    margin-top: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    @include bpm-sm {
      padding: 9px 40px;
      font-size: 20px;
      gap: 0px;
    }
  }
}
