.gameRequirementsGrid {
  &__wrapper {
    padding: 2rem 2rem;
    display: none;

    @include bpm-xs {
      display: grid;
    }
  }
  &--header {
    margin: 20px 0px;
    h2 {
      font-family: Lato;
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height */

      color: #333333;
    }
  }
  &--content {
    display: inline-grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: center;
    padding: 10px;
    &-image {
      padding: 40px;
      &:nth-child(1) {
        border-right: 1px solid #999999;
        border-bottom: 1px solid #999999;
        margin-right: -1px;
       
      }
      &:nth-child(4) {
        border-top: 1px solid #999999;
        border-left: 1px solid #999999;
        margin-top: -1px;
      }
    }
  }
}
