.invitation-card {
  &__wrapper {
  }

  display: flex;
  align-items: center;
  &__left {
    img {
      width: 100%;
    }
  }
  &__right {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    flex-direction: column;
    padding: 1.5rem;

    &--header {
      font-size: 2rem;
      font-weight: 900;
    }
    &--content {
      font-size: 1.4rem;
      font-weight: 500;
      margin: 1.5rem 0;
      span {
        font-weight: 900;
      }
    }
    &--link {
      margin-bottom: 1.5rem;
      display: grid;
      cursor: pointer;
      grid-template-columns: 0.7fr 5fr 2fr;
      align-items: center;
      justify-self: center;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      padding: 0.5rem 1rem;
      border-radius: 32px;
      img {
        width: 2rem;
      }
      p {
        color: #4086ff;
        font-size: 1.3rem;
      }
      span {
        color: #fe9b00;
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        width: max-content;
        justify-self: end;
        img {
          margin-right: 0.5rem;
          width: 1.5rem;
        }
      }
    }
    @include bp-lg {
      &--header {
        font-size: 2.8rem;
      }
      &--content {
        font-size: 1.8rem;
        margin: 2rem 0;
      }
      &--link {
        padding: 1rem 2rem;
        margin-bottom: 2rem;
        img {
          width: 2.5rem;
        }
        p,
        span {
          font-size: 1.6rem;
          img {
            width: 2rem;
          }
        }
      }
    }
    .sharing__icons {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      button + button {
        margin-left: 1rem;
      }
    }
  }
  &.responsive {
    flex-direction: column;
    .invitation-card__right {
      padding: 1.5rem 0;
      align-items: center;
      &--header {
        text-align: center;
        font-size: 1.8rem;
      }
      &--content {
        text-align: center;
      }
      &--link {
        p {
          font-size: 1.4rem;
        }
      }
    }
  }
}
