.story-telling-rules {
  &__wrapper {
    padding: 0 7.2rem;
    margin-top: 1.6rem;
    @include bpm-xxs {
      padding: 0 3rem;
    }
  }
  display: flex;
  justify-content: space-between;
  @include bpm-xxs {
    flex-direction: column-reverse;
  }
  &__left {
    display: flex;
    flex-direction: column;
    &--p-heading {
      font-family: Lato;
      font-size: 48px;
      font-weight: 800;
      line-height: 58px;
      letter-spacing: 0px;
      text-align: left;
      color: #333333;
      @include bpm-xxs {
        font-family: Lato;
        font-size: 28px;
        font-weight: 800;
        line-height: 34px;
        letter-spacing: 0px;
        text-align: center;
        margin-top: 2.8rem;
      }
    }
    &--s-text {
      //
    }
    &__rules {
      &__wrapper {
        margin-top: 4.6rem;
        @include bpm-xxs {
          margin-top: 2.6rem;
        }
      }
      display: flex;
      align-items: center;
      margin-top: 1.6rem;
      &-p-text {
        font-family: Lato;
        font-size: 24px;
        font-weight: 700;
        // margin-left: 3.6rem;
        line-height: 29px;
        letter-spacing: 0px;
        text-align: left;
        color: #333333;
        @include bpm-xxs {
          font-size: 18px;
          margin-left: 5px;
        }
      }
      &-img {
        margin-right: 3rem;
      }
    }
  }
  &__right {
    display: flex;
    &-img {
      width: 65rem;
      object-fit: contain;
      @include bpm-xxs {
        width: 100%;
      }
    }
  }
  .primary-button {
    margin-top: 5.6rem;
  }
}
