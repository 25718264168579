.sent-email-alert_bar {
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px 15px 15px;
  background: #333333;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  position: fixed;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  top: 40px;
  left: 0;
  right: 0;
  &-left {
    width: 10%;
    @include bpm-sm {
      width: 20%;
    }
    @include bpm-xs {
      display: none;
    }
    &-img-container {
      background-color: #ffffff;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      margin: 0px 22px;
      padding: 2px 2px;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  &-right {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @include bpm-sm {
      width: 80%;
    }
    &_text-container {
      display: flex;
      flex-direction: column;
      &_primary-text {
        font-family: "Lato";
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 20px;
        color: #ffffff;
        margin-bottom: 5px;
      }
      &_secondary-text {
        font-family: "Lato";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #e9ba7d;
        margin-right: 1rem;
      }
    }
  }
}
