.spark-game-result {
  &__wrapper {
    padding: 0 7rem 7rem 7rem;
    @include bpm-md {
      padding: 0 3rem 7rem 3rem;
    }
    @include bpm-sm {
      padding: 0 1.5rem 7rem 1.5rem;
    }
  }
  &__pos-grammar {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
    margin-bottom: 3rem;
    @include bpm-md {
      grid-gap: 2rem;
      margin-bottom: 2rem;
    }
    @include bpm-sm {
      grid-gap: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      // margin-bottom: 2rem;
    }
  }
}
