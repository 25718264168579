.affiliate-invite-friend {
  &__wrapper {
    padding: 0;
    margin-top: 2rem;
  }
  display: flex;
  justify-content: center;
  &__card {
    &__wrapper {
      width: 90%;
      padding: 2.4rem 4rem;
      background-color: white;
      min-height: 20rem;
      border-radius: 1.6rem;
      @include bpm-md {
        padding: 2.4rem 3rem;
      }
      @include bpm-xs {
        padding: 2.4rem 2.25rem;
      }
    }
    display: flex;
    flex-direction: row;
    @include bpm-md {
      flex-direction: column-reverse;
      width: 100%;
    }
    &__left {
      display: flex;
      flex-direction: column;
      h3 {
        font-family: Lato;
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        @include bpm-md {
          font-size: 18px;
          text-align: initial;
          line-height: 21.6px;
          font-weight: 700;
          margin-top: 4rem;
        }
      }
      p {
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-top: 1.6rem;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        @include bpm-md {
          font-size: 14px;
          text-align: initial;
          margin-top: 0.8rem;
        }
      }
      &__share_url_container {
        display: flex;
        justify-content: flex-start;
        row-gap: 2.4rem;
        column-gap: 1.6rem;
        margin-top: 4rem;
        width: 90%;
        @include bpm-md {
          flex-direction: column;
          width: 100%;
        }
        &__whatsappButton {
          width: 40%;
          cursor: pointer;
          height: 4.6rem;
          display: flex;

          background-color: rgba(86, 208, 98, 1);
          align-items: center;
          justify-content: center;
          border-radius: 3.2rem;
          @include bpm-md {
            width: 100%;
          }
          &--p-text {
            font-family: Lato;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 700;
            line-height: 14px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(255, 255, 255, 1);
          }
          &--whatsapp-icon {
            width: 1.8rem;
            height: 1.6rem;
            margin-bottom: -4px;
            /* margin-left: -2px; */
            margin-right: 5px;
          }
        }
        &__copy-url-container {
          display: flex;
          justify-content: center;
          border-radius: 3.2rem;
          cursor: pointer;
          height: 5rem;
          width: 46%;
          @include bpm-md {
            width: 100%;
          }
          background-color: rgba(242, 246, 254, 1);
          &--url {
            width: 11.6rem;
            font-family: Lato;
            font-size: 1.3rem;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            color: rgba(64, 134, 255, 1);
            letter-spacing: 0em;
            text-align: left;
            margin: 1.8rem 2rem 1.4rem 1.4rem;
          }
          &__copy-link {
            width: 40%;
            height: 100%;
            z-index: 5;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f2b65a;
            margin-left: auto;
            border-radius: 0 3.2rem 3.2rem 0;
            h2 {
              font-family: Lato;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              color: rgba(255, 255, 255, 1);
              line-height: 17px;
              letter-spacing: 0em;
              text-align: left;
            }
          }
        }
      }
    }
    &__right {
      &--img {
        width: 46%;
        object-fit: contain;
        @include bpm-md {
          width: 100%;
        }
      }
    }
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  &__referral-code {
    width: 90%;
    text-align: left;
    font-size: 20px;
    margin-top: 2rem;
    font-weight: 500;
  }
}
