.registeration-steps {
  &__wrapper {
    padding: 0;
  }
  display: flex;
  justify-content: center;
  margin: 4rem 0;
  @include bpm-xs {
    margin: 0;
  }

  &--img {
    margin-top: 5rem;
    margin-bottom: 5rem;
    width: 80%;
    /* padding: 0 7rem; */
    object-fit: contain;
  }
}
