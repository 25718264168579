//! This component appears below the purchase order component on the dashboard homepage
.unlockDashboardCard {
  //! This div wraps around the whole component
  &__wrapper {
    display: flex;
    @include bpm-xs {
      flex-direction: column;
    }
    justify-content: space-between;
    margin-top: 30px;
    background: linear-gradient(
      111.29deg,
      rgba(255, 255, 255, 0.53) -1.83%,
      rgba(255, 255, 255, 0) 109.95%
    );
    filter: drop-shadow(37.6126px 45.1351px 75.2252px rgba(0, 0, 0, 0.05));
    border-radius: 16px;
    padding: 30px;
    border: 2px solid #ffffff;
  }
  //! the left div holds the header text , the input fields and the save button
  &--left {
    display: flex;
    flex-direction: column;
    &-head {
      text-align: left;
      h1 {
        font-family: Lato;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        color: #333333;
        margin-bottom: 13px;
      }
      p {
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #666666;
      }
    }
    //! the inputs div holds the input box along with the dropdown div for age
    &-inputs {
      margin-top: 20px;
      text-align: left;
      &.dropdown {
        margin-top: 0;
        @include bpm-xs {
          margin: 1.7rem auto 0;
        }
      }
      #inputName {
        width: 263px;
        height: 38px;
        border: none;
        box-shadow: 0px 0px 8.88598px rgba(75, 0, 188, 0.08);
        border-radius: 8px;
        padding: 15px;
        &:focus {
          outline: none;
        }
        &::placeholder {
          font-family: Lato;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 14px;
          color: #333333;
        }
      }
      //! The input age is the default visible div that is used to activate/deactivate the dropdown for age
      &-inputAge {
        margin-top: 13px;
        width: 263px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0px 0px 8.88598px rgba(75, 0, 188, 0.08);
        border-radius: 8px;
        padding: 15px;
        background-color: #ffffff;
        &.dropdown {
          margin-top: 0;
        }
        p {
          font-family: Lato;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 14px;
          color: #333333;
        }
        //! This is the dropdown div . it has multiple p tags 5-15 based on the age
        &-select {
          background-color: white;
          position: absolute;
          width: 150px;
          margin-top: 5px;
          border-radius: 8px;
          text-align: center;
          &.dropdown {
            min-width: 25rem;
            z-index: 10;
          }

          p {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            padding: 2px 0 1px;
            font-size: 13px;
            line-height: 14px;
            &.dropdown {
              line-height: 19px;
            }
            color: #333333;
            margin-bottom: 3px;

            &:hover {
              background-color: lightgray;
              cursor: pointer;
            }
          }
          &.hidden {
            display: none;
          }
        }
        .profile-arrow {
          cursor: pointer;
          transition: 0.5s;
          &.active {
            transition: 0.5s;
            transform: rotate(180deg);
          }
        }
      }
    }
    .primary-button {
      width: 71px;
      margin-top: 20px;
    }
  }
  //! The right div simply has the image on the right end of the card
  &--right {
    @include bpm-xs {
      margin-top: 30px;
    }
    &-image {
      width: 458px;
      @include bpm-xs {
        width: 288px;
      }
    }
  }
}
